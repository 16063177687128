import { Reducer } from 'redux';
import { NewsResponse, PromotionState, PromotionTypes } from '../../../types/news.type'
import { TagResponse } from '../../../types/tag.type';
import { initNews } from './news.reducer';


const initialState: PromotionState = {
  isLoadingPromotion: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: '',
      prev: '',
      count: 0,
      results: []
    },
  },
  promotion: initNews,
  deleteResult: {
    results: {
      isSuccess: false,
      newsId: null
    }
  },
  error: {}
}

export const promotion: Reducer<PromotionState> = (state = initialState, action) => {
  switch (action.type) {
    case PromotionTypes.FIND_ALL_PROMOTION:
      return {
        ...state,
        data: action.payload
      }
    case PromotionTypes.FIND_ONE_PROMOTION:
      return {
        ...state,
        promotion: action.payload
      }
    case PromotionTypes.CREATE_PROMOTION:
      return {
        ...state,
        promotion: action.payload
      }
    case PromotionTypes.UPDATE_PROMOTION:
      return {
        ...state,
        promotion: action.payload
      }
    case PromotionTypes.DELETE_PROMOTION:
      return {
        ...state,
        deleteResult: action.payload
      }
    case PromotionTypes.SET_LOADING_PROMOTION:
      return {
        ...state,
        isLoadingPromotion: action.payload
      }
    case PromotionTypes.SET_ERROR_PROMOTION:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default promotion;