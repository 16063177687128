import {
  ListItemText,
  List,
  ListItemIcon,
  ListItem,
  Collapse,
  ListSubheader,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import COLORS from "../styles/colors";

import Disburse from "./Icon/Disburse";
import {
  Award,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  CreditCard,
  Database,
  Grid,
  Home,
  Settings as IconSetting,
  Package,
  Pocket,
  Star,
  Tag,
  Users,
  Bookmark,
  Image,
  FileText,
  Layers,
  ShoppingBag,
  Truck,
  Briefcase,
  Menu,
} from "react-feather";
import Refund from "./Icon/Refund";
import Feedback from "./Icon/Feedback";
import ShipmentDiscount from "./Icon/ShipmentDiscount";
import Discount from "./Icon/Discount";
import { EPermission } from "../helpers/role";
import { useSelector } from "react-redux";
import useStock from "../hooks/useStock";
import { ApplicationState } from "../redux/store";
import { useMemo } from "react";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import Settings from "./Icon/Setting";
import VoucherGenerateIcon from "./Icon/VoucherGenerate";
import VoucherPartnerIcon from "./Icon/VoucherPartner";
import IconGear from "../assets/icon-gear.svg";
import IconBook from "../assets/icon-book.svg";
import Etalase from "./Icon/Etalase";
import IconSalesOrder from "../assets/icon-sales-order.svg";
import IconAgentRegion from "../assets/icon-agent-region.svg";
import IconSalesReturn from "../assets/icon-sales-return.svg";
import IconReplacement from "../assets/icon-replacement.svg";
import IconPickup from "../assets/icon-pickup.svg";
import IconAroTransaction from "../assets/icon-aro-transaction.svg";
import IconAroDisbursement from "../assets/icon-aro-disbursement.svg";
import IconPointPickup from "../assets/icon-pickup-point.svg";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
    textDecoration: "none",
  },
  icon: {
    color: theme.palette.common.white,
    textTransform: "none",
    textDecoration: "none",
  },
  text: {
    color: theme.palette.common.white,
    textTransform: "none",
    textDecoration: "none",
  },
  listItemSelected: {
    borderStyle: "solid",
    borderLeftWidth: 3,
    borderLeftColor: COLORS.greyLight,
    backgroundColor: "#373333",
  },
  listItem: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedSelected: {
    backgroundColor: "#444040",
    paddingLeft: theme.spacing(4),
  },
  listRootSidebar: {
    padding: 0,
  },
}));

const CustomListItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.75, 2, 0.75, 2),
      "&:focus, &:active": {
        borderStyle: "solid",
        borderLeftWidth: 3,
        borderLeftColor: COLORS.greyLight,
        backgroundColor: "#373333",
      },
      "&:hover": {
        backgroundColor: "#2f2b2b",
      },
    },
  })
)(ListItem);

const CustomListSubheader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0, 2, 0, 2),
      color: COLORS.grey,
      fontSize: "12px",
      lineHeight: "14px",
      paddingTop: theme.spacing(1),
      borderTop: "1px solid #545454",
      paddingBottom: theme.spacing(1),
    },
  })
)(ListSubheader);

const CustomListSubItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.8, 2, 0.8, 9),
      // paddingLeft: 75,
      "&:hover, &:focus, &:active": {
        backgroundColor: "#444040",
      },
    },
  })
)(ListItem);

const LinkItem = (props: {
  indicator?: boolean;
  title: string;
  to?: any;
  iconComponent: any;
  selected?: boolean;
  subitems?: Array<{
    title: string;
    to: any;
    selected?: boolean;
  }>;
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.selected);

  if (props.subitems && props.subitems.length > 0) {
    return (
      <>
        <CustomListItem
          button
          className={
            props.selected ? classes.listItemSelected : classes.listItem
          }
          onClick={() => setOpen(!open)}
        >
          <ListItemIcon className={classes.icon}>
            {props.iconComponent}
          </ListItemIcon>
          <ListItemText primary={props.title} />
          {props.indicator && (
            <div
              style={{
                width: 5,
                height: 5,
                borderRadius: 100,
                backgroundColor: COLORS.warn,
              }}
            />
          )}
          {open ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </CustomListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.subitems.map((item, key) => (
              <Link key={key} to={item.to} className={classes.link}>
                <CustomListSubItem
                  button
                  className={
                    item.selected ? classes.nestedSelected : classes.nested
                  }
                >
                  <ListItemText primary={item.title} />
                </CustomListSubItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <Link to={props.to} className={classes.link}>
        <CustomListItem
          button
          className={
            props.selected ? classes.listItemSelected : classes.listItem
          }
        >
          <ListItemIcon className={classes.icon}>
            {props.iconComponent}
          </ListItemIcon>
          <ListItemText className={classes.text} primary={props.title} />
          {props.indicator && (
            <div
              style={{
                width: 8,
                height: 8,
                borderRadius: 100,
                backgroundColor: COLORS.warn,
              }}
            />
          )}
        </CustomListItem>
      </Link>
    );
  }
};

type Props = {
  permissions: Array<string>;
};

export default function Sidebar(props: Props) {
  const location = useLocation();
  const classes = useStyles();

  const stockState = useSelector(
    (state: ApplicationState) => state.stockRetail
  );

  const _renderSidebarAgentTransaction = () => {
    if (
      !props.permissions.includes(EPermission.SALES_ORDER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.RETURN_ORDER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.REPLACEMENT_ORDER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.TRIP_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.PICKUP_FULL_ACCESS)
    ) {
      return false;
    } else {
      return (
        <List
          subheader={
            <CustomListSubheader disableSticky>
              Agent Transaction
            </CustomListSubheader>
          }
        >
          <LinkItem
            title="Order"
            to="/sales-order"
            iconComponent={<img src={IconSalesOrder} />}
            selected={
              location.pathname.includes("/sales-order") ||
              location.pathname.includes("/delivery-order")
            }
            subitems={[
              {
                title: "Sales Order",
                to: "/sales-order",
                selected: location.pathname.includes("/sales-order"),
              },
              {
                title: "Delivery Order",
                to: "/delivery-order",
                selected: location.pathname.includes("/delivery-order"),
              },
            ]}
          />

          <LinkItem
            title="Retur"
            to="/sales-return"
            iconComponent={<img src={IconSalesReturn} />}
            selected={
              location.pathname.includes("/sales-return") ||
              location.pathname.includes("/pickup-order")
            }
            subitems={[
              {
                title: "Sales Retur",
                to: "/sales-return",
                selected: location.pathname.includes("/sales-return"),
              },
              {
                title: "Pickup Order",
                to: "/pickup-order",
                selected: location.pathname.includes("/pickup-order"),
              },
            ]}
          />

          <LinkItem
            title="Replacement"
            to="/replacements"
            iconComponent={<img src={IconReplacement} />}
            selected={
              location.pathname.includes("/replacements") ||
              location.pathname.includes("/replacement-pickup") ||
              location.pathname.includes("/replacement-delivery")
            }
            subitems={[
              {
                title: "Replacement",
                to: "/replacements",
                selected: location.pathname.includes("/replacements"),
              },
              {
                title: "Replacement Pickup",
                to: "/replacement-pickup",
                selected: location.pathname.includes("/replacement-pickup"),
              },
              {
                title: "Replacement Delivery",
                to: "/replacement-delivery",
                selected: location.pathname.includes("/replacement-delivery"),
              },
            ]}
          />

          <LinkItem
            title="Trip"
            to="/trip"
            iconComponent={<Layers size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/trip")}
          />

          <LinkItem
            title="Pickup"
            to="/pickups"
            iconComponent={<img src={IconPickup} />}
            selected={location.pathname.includes("/pickups")}
          />
        </List>
      );
    }
  };

  const _renderSidebarAgentRegionTransaction = () => {
    if (
      !props.permissions.includes(EPermission.SALES_ORDER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.RETURN_ORDER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.REPLACEMENT_ORDER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.TRIP_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.PICKUP_FULL_ACCESS)
    ) {
      return false;
    } else {
      return (
        <List
          subheader={
            <CustomListSubheader disableSticky>
              Agent Region Transaction
            </CustomListSubheader>
          }
        >
          <LinkItem
            title="Transaction"
            to="/aro-transactions"
            iconComponent={<img src={IconAroTransaction} />}
            selected={location.pathname.includes("/aro-transactions")}
          />
          <LinkItem
            title="Disbursemet"
            to="/aro-disbursement"
            iconComponent={<img src={IconAroDisbursement} />}
            selected={location.pathname.includes("/aro-disbursement")}
          />
          <LinkItem
            title="Fullfillment"
            to="/aro-fullfillment"
            iconComponent={<Package size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/aro-fullfillment")}
          />
        </List>
      );
    }
  };

  const _renderSidebarDistributor = () => {
    if (
      !props.permissions.includes(EPermission.DISTRIBUTOR_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.DISTRIBUTOR_LIST)
    ) {
      return false;
    }
    return (
      <List
        subheader={
          <CustomListSubheader disableSticky>Agent Region</CustomListSubheader>
        }
      >
        {props.permissions.includes(EPermission.DISTRIBUTOR_FULL_ACCESS) ||
        props.permissions.includes(EPermission.DISTRIBUTOR_LIST) ? (
          <>
            <LinkItem
              // indicator={stockState.limitedStock && stockState.limitedStock > 0 ? true : false}
              title="Distributor"
              to="/distributor"
              iconComponent={<Briefcase size={22} color={COLORS.accent} />}
              selected={location.pathname.includes("/distributor")}
            />
            <LinkItem
              // indicator={stockState.limitedStock && stockState.limitedStock > 0 ? true : false}
              title="Agent Region"
              to="/agent-region"
              iconComponent={<img src={IconAgentRegion} />}
              selected={
                location.pathname.includes("/agent-region") ||
                location.pathname.includes("/create-agent-region")
              }
            />
          </>
        ) : (
          false
        )}
      </List>
    );
  };

  const _renderSidebarServiceCenter = () => {
    if (
      !props.permissions.includes(
        EPermission.SERVICE_CENTER_TRANSACTION_FULL_ACCESS
      ) &&
      !props.permissions.includes(EPermission.SERVICE_CENTER_TRANSACTION_LIST)
    ) {
      return false;
    }
    return (
      <List
        subheader={
          <CustomListSubheader disableSticky>
            Service Center
          </CustomListSubheader>
        }
      >
        {props.permissions.includes(
          EPermission.SERVICE_CENTER_TRANSACTION_FULL_ACCESS
        ) ||
        props.permissions.includes(
          EPermission.SERVICE_CENTER_TRANSACTION_LIST
        ) ? (
          <>
            <LinkItem
              // indicator={stockState.limitedStock && stockState.limitedStock > 0 ? true : false}
              title="Service Center"
              to="/service-center-list"
              iconComponent={<img src={IconGear} width={22} height={22} />}
              selected={location.pathname.includes("/service-center-list")}
            />
            <LinkItem
              // indicator={stockState.limitedStock && stockState.limitedStock > 0 ? true : false}
              title="Transaction"
              to="/service-center-transaction"
              iconComponent={<img src={IconBook} width={22} height={22} />}
              selected={location.pathname.includes(
                "/service-center-transaction"
              )}
            />
            <LinkItem
              title="Fulfillment Sparepart"
              to="/fulfillment-sparepart"
              iconComponent={<IconSetting size={22} />}
              selected={location.pathname.includes("/fulfillment-sparepart")}
            />
          </>
        ) : (
          false
        )}
      </List>
    );
  };

  const _renderSidebarRetailTransaction = () => {
    if (
      !props.permissions.includes(EPermission.TRANSACTION_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.TRANSACTION_LIST) &&
      !props.permissions.includes(EPermission.DISBURSMENT_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.DISBURSMENT_LIST) &&
      !props.permissions.includes(EPermission.REFUND_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.REFUND_LIST)
    ) {
      return false;
    }
    return (
      <List
        subheader={
          <CustomListSubheader disableSticky>
            Retail Transaction
          </CustomListSubheader>
        }
      >
        {props.permissions.includes(EPermission.TRANSACTION_FULL_ACCESS) ||
        props.permissions.includes(EPermission.TRANSACTION_LIST) ? (
          <LinkItem
            title="Transaction"
            to="/transaction"
            iconComponent={<Package size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/transaction")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(EPermission.DISBURSMENT_FULL_ACCESS) ||
        props.permissions.includes(EPermission.DISBURSMENT_LIST) ? (
          <LinkItem
            title="Disbursement"
            to="/disbursement"
            iconComponent={<Disburse size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/disbursement")}
          />
        ) : (
          false
        )}
        {props.permissions.includes(EPermission.REFUND_FULL_ACCESS) ||
        props.permissions.includes(EPermission.REFUND_LIST) ? (
          <LinkItem
            title="Refund"
            to="/refund"
            iconComponent={<Refund size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/refund")}
          />
        ) : (
          false
        )}
      </List>
    );
  };

  const _renderSidebarProduct = () => {
    if (
      !props.permissions.includes(EPermission.PRODUCT_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.PRODUCT_LIST) &&
      !props.permissions.includes(EPermission.FEATURED_PRODUCT_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.FEATURED_PRODUCT_LIST) &&
      !props.permissions.includes(EPermission.STOREFRONT_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.STOREFRONT_LIST)
    ) {
      return false;
    }
    return (
      <List
        subheader={
          <CustomListSubheader disableSticky>Product</CustomListSubheader>
        }
      >
        {props.permissions.includes(EPermission.CATEGORY_FULL_ACCESS) ||
        props.permissions.includes(EPermission.CATEGORY_LIST) ? (
          <LinkItem
            title="Category"
            to="/category"
            iconComponent={<Tag size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/category")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(EPermission.PRODUCT_FULL_ACCESS) ||
        props.permissions.includes(EPermission.PRODUCT_LIST) ? (
          <LinkItem
            title="Product"
            to={"/product"}
            iconComponent={<Grid size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/product")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(EPermission.FEATURED_PRODUCT_FULL_ACCESS) ||
        props.permissions.includes(EPermission.FEATURED_PRODUCT_LIST) ? (
          <LinkItem
            title="Featured Product"
            to="/featured-product"
            iconComponent={<Star size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/featured-product")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(EPermission.STOREFRONT_FULL_ACCESS) ||
        props.permissions.includes(EPermission.STOREFRONT_LIST) ? (
          <LinkItem
            title="Etalase"
            to="/etalase"
            iconComponent={<Etalase size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/etalase")}
          />
        ) : (
          false
        )}
      </List>
    );
  };

  const _renderSidebarStock = () => {
    if (
      !props.permissions.includes(EPermission.STOCK_RETAIL_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.STOCK_RETAIL_LIST)
    ) {
      return false;
    }
    return (
      <List
        subheader={
          <CustomListSubheader disableSticky>Stock</CustomListSubheader>
        }
      >
        {props.permissions.includes(EPermission.STOCK_RETAIL_FULL_ACCESS) ||
        props.permissions.includes(EPermission.STOCK_RETAIL_LIST) ? (
          <>
            <LinkItem
              indicator={
                stockState.limitedStock && stockState.limitedStock > 0
                  ? true
                  : false
              }
              title="Stock Retail"
              to="/stock-retail"
              iconComponent={<Database size={22} color={COLORS.accent} />}
              selected={location.pathname.includes("/stock-retail")}
            />
          </>
        ) : (
          false
        )}
      </List>
    );
  };

  const _renderSidebarUsers = () => {
    if (
      !props.permissions.includes(EPermission.USER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.USER_LIST) &&
      !props.permissions.includes(EPermission.CUSTOMER_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.CUSTOMER_LIST) &&
      !props.permissions.includes(
        EPermission.BANK_ACCOUNT_APPROVAL_FULL_ACCESS
      ) &&
      !props.permissions.includes(EPermission.BANK_ACCOUNT_APPROVAL_LIST) &&
      !props.permissions.includes(EPermission.FEEDBACK_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.FEEDBACK_LIST)
    ) {
      return false;
    }
    return (
      <List
        subheader={
          <CustomListSubheader disableSticky>Users</CustomListSubheader>
        }
      >
        {props.permissions.includes(EPermission.USER_FULL_ACCESS) ||
        props.permissions.includes(EPermission.USER_LIST) ? (
          <LinkItem
            title="User"
            to="/user"
            iconComponent={<Users size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/user")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(EPermission.CUSTOMER_FULL_ACCESS) ||
        props.permissions.includes(EPermission.CUSTOMER_LIST) ? (
          <LinkItem
            title="Customer"
            to="/customer"
            iconComponent={<Users size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/customer")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(
          EPermission.BANK_ACCOUNT_APPROVAL_FULL_ACCESS
        ) ||
        props.permissions.includes(EPermission.BANK_ACCOUNT_APPROVAL_LIST) ? (
          <LinkItem
            title="Bank Acct. Approval"
            to="/bank-account-approval"
            iconComponent={<CreditCard size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/bank-account-approval")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(
          EPermission.COLLECTION_APPROVAL_FULL_ACCESS
        ) ||
        props.permissions.includes(EPermission.COLLECTION_APPROVAL_LIST) ? (
          <LinkItem
            title="Oxone Collection"
            to="/oxone-collection-approval"
            iconComponent={<CheckCircle size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/oxone-collection-approval")}
          />
        ) : (
          false
        )}

        {props.permissions.includes(EPermission.FEEDBACK_FULL_ACCESS) ||
        props.permissions.includes(EPermission.FEEDBACK_LIST) ? (
          <LinkItem
            title="Feedback"
            to="/feedback"
            iconComponent={<Feedback size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/feedback")}
          />
        ) : (
          false
        )}
      </List>
    );
  };

  const _renderSidebarConfiguration = () => {
    if (
      !props.permissions.includes(EPermission.REFERRAL_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.SHIPMENT_DISCOUNT_FULL_ACCESS)
    ) {
      return false;
    } else {
      return (
        <List
          subheader={
            <CustomListSubheader disableSticky>
              Configuration
            </CustomListSubheader>
          }
        >
          {props.permissions.includes(EPermission.REFERRAL_FULL_ACCESS) && (
            <LinkItem
              title="Referral Commissions"
              to="/referral"
              iconComponent={<Discount size={22} color={COLORS.accent} />}
              selected={location.pathname.includes("/referral")}
            />
          )}
          {props.permissions.includes(
            EPermission.DISCOUNT_REFERRAL_FULL_ACCESS
          ) && (
            <LinkItem
              title="Discount Referral"
              to="/discount-referral"
              iconComponent={<Star size={22} color={COLORS.accent} />}
              selected={location.pathname.includes("/discount-referral")}
            />
          )}
          <LinkItem
            title="Shipment Discount"
            to="/shipment-discount"
            iconComponent={<ShipmentDiscount size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/shipment-discount")}
          />
          <LinkItem
            title="Extra Warranty"
            to="/extra-warranty"
            iconComponent={<Award size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/extra-warranty")}
          />

          <LinkItem
            title="Loyalty"
            to="/loyalty"
            iconComponent={<Pocket size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/loyalty")}
          />
          <LinkItem
            title="Version App"
            to="/version-management"
            iconComponent={<Settings size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/version-management")}
          />
          <LinkItem
            title="Oxone Pickup Point"
            to="/pickup-point"
            iconComponent={<img src={IconPointPickup} />}
            selected={location.pathname.includes("/pickup-point")}
          />
        </List>
      );
    }
  };

  const _renderSidebarContent = () => {
    if (
      !props.permissions.includes(EPermission.NEWS_EVENT_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.PROMOTION_FULL_ACCESS) &&
      !props.permissions.includes(EPermission.BANNER_FULL_ACCESS)
    ) {
      return false;
    } else {
      return (
        <List
          subheader={
            <CustomListSubheader disableSticky>Content</CustomListSubheader>
          }
        >
          <LinkItem
            title="Banner"
            to="/banner"
            iconComponent={<Image size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/banner")}
          />
          <LinkItem
            title="Promotions"
            to="/promotion"
            iconComponent={<Bookmark size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/promotion")}
          />
          <LinkItem
            title="News & Events"
            to="/news-event"
            iconComponent={<FileText size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/news-event")}
          />
        </List>
      );
    }
  };

  const _renderSidebarVoucher = () => {
    if (!props.permissions.includes(EPermission.VOUCHER_GENERATE_FULL_ACCESS)) {
      return false;
    } else {
      return (
        <List
          subheader={
            <CustomListSubheader disableSticky>Voucher</CustomListSubheader>
          }
        >
          <LinkItem
            title="Voucher Generate"
            to="/voucher-generate"
            iconComponent={
              <VoucherGenerateIcon size={22} color={COLORS.accent} />
            }
            selected={location.pathname.includes("/voucher-generate")}
          />
          <LinkItem
            title="Voucher Partner"
            to="/voucher-partner"
            iconComponent={
              <VoucherPartnerIcon size={22} color={COLORS.accent} />
            }
            selected={location.pathname.includes("/voucher-partner")}
          />
          <LinkItem
            title="Report Voucher Event"
            to="/report-voucher-event"
            iconComponent={<Menu size={22} color={COLORS.accent} />}
            selected={location.pathname.includes("/report-voucher-event")}
          />
        </List>
      );
    }
  };

  return (
    <div id="scrollbar-sidebar">
      <List className={classes.listRootSidebar}>
        {props.permissions.includes(EPermission.DASHBOARD_SUPERADMIN) ? (
          <LinkItem
            title="Dashboard"
            to="/dashboard-b2c"
            iconComponent={<Home size={22} />}
            selected={location.pathname.includes("/dashboard-b2c")}
          />
        ) : props.permissions.includes(EPermission.DASHBOARD_B2C) ? (
          <LinkItem
            title="Dashboard B2C"
            to="/dashboard-b2c"
            iconComponent={<Home size={22} />}
            selected={location.pathname.includes("/dashboard-b2c")}
          />
        ) : (
          false
        )}

        {_renderSidebarRetailTransaction()}

        {_renderSidebarAgentTransaction()}

        {_renderSidebarAgentRegionTransaction()}

        {_renderSidebarProduct()}

        {_renderSidebarStock()}

        {_renderSidebarVoucher()}

        {_renderSidebarDistributor()}

        {_renderSidebarServiceCenter()}

        {_renderSidebarUsers()}

        {_renderSidebarConfiguration()}

        {_renderSidebarContent()}
      </List>
    </div>
  );
}
