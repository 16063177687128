import React from "react";
import {
  Typography,
  Box,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Chip,
  Divider,
} from "@material-ui/core";
import COLORS from "../../styles/colors";
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import truncate from 'truncate-html'

export type Props = {
  news: any
}

export default function ContentPreview(props: Props) {
  const classes = useStyles();

  function convertDate(data: string) {
    return convertDateTimeToText(data, "dd mmm yyyy")
  }

  return (
    <Box
      alignItems="center"
      width="80%"
      style={{
        borderRadius: 10,
        borderColor: COLORS.greyLight,
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 16
      }}
    >
      <Box
        width="100%"
        style={{
          borderRadius: 10,
        }}
      >
        <img
          className={classes.cardMedia}
          src={props.news.imageLink ? props.news.imageLink : "/assets/default_500x500.png"}
        />
      </Box>
      <Box>
        <Typography variant="body1" color="textSecondary" style={{ marginTop: 4 }}>
          {convertDate(props.news.createdAt)}
        </Typography>
        <Typography variant="h5" style={{ lineHeight: 1.5, marginBottom: 8 }}>
          {props.news.title}
        </Typography>
        <Typography variant="body1" style={{ lineHeight: 1.5 }}>
          {truncate(props.news.content, {
            length: 180,
            stripTags: true
          })}
        </Typography>

        {
          props.news.tags && props.news.tags.length > 0 &&
          <Box display="flex" flexDirection="row" marginTop={2}>
            {props.news.tags.map((item: any, index: number) => (
              <Chip key={index} label={item.tagName} style={{ marginRight: 8 }} />
            ))}
          </Box>
        }

        <Divider style={{ marginTop: 16, marginBottom: 8 }} />

        <Typography variant="body1" style={{ lineHeight: 1.5, marginTop: 8 }}>
          Youtube Link
        </Typography>
        {props.news.youtubeLink ?
          <a href={props.news.youtubeLink} target="_blank" style={{ lineHeight: 1.5 }}>
            {props.news.youtubeLink}
          </a> :
          <Typography variant="body1" style={{ lineHeight: 1.5, fontStyle: "italic", color: COLORS.grey, marginTop: 4 }}>
            Not Set
          </Typography>
        }
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      width: "100%",
      height: '100%',
      borderRadius: 10,
      backgroundColor: COLORS.accent,
      objectFit: 'contain'
    },
    categoryItem: {
      padding: theme.spacing(0.5, 1, 0.5, 1),
      backgroundColor: COLORS.grey,
      borderRadius: 10,
      marginRight: 5,
    },
  })
);
