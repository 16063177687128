import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizedTable, {
  ITableHeader,
} from '../../../components/Table/CustomizedTable';
import useReferralCommission from '../../../hooks/useReferralCommission';
import { ApplicationState } from '../../../redux/store';

const headers: ITableHeader[] = [
  {
    title: 'Updated Date',
    align: 'left',
    sortable: true,
    column: 'updatedAt',
    type: 'date'
  },
  {
    title: 'Type',
    align: 'left',
    column: 'referralType',
    type: 'escape-string'
  },
  {
    title: 'Amount',
    align: 'left',
    column: 'amount',
  },
  {
    title: 'Updated By',
    align: 'left',
    type: 'nested',
    column: JSON.stringify(['adminMetadata', 'adminName']),
  },
  {
    title: 'Role',
    align: 'left',
    type: 'nested',
    column: JSON.stringify(['adminMetadata', 'role']),
  },
];

export default function ReferralDiscountHistory() {
  const { logReferralComission } = useSelector((state: ApplicationState) => state.masterDataReferral)

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '' });

  const { fetchLogReferralCommission } = useReferralCommission()

  useEffect(() => {
    fetchLogReferralCommission(query)
  }, [query])

  useEffect(() => {
    if (logReferralComission.payload.count > 0) {
      setTotalData(logReferralComission.payload.count)
      setTotalPage(Math.ceil(logReferralComission.payload.count / rowsPerPage))
    }
  }, [logReferralComission.payload])

  function changePagination(value: number) {
    setPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      }
    })
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      }
    })
  }

  return (
    <CustomizedTable
      data={logReferralComission.payload.results}
      headers={headers}
      totalData={totalData}
      page={page}
      totalPage={totalPage}
      rowsPerPage={rowsPerPage}
      onChange={(newPage) => changePagination(newPage)}
      onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
    />
  );
}
