import { Typography } from '@material-ui/core'
import React from 'react'
import { SummaryProductImages } from '../../../types/b2b/order/delivery-order.type'
import DetailGeneralInformation from '../DetailGeneralInformation'
import PODImagePreview from '../PODImagePreview'

interface Props {
  returnId: string
  roId: string | null
  status: string
  distributor: string
  returnDate: string | null
  approveDate: string | null
  returnNote: string
  productImages: SummaryProductImages[]
}

const GeneralInformationSR: React.FC<Props> = (props) => {
  const [showImages, setShowImages] = React.useState<boolean>(false)

  const handleOpenImage = () => {
    setShowImages(true)
  }

  const handleCloseDialog = () => {
    setShowImages(false)
  }

  return (
    <>
      <DetailGeneralInformation
        styleContainer={{ paddingBottom: 0 }}
        data={[
          {
            label: "Return ID",
            value: props.returnId,
          },
          {
            label: "RO ID",
            value: props.roId,
          },
          {
            label: "Return Status",
            value: props.status,
          },
          {
            label: "Distributor",
            value: props.distributor,
          },
          {
            label: "Return Date",
            value: props.returnDate,
          },
          {
            label: 'Approve Date',
            value: props.approveDate,
          },
        ]}
      />

      <div>
        <Typography color="textSecondary">Retur Note</Typography>
        <Typography>{props.returnNote}</Typography>
      </div>

      {props.productImages && (
        <>
          <Typography color="textSecondary" style={{ marginBottom: 10, marginTop: 10 }}>Foto Produk</Typography>
          <div style={{
            width: '100%',
            overflowX: 'auto',
            height: 100,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 20
          }}>
            {props.productImages.map((item, idx) => (
              <div
                key={idx}
                style={{
                  cursor: 'pointer',
                  marginRight: 10,
                  width: 100,
                  height: '100%',
                }}
              >
                <img
                  onClick={() => setShowImages(true)}
                  src={item.imageLink}
                  key={idx}
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: 'cover',
                    borderRadius: 10,
                  }}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {props.productImages && (
        <PODImagePreview
          handleCloseDialog={() => handleCloseDialog()}
          title="Foto Produk"
          isShow={showImages}
          podImages={props.productImages}
        />
      )}
    </>
  )
}

export default GeneralInformationSR