import { Reducer } from "redux";
import { CoinHistoryState, CoinTypes } from "../../types/coin.type";

const initialState: CoinHistoryState = {
  isLoadingCoinHistory: false,
  coinHistory: {
    code: "",
    message: "",
    payload: {
      prev: "",
      next: "",
      count: 0,
      results: [],
    },
  },

  error: {},
};

export const coin: Reducer<CoinHistoryState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CoinTypes.SET_LOADING_COIN_HISTORY:
      return {
        ...state,
        isLoadingCoinHistory: action.payload,
      };

    case CoinTypes.FIND_LOG_COIN_HISTORY:
      return {
        ...state,
        coinHistory: action.payload,
      };

    default:
      return state;
  }
};
