import { useDispatch } from 'react-redux'
import { apiFindAllTag } from '../api/content/tag'
import { setLoadingTag, findAllTag, setErrorTag } from '../redux/action/content/tag.action'

const useTag = () => {
  const dispatch = useDispatch()

  const fetchAllTags = async () => {
    try {
      dispatch(setLoadingTag(true))

      const result = await apiFindAllTag()
      dispatch(findAllTag(result.data))

      dispatch(setLoadingTag(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingTag(false))
      dispatch(setErrorTag(error.response))
    }

  }

  return {
    fetchAllTags
  }
}

export default useTag