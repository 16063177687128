/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useLocation, useParams } from "react-router-dom";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import ProductPreviewLong from "../../components/Product/ProductPreviewLong";
import { useDispatch, useSelector } from "react-redux";
import useCategory from "../../hooks/useCategory";
import { ApplicationState } from "../../redux/store";
import useFeaturedProduct from "../../hooks/useFeaturedProduct";
import useProduct from "../../hooks/useProduct";
import useDebounce from "../../hooks/useDebounce";
import { ProductResponse } from "../../types/product.type";
import { Autocomplete } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { initFeaturedProduct } from "../../redux/reducer/featured-product.reducer";
import { findOneFeaturedProduct } from "../../redux/action/featured-product";
import { enableUpdateParams } from "../../redux/action/config-app.action";

export default function FeaturedProductEdit() {
  const location = useLocation();
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [mode, setMode] = useState<"detail" | "edit" | "create">("detail");

  const [file, setFile] = useState<any>(null);
  const [status, setStatus] = useState(1);
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState("");
  const [query, setQuery] = useState({ limit: 50, offset: 0, search: "" });

  const [relatedProduct, setRelatedProduct] = useState<ProductResponse>();

  const featuredProductState = useSelector(
    (state: ApplicationState) => state.featuredProduct
  );
  const productState = useSelector((state: ApplicationState) => state.product);

  const { addFeaturedProduct, updateOneFeaturedProduct } = useFeaturedProduct();
  const { fetchAllProduct, fetchOneProduct } = useProduct();

  const debouncedTitle = useDebounce(search, 300);

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: value,
        };
      });
    } else {
      setQuery({ limit: 50, offset: 0, search: "" });
    }
  };

  useEffect(() => {
    fetchAllProduct(query);
  }, [query]);

  useEffect(() => {
    if (id && location.pathname.includes("edit")) {
      fetchOneProduct(id);
      // edit
      setStatus(featuredProductState.featuredProduct.featuredPublished ? 1 : 0);
      setMode("edit");
    } else if (id) {
      fetchOneProduct(id);
      // detail
      setStatus(featuredProductState.featuredProduct.featuredPublished ? 1 : 0);
      setMode("detail");
    } else {
      // create
      setMode("create");
      dispatch(findOneFeaturedProduct(initFeaturedProduct));
    }
  }, []);

  useEffect(() => {
    if (productState.product) {
      setRelatedProduct(productState.product);
    }
  }, [productState.product]);

  const cancel = () => {
    dispatch(enableUpdateParams(false));
    history.go(-1);
    setFile(null);
  };

  const submit = () => {
    const data = new FormData();

    data.append("productId", relatedProduct ? relatedProduct?.productId : "");
    data.append("published", String(status));
    if (file) {
      data.append("image", file);
    }

    if (id) {
      updateOneFeaturedProduct(data);
    } else {
      addFeaturedProduct(data);
    }
    setFile(null);
  };

  let title = "";
  let subtitle = "";

  if (mode === "create") {
    title = "Create Featured Product";
    subtitle = "Create new featured product";
  } else if (mode === "edit") {
    title = "Edit Featured Product";
    subtitle = "Edit featured product";
  } else if (mode === "detail") {
    title = "Detail Featured Product";
    subtitle = "Detail featured product";
  }

  return (
    <Layout title={title}>
      <Grid container spacing={3} alignItems="center" justify="space-between">
        <Grid item xs={12} sm container>
          <HeaderPage
            title={title}
            subtitle={subtitle}
            showBackButton
            onBack={() => {
              cancel();
            }}
          />
        </Grid>

        <Grid direction="row" item>
          {(mode === "edit" || mode === "create") && (
            <AppButton
              color="secondary"
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </AppButton>
          )}
          {(mode === "edit" || mode === "create") && (
            <AppButton
              color="primary"
              onClick={submit}
              disabled={featuredProductState.isLoadingFeaturedProduct}
            >
              Save
            </AppButton>
          )}
          {mode === "detail" && (
            <AppButton
              fullWidth
              onClick={() => setMode("edit")}
              color="primary"
            >
              Edit
            </AppButton>
          )}
        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={12}
            spacing={3}
            className={classes.containerForm}
          >
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <UploadImage
                image={
                  featuredProductState.featuredProduct.featuredImageUrl || ""
                }
                title="Featured Product Image"
                imageResolution="1200x600"
                onFileSelected={(e: any) => setFile(e)}
                editable={mode === "edit" || mode === "create"}
              />
            </Grid>
          </Grid>
          {mode === "edit" || mode === "create" ? (
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              spacing={3}
              className={classes.containerForm}
            >
              <Grid item xs={12} md={6} lg={6} className={classes.input}>
                <div className={classes.formStatus}>
                  <InputLabel
                    style={{ fontSize: 10, marginBottom: 6 }}
                    id="select-related-product"
                    required
                  >
                    Featured Product Related to
                  </InputLabel>
                  <Autocomplete
                    id="asynchronous-demo"
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    value={relatedProduct}
                    onChange={(event: any, newValue: any) =>
                      setRelatedProduct(newValue)
                    }
                    getOptionSelected={(option: ProductResponse, value: any) =>
                      option ? option.productName === value.productName : false
                    }
                    getOptionLabel={(option: ProductResponse) =>
                      option ? `(${option.sku}) ${option.productName}` : ""
                    }
                    options={productState.data.payload.results}
                    loading={productState.isLoadingProduct}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Pilih Produk..."
                        onChange={(e: any) => setSearch(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {productState.isLoadingProduct ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>

                {/* <FormControl className={classes.formStatus}>
                <InputLabel id="select-related-product" required>
                  Featured Product Related to
                </InputLabel>
                <Select
                  required
                  label="Status"
                  placeholder="Status"
                  labelId="select-related-product"
                  id="demo-customized-select"
                  value={relatedProduct}
                  onChange={(event: any) => {
                    setRelatedProduct(event.target.value);
                  }}
                >
                  <MenuItem value="1">Produk 1</MenuItem>
                  <MenuItem value="2">Produk 2</MenuItem>
                </Select>
              </FormControl> */}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl className={classes.formStatus}>
                  <InputLabel id="select-status" required>
                    Status
                  </InputLabel>
                  <Select
                    required
                    label="Status"
                    placeholder="Status"
                    labelId="select-status"
                    id="demo-customized-select"
                    value={status}
                    onChange={(event: any) => {
                      setStatus(event.target.value);
                    }}
                  >
                    <MenuItem value="1">Published</MenuItem>
                    <MenuItem value="0">Unpublished</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            false
          )}

          <Grid
            container
            item
            xs={12}
            md={12}
            lg={12}
            spacing={3}
            className={classes.containerForm}
          >
            <Grid item xs={12} md={12} lg={12}>
              {!relatedProduct ? (
                <Typography
                  variant="caption"
                  display="block"
                  style={{ color: COLORS.grey, marginBottom: 8, fontSize: 10 }}
                >
                  Preview Product
                </Typography>
              ) : (
                false
              )}

              {relatedProduct ? (
                <ProductPreviewLong product={relatedProduct} />
              ) : (
                <div
                  color={COLORS.grey}
                  style={{
                    display: "flex",
                    borderColor: COLORS.grey,
                    borderWidth: 1,
                    borderStyle: "solid",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 10,
                    height: 100,
                  }}
                >
                  <Typography variant="body1" style={{ color: COLORS.grey }}>
                    No product selected
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
  })
);
