import React from 'react'
import Layout from '../components/Layout/Layout'
import { makeStyles, Theme } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import AppButton from '../components/Form/AppButton';
import { useHistory } from 'react-router-dom';

const Unauthorized = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Layout title="Unauthorized User" unauthorized>
      <div className={classes.container}>
        <Cancel style={{ fontSize: 120 }} />
        <div className={classes.title}>Not Authorized</div>
        <div className={classes.subtitle}>Role anda tidak mempunyai akses ke halaman ini</div>
        <AppButton
          onClick={() => history.replace('dashboard-b2c')}
          style={{ marginTop: 30 }}>
          Kembali
        </AppButton>
      </div>
    </Layout>
  )
}

export default Unauthorized;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: '90vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginTop: 30
  },
  subtitle: {
    fontSize: 20,
    marginTop: 0
  }
}))