import { Reducer } from 'redux';
import { StockRetailTypes, StockState } from '../../types/stock-retail.type';

const initialState: StockState = {
  isLoadingStock: false,
  stock: {
    code: '',
    message: '',
    payload: {
      isSuccess: false
    }
  },
  data: {
    code: '',
    message: '',
    payload: {
      count: 0,
      next: '',
      prev: '',
      results: []
    }
  },
  error: {},
  limitedStock: 0
}

export const stockRetail: Reducer<StockState> = (state = initialState, action) => {
  switch (action.type) {
    case StockRetailTypes.CHANGE_STOCK:
      return {
        ...state,
        stock: action.payload
      }
    case StockRetailTypes.FIND_STOCK_HISTORY:
      return {
        ...state,
        data: action.payload
      }
    case StockRetailTypes.SET_LOADING_STOCK:
      return {
        ...state,
        isLoadingStock: action.payload
      }
    case StockRetailTypes.SET_LIMITED_STOCK:
      return {
        ...state,
        limitedStock: action.payload
      }
    case StockRetailTypes.SET_ERROR_STOCK:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}