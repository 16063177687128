import { NewsDeleteResponse, NewsResponse, NewsResponsePayload, NewsTypes } from '../../../types/news.type';

export function findAllNews(payload: NewsResponsePayload) {
  return {
    type: NewsTypes.FIND_ALL_NEWS,
    payload
  }
}

export function findOneNews(payload: NewsResponse) {
  return {
    type: NewsTypes.FIND_ONE_NEWS,
    payload
  }
}

export function createNews(payload: NewsResponse) {
  return {
    type: NewsTypes.CREATE_NEWS,
    payload
  }
}

export function updateNews(payload: NewsResponse) {
  return {
    type: NewsTypes.UPDATE_NEWS,
    payload
  }
}

export function deleteNews(payload: NewsDeleteResponse) {
  return {
    type: NewsTypes.DELETE_NEWS,
    payload
  }
}

export function setLoadingNews(payload: boolean) {
  return {
    type: NewsTypes.SET_LOADING_NEWS,
    payload
  }
}

export function setErrorNews(payload: any) {
  return {
    type: NewsTypes.SET_ERROR_NEWS,
    payload
  }
}
