import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  CardMedia,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { Home, Truck } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { apiCalculateEarnedCoin, apiFindHistoryTransaction } from "../../api/transaction";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import { LoadingScreen } from "../../components/LoadingScreen";
import StatusTransaction from "../../components/StatusTransaction";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import { useAuth } from "../../hooks/useAuth";
import usePaginateTable, { IPaginateTable } from "../../hooks/usePaginateTable";
import useTransaction from "../../hooks/useTransaction";
import { ApplicationState } from "../../redux/store";
import COLORS from "../../styles/colors";
import {
  initialStateTransaction,
  ProductItem,
  TransactionResponse,
} from "../../types/transaction.types";
import { EVoucherDiscountType } from "../../types/voucher.type";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-toastify";

let headers: ITableHeader[] = [
  {
    title: "Status Transaksi",
    align: "left",
    sortable: true,
    type: "status-transaction",
    column: "statusAfter",
  },
  {
    title: "Tanggal",
    align: "left",
    column: "updatedAt",
    type: "dd mmm yyyy hh:mm",
  },
  {
    title: "Aktor",
    align: "left",
    column: "actor",
  },
  {
    title: "Role",
    align: "left",
    column: "role",
  },
  {
    title: "Remark",
    align: "left",
    column: "note",
  },
];

type ITransactionDetail = {
  id: string;
  id_trx: string;
  name: string;
  email: string;
  phone: string;
  roles: string[];
  image: string;
  gudang: string;
  status: string | number;
  created_at: string;
  paid_at: string;
  rekening_bank: string;
  total_refund: number;
};

type TransactionDetailProps = {
  isAro?: boolean;
};

const TransactionDetail: React.FC<TransactionDetailProps> = ({
  isAro = false,
}) => {
  let { id }: any = useParams();

  const auth = useAuth();

  const classes = useStyles();

  const [isShowModalFormCancelOrder, setIsShowModalFormCancelOrder] =
    useState(false);
  const [isShowModalFormInputResi, setIsShowModalFormInputResi] =
    useState(false);
  const [dialogPickupPIN, setDialogPickupPIN] = useState(false);
  const [isShowModalFormRefund, setIsShowModalFormRefund] = useState(false);

  const [detailTransaction, setDetailTransaction] =
    useState<TransactionResponse>(initialStateTransaction);

  const [actionType, setActionType] = useState("");
  const [waybillNumber, setWaybillNumber] = useState("");
  const [page, setPage] = useState(1);
  const [remark, setRemark] = useState("");
  const [totalData] = useState(50);
  const [totalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pickupPIN, setPickupPIN] = useState("");
  const [earnedCoin, setEarnedCoin] = useState(0);
  const [finalTransactionPriceBefore, setFinalTransactionPriceBefore] = useState(0);
  const [finalTransactionPriceAfter, setFinalTransactionPriceAfter] = useState(0);
  const [note, setNote] = useState("");
  const [initialPickupDate, setInitialPickupDate] = useState<Date>(new Date());

  const dispatch = useDispatch();

  const { transaction, invoice, isLoadingTransaction, isSuccess } = useSelector(
    (state: ApplicationState) => state.transaction
  );

  console.log(transaction);

  const debouncedfinalTransactionPriceAfter = useDebounce(finalTransactionPriceAfter, 300);

  const {
    fetchOneTransaction,
    // fetchHistoryTransaction,
    processTransaction,
    rejectTransaction,
    updateWaybillTransaction,
    updatePickupTransaction,
  } = useTransaction();

  useEffect(() => {
    calculateEarnedCoin();
  }, [debouncedfinalTransactionPriceAfter])

  useEffect(() => {
    fetchOneTransaction(id);
    // fetchHistoryTransaction(id)
  }, []);

  useEffect(() => {
    if (isSuccess) {
      fetchOneTransaction(id);
      // fetchHistoryTransaction(id)
    }
  }, [isSuccess]);

  useEffect(() => {
    if (dialogPickupPIN) {
      setFinalTransactionPriceBefore(transaction.finalTransactionPrice);
      setFinalTransactionPriceAfter(transaction.finalTransactionPrice);
      setEarnedCoin(transaction.earnedCoin);
      setNote("");
      setInitialPickupDate(new Date());
    }
  }, [dialogPickupPIN]);


  useEffect(() => {
    if (isShowModalFormInputResi) {
      setFinalTransactionPriceBefore(transaction.finalTransactionPrice);
      setFinalTransactionPriceAfter(transaction.finalTransactionPrice);
      setEarnedCoin(transaction.earnedCoin);
      setNote("");
    }
  }, [isShowModalFormInputResi]);

  const validationForm = (type: 'pin' | 'resi') => {

    if (type === 'pin') {
      if (!initialPickupDate) {
        toast.error('Tanggal pengembalian tidak boleh kosong', {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false
        });
        return false
      }
    }

    if (!finalTransactionPriceAfter) {
      toast.error('Total transaksi baru tidak boleh kosong', {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false
      });
      return false
    }

    // if (Number(finalTransactionPriceAfter) !== Number(finalTransactionPriceBefore) && !note) {

    //   toast.error('Total transaksi lama dan baru tidak sama, harap masukan Note', {
    //     position: toast.POSITION.TOP_CENTER,
    //     pauseOnHover: false
    //   });
    //   return false
    // }

    return true;
  }

  const calculateEarnedCoin = async () => {
    try {

      const body = {
        finalTransactionPrice: finalTransactionPriceAfter,
        loyaltyCoin: transaction?.transactionMetadata?.buyer?.loyalty?.coin
      }

      const result = await apiCalculateEarnedCoin(body);

      if (result) {
        setEarnedCoin(result.data.payload);
      }

    } catch (error) {
      console.log(`error calculate earned coin: ${error}`)
    }
  }

  const [detail] = useState<ITransactionDetail>({
    id: "1",
    id_trx: "202102210405",
    name: "Adddaaa Aja",
    email: "email@gmail.com",
    phone: "1829182912",
    roles: ["Gudang"],
    image:
      "https://i.pinimg.com/564x/ff/5b/17/ff5b17255f48953efe05f23165384122.jpg",
    gudang: "Oxone Barat",
    status: 1,
    created_at: "10/02/2021",
    paid_at: "10/02/2021",
    rekening_bank: "BANK MANDIRI 102910201 a.n Bambang",
    total_refund: 101000,
  });

  const handleCloseModalFormCancelOrder = () => {
    //
    setIsShowModalFormCancelOrder(false);
  };
  const handleOpenModalFormCancelOrder = () => {
    setIsShowModalFormCancelOrder(true);
  };

  const handleCloseModalFormInputResi = () => {
    //
    setIsShowModalFormInputResi(false);
  };
  const handleOpenModalFormInputResi = () => {
    //
    setIsShowModalFormInputResi(true);
  };

  const handleDialogPickupPIN = (state: boolean) => {
    setDialogPickupPIN(state);
  };

  const handleCloseModalFormRefund = () => {
    //
    setIsShowModalFormRefund(false);
  };
  const handleOpenModalFormRefund = () => {
    //
    setIsShowModalFormRefund(true);
  };

  const handleProcessTransaction = () => {
    setActionType("process");
    processTransaction(transaction.transactionId);
  };

  const handlePickupDate = (date: any) => {
    setInitialPickupDate(date);
  };

  // history
  const {
    onChangePage: historyStatus_onChangePage,
    onChangeRowsPerPage: historyStatus_onChangeRowsPerPage,
    fetchList: historyStatus_fetchList,
    loading: historyStatus_loading,
    params: historyStatus_params,
    data: historyStatus_data,
    page: historyStatus_page,
    totalPage: historyStatus_totalPage,
    totalData: historyStatus_totalData,
    rowsPerPage: historyStatus_rowsPerPage,
  }: IPaginateTable & { data: any[] } = usePaginateTable(
    apiFindHistoryTransaction,
    {
      limit: 25,
      offset: 0,
    },
    25,
    { transactionId: id },
    false
  );

  const listHistoryStatus = historyStatus_data.map((item) => ({
    ...item,
    role: item.updatedByAdmin
      ? "Admin"
      : item.updatedByCustomer
        ? item.updatedByCustomer.customerType === "aro"
          ? "ARO"
          : "Customer"
        : item.updatedBy === "SYSTEM" || item.updatedBy === "MIDTRANS"
          ? item.updatedBy
          : item.statusAfter === "created"
            ? transaction &&
              transaction.transactionMetadata &&
              transaction.transactionMetadata.buyer &&
              transaction.transactionMetadata.buyer.name
              ? "Customer"
              : null
            : null,
    actor:
      item.updatedByAdmin && item.updatedByAdmin.name
        ? item.updatedByAdmin.name
        : item.updatedByCustomer && item.updatedByCustomer.customerName
          ? item.updatedByCustomer.customerName
          : item.updatedBy
            ? item.updatedBy
            : item.statusAfter === "created"
              ? transaction &&
                transaction.transactionMetadata &&
                transaction.transactionMetadata.buyer &&
                transaction.transactionMetadata.buyer.name
                ? transaction.transactionMetadata.buyer.name
                : null
              : null,
  }));
  // end history

  let title = isAro ? "Transaction Detail of ARO" : "Detail Transaction";
  let subtitle = "Detailed information about transaction";

  if (!isLoadingTransaction) {
    return (
      <Layout title={title}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <HeaderPage title={title} subtitle={subtitle} showBackButton />
        </Box>

        <Paper elevation={3} className={classes.paper}>
          <Typography variant="body1">
            No.Transaksi:{" "}
            <Typography
              component="span"
              variant="body1"
              style={{ fontWeight: "bold" }}
            >
              {invoice.invoiceId}
            </Typography>
          </Typography>
        </Paper>

        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={3} className={classes.containerForm}>
            <Grid item xs={3} className={classes.input}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginBottom: 5 }}
              >
                Informasi Pembelian
              </Typography>
              <Typography variant="body1">
                {transaction.transactionMetadata &&
                  transaction.transactionMetadata.buyer &&
                  transaction.transactionMetadata.buyer
                  ? transaction.transactionMetadata.buyer.name
                  : "---"}
              </Typography>
              <Typography variant="body1">
                Tanggal Pembelian:{" "}
                <Typography
                  component="span"
                  variant="body1"
                  style={{ fontWeight: "bold" }}
                >
                  {convertDateTimeToText(transaction.createdAt, "dd mmm yyyy")}
                </Typography>
              </Typography>
              {
                transaction?.transactionMetadata?.pickup?.pickupDate && (
                  <Typography variant="body1">
                    Tanggal Pengambilan:{" "}
                    <Typography
                      component="span"
                      variant="body1"
                      style={{ fontWeight: "bold" }}
                    >
                      {convertDateTimeToText(transaction.transactionMetadata.pickup.pickupDate, "dd mmm yyyy")}
                    </Typography>
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={2} className={classes.input}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginBottom: 5 }}
              >
                Status Pesanan
              </Typography>

              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              // alignItems="center"
              // alignContent="center"
              >
                <StatusTransaction status={transaction.transactionStatus} />
              </Box>
            </Grid>
            <Grid item xs={2} className={classes.input}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ marginBottom: 5 }}
              >
                Tanggal Dibayar
              </Typography>
              <Typography variant="body1">
                {convertDateTimeToText(transaction.updatedAt, "dd mmm yyyy")}
              </Typography>
            </Grid>
            {isAro ? (
              <></>
            ) : (
              <Grid item xs={5} className={classes.input}>
                {auth.user.role !== "packaging" && (
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{ marginBottom: 5 }}
                  >
                    Actions
                  </Typography>
                )}
                {auth.user.role !== "packaging" &&
                  transaction.transactionStatus === "paid" && (
                    <Box display="flex" flexDirection="row">
                      <AppButton
                        size="small"
                        color="primary"
                        onClick={() => handleProcessTransaction()}
                      >
                        Proses Pesanan
                      </AppButton>
                      <AppButton
                        size="small"
                        color="secondary"
                        onClick={() => handleOpenModalFormCancelOrder()}
                      >
                        Batalkan Pesanan
                      </AppButton>
                    </Box>
                  )}

                {auth.user.role !== "packaging" &&
                  transaction.transactionStatus === "process" &&
                  transaction.shipmentType == "DELIVERY" && (
                    <AppButton
                      size="small"
                      color="primary"
                      onClick={() => handleOpenModalFormInputResi()}
                    >
                      Input Resi
                    </AppButton>
                  )}

                {auth.user.role !== "packaging" &&
                  transaction.transactionStatus === "process" &&
                  transaction.shipmentType == "PICKUP" && (
                    <AppButton
                      size="small"
                      color="primary"
                      onClick={() => handleDialogPickupPIN(true)}
                    >
                      Input PIN
                    </AppButton>
                  )}

                {auth.user.role !== "packaging" &&
                  transaction.transactionStatus === "rejected" && (
                    <AppButton
                      size="small"
                      color="primary"
                      onClick={() => handleOpenModalFormRefund()}
                    >
                      Refund
                    </AppButton>
                  )}
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: 2 }}>
            <Grid item xs={12}>
              <Alert severity="warning" style={{ marginTop: 7, display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1">
                  Note:{" "}
                  <span style={{ fontWeight: transaction?.note ? "bold" : "normal" }}>
                    {transaction?.note ? transaction.note : (
                      <Typography
                        component="span"
                        variant="body1"
                        color="textSecondary"
                        style={{
                          fontStyle: "italic",
                        }}
                      >
                        Not Set
                      </Typography>
                    )}
                  </span>
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper
              elevation={3}
              className={classes.paper}
              style={{ height: "97%" }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Daftar Belanja ({transaction.items.length})
              </Typography>

              {transaction.items &&
                transaction.items.length > 0 &&
                transaction.items.map((item: ProductItem, index: number) => (
                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ marginTop: 10 }}
                    key={index}
                  >
                    <Box marginRight={1}>
                      <CardMedia
                        className={classes.cardMedia}
                        image={
                          item.productMetadata &&
                            item.productMetadata.images &&
                            item.productMetadata.images.length > 0
                            ? item.productMetadata.images[0]
                            : "/assets/default_500x500.png"
                        }
                      />
                    </Box>
                    <Box style={{ width: "100%" }}>
                      <Typography variant="body1">
                        {item.productMetadata.sku} {item.productMetadata.name}
                      </Typography>
                      <div>
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{
                            paddingLeft: 5,
                            paddingRight: 5,
                            paddingTop: 2,
                            paddingBottom: 2,
                            backgroundColor: COLORS.errorLight,
                            fontWeight: "bold",
                            color: COLORS.error,
                            borderRadius: 5,
                            marginRight: 5,
                          }}
                        >
                          {item.discountText}
                        </Typography>
                        <Typography
                          component="span"
                          color="textSecondary"
                          variant="subtitle1"
                          style={{
                            textDecoration: "line-through",
                            marginRight: 5,
                          }}
                        >
                          {convertNumToCurrency(item.productMetadata.price)}
                        </Typography>
                        <Typography
                          component="span"
                          style={{ fontWeight: "bold" }}
                        >
                          {convertNumToCurrency(item.finalTotalPrice)}
                        </Typography>
                      </div>
                      <Typography variant="subtitle1" color="textSecondary">
                        {item.qty} Barang ({item.productMetadata.weight / 1000}{" "}
                        Kg)
                      </Typography>

                      <Typography variant="subtitle1" style={{ marginTop: 5 }}>
                        Catatan Pembeli
                      </Typography>
                      {item.note ? (
                        <Typography
                          variant="subtitle1"
                          style={{
                            marginTop: 5,
                            padding: "5px 10px",
                            border: "thin solid #e5e5e5",
                            width: "100%",
                            borderRadius: 5,
                          }}
                        >
                          {item.note}
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          style={{
                            marginTop: 5,
                            padding: "5px 10px",
                            border: "thin solid #e5e5e5",
                            width: "100%",
                            fontStyle: "italic",
                            borderRadius: 5,
                          }}
                          color="textSecondary"
                        >
                          Not Set
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))}
            </Paper>
          </Grid>

          <Grid item xs={6}>
            {transaction.shipmentType == "DELIVERY" ? (
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Informasi Pengiriman
                </Typography>

                <Box style={{ marginTop: 10 }}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Home size={15} color={COLORS.grey500} />
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ marginLeft: 5 }}
                    >
                      Lokasi Pengiriman
                    </Typography>
                  </Box>

                  <Typography style={{ fontWeight: "bold" }}>
                    {transaction.shipping?.expeditionMetadata?.addressName}
                  </Typography>

                  <Typography>
                    {transaction.shipping?.expeditionMetadata?.receiverName} (
                    {transaction.shipping?.expeditionMetadata?.receiverPhone})
                  </Typography>
                  <Typography>
                    {transaction.shipping?.expeditionMetadata?.fullAddress}
                  </Typography>
                </Box>

                <Box style={{ marginTop: 10 }}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Truck size={15} color={COLORS.grey500} />
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ marginLeft: 5 }}
                    >
                      Kurir Pengiriman
                    </Typography>
                  </Box>

                  <Typography>
                    {transaction.shipping?.expeditionMetadata?.expeditionName}{" "}
                    {convertNumToCurrency(transaction.shipping?.shippingCost)}
                  </Typography>
                  <Typography color="textSecondary">
                    Estimasi tiba dalam ({transaction.shipping?.expeditionEtd}{" "}
                    hari kerja)
                  </Typography>
                </Box>
              </Paper>
            ) : (
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="body1" style={{ fontWeight: "bold" }}>
                  Informasi Pengiriman
                </Typography>

                <Box style={{ marginTop: 10 }}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Home size={15} color={COLORS.grey500} />
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ marginLeft: 5 }}
                    >
                      Lokasi Pickup
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ marginLeft: 5, fontWeight: "bold" }}
                    >
                      Alamat Pengambilan
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ marginLeft: 5 }}
                    >
                      {transaction.pickup?.metaAddress.name +
                        ", " +
                        transaction.pickup?.metaAddress.phone}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ marginLeft: 5 }}
                    >
                      {transaction.pickup?.metaAddress.address +
                        ", " +
                        transaction.pickup?.metaAddress.subDistrict +
                        ", " +
                        transaction.pickup?.metaAddress.city +
                        ", " +
                        transaction.pickup?.metaAddress.province}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            )}

            <Paper
              elevation={3}
              className={classes.paper}
              style={{ marginTop: 10 }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                Ringkasan Belanja
              </Typography>

              <Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography>
                    Total Harga ({transaction.items.length}) Barang
                  </Typography>
                  <Typography>
                    {convertNumToCurrency(transaction.transactionPrice)}
                  </Typography>
                </Box>

                {transaction.transactionMetadata.summaryPaymentTransaction ? (
                  <div>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography>Total Diskon Produk</Typography>
                      <Typography color="error">
                        -
                        {convertNumToCurrency(
                          transaction.transactionMetadata
                            .summaryPaymentTransaction.totalDiscountProduct
                        )}
                      </Typography>
                    </Box>

                    {transaction.transactionMetadata.summaryPaymentTransaction
                      .totalDiscountVoucher ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Typography>
                          Total Diskon Voucher{" "}
                          {transaction.voucherMetadata.discountType ===
                            EVoucherDiscountType.percentage
                            ? "(" +
                            transaction.voucherMetadata.discountValue +
                            "%)"
                            : false}
                        </Typography>
                        <Typography color="error">
                          -
                          {convertNumToCurrency(
                            transaction.transactionMetadata
                              .summaryPaymentTransaction.totalDiscountVoucher
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      false
                    )}

                    {transaction.transactionMetadata.summaryPaymentTransaction
                      .totalDiscountReferral ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Typography>
                          Total Diskon Referral{" "}
                          {"(" +
                            transaction.transactionMetadata
                              .summaryPaymentTransaction.referralValue +
                            ")"}
                        </Typography>
                        <Typography color="error">
                          -
                          {convertNumToCurrency(
                            transaction.transactionMetadata
                              .summaryPaymentTransaction.totalDiscountReferral
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      false
                    )}
                  </div>
                ) : (
                  false
                )}

                {transaction.coinUsed ? (
                  transaction.coinUsed > 0 ? (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography>Koin Digunakan</Typography>
                      <Typography color="error">
                        -{convertNumToCurrency(transaction.coinUsed)}
                      </Typography>
                    </Box>
                  ) : (
                    false
                  )
                ) : (
                  false
                )}

                {/* {transaction.transactionMetadata.summaryPaymentTransaction.totalDiscountVoucher ? (
                  <div
                    style={[
                      globalStyles.LAYOUT.flexRowSpaceBetween,
                      globalStyles.LAYOUT.paddingVerticalSmall,
                    ]}>
                    <div style={[globalStyles.LAYOUT.flexRow]}>
                      <AppText>
                        Diskon voucher
                  </AppText>
                    </div>
                    <AppText color={globalStyles.COLOR.error}>
                      -{convertNumToCurrency(detail.summaryPaymentTransaction.totalDiscountVoucher)}
                    </AppText>
                  </div>
                ) : false} */}

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography>
                    Ongkos Kirim ({transaction.shipping?.weight / 1000 || 0} kg)
                  </Typography>
                  <Typography>
                    {convertNumToCurrency(transaction.shipping?.shippingCost)}
                  </Typography>
                </Box>

                {transaction.shipping?.shippingCost > 0 ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Diskon Ongkos Kirim</Typography>
                    <Typography color="error">
                      -
                      {convertNumToCurrency(
                        transaction.shipping?.shippingDiscount
                      )}
                    </Typography>
                  </Box>
                ) : (
                  false
                )}

                <hr />

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography>Total Pembayaran</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {convertNumToCurrency(transaction.finalTransactionPrice)}
                  </Typography>
                </Box>
                {
                  transaction?.transactionMetadata?.finalTransactionPriceAfter && transaction?.transactionMetadata?.finalTransactionPriceAfter !== transaction?.transactionMetadata?.finalTransactionPriceBefore && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography>Total Pembayaran (Revisi)</Typography>
                      <Typography style={{ fontWeight: "bold" }}>
                        {convertNumToCurrency(transaction?.transactionMetadata?.finalTransactionPriceAfter)}
                      </Typography>
                    </Box>
                  )
                }

              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Riwayat Status Pesanan
              </Typography>

              <Box>
                <CustomizedTable
                  data={listHistoryStatus}
                  headers={headers}
                  totalData={historyStatus_totalData}
                  page={historyStatus_page}
                  totalPage={historyStatus_totalPage}
                  rowsPerPage={historyStatus_rowsPerPage}
                  onChange={historyStatus_onChangePage}
                  onRowsPerPageChange={historyStatus_onChangeRowsPerPage}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {!isAro ? (
          <>
            <Modal
              open={isShowModalFormCancelOrder}
              onClose={handleCloseModalFormCancelOrder}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.containerModal}>
                <div className={classes.contentModal}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Pembatalan Pesanan</Typography>

                    <div onClick={() => handleCloseModalFormCancelOrder()}>
                      <Close />
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>No.Transaksi</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography style={{ fontWeight: "bold" }}>
                        {invoice.invoiceId || "---"}
                      </Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Pembeli</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography>
                        {transaction.transactionMetadata &&
                          transaction.transactionMetadata.buyer
                          ? transaction.transactionMetadata.buyer.name
                          : "---"}
                      </Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Alasan Pembatalan</Typography>
                    </Box>
                    <Box width="70%">
                      <FormInput
                        onChange={(value: any) => setRemark(value)}
                        type="address"
                        fullWidth
                        required
                        placeholder="Alasan Pembatalan"
                      />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-end">
                    <Box>
                      <AppButton
                        color="secondary"
                        onClick={handleCloseModalFormCancelOrder}
                      >
                        Cancel
                      </AppButton>
                    </Box>
                    <Box>
                      <AppButton
                        onClick={() => {
                          rejectTransaction({
                            transactionId: transaction.transactionId,
                            note: remark,
                          });
                          setRemark("");
                          handleCloseModalFormCancelOrder();
                        }}
                      >
                        Batalkan
                      </AppButton>
                    </Box>
                  </Box>
                </div>
              </div>
            </Modal>

            {/* <Modal
              open={isShowModalFormInputResi}
              onClose={handleCloseModalFormInputResi}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.containerModal}>
                <div className={classes.contentModal}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Input Resi</Typography>

                    <div onClick={() => handleCloseModalFormInputResi()}>
                      <Close />
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>No.Transaksi</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography style={{ fontWeight: "bold" }}>
                        {invoice.invoiceId}
                      </Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Pembeli</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography>{detail.name}</Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Lokasi Pengiriman</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography style={{ fontWeight: "bold" }}>
                        {transaction.shipping?.expeditionMetadata &&
                          transaction.shipping?.expeditionMetadata?.addressName}
                      </Typography>
                      <Typography>
                        {transaction.shipping?.expeditionMetadata?.receiverName}{" "}
                        (
                        {
                          transaction.shipping?.expeditionMetadata
                            ?.receiverPhone
                        }
                        )
                      </Typography>
                      <Typography>
                        {transaction.shipping?.expeditionMetadata?.fullAddress}
                      </Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Nomor Resi</Typography>
                    </Box>
                    <Box width="70%">
                      <FormInput
                        onChange={(value: string) => setWaybillNumber(value)}
                        type="text"
                        fullWidth
                        required
                        placeholder="Tulis Nomor Resi"
                      />
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box display="flex" justifyContent="flex-end">
                    <Box width="30%">Coin yang didapat</Box>
                    <Box width="70%">
                      <FormInput
                        type="number"
                        fullWidth
                        value={earnedCoin}
                        required
                        placeholder="Tulis coin"
                        onChange={(value: string) => setEarnedCoin(value)}
                      />

                      <Alert severity="warning">Coin dapat diubah namun jika terdapat kesalahan pada sistem, jika tidak ada kesalahan sistem jangan diubah
                        <div>
                          <Link
                            style={{
                              textDecoration: "none",
                            }}
                            to={`/customer/${transaction.transactionMetadata.buyer.customerId}?historyCoin=true`}
                            target="_blank"
                          >
                            Lihat History Coin
                          </Link>
                        </div>
                      </Alert>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box display="flex" justifyContent="flex-end">
                    <Box width="30%">Note</Box>
                    <Box width="70%">
                      <FormInput
                        type="text"
                        fullWidth
                        required
                        placeholder="Tulis Note"
                        onChange={(value: string) => setNote(value)}
                      />
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-end">
                    <Box>
                      <AppButton
                        color="secondary"
                        onClick={handleCloseModalFormInputResi}
                      >
                        Cancel
                      </AppButton>
                    </Box>
                    <Box>
                      <AppButton
                        disabled={!waybillNumber}
                        onClick={() => {
                          updateWaybillTransaction({
                            waybill: waybillNumber,
                            invoiceId: invoice.invoiceId,
                            earnedCoin: earnedCoin,
                            note: note
                          });
                          handleCloseModalFormInputResi();
                          setWaybillNumber("");
                        }}
                      >
                        Save
                      </AppButton>
                    </Box>
                  </Box>
                </div>
              </div>
            </Modal> */}

            <Dialog
              onClose={() => handleCloseModalFormInputResi()}
              aria-labelledby="customized-dialog-title"
              open={isShowModalFormInputResi}
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle id="customized-dialog-title">Input Resi</DialogTitle>
              <DialogContent dividers >
                <div className={classes.rejectContent}>
                  <div>Nomor Transaksi</div>
                  <div>
                    {invoice.invoiceId}
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Pembeli</div>
                  <div>
                    {detail.name}
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Lokasi Pengiriman</div>
                  <div>
                    <div>
                      {
                        transaction?.shipping?.expeditionMetadata
                          ?.receiverName
                      }{" "}
                      (
                      {
                        transaction?.shipping?.expeditionMetadata
                          ?.receiverPhone
                      }
                      )
                    </div>
                    <div>
                      {
                        transaction?.shipping?.expeditionMetadata
                          ?.fullAddress
                      }
                    </div>
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Input Resi</div>
                  <FormInput
                    // type="address"
                    fullWidth
                    required
                    placeholder="Nomor Resi"
                    onChange={(value: string) => setWaybillNumber(value)}
                  />
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Total transaksi lama</div>
                  <div>
                    {convertNumToCurrency(finalTransactionPriceBefore)}
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Total transaksi baru</div>
                  <div>
                    <FormInput
                      type="currency"
                      fullWidth
                      value={finalTransactionPriceAfter}
                      required
                      placeholder="Tulis transaksi baru"
                      onChange={(value: number) => {
                        const newValue = Number(String(value).replace(/\D/g, ''));
                        setFinalTransactionPriceAfter(newValue)
                      }}
                    />
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Coin yang didapat</div>
                  <div>
                    {convertNumToCurrency(earnedCoin)}

                    <Alert severity="warning">
                      Rumus hitung coin: ({finalTransactionPriceAfter || 0} / 100000) * {transaction?.transactionMetadata?.buyer?.loyalty?.coin}

                      <div>
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                          to={`/customer/${transaction.transactionMetadata.buyer.customerId}?historyCoin=true`}
                          target="_blank"
                        >
                          Lihat History Coin
                        </Link>
                      </div>
                    </Alert>

                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Note</div>
                  <FormInput
                    type="address"
                    fullWidth
                    required
                    placeholder="Tulis Note"
                    onChange={(value: string) => setNote(value)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <AppButton
                  color="secondary"
                  onClick={() => {
                    setWaybillNumber("");
                    handleCloseModalFormInputResi();
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  color="primary"
                  disabled={!waybillNumber}
                  onClick={() => {

                    const isValid = validationForm('resi');
                    console.log("Validation result:", isValid);

                    if (!isValid) {
                      console.log("Form is not valid. Skipping update.");
                      return;
                    }

                    updateWaybillTransaction({
                      waybill: waybillNumber,
                      invoiceId: invoice.invoiceId,
                      earnedCoin: earnedCoin,
                      note: note,
                      finalTransactionPriceBefore: finalTransactionPriceBefore,
                      finalTransactionPriceAfter: finalTransactionPriceAfter
                    });
                    handleCloseModalFormInputResi();
                    setWaybillNumber("");
                  }}
                >
                  Input Resi
                </AppButton>
              </DialogActions>
            </Dialog>

            <Dialog
              onClose={() => handleDialogPickupPIN(false)}
              aria-labelledby="customized-dialog-title"
              open={dialogPickupPIN}
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle id="customized-dialog-title">Input PIN</DialogTitle>
              <DialogContent dividers>
                <div className={classes.rejectContent}>
                  <div>Nomor Transaksi</div>
                  <div>{invoice.invoiceId}</div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Pembeli</div>
                  <div>
                    {transaction.transactionMetadata &&
                      transaction.transactionMetadata.buyer
                      ? transaction.transactionMetadata.buyer.name
                      : "---"}
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Lokasi Pickup</div>
                  <div>
                    <div>
                      {transaction?.pickup?.metaAddress?.address +
                        ", " +
                        transaction.pickup?.metaAddress.subDistrict +
                        ", " +
                        transaction.pickup?.metaAddress.city +
                        ", " +
                        transaction.pickup?.metaAddress.province}
                    </div>
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>PIN Pickup</div>
                  <FormInput
                    // type="address"
                    fullWidth
                    required
                    placeholder="Tulis PIN"
                    onChange={(value: string) => setPickupPIN(value)}
                  />
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Tanggal Pengambilan</div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div>
                      <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        format="dd MM yyyy"
                        placeholder="DD MM YYYY"
                        margin="normal"
                        fullWidth={true}
                        id="date-picker-inline"
                        value={initialPickupDate}
                        onChange={handlePickupDate}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        style={{ marginTop: 0 }}
                        minDate={new Date()}
                      />
                    </div>
                  </MuiPickersUtilsProvider>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Total transaksi lama</div>
                  <div>
                    {convertNumToCurrency(finalTransactionPriceBefore)}
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Total transaksi baru</div>
                  <div>
                    <FormInput
                      type="currency"
                      fullWidth
                      value={finalTransactionPriceAfter}
                      required
                      placeholder="Tulis transaksi baru"
                      onChange={(value: number) => {
                        const newValue = Number(String(value).replace(/\D/g, ''));
                        setFinalTransactionPriceAfter(newValue)
                      }}
                    />
                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Coin yang didapat</div>
                  <div>
                    {convertNumToCurrency(earnedCoin)}

                    <Alert severity="warning">
                      Rumus hitung coin: ({finalTransactionPriceAfter || 0} / 100000) * {transaction?.transactionMetadata?.buyer?.loyalty?.coin}

                      <div>
                        <Link
                          style={{
                            textDecoration: "none",
                          }}
                          to={`/customer/${transaction.transactionMetadata.buyer.customerId}?historyCoin=true`}
                          target="_blank"
                        >
                          Lihat History Coin
                        </Link>
                      </div>
                    </Alert>

                  </div>
                </div>

                <Divider />

                <div className={classes.rejectContent}>
                  <div>Note</div>
                  <FormInput
                    type="address"
                    fullWidth
                    required
                    placeholder="Tulis Note"
                    onChange={(value: string) => setNote(value)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <AppButton
                  color="secondary"
                  onClick={() => {
                    setPickupPIN("");
                    handleDialogPickupPIN(false);
                  }}
                >
                  Cancel
                </AppButton>
                <AppButton
                  color="primary"
                  disabled={!pickupPIN}
                  onClick={() => {

                    const isValid = validationForm('pin');
                    console.log("Validation result:", isValid);

                    if (!isValid) {
                      console.log("Form is not valid. Skipping update.");
                      return;
                    }

                    updatePickupTransaction({
                      pickupPIN: pickupPIN,
                      pickupDate: initialPickupDate,
                      invoiceId: invoice.invoiceId,
                      earnedCoin: earnedCoin,
                      note: note,
                      finalTransactionPriceBefore: finalTransactionPriceBefore,
                      finalTransactionPriceAfter: finalTransactionPriceAfter
                    });
                    handleDialogPickupPIN(false);
                    setWaybillNumber("");
                  }}
                >
                  Input PIN
                </AppButton>
              </DialogActions>
            </Dialog>

            <Modal
              open={isShowModalFormRefund}
              onClose={handleCloseModalFormRefund}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={classes.containerModal}>
                <div className={classes.contentModal}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Refund Biaya</Typography>

                    <div onClick={() => handleCloseModalFormRefund()}>
                      <Close />
                    </div>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>No.Transaksi</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography style={{ fontWeight: "bold" }}>
                        {detail.id_trx}
                      </Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Pembeli</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography>{detail.name}</Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography>Rekening Bank</Typography>
                    </Box>
                    <Box width="70%">
                      <Typography>{detail.rekening_bank}</Typography>
                    </Box>
                  </Box>

                  <hr color={COLORS.greyLight} />

                  <Box
                    display="flex"
                    flexDirection="row"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <Box width="30%">
                      <Typography style={{ fontWeight: "bold" }}>
                        Total Refund
                      </Typography>
                    </Box>
                    <Box width="70%">
                      <Typography style={{ fontWeight: "bold" }}>
                        {convertNumToCurrency(detail.total_refund)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 15,
                      paddingRight: 15,
                      backgroundColor: COLORS.grey200,
                      marginLeft: -15,
                      marginRight: -15,
                      marginBottom: 15,
                    }}
                  >
                    <Box>
                      <Typography>
                        Pastikan data benar. Transaksi yang sudah di refund
                        tidak dapat dibatalkan.
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="flex-end">
                    <Box>
                      <AppButton
                        color="secondary"
                        onClick={handleCloseModalFormRefund}
                      >
                        Cancel
                      </AppButton>
                    </Box>
                    <Box>
                      <AppButton>Save</AppButton>
                    </Box>
                  </Box>
                </div>
              </div>
            </Modal>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={isSuccess ? true : false}
              autoHideDuration={6000}
            >
              <Alert severity="success" variant="filled">
                Berhasil{" "}
                {actionType === "process"
                  ? "memproses pesanan"
                  : actionType === "reject"
                    ? "membatalkan pesanan"
                    : actionType === "waybill"
                      ? "memasukkan nomor resi"
                      : ""}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <></>
        )}
      </Layout>
    );
  }
  return <LoadingScreen />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "700px",
      alihandleCloseModalFormCreategnContent: "center",
      overflowY: 'scroll'
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
    rejectContent: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "140px 1fr",
      marginTop: 10,
      marginBottom: 10,
    },
  })
);

export default TransactionDetail;
