import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  CardMedia,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  Theme,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import AppButton from "../../components/Form/AppButton";
import FilterDate from "../../components/Form/FilterDate";
import FormInput from "../../components/Form/FormInput";
import Search from "../../components/Form/Search";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import StatusTransaction from "../../components/StatusTransaction";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import AppTabs from "../../components/Tabs/AppTabs";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import useTransaction from "../../hooks/useTransaction";
import {
  enableUpdateParams,
  setLatestParams,
} from "../../redux/action/config-app.action";
import {
  findAllTransaction,
  setOneTransaction,
} from "../../redux/action/transaction.action";
import { ApplicationState } from "../../redux/store";
import COLORS from "../../styles/colors";
import {
  TransactionListResponse,
  TransactionLog,
} from "../../types/transaction.types";
import { validateMaxText } from "../../helpers/functions/text";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { apiCalculateEarnedCoin } from "../../api/transaction";
import { toast } from "react-toastify";

let headersPaid: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Actions",
    align: "center",
    column: "actionsPaid",
  },
];

let headersProcess: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Actions",
    align: "center",
    column: "actionsWaybill",
  },
];

let headersSent: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Delivery Date",
    align: "left",
    column: "delivery_date",
  },
];

let headersCanceled: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Status",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Cancel Date",
    align: "left",
    sortable: true,
    column: "updatedAt",
    type: "date",
  },
  {
    title: "Remark",
    align: "left",
    column: "note",
  },
];

let headersAll: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  {
    title: "Actions",
    align: "center",
    column: "actionsAll",
    alignContent: "center",
  },
];

let headersPackaging: ITableHeader[] = [
  {
    title: "Name",
    align: "left",
    sortable: true,
    column: "customName",
  },
  {
    title: "Order",
    align: "left",
    column: "order",
  },
  {
    title: "Process",
    align: "left",
    column: "process",
    width: 130,
  },
  {
    title: "Shipment",
    align: "left",
    column: "shipment",
  },
  // {
  //   title: 'Actions',
  //   align: 'center',
  //   column: 'actionsAllPackaging',
  //   alignContent: 'center'
  // },
];

let data = [];

const tabs = [
  "Sudah Dibayar",
  "Diproses",
  "Barang Dikirim / Dipickup",
  "Dibatalkan",
  "Semua Transaksi",
];

export default function Transaction() {
  const classes = useStyles();
  const auth = useAuth();
  const date = new Date();
  const defaultParamsStartAt = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  const defaultParamsEndAt = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );

  const transactionState = useSelector(
    (state: ApplicationState) => state.transaction
  );

  console.log(transactionState);

  const [actionType, setActionType] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [lastActiveTab, setLastActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [filterStatus, setFilterStatus] = useState("ASC");
  const [search, setSearch] = useState<string | null>(null);
  const [dialogReject, setDialogReject] = useState(false);
  const [dialogWaybill, setDialogWaybill] = useState(false);
  const [dialogPickupPIN, setDialogPickupPIN] = useState(false);
  const [dialogNote, setDialogNote] = useState(false);
  const [dialogNoteContent, setDialogNoteContent] = useState<any>('');
  const [remark, setRemark] = useState("");
  const [waybillNumber, setWaybillNumber] = useState("");
  const [pickupPIN, setPickupPIN] = useState("");
  const [finalTransactionPriceBefore, setFinalTransactionPriceBefore] = useState(0);
  const [finalTransactionPriceAfter, setFinalTransactionPriceAfter] = useState(0);
  const [earnedCoin, setEarnedCoin] = useState(0);
  const [note, setNote] = useState("");
  const [initialPickupDate, setInitialPickupDate] = useState<Date>(new Date());
  const [query, setQuery] = useState({
    offset: 0,
    limit: 25,
    filterStatus: "paid",
    startAt: "",
    endAt: "",
    // filterStatusExclude: '',
    filterStatusExclude: "canceled,expired,payment_failed,created,refunded",
    orderBy: "CREATED_AT_ASC",
  });
  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });

  const location = useLocation();
  const history = useHistory();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const {
    fetchAllTransaction,
    fetchOneTransaction,
    processTransaction,
    rejectTransaction,
    updateWaybillTransaction,
    updatePickupTransaction,
  } = useTransaction();
  const dispatch = useDispatch();

  const debouncedTitle = useDebounce(search, 300);

  const debouncedfinalTransactionPriceAfter = useDebounce(finalTransactionPriceAfter, 300);

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  useEffect(() => {
    calculateEarnedCoin();
  }, [debouncedfinalTransactionPriceAfter])

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          limit: 25,
          search: value,
        };
      });
      setPage(1);
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: "",
        };
      });
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    if (updateParams) {
      // console.info('trigger 1')
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            ...latestParams.query,
            ...query,
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
          activeTab: activeTab,
        })
      );
      fetchAllTransaction(query);
      setDateTemp({
        startAt: "",
        endAt: "",
      });
    } else {
      // console.info('trigger 2')
      // console.info(latestParams)
      setActiveTab(latestParams.activeTab || 0);
      fetchAllTransaction(latestParams.query);
      setPage(latestParams.query.page || page || 1);
      setRowsPerPage(latestParams.query.limit || 25);
      dispatch(enableUpdateParams(true));
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt,
      });
    }
  }, [query]);

  const changeTab = (value: number) => {
    // setQuery(latestParams.query)
    setActiveTab(value);
    setPage(1);

    dispatch(
      setLatestParams({
        ...latestParams,
        query: {
          ...latestParams.query,
        },
        activeTab: value,
      })
    );

    if (value === 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: "paid",
        };
      });
    }
    if (value === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: "process",
        };
      });
    }
    if (value === 2) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: ["sent", "pickup"],
        };
      });
    }
    if (value === 3) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: "rejected",
        };
      });
    }
    if (value === 4) {
      setFilterStatus("DESC");
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: "",
          orderBy: "CREATED_AT_DESC",
        };
      });
    }
  };

  useEffect(() => {
    setTotalData(transactionState.data.payload.count);

    if (transactionState.data.payload.count > 0) {
      setTotalPage(
        Math.ceil(transactionState.data.payload.count / rowsPerPage)
      );
    } else {
      setTotalPage(1);
    }
  }, [transactionState.data]);

  useEffect(() => {
    if (dialogPickupPIN) {
      setFinalTransactionPriceBefore(transactionState.transaction.finalTransactionPrice);
      setFinalTransactionPriceAfter(transactionState.transaction.finalTransactionPrice);
      setEarnedCoin(transactionState.transaction.earnedCoin)
      setNote("")
      setInitialPickupDate(new Date())
    }
  }, [dialogPickupPIN]);

  useEffect(() => {
    if (dialogWaybill) {
      setFinalTransactionPriceBefore(transactionState.transaction.finalTransactionPrice);
      setFinalTransactionPriceAfter(transactionState.transaction.finalTransactionPrice);
      setEarnedCoin(transactionState.transaction.earnedCoin)
      setNote("")
    }
  }, [dialogWaybill]);

  const validationForm = (type: 'pin' | 'resi') => {

    if (type === 'pin') {
      if (!initialPickupDate) {
        toast.error('Tanggal pengembalian tidak boleh kosong', {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false
        });
        return false
      }
    }

    if (!finalTransactionPriceAfter) {
      toast.error('Total transaksi baru tidak boleh kosong', {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false
      });
      return false
    }

    // if (Number(finalTransactionPriceAfter) !== Number(finalTransactionPriceBefore) && !note) {

    //   toast.error('Total transaksi lama dan baru tidak sama, harap masukan Note', {
    //     position: toast.POSITION.TOP_CENTER,
    //     pauseOnHover: false
    //   });
    //   return false
    // }

    return true;
  }

  const calculateEarnedCoin = async () => {
    try {

      const body = {
        finalTransactionPrice: finalTransactionPriceAfter,
        loyaltyCoin: transactionState?.transaction?.transactionMetadata?.buyer?.loyalty?.coin
      }

      const result = await apiCalculateEarnedCoin(body);

      if (result) {
        setEarnedCoin(result.data.payload);
      }

    } catch (error) {
      console.log(`error calculate earned coin: ${error}`)
    }
  }

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        startAt: dateTemp.startAt || oldVal.startAt,
        endAt: dateTemp.endAt || oldVal.endAt,
        offset: 0,
        limit: value,
      };
    });
  }

  const handleDialogReject = (state: boolean) => {
    setDialogReject(state);
  };

  const handleDialogWaybill = (state: boolean) => {
    setDialogWaybill(state);
  };

  const handleDialogPickupPIN = (state: boolean) => {
    setDialogPickupPIN(state);
  };

  const handlePickupDate = (date: any) => {
    setInitialPickupDate(date);
  };

  const renderActionAll = (item: TransactionListResponse) => {
    if (item.transactionStatus === "paid") {
      return renderActionPaid(item);
    }
    if (item.transactionStatus === "process") {
      return renderActionWaybill(item);
    }
    return false;
  };

  const renderHeadersAllPackaging = (item: TransactionListResponse) => {
    if (item.transactionStatus === "process") {
      return renderActionWaybill(item);
    }
    return false;
  };

  const renderActionPaid = (item: TransactionListResponse) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Box marginBottom={1}>
          <AppButton
            size="small"
            color="primary"
            onClick={() => {
              setActionType("process");
              processTransaction(item.transactionId);
            }}
          >
            Proses Pesanan
          </AppButton>
        </Box>
        <Box>
          <AppButton
            size="small"
            color="secondary"
            onClick={() => {
              setActionType("reject");
              dispatch(setOneTransaction(item));
              handleDialogReject(true);
            }}
          >
            Batalkan
          </AppButton>
        </Box>
      </Box>
    );
  };

  const renderActionWaybill = (item: TransactionListResponse) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Box marginBottom={1}>
          {item.shipmentType == "DELIVERY" ? (
            <AppButton
              size="small"
              color="primary"
              onClick={() => {
                setActionType("waybill");
                dispatch(setOneTransaction(item));
                handleDialogWaybill(true);
              }}
            >
              Input Resi
            </AppButton>
          ) : (
            <AppButton
              size="small"
              color="primary"
              onClick={() => {
                setActionType("waybill");
                dispatch(setOneTransaction(item));
                handleDialogPickupPIN(true);
              }}
            >
              Input PIN
            </AppButton>
          )}
        </Box>
      </Box>
    );
  };

  data = transactionState.data.payload.results.map(
    (item: TransactionListResponse) => {
      return {
        ...item,
        delivery_date: item.update_status_logs.find(
          (log) => log.statusAfter === "sent"
        )
          ? convertDateTimeToText(
            (
              item.update_status_logs.find(
                (log) => log.statusAfter === "sent"
              ) as any
            ).createdAt
          )
          : null,
        customName: (
          <div>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {item.transactionMetadata && item.transactionMetadata.buyer
                ? validateMaxText(30, item.transactionMetadata.buyer.name)
                : "---"}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" style={{ width: 90 }}>
                No. Transaksi
              </Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: "bold" }}
              >
                : {item.invoice?.invoiceId}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" style={{ width: 90 }}>
                Tgl. Pembelian
              </Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: "bold" }}
              >
                : {convertDateTimeToText(item.createdAt, "dd mmm yyyy hh:mm")}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="subtitle1" style={{ width: 90 }}>
                Voucher
              </Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: "bold" }}
              >
                :{" "}
                {item.voucherMetadata ? item.voucherMetadata.voucherCode : "-"}
              </Typography>
            </div>
          </div>
        ),
        order: (
          <Box display="flex" flexDirection="row">
            <Box marginRight={1}>
              {item.items &&
                item.items.length > 0 &&
                item.items[0].productMetadata.images &&
                item.items[0].productMetadata.images.length > 0 ? (
                <CardMedia
                  className={classes.cardMedia}
                  image={item.items[0].productMetadata.images[0]}
                />
              ) : (
                <CardMedia
                  className={classes.cardMedia}
                  image={"/assets/default_500x500.png"}
                />
              )}
            </Box>
            <Box>
              <Typography variant="body1">
                {item.items &&
                  item.items.length > 0 &&
                  (item.items[0].productMetadata.sku
                    ? item.items[0].productMetadata.sku + " "
                    : "") + item.items[0].productMetadata.name}
              </Typography>

              {item.items.length - 1 > 0 && (
                <Typography variant="subtitle1">
                  + {item.items.length - 1} barang lainnya
                </Typography>
              )}

              <Box
                display="flex"
                alignItems="center"
                style={{ gap: 10 }}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    borderBottom: "2px dotted rgb(169 169 169)",
                    display: "inline-block",
                  }}
                  to={"/transaction/" + item.transactionId}
                  target="_blank"
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: COLORS.primary }}
                  >
                    Lihat Detail Pesanan
                  </Typography>
                </Link>
                {
                  item?.note && (
                    <AppButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        setDialogNote(true);
                        setDialogNoteContent(item?.note);
                      }}
                    >
                      View Note
                    </AppButton>
                  )
                }
              </Box>

            </Box>
          </Box>
        ),
        process: (
          <Box display="flex" justifyContent="center" flexDirection="column">
            <StatusTransaction status={item.transactionStatus} />
          </Box>
        ),
        shipment: (
          <Box>
            {item?.shipmentType == "DELIVERY" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 60 }}>
                    Penerima
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    :{" "}
                    {item.shipping && item.shipping.expeditionMetadata
                      ? validateMaxText(20, item.shipping?.expeditionMetadata?.receiverName)
                      : "---"}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 100 }}>
                    Tipe Pengiriman
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    : DELIVERY
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 60 }}>
                    Kurir
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    :{" "}
                    {item.shipping && item.shipping.expeditionMetadata
                      ? item.shipping.expeditionMetadata?.expeditionName
                      : "---"}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 60 }}>
                    No Resi
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    :{" "}
                    {item.shipping && item.shipping.waybill
                      ? item.shipping.waybill
                      : "---"}
                  </Typography>
                </div>
              </>
            )}

            {item?.shipmentType == "PICKUP" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 60 }}>
                    Penerima
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    :{" "}
                    {item.transactionMetadata && item.transactionMetadata.buyer
                      ? validateMaxText(20, item.transactionMetadata.buyer.name)
                      : "---"}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 100 }}>
                    Tipe Pengiriman
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    :{" "}
                    <span
                      style={{
                        backgroundColor: "#FABB43",
                        padding: 3,
                        borderRadius: 4,
                      }}
                    >
                      PICKUP
                    </span>
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1" style={{ width: 100 }}>
                    Pickup PIN
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="span"
                    style={{ fontWeight: "bold" }}
                  >
                    : -
                  </Typography>
                </div>
              </>
            )}
          </Box>
        ),
        actionsPaid: (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
          >
            <Box marginBottom={1}>
              <AppButton
                size="small"
                color="primary"
                onClick={() => {
                  setActionType("process");
                  processTransaction(item.transactionId);
                }}
              >
                Proses Pesanan
              </AppButton>
            </Box>
            <Box>
              <AppButton
                size="small"
                color="secondary"
                onClick={() => {
                  setActionType("reject");
                  dispatch(setOneTransaction(item));
                  handleDialogReject(true);
                }}
              >
                Batalkan
              </AppButton>
            </Box>
          </Box>
        ),
        actionsWaybill: renderActionWaybill(item),
        actionsAll: renderActionAll(item),
        // actionsAllPackaging: renderHeadersAllPackaging(item),
        note: renderNote(item.update_status_logs),
      };
    }
  );

  function renderNote(data: TransactionLog[]) {
    if (Array.isArray(data) && data.length > 0) {
      const found = data.find(
        (item: TransactionLog) => item.statusAfter === "rejected"
      );
      if (found) {
        return found.note;
      }
    }
    return "";
  }

  function renderHeader(header: ITableHeader[]) {
    if (auth.user.role === "packaging") {
      return header.filter((item: ITableHeader) => item.title !== "Actions");
    }
    return header;
  }

  function setFilter(sort: "ASC" | "DESC") {
    setFilterStatus(sort);
    if (sort === "ASC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "CREATED_AT_ASC",
        };
      });
    } else if (sort === "DESC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "CREATED_AT_DESC",
        };
      });
    }
  }

  return (
    <Layout
      title="Transaction"
      loading={
        transactionState.isLoadingTransaction ||
        transactionState.isLoadingProcess
      }
    >
      <HeaderPage
        title="Retail Transaction"
        subtitle="List of all Transaction"
      />

      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={12} lg={4}></Grid>
        <Grid item xs={12} md={1} lg={1}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-status" shrink>
              Sort
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value="ASC">ASC</MenuItem>
              <MenuItem value="DESC">DESC</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by Name, Transaction ID"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              dispatch(
                findAllTransaction({
                  code: "",
                  message: "",
                  payload: {
                    offset: 0,
                    limit: 0,
                    totalPage: 0,
                    count: 0,
                    results: [],
                  },
                })
              );
              changeTab(value);
            }}
          />

          {activeTab === 0 && (
            <CustomizedTable
              data={data}
              headers={renderHeader(headersPaid)}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={changePagination}
              onRowsPerPageChange={changeRowsPerPage}
            />
          )}

          {activeTab === 1 && (
            <CustomizedTable
              data={data}
              headers={renderHeader(headersProcess)}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={changePagination}
              onRowsPerPageChange={changeRowsPerPage}
            />
          )}

          {activeTab === 2 && (
            <CustomizedTable
              data={data}
              headers={headersSent}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={changePagination}
              onRowsPerPageChange={changeRowsPerPage}
            />
          )}

          {activeTab === 3 && (
            <CustomizedTable
              data={data}
              headers={headersCanceled}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={changePagination}
              onRowsPerPageChange={changeRowsPerPage}
            />
          )}

          {activeTab === 4 && (
            <CustomizedTable
              data={data}
              headers={
                auth.user.role === "packaging" ? headersPackaging : headersAll
              }
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={changePagination}
              onRowsPerPageChange={changeRowsPerPage}
            />
          )}
        </Grid>
      </Grid>

      <Dialog
        maxWidth="lg"
        onClose={() => handleDialogReject(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogReject}
      >
        <DialogTitle id="customized-dialog-title">
          Pembatalan Pesanan
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.rejectContent}>
            <div>Nomor Transaksi</div>
            <div>
              {transactionState.transaction && transactionState.invoice
                ? transactionState.invoice?.invoiceId
                : "---"}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Pembeli</div>
            <div>
              {transactionState.transaction.transactionMetadata &&
                transactionState.transaction.transactionMetadata.buyer
                ? transactionState.transaction.transactionMetadata.buyer.name
                : "---"}
            </div>
          </div>

          <Box>
            <Typography color="textSecondary">Alasan Pembatalan</Typography>
            <FormInput
              value={remark}
              onChange={(value: string) => setRemark(value)}
              type="address"
              fullWidth
              placeholder="Tulis alasan disini"
              required
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            autoFocus
            onClick={() => {
              handleDialogReject(false);
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            color="primary"
            disabled={!remark}
            onClick={() => {
              rejectTransaction({
                transactionId: transactionState.transaction.transactionId,
                note: remark,
              });
              setRemark("");
              // setActiveTab(3)
              handleDialogReject(false);
            }}
          >
            Batalkan
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => handleDialogWaybill(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogWaybill}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">Input Resi</DialogTitle>
        <DialogContent dividers >
          <div className={classes.rejectContent}>
            <div>Nomor Transaksi</div>
            <div>
              {transactionState.transaction && transactionState.invoice
                ? transactionState.invoice?.invoiceId
                : "---"}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Pembeli</div>
            <div>
              {transactionState.transaction.transactionMetadata &&
                transactionState.transaction.transactionMetadata.buyer
                ? transactionState.transaction.transactionMetadata.buyer.name
                : "---"}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Lokasi Pengiriman</div>
            <div>
              <div>
                {
                  transactionState?.transaction?.shipping?.expeditionMetadata
                    ?.receiverName
                }{" "}
                (
                {
                  transactionState?.transaction?.shipping?.expeditionMetadata
                    ?.receiverPhone
                }
                )
              </div>
              <div>
                {
                  transactionState?.transaction?.shipping?.expeditionMetadata
                    ?.fullAddress
                }
              </div>
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Input Resi</div>
            <FormInput
              // type="address"
              fullWidth
              required
              placeholder="Nomor Resi"
              onChange={(value: string) => setWaybillNumber(value)}
            />
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Total transaksi lama</div>
            <div>
              {convertNumToCurrency(finalTransactionPriceBefore)}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Total transaksi baru</div>
            <div>
              <FormInput
                type="currency"
                fullWidth
                value={finalTransactionPriceAfter}
                required
                placeholder="Tulis transaksi baru"
                onChange={(value: number) => {
                  const newValue = Number(String(value).replace(/\D/g, ''));
                  setFinalTransactionPriceAfter(newValue)
                }}
              />
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Coin yang didapat</div>
            <div>
              {convertNumToCurrency(earnedCoin)}

              <Alert severity="warning">
                Rumus hitung coin: ({finalTransactionPriceAfter || 0} / 100000) * {transactionState?.transaction?.transactionMetadata?.buyer?.loyalty?.coin}

                <div>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to={`/customer/${transactionState.transaction.transactionMetadata.buyer.customerId}?historyCoin=true`}
                    target="_blank"
                  >
                    Lihat History Coin
                  </Link>
                </div>
              </Alert>

            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Note</div>
            <FormInput
              type="address"
              fullWidth
              required
              placeholder="Tulis Note"
              onChange={(value: string) => setNote(value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setWaybillNumber("");
              handleDialogWaybill(false);
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            color="primary"
            disabled={!waybillNumber}
            onClick={() => {

              const isValid = validationForm('resi');
              console.log("Validation result:", isValid);

              if (!isValid) {
                console.log("Form is not valid. Skipping update.");
                return;
              }

              updateWaybillTransaction({
                waybill: waybillNumber,
                invoiceId: transactionState.invoice?.invoiceId,
                earnedCoin: earnedCoin,
                note: note,
                finalTransactionPriceBefore: finalTransactionPriceBefore,
                finalTransactionPriceAfter: finalTransactionPriceAfter
              });
              handleDialogWaybill(false);
              setWaybillNumber("");
            }}
          >
            Input Resi
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => handleDialogPickupPIN(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogPickupPIN}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">Input PIN</DialogTitle>
        <DialogContent dividers>
          <div className={classes.rejectContent}>
            <div>Nomor Transaksi</div>
            <div>
              {transactionState.transaction && transactionState.invoice
                ? transactionState.invoice?.invoiceId
                : "---"}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Pembeli</div>
            <div>
              {transactionState.transaction.transactionMetadata &&
                transactionState.transaction.transactionMetadata.buyer
                ? transactionState.transaction.transactionMetadata.buyer.name
                : "---"}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Lokasi Pickup</div>
            <div>
              <div>
                {transactionState?.transaction?.pickup?.metaAddress?.address +
                  ", " +
                  transactionState?.transaction.pickup?.metaAddress
                    .subDistrict +
                  ", " +
                  transactionState?.transaction.pickup?.metaAddress.city +
                  ", " +
                  transactionState?.transaction.pickup?.metaAddress.province}
              </div>
              {/* <div>
                {
                  transactionState?.transaction?.pickup?.metaAddress
                    ?.subDistrict
                }
              </div> */}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>PIN Pickup</div>
            <FormInput
              // type="address"
              fullWidth
              required
              placeholder="Tulis PIN"
              onChange={(value: string) => setPickupPIN(value)}
            />
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Tanggal Pengambilan</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div
              >
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="dd MM yyyy"
                  placeholder="DD MM YYYY"
                  margin="normal"
                  fullWidth={true}
                  id="date-picker-inline"
                  value={initialPickupDate}
                  onChange={handlePickupDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ marginTop: 0 }}
                  minDate={new Date()}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Total transaksi lama</div>
            <div>
              {convertNumToCurrency(finalTransactionPriceBefore)}
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Total transaksi baru</div>
            <div>
              <FormInput
                type="currency"
                fullWidth
                value={finalTransactionPriceAfter}
                required
                placeholder="Tulis transaksi baru"
                onChange={(value: number) => {
                  const newValue = Number(String(value).replace(/\D/g, ''));
                  setFinalTransactionPriceAfter(newValue)
                }}
              />
            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Coin yang didapat</div>
            <div>
              {convertNumToCurrency(earnedCoin)}

              <Alert severity="warning">
                Rumus hitung coin: ({finalTransactionPriceAfter || 0} / 100000) * {transactionState?.transaction?.transactionMetadata?.buyer?.loyalty?.coin}

                <div>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to={`/customer/${transactionState.transaction.transactionMetadata.buyer.customerId}?historyCoin=true`}
                    target="_blank"
                  >
                    Lihat History Coin
                  </Link>
                </div>
              </Alert>

            </div>
          </div>

          <Divider />

          <div className={classes.rejectContent}>
            <div>Note</div>
            <FormInput
              type="address"
              fullWidth
              required
              placeholder="Tulis Note"
              onChange={(value: string) => setNote(value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setPickupPIN("");
              handleDialogPickupPIN(false);
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            color="primary"
            disabled={!pickupPIN}
            onClick={() => {

              const isValid = validationForm('pin');
              console.log("Validation result:", isValid);

              if (!isValid) {
                console.log("Form is not valid. Skipping update.");
                return;
              }

              updatePickupTransaction({
                pickupPIN: pickupPIN,
                pickupDate: initialPickupDate,
                invoiceId: transactionState.invoice?.invoiceId,
                earnedCoin: earnedCoin,
                note: note,
                finalTransactionPriceBefore: finalTransactionPriceBefore,
                finalTransactionPriceAfter: finalTransactionPriceAfter
              });
              handleDialogPickupPIN(false);
              setWaybillNumber("");
            }}
          >
            Input PIN
          </AppButton>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setDialogNote(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogNote}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">Note</DialogTitle>
        <DialogContent dividers>

          <Box>
            <Typography variant="body1">
              {dialogNoteContent}
            </Typography>
          </Box>

        </DialogContent>
        <DialogActions>
          <AppButton
            color="secondary"
            onClick={() => {
              setDialogNote(false);
            }}
          >
            Cancel
          </AppButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={transactionState.isSuccess ? true : false}
        autoHideDuration={6000}
      >
        <Alert severity="success" variant="filled">
          Berhasil{" "}
          {actionType === "process"
            ? "memproses pesanan"
            : actionType === "reject"
              ? "membatalkan pesanan"
              : actionType === "waybill"
                ? "memasukkan nomor resi"
                : ""}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    rejectContent: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "140px 1fr",
      marginTop: 10,
      marginBottom: 10,
    },
  })
);
