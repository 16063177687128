import { Reducer } from 'redux';
import { FeaturedProductState, FeaturedProductTypes } from '../../types/featured-product.type';

export const initFeaturedProduct = {
  productId: '',
  createdByUserId: '',
  productName: '',
  sku: '',
  metadata: '',
  description: '',
  published: false,
  featuredPublished: false,
  imageUrl: '',
  featuredImageUrl: '',
}

const initalState: FeaturedProductState = {
  isLoadingFeaturedProduct: false,
  featuredProduct: initFeaturedProduct,
  data: {
    code: null,
    message: null,
    payload: {
      next: '',
      prev: '',
      count: 0,
      results: []
    }
  },
  error: {},
  isSuccess: false
}

export const featuredProduct: Reducer<FeaturedProductState> = (state = initalState, action) => {
  switch (action.type) {
    case FeaturedProductTypes.FIND_ALL_FEATURED_PRODUCT:
      return {
        ...state,
        data: action.payload
      }
    case FeaturedProductTypes.FIND_ONE_FEATURED_PRODUCT:
      return {
        ...state,
        featuredProduct: action.payload
      }
    case FeaturedProductTypes.CREATE_FEATURED_PRODUCT:
      return {
        ...state,
        featuredProduct: action.payload,
        isSuccess: true
      }
    case FeaturedProductTypes.UPDATE_FEATURED_PRODUCT:
      return {
        ...state,
        isSuccess: action.payload
      }
    case FeaturedProductTypes.REMOVE_FEATURED_PRODUCT:
      return {
        ...state,
        isSuccess: action.payload
      }
    case FeaturedProductTypes.SET_LOADING_FEATURED_PRODUCT:
      return {
        ...state,
        isLoadingFeaturedProduct: action.payload
      }
    case FeaturedProductTypes.SET_ERROR_FEATURED_PRODUCT:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}