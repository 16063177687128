import axios from 'axios';
import { getHeaders } from '../helpers/auth';



export async function apiFindAllFeedback(params: string) {
  const headers = await getHeaders()
  const query = params || null
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/feedback' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneFeedback(feedbackId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/feedback/' + feedbackId,
    method: 'GET',
    headers
  });
}

export async function apiUpdateFeedback(data: { status: string, feedbackId: string }) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/feedback/' + data.feedbackId,
    method: 'PATCH',
    headers,
    data
  });
}