import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Edit2, Eye } from "react-feather";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import useLoyalty from "../../hooks/useLoyalty";
import { ApplicationState } from "../../redux/store";
import COLORS from "../../styles/colors";
import { LoyaltyResponse } from "../../types/master-data-referral.type";

export default function Loyalty() {
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 25, offset: 0 });
  const [dialogEditLoyalty, setDialogEditLoyalty] = useState<boolean>(false);
  const [unlimited, setIsUnlimited] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("");
  const { listLoyaltyLevel } = useSelector(
    (state: ApplicationState) => state.masterDataReferral
  );
  const history = useHistory();
  const [loyaltyData, setLoyaltyData] = useState<{
    loyaltyId: string;
    loyaltyName: string;
    minTotalTransaction: number;
    maxTotalTransaction: number;
    coin: number;
    coinExpired: number;
  }>({
    loyaltyId: "",
    loyaltyName: "",
    minTotalTransaction: 0,
    maxTotalTransaction: 0,
    coin: 0,
    coinExpired: 0,
  });

  const headers: ITableHeader[] = [
    {
      title: "Loyalty Name",
      align: "left",
      column: "loyaltyName",
      type: "text",
    },
    {
      title: "Akumulasi Transaksi",
      align: "left",
      type: "text",
      column: "akumulasi",
    },
    {
      title: "Masa Berlaku",
      align: "left",
      type: "text",
      column: "coinExpired",
    },
    {
      title: "Koin",
      align: "left",
      type: "text",
      column: "coin",
    },
    {
      title: "Updated By",
      align: "left",
      type: "date",
      column: "updatedAt",
    },
    {
      title: "Actions",
      align: "center",
      column: "actions",
    },
  ];

  const { fetchLoyalty, updateLoyalty } = useLoyalty();

  useEffect(() => {
    fetchLoyalty();
  }, []);

  useEffect(() => {
    if (loyaltyData.maxTotalTransaction == 1000000000) {
      setIsUnlimited(true);
    } else {
      setIsUnlimited(false);
    }
  }, [loyaltyData]);

  useEffect(() => {
    if (listLoyaltyLevel.payload.count > 0) {
      setTotalData(listLoyaltyLevel.payload.count);
      setTotalPage(Math.ceil(listLoyaltyLevel.payload.count / rowsPerPage));
    }
  }, [listLoyaltyLevel.payload]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  const renderAction = (item: LoyaltyResponse) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Eye
          color={COLORS.primary}
          size={18}
          style={{ cursor: "pointer", marginRight: 6 }}
          onClick={() => history.push("/detail-loyalty/" + item.loyaltyId)}
        />
        <Edit2
          color={COLORS.primary}
          size={18}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setLoyaltyData(item);
            setDialogEditLoyalty(true);
          }}
        />
      </Box>
    );
  };

  const data = listLoyaltyLevel.payload.results.map((item: LoyaltyResponse) => {
    return {
      loyaltyName: item.loyaltyName,
      akumulasi:
        convertNumToCurrency(item.minTotalTransaction) +
        " - " +
        (item.maxTotalTransaction == 1000000000
          ? "Unlimited"
          : convertNumToCurrency(item.maxTotalTransaction)),
      coinExpired: item.coinExpired + " Bulan",
      coin: item.coin,
      updatedAt: item.updatedAt,
      actions: renderAction(item),
    };
  });

  function handleCloseDialogEditLoyalty() {
    setDialogEditLoyalty(false);
  }

  const editLoyalty = () => {
    updateLoyalty({
      loyaltyId: loyaltyData?.loyaltyId,
      loyaltyName: loyaltyData.loyaltyName,
      coin: loyaltyData.coin,
      coinExpired: loyaltyData.coinExpired,
      minTotalTransaction: loyaltyData.minTotalTransaction,
      maxTotalTransaction: loyaltyData.maxTotalTransaction,
      unlimited: unlimited,
      remark: remark,
    }).then(() => {
      fetchLoyalty();
    });

    handleCloseDialogEditLoyalty();
    setIsUnlimited(false);
    setRemark("");
  };

  return (
    <Layout title="Loyalty" loading={false}>
      <HeaderPage title="Loyalty" subtitle="Loyalty Configuration" />
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <CustomizedTable
          data={data}
          headers={headers}
          totalData={totalData}
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          onChange={(newPage) => changePagination(newPage)}
          onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
        />
      </Grid>

      <Dialog
        aria-labelledby="simple-dialog-title"
        open={dialogEditLoyalty}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Edit Loyalty</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FormInput
              onChange={(value: any) => {
                setLoyaltyData({
                  ...loyaltyData,
                  loyaltyName: value,
                });
              }}
              type="text"
              value={loyaltyData.loyaltyName}
              required
              style={{ width: "90%" }}
              placeholder="Masukan Nama Loyalty"
              label="Nama Loyalty"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              onChange={(value: any) => {
                setLoyaltyData({
                  ...loyaltyData,
                  coinExpired: value,
                });
              }}
              type="number-only"
              value={loyaltyData.coinExpired}
              fullWidth
              required
              placeholder="Masukan Masa Berlaku Koin"
              label="Masa Berlaku Koin"
            />
          </Grid>
          <FormInput
            onChange={(value: any) => {
              setLoyaltyData({
                ...loyaltyData,
                coin: value,
              });
            }}
            type="number-only"
            value={loyaltyData.coin}
            fullWidth
            required
            placeholder="Masukan Koin"
            label="Koin didapat setiap kelipatan Rp100.000"
          />
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ marginTop: -20, marginBottom: 20 }}
          >
            Nilai koin penukaran setiap nominal kelipatan
          </Typography>
          <Grid item xs={6}>
            <FormInput
              onChange={(value: any) => {
                setLoyaltyData({
                  ...loyaltyData,
                  minTotalTransaction: value,
                });
              }}
              type="currency"
              value={loyaltyData.minTotalTransaction}
              style={{ width: "90%" }}
              required
              placeholder="Masukan Min Akumulasi Transaksi"
              label="Min Akumulasi Transaksi"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              onChange={(value: any) => {
                setLoyaltyData({
                  ...loyaltyData,
                  maxTotalTransaction: value,
                });
              }}
              type={
                loyaltyData.maxTotalTransaction == 1000000000
                  ? "text"
                  : "currency"
              }
              value={
                loyaltyData.maxTotalTransaction == 1000000000
                  ? "Unlimited"
                  : loyaltyData.maxTotalTransaction
              }
              fullWidth
              required
              placeholder="Masukan Max Akumulasi Transaksi"
              label="Max Akumulasi Transaksi"
            />
          </Grid>
          <FormGroup>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: -10 }}
            >
              Unlimited Max Akumulasi Transaksi
            </Typography>
            <FormControlLabel
              onClick={() => setIsUnlimited(!unlimited)}
              control={<Checkbox />}
              checked={unlimited}
              label="Unlimited"
            />
          </FormGroup>
          <TextField
            multiline
            rows={4}
            label="Note"
            fullWidth
            placeholder="Masukan Catatan"
            onChange={(e: any) => setRemark(e.target.value)}
            value={remark}
          />
          <DialogActions style={{ marginTop: 20, marginLeft: "auto" }}>
            <AppButton
              fullWidth
              color="secondary"
              onClick={handleCloseDialogEditLoyalty}
            >
              Cancel
            </AppButton>
            <AppButton
              fullWidth
              color="primary"
              onClick={editLoyalty}
              disabled={
                loyaltyData.coin.toString().length == 0 ||
                loyaltyData.loyaltyName.length === 0 ||
                loyaltyData.coinExpired.toString().length == 0 ||
                loyaltyData.minTotalTransaction.toString().length == 0 ||
                loyaltyData.maxTotalTransaction.toString().length == 0
              }
            >
              Save
            </AppButton>
          </DialogActions>
        </Grid>
      </Dialog>
    </Layout>
  );
}
