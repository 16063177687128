import { ServiceCenterTransactionType } from "./service-center.types";

export enum ServiceCenterTransactionTypes {
  FIND_ALL_SERVICE_CENTER_TRANSACTION = "FIND_ALL_SERVICE_CENTER_TRANSACTION",
  FIND_ONE_SERVICE_CENTER_TRANSACTION = "FIND_ONE_SERVICE_CENTER_TRANSACTION",
  UPDATE_SERVICE_CENTER_TRANSACTION = "UPDATE_SERVICE_CENTER_TRANSACTION",
  SET_LOADING_SERVICE_CENTER_TRANSACTION = "SET_LOADING_SERVICE_CENTER_TRANSACTION",
  SET_ERROR_SERVICE_CENTER_TRANSACTION = "SET_ERROR_SERVICE_CENTER_TRANSACTION",
  SET_LAST_PATH_SERVICE_CENTER_TRANSACTION = "SET_LAST_PATH_SERVICE_CENTER_TRANSACTION",
  SET_LAST_QUERY_SERVICE_CENTER_TRANSACTION = "SET_LAST_QUERY_SERVICE_CENTER_TRANSACTION",
}

export type ServiceCenterTransactionResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ServiceCenterTransactionType[];
  };
};

export type ServiceCenterTransactionState = {
  isLoadingTransaction: boolean;
  data: ServiceCenterTransactionResponsePayload;
  serviceCenterTransaction: any;
  error: any;
  isSuccess: boolean;
};
