import { FormControl, Grid, InputLabel, MenuItem, Select, Switch } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../components/Form/AppButton";
import Search from "../../components/Form/Search";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import CustomizedDnDTable, { ITableHeaderDnd } from "../../components/Table/CustomizedDnDTable";
import { httpRequest } from "../../helpers/api/api";
import useFetch, { DefaultResponse } from "../../hooks/useFetch";
import { ApplicationState } from "../../redux/store";
import useGlobalStyles from "../../styles/globalStyles";
import { ChangeOrderRequest, IStorefrontQuery, StorefrontProperties } from "../../types/storefront.type";

const headers: ITableHeaderDnd[] = [
  {
    title: "Code",
    column: "id",
    type: "id",
  },
  {
    title: "Order Handle",
    column: "order",
    type: "order-handle",
  },
  {
    title: "Image",
    column: "imageUrl",
    type: "image-product",
    imageSize: "medium-portrait",
  },
  {
    title: "Name",
    column: "title",
  },
  {
    title: "Category",
    column: "category",
  },
  {
    title: "Total Product",
    column: "totalProduct",
  },
  {
    title: "Status",
    column: "status",
  },
];

const initialQuery: IStorefrontQuery = {
  // limit: 25,
  page: 1,
};

const Storefront: React.FC = () => {
  const globalClasses = useGlobalStyles();
  const [filterPublished, setFilterPublished] = React.useState<"all" | "published" | "unpublished">("all");
  const { state: locationState, activeTab: currentActiveTab } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const {
    data,
    loading,
    pagination,
    query,
    search,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    fetchDataTableAlt,
  } = useFetch<StorefrontProperties>({
    url: "storefront",
    query: initialQuery,
  });

  const handleChangeStatus = async (item: StorefrontProperties) => {
    try {
      await httpRequest.patch<DefaultResponse<{ isSuccess: boolean }>>(
        "storefront/update-status/" + item.storefrontId,
        {
          published: !item.published,
        }
      );
      setQuery({ ...query });
    } catch (error) {
      console.error("Failed update status", JSON.stringify(error));
    }
  };

  const removeStorefront = async (id: any) => {
    try {
      await httpRequest.delete<DefaultResponse<{ isSuccess: boolean }>>("storefront/" + id);

      setQuery({ ...initialQuery });
    } catch (error) {
      console.log("error remove etalase", JSON.stringify(error));
    }
  };

  const dataStorefronts = data.map((item) => {
    return {
      id: item.storefrontId,
      imageUrl: item.imageUrl,
      title: item.title,
      category: item.categories[0].name,
      totalProduct: item.products.length,
      status: (
        <Switch
          checked={item.published}
          onChange={() => handleChangeStatus(item)}
          name="checkedA"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      ),
    };
  });

  const changeOrderBanner = async (item: ChangeOrderRequest, query: any) => {
    try {
      await httpRequest.patch<DefaultResponse<{ isSuccess: boolean }>>("storefront/change-order/" + item.id, {
        currentIndex: item.currentIndex,
        prevIndex: item.prevIndex,
      });
      setQuery({ ...initialQuery });
    } catch (error) {
      console.error("Failed update status", JSON.stringify(error));
    }
  };

  return (
    <Layout loading={loading} title="Etalase">
      <HeaderPage title="Etalase" subtitle="List of all etalase" />

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <Link to={{ pathname: "etalase/create" }} className={globalClasses.link}>
            <AppButton variant="contained" color="primary" startIcon={<Add />}>
              Add New Etalase
            </AppButton>
          </Link>
        </Grid>
        <Grid item container xs={12} md={6} spacing={3}>
          <Grid item xs={12} md={6}>
            <Search
              placeholder="Search by Voucher Code, Event Name"
              onChange={changeHandler}
              value={locationState && locationState.search ? locationState.search : search}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterPublished}
                defaultValue="all"
                onChange={(event: any) => {
                  const value = event.target.value;
                  setFilterPublished(value);
                  if (value === "all") {
                    setQuery((oldVal: IStorefrontQuery) => {
                      return {
                        ...oldVal,
                        status: false,
                      };
                    });
                  } else {
                    setQuery((oldVal: IStorefrontQuery) => {
                      return {
                        ...oldVal,
                        status: value,
                      };
                    });
                  }
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"published"}>Published</MenuItem>
                <MenuItem value={"unpublished"}>Unpublished</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CustomizedDnDTable
            data={dataStorefronts}
            headers={headers}
            totalData={pagination.totalData}
            showAction={["detail", "edit", "delete"]}
            page={pagination.page}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            hidePagination={true}
            onDeleteItem={(data: any) => {
              removeStorefront(data.id);
            }}
            onChangeOrder={(data: ChangeOrderRequest) => changeOrderBanner(data, query)}
            onChange={(newPage: number) => handlePageChange(newPage)}
            onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Storefront;
