import {
  Box,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  withStyles,
  StepConnector,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@material-ui/core';
import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { useParams, useHistory } from 'react-router-dom';
import HeaderPage from '../../components/Layout/HeaderPage';
import GeneralInformation from '../B2BTransaction/GeneralInformation';
import ListDO from './ListDO';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import COLORS from '../../styles/colors';
import { ChevronRight, Note } from '@material-ui/icons';
import AppButton from '../../components/Form/AppButton';
import LiveTrackingMap, { MarkerLocation } from './LiveTrackingMap';
import AppTabs from '../../components/Tabs/AppTabs';
import useFetchOne from '../../hooks/useFetchOne';
import { initialTrip, TripHistories, TripProperties, StepItem, initialDefaultStep, TripAllItemsCheck, TripReturnAllItems, DeliveryOrderPropertiesCheck, PickupOrderPropertiesCheck, ReplacementPickupPropertiesCheck, ReplacementDeliveryPropertiesCheck, PickupProperties } from '../../types/b2b/trip.type';
import axios from 'axios';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import { ETripStatus, DeliveryOrderProperties } from '../../types/b2b/order/delivery-order.type';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { httpRequest } from '../../helpers/api/api';
import { useDispatch } from 'react-redux';
import { setB2BID } from '../../redux/action/b2b.action';
import { PickupOrderProperties } from '../../types/b2b/return/pickup-order.type';
import { ReplacementDeliveryProperties } from '../../types/b2b/replacement/replacement-delivery.type';
import { ReplacementPickupProperties } from '../../types/b2b/replacement/replacement-pickup.type';
import database from '../../helpers/firebase';
import { getDatabase, ref, onValue } from "firebase/database";

const tabs = ['General Information', 'Live Tracking Map'];

type Props = {
  type: "trip" | "pickup";
};

const date = new Date();
const dateString = date.toISOString();
const currStatus: ETripStatus = ETripStatus.change_route;
const currentStepsAlt: TripHistories[] = [
  {
    status: ETripStatus.created,
    date: dateString,
    note: null,
  },
  {
    status: ETripStatus.loading,
    date: dateString,
    note: null,
  },
  {
    status: ETripStatus.loading_complete,
    date: dateString,
    note: null,
  },
  {
    status: ETripStatus.otw,
    date: dateString,
    note: null,
  },
  {
    status: ETripStatus.change_route,
    date: dateString,
    note: "Ubah rute karena macet",
  },
  {
    status: ETripStatus.otw,
    date: dateString,
    note: null,
  },
  {
    status: ETripStatus.change_route,
    date: dateString,
    note: "Ubah rute lagi",
  },
];

export default function TripDetail(props: Props) {
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const [activeTab, setActiveTab] = useState(0);
  const [defaultSteps, setDefaultSteps] = useState<StepItem[]>(initialDefaultStep)
  const [changeRouteCount, setChangeRouteCount] = useState<number>(0)
  const [currentHistories, setCurrentHistories] = useState<StepItem[]>([])
  const [currentDataItems, setCurrentDataItems] = React.useState<TripAllItemsCheck>({
    deliveryOrders: [],
    pickupOrders: [],
    replacementPickups: [],
    replacementDeliveries: [],
  })
  const [markers, setMarkers] = React.useState<MarkerLocation[]>([])

  const handleChangeStatus = (newStatus: string) => {
    console.info("newStatus", newStatus);
  };

  let title = "Detail Trip";
  let subtitle = "Detailed information about trip order";

  if (props.type === "pickup") {
    title = "Detail Pickup";
    subtitle = "Detailed information about pickup order";
  }

  const { fetchOneData, data, loading } = useFetchOne<TripProperties & PickupProperties>({
    url: props.type === 'trip' ? "trip" : 'pickup',
    initialData: initialTrip,
  });

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    if (id) {
      fetchOneData(id, source);
    }

    return () => {
      source.cancel();
    };
  }, [id]);

  const tripHistoriesMapped = () => {
    let res: StepItem[] = [];
    if (data.status !== ETripStatus.change_route && changeRouteCount === 0) {
      res = [...initialDefaultStep].map((a, idx) => {
        const found = data.tripHistories.find(
          (item, idxChild) => idx === idxChild
        );
        if (found) {
          return {
            ...a,
            date: found ? found.date : null,
            note: found ? found.note : null,
          };
        } else {
          return a;
        }
      });
    } else {
      let oldSteps = [...initialDefaultStep];
      let nTimes = changeRouteCount;

      while (nTimes > 0) {
        oldSteps.splice(oldSteps.length - 2, 0, {
          status: ETripStatus.change_route,
          statusText: "Ganti Rute",
          date: dateString,
          note: null,
        });
        oldSteps.splice(oldSteps.length - 2, 0, {
          status: ETripStatus.otw,
          statusText: "OTW",
          date: null,
          note: null,
        });

        nTimes--;
      }
      setDefaultSteps(oldSteps);

      res = oldSteps.map((a, idx) => {
        const found = data.tripHistories.find(
          (item, idxChild) => idx === idxChild
        );
        if (found) {
          return {
            ...a,
            date: found ? found.date : null,
            note: found ? found.note : null,
          };
        } else {
          return a;
        }
      });
    }

    setCurrentHistories(res);
  };

  const onDataChange = (snapshot: any) => {
    const newMarkers: MarkerLocation[] = [{
      lat: Number(data.branchMetadata.coordinate.split(';')[0]),
      lng: Number(data.branchMetadata.coordinate.split(';')[1]),
      isDriver: false
    }]

    if (snapshot && snapshot.val()) {
      newMarkers.push({
        lat: snapshot.val().latitude,
        lng: snapshot.val().longitude,
        isDriver: true
      })
    }

    setMarkers(newMarkers)

    console.info('snapshot', snapshot.val().latitude)

  }

  React.useEffect(() => {
    if (data) {
      const currentIndex = defaultSteps.findIndex(
        (item) => item.status === data.status
      );
      if (data.status !== ETripStatus.change_route) {
        setActiveStep(currentIndex);
      }

      setDefaultSteps(initialDefaultStep);

      if (props.type === 'trip') {
        tripHistoriesMapped();

        const found = data.tripHistories.filter(item => item.status === ETripStatus.change_route)
        setChangeRouteCount(found.length)

        if (data.status === ETripStatus.otw) {
          const getDriverLoc = ref(database, '/TripDriver/' + data.tripId)
          if (getDriverLoc) {
            onValue(getDriverLoc, onDataChange)
          }
        }
      }


    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      let deliveryOrders: DeliveryOrderPropertiesCheck[] = [];
      let pickupOrders: PickupOrderPropertiesCheck[] = [];
      let replacementPickups: ReplacementPickupPropertiesCheck[] = [];
      let replacementDeliveries: ReplacementDeliveryPropertiesCheck[] = [];

      if (data.deliveryOrders) {
        deliveryOrders = data.deliveryOrders.map((item) => {
          return {
            ...item,
            id: item.doId,
            qtyProduct: item.data_products ? item.data_products.length : 0,
            createdAtConvert: convertDateTimeToText(
              item.createdAt,
              "dd mmm yyyy hh:mm"
            ),
          };
        });
      }

      if (data.pickupOrders) {
        pickupOrders = data.pickupOrders.map((item) => {
          return {
            ...item,
            id: item.poId,
            qtyProduct: item.data_products ? item.data_products.length : 0,
            createdAtConvert: convertDateTimeToText(
              item.createdAt,
              "dd mmm yyyy hh:mm"
            ),
          };
        });
      }

      if (data.replacementPickups) {
        replacementPickups = data.replacementPickups.map((item) => {
          return {
            ...item,
            id: item.rplpId,
            qtyProduct: item.data_products ? item.data_products.length : 0,
            createdAtConvert: convertDateTimeToText(
              item.createdAt,
              "dd mmm yyyy hh:mm"
            ),
          };
        });
      }

      if (data.replacementDeliveries) {
        replacementDeliveries = data.replacementDeliveries.map((item) => {
          return {
            ...item,
            id: item.rpldId,
            qtyProduct: item.data_products ? item.data_products.length : 0,
            createdAtConvert: convertDateTimeToText(
              item.createdAt,
              "dd mmm yyyy hh:mm"
            ),
          };
        });
      }

      setCurrentDataItems({
        deliveryOrders: deliveryOrders || [],
        pickupOrders: pickupOrders || [],
        replacementPickups: replacementPickups || [],
        replacementDeliveries: replacementDeliveries || [],
      });
    }
  }, [data, loading]);

  const history = useHistory();
  const dispatch = useDispatch();

  const handlePressDetail = (data: { value: any; activeTab: number }) => {
    dispatch(
      setB2BID(
        data.activeTab === 0
          ? data.value.orderId
          : data.activeTab === 1
            ? data.value.returnId
            : data.value.rplId
      )
    );
    if (data.activeTab === 0) {
      history.push("/delivery-order/" + data.value.doId);
    } else if (data.activeTab === 1) {
      history.push("/pickup-order/" + data.value.poId);
    } else if (data.activeTab === 2) {
      history.push("/replacement-pickup/" + data.value.rplpId);
    } else if (data.activeTab === 3) {
      history.push("/replacement-delivery/" + data.value.rpldId);
    }
  };

  return (
    <Layout title={title}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <HeaderPage title={title} subtitle={subtitle} showBackButton />
      </Box>

      {props.type === "trip" && (
        <Box width="100%">
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => setActiveTab(value)}
          />
        </Box>
      )}

      {activeTab === 0 && (
        <Paper elevation={3} className={classes.paper}>
          <Box display="flex" flexWrap="wrap" className={classes.containerForm}>
            <Box width="100%" className={classes.input}>
              {/* {props.type === "trip" && (
                <Box
                  display="flex"
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="85%">
                    <FormControl disabled={data.status === ETripStatus.arrived} className={classes.formStatus}>
                      <InputLabel id="select-status" required>
                        Ubah Status Trip Order
                      </InputLabel>
                      <Select
                        required
                        label="Choose Status Trip"
                        placeholder="Choose Status Trip"
                        labelId="select-status-trip"
                        id="demo-customized-select"
                        onChange={(event: any) => {
                          handleChangeStatus(event.target.value);
                        }}
                      >
                        <MenuItem value="1">Published</MenuItem>
                        <MenuItem value="0">Unpublished</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box width="15%" display="flex" justifyContent="center">
                    <AppButton color="primary">Update Trip Status</AppButton>
                  </Box>
                </Box>
              )} */}

              {props.type === "pickup" ? (
                <GeneralInformation
                  itemPerRow={4}
                  data={[
                    {
                      label: "Pickup ID",
                      value: id,
                    },
                    {
                      label: "Pickup Confirmed at",
                      value: data.updatedAt ? convertDateTimeToText(data.updatedAt, 'dd mmm yyyy hh:mm') : null,
                    },
                    {},
                    {},
                    {
                      label: "Distributor",
                      value: data.distributorMetadata.name,
                    },
                    {
                      label: "Lokasi Pengiriman",
                      value: data.branchMetadata.name,
                    },
                    {
                      label: "PIC Pickup",
                      value: data.agentMetadata.pickupPIC || null,
                    },
                  ]}
                />
              ) : (
                  <GeneralInformation
                    data={[
                      {
                        label: 'Trip ID',
                        value: data.tripId,
                      },
                      {
                        label: 'Order Status',
                        value: data.status,
                      },
                      {
                        label: 'Trip Created',
                        value: data.createdAt ? convertDateTimeToText(data.createdAt, 'dd mmm yyyy hh:mm') : null,
                      },
                      {
                        label: 'Distributor',
                        value: data.distributorMetadata.name,
                      },
                      {
                        label: 'Lokasi Pengiriman',
                        value: data.branchMetadata.name,
                      },
                      {
                        label: 'Driver',
                        value: data.driverMetadata.name,
                      },
                      {
                        label: 'Nama Penerima',
                        value: data.receiverName,
                      },
                      {
                        label: 'Tanda Tangan',
                        value: data.signature,
                        signature: true
                      },
                    ]}
                  />
                )}
            </Box>
            {props.type === "trip" && (
              <>
                <Box width="100%" className={classes.input}>
                  <Divider style={{ marginBottom: 20 }} />
                  <Typography>Trip History</Typography>

                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    className={classes.stepper}
                    connector={<ColorlibConnector />}
                  >
                    {currentHistories.map((item, idx) => (
                      <Step key={idx}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.statusText}
                          </Typography>
                          {item.date ? (
                            <Typography variant="subtitle1">
                              {item.date
                                ? convertDateTimeToText(
                                  item.date,
                                  "dd mmm yyyy hh:mm"
                                )
                                : null}
                            </Typography>
                          ) : (
                              <Typography
                                style={{ fontStyle: "italic" }}
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                Not set yet
                              </Typography>
                            )}

                          {item.note && (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="center"
                              alignSelf="center"
                            >
                              <Box
                                style={{
                                  marginTop: 5,
                                  paddingTop: 3,
                                  paddingBottom: 3,
                                  paddingLeft: 10,
                                  paddingRight: 10,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderColor: COLORS.primary,
                                  borderRadius: 5,
                                }}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                                alignSelf="center"
                              >
                                <Note />
                                <Typography> Note</Typography>
                              </Box>
                            </Box>
                          )}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </>
            )}
            <Box width="100%" className={classes.input}>
              <Divider style={{ marginBottom: 20 }} />
              <Typography>Delivery Order</Typography>
              <Box className={classes.row}>
                <ListDO
                  type="trip"
                  data={currentDataItems}
                  onPressDetail={handlePressDetail}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      )}

      {activeTab === 1 && (
        <Paper elevation={3} className={classes.paper}>
          <LiveTrackingMap
            destinationName={data.branchMetadata.name || ''}
            driverName={data.driverMetadata.name || ''}
            locations={markers}
          />
        </Paper>
      )}
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0, 0, 0, 0),
      padding: 0,
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    stepper: {
      background: "transparent",
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: COLORS.accent,
    zIndex: 1,
    color: COLORS.primary,
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    borderColor: COLORS.primary,
    borderWidth: 1,
    borderStyle: "solid",
    fontSize: 16,
  },
  active: {
    background: COLORS.primary,
    color: COLORS.accent,
    fontWeight: "bold",
  },
  completed: {
    background: COLORS.primary,
    color: COLORS.accent,
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {active || completed ? <CheckCircleIcon /> : <ChevronRight />}
    </div>
  );
}
