import { Chip, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useState, useEffect, useMemo } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable from "../../components/Table/CustomizedTable";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import { headersProductByAdminB2C } from "./headers";
import useProduct from "../../hooks/useProduct";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import useDebounce from "../../hooks/useDebounce";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { ProductResponse } from "../../types/product.type";
import { CategoryResponse } from "../../types/category.type";
import COLORS from "../../styles/colors";
import { findOneProduct } from "../../redux/action/product.action";
import { convertNumToCurrency } from "../../helpers/functions/currency";

export default function Product(props: any) {
  // let headers =
  //   myRole === ERoles.TRANSACTION
  //     ? headersProductByAdminB2C
  //     : headersProductBySuperAdmin;
  let headers = headersProductByAdminB2C;

  const productState = useSelector((state: ApplicationState) => state.product);

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [filterStatus, setFilterStatus] = useState();
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: "" });

  const { fetchAllProduct } = useProduct();
  const dispatch = useDispatch();
  const location = useLocation();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const debouncedTitle = useDebounce(search, 300);

  let data: any = [];

  useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value,
        };
      });
      setPage(1);
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: "" });
    }
  };

  useEffect(() => {
    if (updateParams) {
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllProduct(query);
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchAllProduct(latestParams.query);
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  useEffect(() => {
    setTotalData(productState.data.payload.count);
    if (productState.data.payload.count > 0) {
      setTotalPage(Math.ceil(productState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1);
    }
  }, [productState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  function renderCategories(categories: CategoryResponse[]) {
    let productCategories = [];

    if (categories.length <= 0) {
      return (
        <Chip style={{ fontSize: 10 }} label="Uncategorized" size="small" />
      );
    }

    productCategories = categories.map(
      (category: CategoryResponse, idx: number) => {
        if (
          categories.length === 1 &&
          category.categoryName.includes("Featured")
        ) {
          return (
            <Chip style={{ fontSize: 10 }} label="Uncategorized" size="small" />
          );
        }
        if (
          categories.length > 1 &&
          category.categoryName.includes("Featured")
        ) {
          return false;
        } else {
          return (
            <Chip
              label={category.categoryName}
              size="small"
              style={{ fontSize: 10, marginRight: 8, marginBottom: 4 }}
              key={category.categoryId}
            />
          );
        }
      }
    );

    return productCategories;
  }

  data = productState.data.payload.results.map((item: ProductResponse) => {
    return {
      id: item.productId,
      productId: item.productId,
      image: item.images,
      sku: item.sku,
      productInfo: (
        <div>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", lineHeight: 1.4 }}
          >
            {item.productName}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
              marginTop: 8,
            }}
          >
            {renderCategories(item.categories)}
          </div>
        </div>
      ),
      type: item.type,
      agentPrice: item.price.distributorPrice,
      catalogPrice: item.price.catalogPrice,
      retailDiscount: item.price.retailDiscountNote,
      retailDiscountAmount: item.price.retailDiscountAmount
        ? convertNumToCurrency(item.price.retailDiscountAmount)
        : null,
      retailPrice: item.price.retailPrice,
    };
  });

  return (
    <Layout title="Product" loading={productState.isLoadingProduct}>
      <HeaderPage title="Product" subtitle="List of all product" />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={12} lg={9}></Grid>
        {/* <Grid item xs={12} md={6} lg={2}>
          <FormControl className={classes.formStatus}>
            <InputLabel id="select-status" required>
              Status
            </InputLabel>
            <Select
              required
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilterStatus(event.target.value);
              }}
            >
              <MenuItem value="1">Published</MenuItem>
              <MenuItem value="0">Unpublished</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by product name, SKU"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            showAction={["detail"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            // onPressDetail={(data: any) => {
            //   dispatch(findOneProduct(data))
            // }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);
