import { useDispatch, useSelector } from 'react-redux';
import {
  apiFindAllTransaction,
  apiFindHistoryTransaction,
  apiFindOneTransaction,
  apiProcessStatusTransaction,
  apiRefundTransaction,
  apiRejectTransaction,
  apiUpdateWaybillTransaction,
} from '../api/transaction';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { ApplicationState } from '../redux/store';
import { TransactionResponse } from '../types/transaction.types';
import { findAllServiceCenterTransaction, findOneServiceCenterTransaction, setErrorServiceCenterTransaction, setLoadingServiceCenterTransaction } from '../redux/action/service-center-transaction.action';
import { apiFetchListServiceCenterTransaction, apiFetchOneServiceCenterTransaction } from '../api/service-center';

export default function useServiceCenterTransaction() {
  const dispatch = useDispatch();

  const fetchAllServiceCenterTransaction = async (params: any) => {
    try {
      let query = generateQueryString(params);

      const result = await apiFetchListServiceCenterTransaction(query);

      dispatch(findAllServiceCenterTransaction(result.data));

      dispatch(setLoadingServiceCenterTransaction(false));
    } catch (error: any) {
      dispatch(setLoadingServiceCenterTransaction(false));
      dispatch(setErrorServiceCenterTransaction(error));
    }
  };

  const fetchOneServiceCenterTransaction = async (id: any) => {
    try {

      const result = await apiFetchOneServiceCenterTransaction(id);

      dispatch(findOneServiceCenterTransaction(result.data.payload));

      dispatch(setLoadingServiceCenterTransaction(false));
    } catch (error: any) {
      dispatch(setLoadingServiceCenterTransaction(false));
      dispatch(setErrorServiceCenterTransaction(error));
    }
  };

  return {
    fetchAllServiceCenterTransaction,
    fetchOneServiceCenterTransaction
  };
}
