import { endOfDay, endOfMonth, startOfDay, startOfMonth, format } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiSummaryCustomer } from '../api/customer';
import {
  apiSummaryListTransaction,
  apiSummaryMostActiveTransaction,
  apiSummaryMostActiveUpline,
  apiSummaryTotalTransaction,
} from '../api/transaction';
import { convertDateTimeToString } from '../helpers/functions/datetime';
import {
  setSummaryCustomer,
  setSummaryListTransaction,
  setSummaryLoading,
  setSummaryMostActiveTransaction,
  setSummaryMostActiveUpline,
  setSummaryTotalTransaction,
} from '../redux/action/dashboard.action';
import { SummaryRequest } from '../types/customer.type';
export default function useDashboard() {
  const dispatch = useDispatch();

  const date = new Date();
  let defaultStartDate: Date | string = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  let defaultEndDate: Date | string = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );
  defaultStartDate = convertDateTimeToString(defaultStartDate);
  defaultEndDate = convertDateTimeToString(defaultEndDate);

  const generateQuery = (params: any) => {
    const queries = [];
    if (params.startAt) {
      queries.push(`startAt=${params.startAt}`);
    }
    if (params.endAt) {
      queries.push(`endAt=${params.endAt}`);
    }
    return `?${queries.join('&')}`;
  };

  const startOfDaye = startOfDay(new Date());
  const endOfDaye = endOfDay(new Date());
  const startOfMonthe = startOfMonth(new Date());
  const endOfMonthe = endOfMonth(new Date());

  const fetchSummaryMostActiveTransaction = async () => {
    try {
      dispatch(setSummaryLoading(true));

      const result = await apiSummaryMostActiveTransaction();

      if (
        result.data &&
        result.data.payload &&
        result.data.payload.length > 0
      ) {
        dispatch(setSummaryMostActiveTransaction(result.data.payload));
      }

      dispatch(setSummaryLoading(false));
    } catch (error) {
      console.info(error);
      dispatch(setSummaryLoading(false));
    }
  };

  const fetchSummaryMostActiveUpline = async () => {
    try {
      dispatch(setSummaryLoading(true));

      const result = await apiSummaryMostActiveUpline('limit=10&offset=0');

      if (
        result.data &&
        result.data.payload &&
        result.data.payload.results.length > 0
      ) {
        dispatch(setSummaryMostActiveUpline(result.data.payload.results));
      }

      dispatch(setSummaryLoading(false));
    } catch (error) {
      console.info(error);
      dispatch(setSummaryLoading(false));
    }
  };

  const fetchSummaryListTransaction = async () => {
    try {
      dispatch(setSummaryLoading(true));

      const result = await apiSummaryListTransaction();

      if (
        result.data &&
        result.data.payload &&
        result.data.payload.length > 0
      ) {
        dispatch(setSummaryListTransaction(result.data.payload));
      }

      dispatch(setSummaryLoading(false));
    } catch (error) {
      console.info(error);
      dispatch(setSummaryLoading(false));
    }
  };

  const fetchSummaryTotalTransaction = async (params?: any) => {
    try {
      dispatch(setSummaryLoading(true));

      const query = generateQuery(params);

      const result = await apiSummaryTotalTransaction(query);

      if (
        result.data &&
        result.data.payload &&
        result.data.payload.length > 0
      ) {
        console.info(result.data.payload);
        dispatch(setSummaryTotalTransaction(result.data.payload));
      }

      dispatch(setSummaryLoading(false));
    } catch (error) {
      console.info(error);
      dispatch(setSummaryLoading(false));
    }
  };

  const customerSummary = async (params: any) => {
    try {

      dispatch(setSummaryLoading(true));

      const query = '?' + Object.keys(params).map((key) => key + '=' + params[key]).join('&')

      const result = await apiSummaryCustomer(query);

      if (
        result.data &&
        result.data.payload
      ) {
        dispatch(setSummaryCustomer(result.data.payload));
      }

      dispatch(setSummaryLoading(false));

    } catch (error) {
      console.info(error);
      dispatch(setSummaryLoading(false));
    }
  }

  useEffect(() => {
    fetchSummaryMostActiveTransaction();
    fetchSummaryMostActiveUpline();
    fetchSummaryTotalTransaction({
      startAt: defaultStartDate,
      endAt: defaultEndDate,
    });
    fetchSummaryListTransaction();
    customerSummary({
      startOfDay: startOfDaye.toISOString(),
      endOfDay: endOfDaye.toISOString(),
      startOfMonth: format(startOfMonthe, 'yyyy-MM-d'),
      endOfMonth: format(endOfMonthe, 'yyyy-MM-d'),
    })
  }, []);

  return {
    defaultStartDate,
    defaultEndDate,
  };
}
