import axios from "axios";
import { getHeaders } from "../helpers/auth";
import {
  TransactionItem,
  TransactionResponse,
} from "../types/transaction.types";

// types
export enum ECommissionStatus {
  pending = "pending", // create
  failed = "failed", // failed
  processed = "processed", // historical?
  done = "done", // done
}

export type SingleCommission = {
  commissionId: string;
  commissionMetadata: {
    source: "my_transaction" | "downline_transaction";
    userDetail: {
      customerId: string;
      customerName: string;
    };
    sourceUserId: string;
    sourceUserName: string;
    commissionPercentage: string;
  };
  disburseMetadata: any;
  txId: string;
  txMetadata: TransactionResponse;
  userId: string;
  amount: number;
  status: ECommissionStatus;
  createdAt?: Date;
  processedAt?: Date;
  paidAt?: Date;
  updatedAt?: Date;
  user?: {
    name: string;
    phoneNumber: string;
  };
  invoice?: {
    invoiceId: string;
    externalId: string;
  };
};

export type GetListPendingCommissionResponse = {
  userId: string;
  status: string;
  user: {
    name: string;
    phoneNumber: string;
  };
  disburseMetadata: {
    bankOwnerName: string;
    bankAccountNumber: string;
    bankName: string;
    status: "approved" | "rejected" | "pending";
  };
  totalAmount: number;
};

export type GetListFailedCommissionResponse = {
  userId: string;
  status: string;
  user: {
    name: string;
    phoneNumber: string;
  };
  periodStart: Date;
  periodEnd: Date;
  disburseMetadata: {
    bankOwnerName: string;
    bankAccountNumber: string;
    bankName: string;
    status: "approved" | "rejected" | "pending";
  };
  totalAmount: number;
};

export type GetListDoneCommissionResponse = {
  userId: string;
  status: string;
  user: {
    name: string;
    phoneNumber: string;
  };
  periodStart: Date;
  periodEnd: Date;

  processedAt: Date;
  paidAt: Date;

  disburseMetadata: {
    bankOwnerName: string;
    bankAccountNumber: string;
    bankName: string;
    status: "approved" | "pending" | "rejected";
  };

  totalAmount: number;

  referenceId: string;
};

export type SingleCommissionResult = (
  | GetListPendingCommissionResponse
  | GetListFailedCommissionResponse
  | GetListDoneCommissionResponse
) & {
  commissionIds?: string[];
  commissions?: SingleCommission[];
};

export type GetListCommissionResponse = {
  count: number;
  next: string | null;
  prev: string | null;
  results: SingleCommissionResult[];
};

export type GetTotalCommissionPayload = {
  query: {
    filterStatus: string[];
    userId: string;
  };
  totalCommission: number;
};

//
export async function apiFetchListCommission(params: string): Promise<{
  data: {
    code: string;
    message: string;
    payload: GetListCommissionResponse;
  };
}> {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/commission" + query,
    method: "GET",
    headers,
  });
}

export async function apiExportCustomerComission(params: any) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL + "/commission/customer-referral/export",
    method: "POST",
    headers,
    responseType: "blob",
    data: params,
  });
}

export async function apiFetchListCommissionHistorical(
  params: string
): Promise<{
  data: {
    code: string;
    message: string;
    payload: GetListCommissionResponse;
  };
}> {
  const headers = await getHeaders();
  const query = params || null;
  return axios({
    url:
      process.env.REACT_APP_BASE_URL + "/commission/custom/historical" + query,
    method: "GET",
    headers,
  });
}

export async function apiGetTotalCommission(
  params: string
): Promise<GetTotalCommissionPayload> {
  const headers = await getHeaders();
  const query = params || null;
  const res: {
    data: {
      code: string;
      message: string;
      payload: GetTotalCommissionPayload;
    };
  } = await axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/commission/calculate/totalCommission" +
      query,
    method: "GET",
    headers,
  });

  return res.data.payload;
}
