import { Box, Typography } from '@material-ui/core';
import React from 'react'
import COLORS from '../../../styles/colors';

interface Props {
  totalReplaced: number
  totalApproved: number | null
  totalPickuped: number | null
  totalDelivered: number | null
}

const InfoProductRepl: React.FC<Props> = (props) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Replacement
          </Typography>
        <Typography><b>{props.totalReplaced}</b></Typography>
      </Box>
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Approved
          </Typography>
        <Typography
          style={!props.totalApproved
            ? { color: COLORS.grey, fontStyle: 'italic' }
            : { color: COLORS.primary }}
        >
          <b>{!props.totalApproved ? 'Not set' : props.totalApproved}</b>
        </Typography>
      </Box>
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Pickup
          </Typography>
        <Typography
          style={!props.totalPickuped
            ? { color: COLORS.grey, fontStyle: 'italic' }
            : { color: COLORS.primary }}
        >
          <b>{!props.totalPickuped ? 'Not set' : props.totalPickuped}</b>
        </Typography>
      </Box>
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Delivered
          </Typography>
        <Typography
          style={!props.totalPickuped
            ? { color: COLORS.grey, fontStyle: 'italic' }
            : { color: COLORS.primary }}
        ><b>{!props.totalDelivered ? 'Not set' : props.totalDelivered}</b></Typography>
      </Box>
    </Box>
  )
}

export default InfoProductRepl