import { IQueryTable } from '../../../hooks/useFetch';
import { DeliveryOrderProperties } from './delivery-order.type';

export interface B2BProductOrder {
  productId: string,
  sku: string,
  name: string,
  catalogPrice: number;
  distributorPrice: number;
  images: string[];
}

export interface ProductsOrdered extends B2BProductOrder{
  qty: number;
}

export interface ICreateSalesOrder {
  note: string
  distributorDiscountNote: number
  distributorDiscount: number
  totalPrice: number
  totalFinalPrice: number
  distributorId: string
  products: Array<ProductsOrdered>
}

export enum EB2BStatusRequest {
  posted = 'posted',
  approved = 'approved',
  partial = 'partial',
  closed = 'closed',
  cancel = 'cancel',
  cancel_by_system = 'cancel_by_system',
  all = 'all',
}

export interface ISalesOrderQuery extends IQueryTable {
  status: EB2BStatusRequest | null
}

export enum EB2BStatus {
  posted = 'posted',
  approved = 'approved',
  partial = 'partial',
  closed = 'closed',
  cancel = 'cancel',
  cancel_by_system = 'cancel_by_system',
}

export interface B2BProductMetadata {
  productId: string,
  sku: string,
  name: string,
  catalogPrice: number;
  distributorPrice: number;
  images: string[];
}

export interface ApprovedProductProperties {
  id: string,
  qtyApproved: number | null,
  qtyRequested: number,
  productMetadata: B2BProductMetadata,
  note?: string | null
}

export interface SalesOrderItemProperties {
  orderItemId: string;
  orderId: string;
  productMetadata: B2BProductMetadata;
  finalProductPrice: number;
  qtyOrdered: number;
  qtyApproved: number;
  qtyReceived: number;
  qtyLeftover: number;
  note: string;
  salesOrder?: SalesOrderProperties;
  updatedAt?: Date;
  createdAt?: Date;
  added?: boolean;
}

export interface SalesOrderProperties {
  orderId: string;
  soId: string | null;
  externalId: string;
  status: EB2BStatus;
  note: string;
  distributorDiscountNote: number;
  distributorDiscount: number;
  totalPrice: number;
  totalFinalPrice: number;
  totalQtyOrdered: number;
  totalQtyApproved: number | null;
  totalQtyReceived: number | null;
  totalQtyLeftover: number | null;
  approvalNote: string | null;
  distributorId: string;
  distributorMetadata: any;
  agentMetadata: any | null;
  approvedBy: string | null;
  approvedByMetadata: any | null;
  approvedAt: Date | null;
  updatedAt: Date | string;
  createdAt: Date | string;
  added?: boolean;
  data_products?: SalesOrderItemProperties[]
  deliveryOrders?: DeliveryOrderProperties[]
}

export const initialSalesOrder = {
  orderId: '',
  soId: null,
  externalId: '',
  status: EB2BStatus.posted,
  note: '',
  distributorDiscountNote: 0,
  distributorDiscount: 0,
  totalPrice: 0,
  totalFinalPrice: 0,
  totalQtyOrdered: 0,
  totalQtyApproved: null,
  totalQtyReceived: null,
  totalQtyLeftover: null,
  approvalNote: null,
  distributorId: '',
  distributorMetadata: {},
  agentMetadata: null,
  approvedBy: null,
  approvedByMetadata: null,
  approvedAt: null,
  updatedAt: '',
  createdAt: '',
  data_products: [],
  deliveryOrders: []
}
