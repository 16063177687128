import { ImageProduct } from "./product.type";
import { initialVoucherData, VoucherProperties } from "./voucher.type";

export enum TransactionTypes {
  FIND_ALL_TRANSACTION = "FIND_ALL_TRANSACTION",
  FIND_ONE_TRANSACTION = "FIND_ONE_TRANSACTION",
  SET_ONE_TRANSACTION = "SET_ONE_TRANSACTION",
  SET_TRANSACTION_PROCESS_RESULT = "SET_TRANSACTION_PROCESS_RESULT",
  SET_REFUND_TRANSACTION = "SET_REFUND_TRANSACTION",
  SET_REFUND_AMOUNT = "SET_REFUND_AMOUNT",
  FIND_HISTORY_TRANSACTION = "FIND_HISTORY_TRANSACTION",
  SET_LOADING_TRANSACTION = "SET_LOADING_TRANSACTION",
  SET_LOADING_PROCESS = "SET_LOADING_PROCESS",
  SET_ERROR_TRANSACTION = "SET_ERROR_TRANSACTION",
  SET_LAST_QUERY_TRANSACTION = "SET_LAST_QUERY_TRANSACTION",
}

export type TransactionItem = {
  transactionId: string;
  productId: string;
  productMetadata: {
    id: string;
    name: string;
    brand: string;
    price: number;
    weight: number;
    category: number;
    merchant_name: string;
  };
  price: number;
  qty: number;
  discount: number;
  discountText: string;
  finalTotalPrice: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type TransactionInvoice = {
  paymentId: string;
  invoiceId: string;
  paymentGateway: string;
  paymentMethod: string;
  paymentVendor: string;
  paymentRequestMetadata: any;
  paymentAccount: {
    bank: string;
    type: string;
    bill_key: string;
    biller_code: string;
  };
  amount: number;
  vendorFee: number;
  paymentStatus: string;
  paidAt: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type TransactionLog = {
  additionalMetadata: string | null;
  createdAt: Date | string;
  id: number;
  note: string | null;
  statusAfter: string;
  statusBefore: string | null;
  transactionId: string;
  updatedAt: Date | string;
  updatedBy: string | null;
};

export type TransactionListResponse = TransactionResponse & {
  invoice: InvoiceResponse;
  update_status_logs: TransactionLog[];
  refundMetadata?: {
    images: ImageProduct[];
  };
};

export type TransactionHistoryPayload = {
  code: string | null;
  message: string | null;
  payload: {
    page: number;
    perPage: number;
    totalPage: number;
    totalData: number;
    data: TransactionLog[];
  };
};

export type TransactionResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    offset: number;
    limit: number;
    totalPage: number;
    count: number;
    results: TransactionListResponse[];
  };
};

export type CustomerBankMetadata = {
  accountName: string;
  accountNumber: string;
  bankId: string;
  bankName: string;
  customerBankId: string;
  status: string;
};

export type TransactionMetadata = {
  buyer: {
    name: string;
    gender: string;
    correspondenceEmail: string;
    phoneNumber: string;
    bankAccount?: CustomerBankMetadata;
    customerId?: string;
    loyalty?: any;
  };
  summaryPaymentTransaction: {
    totalPrice: number;
    totalDiscountProduct: number;
    totalDiscountReferral: number | null;
    totalDiscountVoucher: number | null;
    totalPay: number;
    referralValue: number | null;
  };
  pickup?: any;
  finalTransactionPriceBefore?: any;
  finalTransactionPriceAfter?: any;
};

export type ProductMetadata = {
  id: string;
  name: string;
  sku: string;
  brand: string;
  price: number;
  images: string[];
  weight: number;
  category: string | string[];
  merchant_name: string;
};

export type ProductItem = {
  transactionId: string;
  productId: string;
  productMetadata: ProductMetadata;
  price: number;
  qty: number;
  note: string;
  discount: number;
  discountText: string;
  finalTotalPrice: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type ExpeditionMetadata = {
  addressId: string;
  addressName: string;
  fullAddress: string;
  serviceName: string;
  receiverName: string;
  receiverPhone: string;
  expeditionName: string;
};

export type Shipping = {
  transactionId: string;
  expeditionCode: string;
  expeditionService: string;
  expeditionEtd: string;
  expeditionMetadata: ExpeditionMetadata;
  fromProvinceId: string;
  fromCityId: string;
  fromSubdistrictId: string;
  fromAddress: string;
  toProvinceId: string;
  toCityId: string;
  toSubdistrictId: string;
  toAddress: string;
  waybill: string | null;
  weight: number;
  shippingCost: number;
  shippingDiscount: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type Pickup = {
  metaAddress: {
    name: string;
    phone: string;
    address: string;
    city: string;
    subDistrict: string;
    province: string;
    location: {
      lat: string;
      lng: string;
    };
  };
  pickupPIN: string;
};

export type TransactionResponse = {
  createdAt: Date | string;
  discount: number;
  discountText: string;
  externalId: string;
  finalTransactionPrice: number;
  items: ProductItem[];
  shipping: Shipping;
  pickup: Pickup;
  earnedCoin?: any;
  note?: string;
  transactionId: string;
  transactionMetadata: TransactionMetadata;
  transactionPrice: number;
  transactionStatus: "created" | "paid" | "process" | "sent" | "rejected";
  updatedAt: Date | string;
  voucherMetadata: VoucherProperties;
  aroId?: string | null;
  aroMetadata?: any;
  coinUsed?: number;
  shipmentType: string;
};

export type Payment = {
  paymentId: string;
  invoiceId: string;
  paymentGateway: string;
  paymentMethod: string;
  paymentVendor: string;
  paymentRequestMetadata: PaymentRequestMetadata;
  paymentAccount: PaymentAccount;
  amount: number;
  vendorFee: number;
  paymentStatus: string;
  paidAt: Date | string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type PaymentRequestMetadata = {
  item_details: [];
  customer_details: {
    billing_address: {};
    shipping_address: {};
  };
  transaction_details: {
    order_id: string;
    gross_amount: number;
  };
};

export type PaymentAccount = {
  bank: string;
  type: string;
  bill_key: string;
  biller_code: string;
};

export type InvoiceResponse = {
  invoiceId: string;
  externalId: string;
  description: string | null;
  vendorInvoiceId: string;
  amount: number;
  invoiceStatus: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  payment: Payment;
};

export type TransactionState = {
  isLoadingTransaction: boolean;
  isLoadingProcess: boolean;
  data: TransactionResponsePayload;
  transaction: TransactionResponse;
  invoice: InvoiceResponse;
  transactionHistory: any;
  totalRefundedAmount: number;
  error: any;
  lastPathQuery: {
    limit?: number;
    offset?: number;
    search?: string;
    filterStatus?: string;
    startAt?: string;
    endAt?: string;
  };
  isSuccess: boolean;
};

export const initialStateInvoice: InvoiceResponse = {
  invoiceId: "",
  externalId: "f",
  description: null,
  vendorInvoiceId: "",
  amount: 0,
  invoiceStatus: "",
  createdAt: "",
  updatedAt: "",
  payment: {
    paymentId: "",
    invoiceId: "",
    paymentGateway: "",
    paymentMethod: "",
    paymentVendor: "",
    paymentRequestMetadata: {
      item_details: [],
      customer_details: {
        billing_address: {},
        shipping_address: {},
      },
      transaction_details: {
        order_id: "",
        gross_amount: 0,
      },
    },
    paymentAccount: {
      bank: "",
      type: "",
      bill_key: "",
      biller_code: "",
    },
    amount: 0,
    vendorFee: 0,
    paymentStatus: "",
    paidAt: "",
    createdAt: "",
    updatedAt: "",
  },
};

export const initialStateTransaction: TransactionResponse = {
  transactionId: "",
  externalId: "",
  transactionMetadata: {
    buyer: {
      name: "",
      gender: "",
      correspondenceEmail: "",
      phoneNumber: "",
    },
    summaryPaymentTransaction: {
      totalPrice: 0,
      totalDiscountProduct: 0,
      totalDiscountReferral: null,
      totalDiscountVoucher: null,
      totalPay: 0,
      referralValue: null,
    },
  },
  transactionPrice: 0,
  discount: 0,
  finalTransactionPrice: 0,
  discountText: "",
  shipmentType: "",
  transactionStatus: "paid",
  createdAt: "",
  updatedAt: "",
  items: [],
  pickup: {
    metaAddress: {
      name: "",
      phone: "",
      address: "",
      province: "",
      subDistrict: "",
      city: "",
      location: {
        lat: "",
        lng: "",
      },
    },
    pickupPIN: "",
  },
  shipping: {
    transactionId: "",
    expeditionCode: "",
    expeditionService: "",
    expeditionEtd: "",
    expeditionMetadata: {
      addressId: "",
      addressName: "",
      fullAddress: "",
      serviceName: "",
      receiverName: "",
      receiverPhone: "",
      expeditionName: "",
    },
    fromProvinceId: "",
    fromCityId: "",
    fromSubdistrictId: "",
    fromAddress: "",
    toProvinceId: "",
    toCityId: "",
    toSubdistrictId: "",
    toAddress: "",
    waybill: null,
    weight: 0,
    shippingCost: 0,
    shippingDiscount: 0,
    createdAt: "",
    updatedAt: "",
  },
  voucherMetadata: initialVoucherData,
};
