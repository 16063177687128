import { CategoryResponse, CategoryResponsePayload, CategoryTypes } from '../../types/category.type'


export function findAllCategory(payload: CategoryResponsePayload) {
  return {
    type: CategoryTypes.FIND_ALL_CATEGORY,
    payload
  }
}

export function findOneCategory(payload: CategoryResponse) {
  return {
    type: CategoryTypes.FIND_ONE_CATEGORY,
    payload
  }
}

export function createCategory(payload: CategoryResponse) {
  return {
    type: CategoryTypes.CREATE_CATEGORY,
    payload
  }
}

export function updateCategory(payload: CategoryResponse) {
  return {
    type: CategoryTypes.UPDATE_CATEGORY,
    payload
  }
}

export function deleteCategory(payload: any) {
  return {
    type: CategoryTypes.REMOVE_CATEGORY,
    payload
  }
}

export function setLoadingCategory(payload: boolean) {
  return {
    type: CategoryTypes.SET_LOADING_CATEGORY,
    payload
  }
}

export function setErrorCategory(payload: any) {
  return {
    type: CategoryTypes.SET_ERROR_CATEGORY,
    payload
  }
}