import { Reducer } from "redux";
import {
  ProductResponse,
  ProductState,
  ProductTypes,
} from "../../types/product.type";

export const initProduct = {
  productId: "",
  createdByUserId: "",
  productName: "",
  sku: "",
  metadata: {
    specifications: [],
    details: [],
  },
  initialWarranty: "",
  videoMetaData: [],
  description: "",
  published: false,
  price: {
    productId: "",
    catalogPrice: 0,
    retailDiscountFormula: "",
    retailDiscountNote: "",
    retailDiscountAmount: 0,
    retailPrice: 0,
    goldPrice: 0,
    goldDiscountFormula: "",
    goldDiscountNote: "",
    goldDiscountAmount: 0,
    platinumPrice: 0,
    platinumDiscountFormula: "",
    platinumDiscountNote: "",
    platinumDiscountAmount: 0,
    distributorPrice: 0,
  },
  categories: [],
  stock: {
    currentStock: 0,
  },
  images: [],
  b2b: false,
  b2c: false,
  type: "",
};

export const initProductHistory = {
  id: "",
  productId: "",
  updatedBy: "",
  note: "",
  discountNote: {
    silver: "",
    gold: "",
    platinum: "",
  },
  discountAmount: {
    silver: 0,
    gold: 0,
    platinum: 0,
  },
  retailPrice: {
    silver: 0,
    gold: 0,
    platinum: 0,
  },
  createdAt: "",
  updatedAt: "",
};

const initialState: ProductState = {
  isLoadingProduct: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  dataHistory: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  product: initProduct,
  productHistory: initProductHistory,
  error: {},
  lastPathProduct: "",
  isLoadingImageAdd: false,
  isLoadingImageRemove: false,
};

export const product: Reducer<ProductState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ProductTypes.FIND_ALL_PRODUCT:
      const results = action.payload.payload.results.map(
        (item: ProductResponse) => {
          return {
            ...item,
            type:
              item.b2c && item.b2c
                ? "B2C/B2B"
                : item.b2c
                  ? "B2C"
                  : item.b2b
                    ? "B2B"
                    : "",
            metadata:
              typeof item.metadata === "string"
                ? JSON.parse(item.metadata)
                : item.metadata,
          };
        }
      );

      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };

    case ProductTypes.FIND_HISTORY_PRODUCT_PRICES:
      return {
        ...state,
        dataHistory: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
          },
        },
      };

    case ProductTypes.FIND_ONE_PRODUCT:
      return {
        ...state,
        product: {
          ...action.payload,
          metadata:
            typeof action.payload.metadata === "string"
              ? JSON.parse(action.payload.metadata)
              : action.payload.metadata,
        },
      };
    case ProductTypes.ADD_PRODUCT_IMAGE:
      return {
        ...state,
      };
    case ProductTypes.REMOVE_PRODUCT_IMAGE:
      return {
        ...state,
      };
    case ProductTypes.SET_LAST_PATH_PRODUCT:
      return {
        ...state,
        lastPathProduct: action.payload,
      };
    case ProductTypes.SET_LOADING_PRODUCT_IMAGE_ADD:
      return {
        ...state,
        isLoadingImageAdd: action.payload,
      };
    case ProductTypes.SET_LOADING_PRODUCT_IMAGE_REMOVE:
      return {
        ...state,
        isLoadingImageRemove: action.payload,
      };
    case ProductTypes.SET_LOADING_PRODUCT:
      return {
        ...state,
        isLoadingProduct: action.payload,
      };
    case ProductTypes.SET_ERROR_PRODUCT:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
