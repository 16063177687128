import axios from "axios";
import { getHeaders } from "../helpers/auth";

export async function apiFindAllCollection(params: string) {
  const query = params || null;
  const headers = await getHeaders();

  return axios({
    url: process.env.REACT_APP_BASE_URL + "/collection" + query,
    method: "GET",
    headers,
  });
}

export async function apiUpdateCollection(data: any) {
  const headers = await getHeaders();
  console.log(data);

  return axios({
    url: process.env.REACT_APP_BASE_URL + "/collection/" + data.collectionId,
    method: "PATCH",
    headers: headers,
    data,
  });
}
