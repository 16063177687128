import { VersionState, VersionTypes } from '../../types/version.type';
import { Reducer } from 'redux';

const initialState: VersionState = {
  isLoadingVersion: false,
  data: {
    code: '',
    message: '',
    payload: {
      prev: '',
      next: '',
      count: 0,
      results: [],
    },
  },
  version: {
    appVersionId: '',
    androidVersion: '',
    iosVersion: '',
    forceUpdate: false,
    forceUpdateIos: false,
    adminMetadata: {
      adminName: '',
      role: '',
    },
  },
  currentVersion: {
    appVersionId: '',
    androidVersion: '',
    iosVersion: '',
    forceUpdate: false,
    forceUpdateIos: false,
    adminMetadata: {
      adminName: '',
      role: '',
    },
  },
  error: {}
}

export const version: Reducer<VersionState> = (state = initialState, action) => {
  switch (action.type) {
    case VersionTypes.FIND_ALL_VERSION:
      return {
        ...state,
        data: action.payload
      }
    case VersionTypes.FIND_ONE_VERSION:
      return {
        ...state,
        version: action.payload
      }
    case VersionTypes.CREATE_VERSION:
      return {
        ...state,
      }
    case VersionTypes.UPDATE_VERSION:
      return {
        ...state,
      }
    case VersionTypes.DELETE_VERSION:
      return {
        ...state,
      }
    case VersionTypes.SET_LOADING_VERSION:
      return {
        ...state,
        isLoadingVersion: action.payload
      }
    case VersionTypes.CURRENT_VERSION:
      return {
        ...state,
        currentVersion: action.payload
      }

    default:
      return state;
  }
}
