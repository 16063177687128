
export enum FeedbackTypes {
  FIND_ALL_FEEDBACK = 'FIND_ALL_FEEDBACK',
  FIND_ONE_FEEDBACK = 'FIND_ONE_FEEDBACK',
  UPDATE_FEEDBACK = 'UPDATE_FEEDBACK',
  SET_LOADING_FEEDBACK = 'SET_LOADING_FEEDBACK',
  SET_ERROR_FEEDBACK = 'SET_ERROR_FEEDBACK',
  SET_LAST_PATH_FEEDBACK = 'SET_LAST_PATH_FEEDBACK',
}

export type FeedbackResponse = {
  feedback: string,
  deviceInformation: {
    brand: string,
    deviceApi: string,
    fontScale: string,
    appVersion: string,
    systemName: string,
    systemVersion: string
  },
  userId: string,
  userMetaData: {
    role: string,
    email: string,
    phoneNumber: string,
    customerName: string
  },
  feedbackId: string,
  status: string,
  createdAt?: Date | string,
  updatedAt?: Date | string,
  adminId?: string,
  adminMetaData: any
}

export type FeedbackRequest = {
  status: string
}

export type FeedbackResponsePayload = {
  code: string;
  message: string;
  payload: {
    prev: string;
    next: string;
    count: number;
    results: FeedbackResponse[];
  }
}

export type FeedbackState = {
  isLoadingFeedback: boolean;
  data: FeedbackResponsePayload;
  feedback: FeedbackResponse;
  error: any;
}