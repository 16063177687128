import { IconProps } from "./IconProps";

export default function VoucherCommissionIcon(props: IconProps) {
  return (
    <svg width={props.size || '22'} height={props.size || '22'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_865_22398" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.49998 7.56216C3.98198 7.56216 2.74963 8.79451 2.74963 10.3125C2.74963 11.8305 3.98198 13.0629 5.49998 13.0629C7.01798 13.0629 8.25032 11.8305 8.25032 10.3125C8.25032 8.79451 7.01798 7.56216 5.49998 7.56216ZM5.49998 8.93785C6.25898 8.93785 6.87463 9.55351 6.87463 10.3125C6.87463 11.0715 6.25898 11.6872 5.49998 11.6872C4.74098 11.6872 4.12532 11.0715 4.12532 10.3125C4.12532 9.55351 4.74098 8.93785 5.49998 8.93785Z" />
      </mask>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.49998 7.56216C3.98198 7.56216 2.74963 8.79451 2.74963 10.3125C2.74963 11.8305 3.98198 13.0629 5.49998 13.0629C7.01798 13.0629 8.25032 11.8305 8.25032 10.3125C8.25032 8.79451 7.01798 7.56216 5.49998 7.56216ZM5.49998 8.93785C6.25898 8.93785 6.87463 9.55351 6.87463 10.3125C6.87463 11.0715 6.25898 11.6872 5.49998 11.6872C4.74098 11.6872 4.12532 11.0715 4.12532 10.3125C4.12532 9.55351 4.74098 8.93785 5.49998 8.93785Z" fill={props.color || '#fff'} />
      <path d="M5.49998 6.06216C3.15355 6.06216 1.24963 7.96608 1.24963 10.3125H4.24963C4.24963 9.62294 4.8104 9.06216 5.49998 9.06216V6.06216ZM1.24963 10.3125C1.24963 12.6589 3.15355 14.5629 5.49998 14.5629V11.5629C4.8104 11.5629 4.24963 11.0021 4.24963 10.3125H1.24963ZM5.49998 14.5629C7.8464 14.5629 9.75032 12.6589 9.75032 10.3125H6.75032C6.75032 11.0021 6.18955 11.5629 5.49998 11.5629V14.5629ZM9.75032 10.3125C9.75032 7.96608 7.8464 6.06216 5.49998 6.06216V9.06216C6.18955 9.06216 6.75032 9.62294 6.75032 10.3125H9.75032ZM5.49998 10.4379C5.43055 10.4379 5.37463 10.3819 5.37463 10.3125H8.37463C8.37463 8.72508 7.0874 7.43785 5.49998 7.43785V10.4379ZM5.37463 10.3125C5.37463 10.2431 5.43055 10.1872 5.49998 10.1872V13.1872C7.0874 13.1872 8.37463 11.8999 8.37463 10.3125H5.37463ZM5.49998 10.1872C5.5694 10.1872 5.62532 10.2431 5.62532 10.3125H2.62532C2.62532 11.8999 3.91255 13.1872 5.49998 13.1872V10.1872ZM5.62532 10.3125C5.62532 10.3819 5.5694 10.4379 5.49998 10.4379V7.43785C3.91255 7.43785 2.62532 8.72508 2.62532 10.3125H5.62532Z" fill={props.color || '#fff'} mask="url(#path-1-inside-1_865_22398)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7188 6.1875C11.5802 6.1875 10.6562 7.1115 10.6562 8.25C10.6562 9.3885 11.5802 10.3125 12.7188 10.3125C13.8573 10.3125 14.7812 9.3885 14.7812 8.25C14.7812 7.1115 13.8573 6.1875 12.7188 6.1875ZM12.7188 7.5625C13.0982 7.5625 13.4062 7.8705 13.4062 8.25C13.4062 8.6295 13.0982 8.9375 12.7188 8.9375C12.3393 8.9375 12.0312 8.6295 12.0312 8.25C12.0312 7.8705 12.3393 7.5625 12.7188 7.5625Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.62501 18.5625V18.1369C9.62501 16.0937 7.96899 14.4375 5.92625 14.4375H5.07375C3.03102 14.4375 1.375 16.0937 1.375 18.1369V18.5625C1.375 19.1098 1.59242 19.6343 1.97914 20.0207C2.36586 20.4078 2.89009 20.625 3.43751 20.625C4.63548 20.625 6.36453 20.625 7.5625 20.625C8.10992 20.625 8.63414 20.4078 9.02086 20.0207C9.40758 19.6343 9.62501 19.1098 9.62501 18.5625ZM8.25 18.1362V18.5625C8.25 18.7447 8.17782 18.92 8.04891 19.0486C7.92001 19.1778 7.74469 19.25 7.5625 19.25H3.43751C3.25532 19.25 3.08 19.1778 2.95109 19.0486C2.82219 18.92 2.75001 18.7447 2.75001 18.5625V18.1369C2.75001 16.8534 3.7907 15.8125 5.07375 15.8125H5.92625C7.2093 15.8125 8.25 16.8534 8.25 18.1362Z" fill={props.color || '#fff'} />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.1875 5.70226V3.43765C6.1875 3.05766 6.4955 2.74975 6.875 2.74975H18.5625C18.942 2.74975 19.25 3.05766 19.25 3.43765V13.0625C19.25 13.2452 19.1778 13.4199 19.0486 13.5487C18.92 13.6776 18.7447 13.7504 18.5625 13.7504H10.3125C9.933 13.7504 9.625 14.0583 9.625 14.4375C9.625 14.8168 9.933 15.1247 10.3125 15.1247C10.3125 15.1247 15.9026 15.1247 18.5625 15.1247C19.1098 15.1247 19.6343 14.9078 20.0207 14.5212C20.4078 14.134 20.625 13.6099 20.625 13.0625C20.625 10.6814 20.625 5.81874 20.625 3.43765C20.625 2.29842 19.7017 1.3754 18.5625 1.3754H6.875C5.73581 1.3754 4.8125 2.29842 4.8125 3.43765C4.8125 4.46841 4.8125 5.70226 4.8125 5.70226C4.8125 6.08152 5.1205 6.38944 5.5 6.38944C5.8795 6.38944 6.1875 6.08152 6.1875 5.70226Z" fill={props.color || '#fff'} />
    </svg>
  );
}
