import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { convertDateTimeToString } from "../../helpers/functions/datetime";
import useLoyalty from "../../hooks/useLoyalty";
import { ApplicationState } from "../../redux/store";
import { LoyaltyHistoryResponse } from "../../types/master-data-referral.type";

interface ParamsType {
  id: string;
}
const DetailLoyalty = () => {
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { id } = useParams<ParamsType>();
  const [query, setQuery] = useState({ limit: 25, offset: 0 });
  const classes = useStyles();
  const { logLoyalty, loyaltyDetail } = useSelector(
    (state: ApplicationState) => state.masterDataReferral
  );

  const { fetchLoyaltyHistory, fetchOneLoyalty } = useLoyalty();

  const headers: ITableHeader[] = [
    {
      title: "Date",
      align: "left",
      column: "date",
      type: "dd mmm yyyy hh:mm",
    },
    {
      title: "Data",
      align: "left",
      column: "metaData",
      type: "text",
    },
    {
      title: "Aktor",
      align: "left",
      column: "aktor",
      type: "text",
    },
    {
      title: "Role",
      align: "left",
      column: "role",
      type: "text",
    },
    {
      title: "remark",
      align: "left",
      type: "text",
      column: "remark",
    },
  ];

  useEffect(() => {
    fetchLoyaltyHistory(id, query);
    fetchOneLoyalty(id);
  }, []);

  useEffect(() => {
    if (logLoyalty.payload.count > 0) {
      setTotalData(logLoyalty.payload.count);
      setTotalPage(Math.ceil(logLoyalty.payload.count / rowsPerPage));
    }
  }, [logLoyalty.payload]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  const data = logLoyalty?.payload?.results?.map(
    (item: LoyaltyHistoryResponse) => {
      return {
        date: item.updatedAt,
        metaData:
          item.metaData.loyaltyName +
          ", " +
          item.metaData.coinExpired +
          " Bulan, " +
          item.metaData.coin +
          " Koin, " +
          convertNumToCurrency(item.metaData.minTotalTransaction) +
          " - " +
          (item.metaData.maxTotalTransaction == 1000000000
            ? "Unlimited"
            : convertNumToCurrency(item.metaData.maxTotalTransaction)),
        aktor: item.adminMetaData.adminName,
        role: item.adminMetaData.role,
        remark: item.remark,
      };
    }
  );

  return (
    <Layout title="Detail Loyalty" loading={false}>
      <HeaderPage
        title="Detail Loyalty"
        subtitle="Detail Information About Loyalty"
        showBackButton
      />
      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body1" color="textSecondary">
                Nama Loyalty
              </Typography>
              <Typography variant="body1">
                {loyaltyDetail.loyaltyName}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                Min Akumulasi Transaksi
              </Typography>
              <Typography variant="body1">
                {convertNumToCurrency(loyaltyDetail.minTotalTransaction)}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                Created at
              </Typography>
              <Typography variant="body1">
                {convertDateTimeToString(loyaltyDetail.createdAt)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body1" color="textSecondary">
                Masa berlaku koin
              </Typography>
              <Typography variant="body1">
                {loyaltyDetail.coinExpired} Bulan
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                Max Akumulasi Transaksi
              </Typography>
              <Typography variant="body1">
                {loyaltyDetail.maxTotalTransaction == 1000000000
                  ? "Unlimited"
                  : convertNumToCurrency(loyaltyDetail.maxTotalTransaction)}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ marginTop: 10 }}
              >
                Updated at
              </Typography>
              <Typography variant="body1">
                {convertDateTimeToString(loyaltyDetail.updatedAt)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography variant="body1" color="textSecondary">
                Koin didapat setiap kelipatan Rp100.000
              </Typography>
              <Typography variant="body1">{loyaltyDetail.coin}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <div style={{ marginTop: 60 }}>
        <Paper
          elevation={3}
          className={classes.paper}
          style={{ marginBottom: -5 }}
        >
          <Typography variant="h5">Riwayat Loyalty</Typography>
        </Paper>
        <CustomizedTable
          data={data}
          headers={headers}
          totalData={totalData}
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          onChange={(newPage) => changePagination(newPage)}
          onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
        />
      </div>
    </Layout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingLeft: 10,
    },
  })
);

export default DetailLoyalty;
