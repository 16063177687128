import React, {
	useState,
	useEffect,
  useRef,
  useMemo
} from 'react';
import {
  Grid,
  Typography,
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Modal
} from '@material-ui/core';
import Layout from '../../components/Layout/Layout';
import FilterDate from '../../components/Form/FilterDate';
import Search from '../../components/Form/Search';
import AppButton from '../../components/Form/AppButton';
import { Circle, Download, Info, Upload, X, FileText } from 'react-feather';
import HeaderPage from '../../components/Layout/HeaderPage';
import COLORS from '../../styles/colors';
import Disburse from '../../components/Icon/Disburse';
import AppTabs from '../../components/Tabs/AppTabs';
import CustomizedTable, {
  ITableHeader,
  StyledTableCell
} from '../../components/Table/CustomizedTable';
import { toast } from 'react-toastify';

import { convertDateTimeToText } from '../../helpers/functions/datetime';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import usePaginateTable, {
	IPaginateTable
} from '../../hooks/usePaginateTable';
import useDebounce from '../../hooks/useDebounce';

import { apiCheckBalance } from '../../api/disbursement';
import {
	apiFetchListAroDisbursementAvailable,
	SingleAroDisbursementAvailableResult,
	IAroDisburseDto,
  apiCreateAroDisbursement,
  apiRecreateAroDisbursement,
  apiFetchListAroDisbursementHistory,
  AroDisbursementProperties,
  apiExportDisbursementAvailable,
  ExportAvailableAroParams,
  apiExportDisbursementHistory,
  ExportHistoryAroParams
} from '../../api/aro-disbursement';
import FormInput from "../../components/Form/FormInput";
import { format } from 'date-fns';

const tabs = ['Disburse', 'Disburse Failed', 'Historical'];

// header
let headers: ITableHeader[] = [
  {
    title: 'ID',
    type: 'id',
    column: 'customId',
  },
  {
    title: 'params',
    type: 'params',
    column: 'params',
  },
  {
    title: 'Agent Region',
    align: 'left',
    sortable: true,
    column: 'labelName',
  },
  {
    title: 'Phone',
    align: 'left',
    column: 'phone',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bank_number',
  },
  {
    title: 'Amount',
    align: 'right',
    alignContent: 'right',
    column: 'amount_text',
  },
];

let headersHistorical: ITableHeader[] = [
  {
    title: 'ID',
    type: 'id',
    column: 'customId',
  },
  {
    title: 'params',
    type: 'params',
    column: 'params',
  },
  {
    title: 'Name',
    align: 'left',
    sortable: true,
    column: 'labelName',
  },
  {
    title: 'Periode',
    align: 'left',
    column: 'period',
  },
  {
    title: 'Disburse Date',
    type: 'date',
    align: 'left',
    column: 'disburse_date',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bank_number',
  },
  {
    title: 'Potong Nota',
    align: 'right',
    alignContent: 'right',
    type: 'currency',
    column: 'notaPrice',
  },
  {
    title: 'Disburse',
    align: 'right',
    alignContent: 'right',
    type: 'currency',
    column: 'payoutPrice',
  },
  {
    title: 'Status',
    align: 'center',
    alignContent: 'center',
    column: 'status',
  },
  {
    title: 'Remark',
    align: 'center',
    alignContent: 'center',
    column: 'remarkAction'
  }
];

type InputAroDisbursement = {
	notaPrice: string;
	remark: string;
}
type InputAroDisbursementState = {
	[key: string]: InputAroDisbursement;
}

const AroDisbursement: React.FC = () => {
	const classes = useStyles();
	const [isLoadingDisburse, setIsLoadingDisburse] = useState(false);
	const [search, setSearch] = useState('');
	const [selected, setSelected] = useState<any[]>([]);
	const [activeTab, setActiveTab] = useState(0);
  const [IRISBalance, setIRISBalance] = useState(0);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [disburseValue, setDisburseValue] = useState<InputAroDisbursementState>({});
  const [showDisburseModal, setShowDisburseModal] = useState<boolean>(false);
  const [showFailedDisburseModal, setShowFailedDisburseModal] = useState<boolean>(false);
  const [showRemark, setShowRemark] = useState<null|string|undefined>(null);

  const debouncedSearch = useDebounce(search, 500);

  const isFirstMount = useRef(true);
  const isFirstMount2 = useRef(true);

  const {
    onChangePage,
    onChangeRowsPerPage,
    fetchList,
    loading,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable & { data: SingleAroDisbursementAvailableResult[] } = usePaginateTable(
    ( activeTab === 0
      ? apiFetchListAroDisbursementAvailable
      : apiFetchListAroDisbursementHistory
    ),
    {
      limit: 25,
      offset: 0,
      filterStatus: 'pending',
      startAt: '',
      endAt: '',
    }
  );

  useEffect(()=>{
    if(selected.length>0 && !params.startAt && !params.endAt){
      toast.error('Pilih periode disburse dahulu!', {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    }
  }, [selected])

  const totalSelectedDisbursement = useMemo(()=>(
    data[0]?.aroDisbursementId === undefined
    ? data.filter((v)=>selected.includes(v.aroId))
    .reduce((partialSum: number, a) => {
          let notaPrice: number = disburseValue[a.aroId] ? Number(disburseValue[a.aroId].notaPrice): 0;
          return partialSum + (Number(a.amount) - notaPrice)
        }, 0)
    : data.filter((v)=>selected.includes(v.aroDisbursementId))
    .reduce((partialSum: number, a) => partialSum + Number(a.payoutPrice), 0)
  ),[selected, data, disburseValue])

  const __handleDisburseModal = ()=>{
    for(const item of (data.filter((v)=>selected.includes(v.aroId)))){
      let notaPrice = (disburseValue[item.aroId]
        ? Number(disburseValue[item.aroId].notaPrice)
        : 0)
      let remark = (disburseValue[item.aroId]
        ? disburseValue[item.aroId].remark
        : '')
      let remarkRequired = disburseValue[item.aroId]
        ? disburseValue[item.aroId].notaPrice && Number(disburseValue[item.aroId].notaPrice)
          ? true
          : false
        : false;
      let payoutPrice = item.amount - notaPrice;

      if(remarkRequired&&remark === ''){
        return toast.error("Remark in "+item.labelName+" can\'t be empty!", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
      }
      if(payoutPrice<0){
        return toast.error("Payout in "+item.labelName+" can\'t below 0!", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });
      }
    };
    
    setShowDisburseModal(true);
  }

  useEffect(()=>{
    if(isFirstMount2.current){
      isFirstMount2.current=false;
    }else{
      fetchList({
        search: debouncedSearch
      })
    }
  },[debouncedSearch]);

  const __resetSelecton = ()=>{
    setSelected([])
    setDisburseValue({});
    setExpanded([]);
  }

  const doDisburse = () => {
  	const dto: IAroDisburseDto[] = [];

  	data.forEach((item)=>{
  		if(selected.includes(item.aroId)){
  			dto.push({
  				aroId: item.aroId,
  				transactionIds: item.transactionIds,
  				remark: (disburseValue[item.aroId]
  					? disburseValue[item.aroId].remark
  					: ''),
  				notaPrice: (disburseValue[item.aroId]
  					? disburseValue[item.aroId].notaPrice
  					: '')
  			})
  		}
  	});
    setIsLoadingDisburse(true);
    apiCreateAroDisbursement({
      periodStart: params.startAt,
      periodEnd: params.endAt,
      items: dto
    })
    .then(()=>{
      toast.success("Success disburse agent region!", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setShowDisburseModal(false);
      __resetSelecton();
      fetchList();
    })
    .finally(()=>setIsLoadingDisburse(false))
  }

  const doReDisburse = () => {
    setIsLoadingDisburse(true);
    apiRecreateAroDisbursement({
     items:selected
    })
    .then(()=>{
      toast.success("Success disburse agent region!", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
      setShowFailedDisburseModal(false);
      __resetSelecton();
      fetchList();
    })
    .finally(()=>setIsLoadingDisburse(false))
  }

  const exportDisbursement = () => {
    setIsLoadingDisburse(true);
    if(activeTab>0){

      const dataDisburse: ExportHistoryAroParams = {
        aroDisbursementIds: selected,
      };

      apiExportDisbursementHistory(dataDisburse)
      .then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          const downloadDate = format(new Date(), 'dd/MM/yyyy');
          link.setAttribute('download', `oxone-${activeTab===1?'failed':'history'}-aro-disbursement-${downloadDate}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

        setIsLoadingDisburse(false);
      })
      .catch((err) => {
        console.error('error apiExportDisbursementHistory', err);
        setIsLoadingDisburse(false);
      });

    }else{
      const dataDisburse: ExportAvailableAroParams = {
        startAt: params.startAt,
        endAt: params.endAt,
        aroIds: selected,
      };

      apiExportDisbursementAvailable(dataDisburse)
      .then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          const downloadDate = format(new Date(), 'dd/MM/yyyy');
          link.setAttribute('download', `oxone-available-aro-disbursement-${downloadDate}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
        }

        setIsLoadingDisburse(false);
      })
      .catch((err) => {
        console.error('error apiExportDisbursementAvailable', err);
        setIsLoadingDisburse(false);
      });
    }
  };

  useEffect(()=>{
  	getIRISBalance();
  },[]);

  const getIRISBalance = () => {
    apiCheckBalance()
      .then((res) => {
        setIRISBalance(Number(res.balance));
      })
      .catch((err) => {
        console.error('Error getIRISBalance', err);
      });
  };

  const onChangeTab = (value: number) => {
    __resetSelecton();
    setActiveTab(value);
  };

  useEffect(()=>{
    if(isFirstMount.current){
      isFirstMount.current = false;
    }else{
      if (activeTab === 0) {
        fetchList({ filterStatus: '' });
      } else if (activeTab === 1) {
        fetchList({ filterStatus: 'rejected,failed' });
      } else if (activeTab === 2) {
        fetchList({ filterStatus: 'pending,completed' });
      }
    }
  },[activeTab]);

  const handleOnChangePage = (newPage: number) => {
  	__resetSelecton();
    onChangePage(newPage);
  };

  const onChangeFilterData = (startAt: string, endAt: string) => {
  	__resetSelecton();
    fetchList({ startAt, endAt });
  };

  const _renderBankNumber = (item: SingleAroDisbursementAvailableResult) => {
    if (item && item.aroBankAccount) {
      if (!item.aroBankAccount?.bank?.bankName ||
      	!item.aroBankAccount?.accountNumber ||
      	!item.aroBankAccount?.accountName) {
        return (
          <Box display="flex" alignItems="center">
            <Typography style={{ color: COLORS.error, fontStyle: 'italic' }}>Not Set</Typography>
          </Box>)
      } else {
        return (
          <Typography component="span">
            {item.aroBankAccount.bank.bankName}
            <br/>
            {item.aroBankAccount.accountNumber}
            <br/>
            {'a.n ' +item.aroBankAccount.accountName}{' '}
          </Typography>
        );
      }
    } else {
      return null;
    }
  };

  const _renderBankNumber2 = (item: AroDisbursementProperties) => {
    if(item && item?.disbursement?.metadata) {
      if (!item?.disbursement?.metadata?.beneficiary_bank ||
        !item?.disbursement?.metadata?.beneficiary_name ||
        !item?.disbursement?.metadata?.beneficiary_account) {
        return (
          <Box display="flex" alignItems="center">
            <Typography style={{ color: COLORS.error, fontStyle: 'italic' }}>Not Set</Typography>
          </Box>)
      } else {
        return (
          <Typography component="span">
            {item.disbursement.metadata.beneficiary_bank.toUpperCase()}
            <br/>
            {item.disbursement.metadata.beneficiary_account}
            <br/>
            {'a.n ' +item.disbursement.metadata.beneficiary_name}{' '}
          </Typography>
        );
      }
    }else{
      return null;
    }
  };

  const _handleDisburseInput = (id: string, key: string, value: any) => {
  	if(!selected.includes(id)){
  		setSelected((prev: string[])=>[...prev, id]);
  	}
  	setDisburseValue((prev: InputAroDisbursementState)=>{
	  	const newVal: InputAroDisbursement = prev[id]
	  		? {
	  			...prev[id],
	  			[key]: value
	  		}
	  		: {
	  			remark:'',
	  			notaPrice:'',
	  			[key]:value
	  		}
  		return {
	  		...prev,
	  		[id]: newVal
	  	}
  	})
  }

  const _renderExpandedContent = (row: any) => {
    let remarkRequired = disburseValue[row.id]
            ? disburseValue[row.id].notaPrice && Number(disburseValue[row.id].notaPrice)
              ? true
              : false
            : false;
    let payoutPrice = row.amount - (disburseValue[row.id] && disburseValue[row.id].notaPrice
            ? Number(disburseValue[row.id].notaPrice)
            : 0)
  	return (
  		<React.Fragment key={'expanded-'+row.id}>
	  		<StyledTableCell align="center">
	  		</StyledTableCell>
	  		<StyledTableCell colSpan={2}>
					<FormInput
					style={{marginBottom:0}}
					type="currency"
					fullWidth
					label="Potong Nota"
					value={ disburseValue[row.id]
						? disburseValue[row.id].notaPrice
						: ''
					}
          error={payoutPrice<0}
					onChange={(v)=>_handleDisburseInput(row.id,'notaPrice',v)}
					helperText={payoutPrice<0
            ?"Nominal akhir payout tidak boleh kurang dari 0"
            :"Abaikan jika tidak ingin potong nota"
          }
					/>
	  		</StyledTableCell>
	  		<StyledTableCell colSpan={2}>
		  		<FormInput
		  		style={{marginBottom:0}}
					type="text"
					fullWidth
					label="Remark"
					value={ disburseValue[row.id]
						? disburseValue[row.id].remark
						: ''
					}
					onChange={(v)=>_handleDisburseInput(row.id,'remark',v)}
          error={remarkRequired&&!disburseValue[row.id]?.remark}
          required={remarkRequired}
					helperText="Tulis remark potong nota atau disburse disini"
					/>
	  		</StyledTableCell>
	  		<StyledTableCell align="right">
	  			<Typography variant="body1" color="textSecondary">
						Nominal Disburse
					</Typography>
					<Typography variant="body1">{convertNumToCurrency(payoutPrice)}</Typography>
	  		</StyledTableCell>
	  		<StyledTableCell>
	  		</StyledTableCell>
  		</React.Fragment>
		);
  }

  const _renderExpandedContentFailed = (row: any) => {
    return (
      <React.Fragment key={'expanded-'+row.id}>
        <StyledTableCell align="center">
        </StyledTableCell>
        <StyledTableCell colSpan={2}>
          <FormInput
          disabled={false}
          style={{marginBottom:0}}
          type="currency"
          fullWidth
          label="Potong Nota"
          value={ row.notaPrice }
          helperText="Kolom ini tidak dapat diubah"
          onChange={()=>{}}
          />
        </StyledTableCell>
        <StyledTableCell colSpan={2}>
          <FormInput
          disabled={false}
          style={{marginBottom:0}}
          type="text"
          fullWidth
          label="Remark"
          value={ row.remark }
          helperText="Kolom ini tidak dapat diubah"
          onChange={()=>{}}
          />
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography variant="body1" color="textSecondary">
            Nominal Disburse
          </Typography>
          <Typography variant="body1">{convertNumToCurrency(row.payoutPrice)}</Typography>
        </StyledTableCell>
        <StyledTableCell>
        </StyledTableCell>
      </React.Fragment>
    );
  }

  const listData = data[0]?.aroDisbursementId === undefined
  ? data.map((item: SingleAroDisbursementAvailableResult) => {
  	return {
  		...item,
      params: `?startAt=${params.startAt}&endAt=${params.endAt}`,
  		customId: item.aroId,
  		id: item.aroId,
  		phone: item.customer.phoneNumber,
  		bank_number: _renderBankNumber(item),
  		amount_text: (
        <Typography
          style={
            item.amount > 10000
              ? { color: COLORS.primary }
              : { color: COLORS.error }
          }
        >
          {convertNumToCurrency(item.amount)}
        </Typography>
      ),
      disburse_date: null,
      period: null,
      remarkAction: null,
      isDisabled: (!item.aroBankAccount?.bank?.bankName ||
      	!item.aroBankAccount?.accountNumber ||
      	!item.aroBankAccount?.accountName ||
      	item.amount < 10000)
  	}
  })
  : data.map((item: AroDisbursementProperties) => {
    return {
      ...item,
      params: `?type=historical`,
      customId: item.aroDisbursementId,
      id: item.aroDisbursementId,
      labelName: item.aroMetadata.labelName,
      phone: item.aroMetadata.phoneNumber,
      bank_number: _renderBankNumber2(item),
      amount_text: (
        <Typography
          style={
            item.totalPrice > 10000
              ? { color: COLORS.primary }
              : { color: COLORS.error }
          }
        >
          {convertNumToCurrency(item.totalPrice)}
        </Typography>
      ),
      amount: item.totalPrice,
      disburse_date: item.updatedAt,
      period:
        convertDateTimeToText(item.periodStart, 'dd mmm yyyy') +
        ' - ' +
        convertDateTimeToText(item.periodEnd, 'dd mmm yyyy'),
      status: (
        <Typography
          variant="subtitle1"
          component="span"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 10,
            borderColor: COLORS.greyDark,
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: COLORS.grey200,
          }}
        >
          {item.disbursement?.status
            ? item.disbursement.status.toUpperCase()
            : 'COMPLETED'}
        </Typography>
      ),
      isDisabled: (item.totalPrice < 10000),
      remarkAction: (
        <FileText
        style={{cursor:'pointer'}}
        onClick={()=>setShowRemark(item.remark)}
        />
      )
    }
  })

	return (
	<Layout title="Disbursement ARO" loading={isLoadingDisburse||loading}>
		<Grid container alignItems="flex-end">
		<Grid item xs={9}>
			<HeaderPage
			title="Disbursement ARO"
			subtitle="List of all disbursement"
			/>
		</Grid>
		<Grid item xs={3} style={{ marginBottom: 12 }}>
			<Search
			placeholder="Search name"
			onChange={(value: string) => setSearch(value)}
			/>
		</Grid>
		</Grid>

		<Paper elevation={3} className={classes.paper}>
		<Grid container spacing={3} justify="space-between" alignItems="center">
			<Grid item xs={12} md={12} lg={2}>
			<Typography variant="subtitle1" color="textSecondary">
				Total Disbursement
			</Typography>
			<Typography
				style={{ fontWeight: 'bold', lineHeight: 1.4, fontSize: 22 }}
			>
				{convertNumToCurrency(totalSelectedDisbursement)}
			</Typography>
			</Grid>

			<Grid item xs={12} md={12} lg={2}>
			<Typography variant="subtitle1" color="textSecondary">
				IRIS Balance
			</Typography>
			<Typography
				style={{ fontWeight: 'bold', lineHeight: 1.4, fontSize: 22 }}
			>
				{convertNumToCurrency(IRISBalance)}
			</Typography>
			</Grid>

			<Grid item xs={12} md={6} lg={4}>
			<FilterDate
				start_at={params.startAt}
        end_at={params.endAt}
				onChange={(start_at: string, end_at: string) =>{
					onChangeFilterData(start_at, end_at)
				}}
        notSetDefaultDate={true}
			/>
			</Grid>

			<Grid item xs={12} md={6} lg={4}>
			<AppButton
				onClick={exportDisbursement}
				disabled={
          selected.length === 0 ||
          ! params.startAt ||
          ! params.endAt
        }
				style={{ marginBottom: 8 }}
				size="small"
				color="primary"
			>
				<Download size={18} />
				<Typography
				component="span"
				style={{ fontSize: 12, marginLeft: 5 }}
				>
				Export
				</Typography>
			</AppButton>
			<AppButton
				onClick={() => {
				}}
				disabled={
          selected.length === 0 ||
          ! params.startAt ||
          ! params.endAt
        }
				style={{ marginBottom: 8 }}
				size="small"
				color="primary"
			>
				<Download size={18} />
				<Typography
				component="span"
				style={{ fontSize: 12, marginLeft: 5 }}
				>
				Export Detail
				</Typography>
			</AppButton>
			<AppButton
				style={{ marginBottom: 8 }}
				size="small"
				loading={isLoadingDisburse}
				disabled={
					isLoadingDisburse ||
          selected.length === 0 ||
          activeTab > 1 ||
          ! params.startAt ||
          ! params.endAt
				}
				color="primary"
				onClick={()=> activeTab === 1 ? setShowFailedDisburseModal(true) : __handleDisburseModal()}
			>
				<Disburse
				color={
					isLoadingDisburse || selected.length === 0
					? COLORS.greyDark
					: COLORS.accent
				}
				size={18}
				/>
				<Typography
				component="span"
				style={{ fontSize: 12, marginLeft: 5 }}
				>
					Disburse{selected.length>0?' ('+selected.length+')':''}
				</Typography>
			</AppButton>
			</Grid>
		</Grid>
		</Paper>

		{(activeTab === 0 || activeTab === 1) && (
      <Paper elevation={3} className={classes.paperSlim}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: 10 }}
        >
          <Box display="flex" alignItems="center" flexDirection="row">
            <Info size={20} color={COLORS.warn} />
            <Typography style={{ marginLeft: 5 }}>
              Pilih periode dan agent region yang akan di disburse
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <X size={20} />
          </Box>
        </Box>
      </Paper>
    )}
    {activeTab === 2 && (
      <Paper elevation={3} className={classes.paperSlim}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: 10 }}
        >
          <Box display="flex" alignItems="center" flexDirection="row">
            <Info size={20} color={COLORS.warn} />
            <Typography style={{ marginLeft: 5 }}>
              Daftar semua transaksi yang telah di disburse
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <X size={20} />
          </Box>
        </Box>
      </Paper>
    )}

    <Grid container spacing={3} className={classes.containerContent}>
    <Grid
      item
      xs={12}
      style={{
        backgroundColor: COLORS.grey200,
        padding: 0,
        borderRadius: 10,
      }}
    >
      <AppTabs
        active={activeTab}
        tabs={tabs}
        onChange={(value: number) => {
          onChangeTab(value);
        }}
      />
      {activeTab === 0 && (
        <CustomizedTable
          selected={selected}
          selection={true}
          onSelected={(id: string) => {
            let list: any[] = [...selected];
            if (list.includes(id)) {
              list = list.filter((item) => item !== id);
            } else {
              list.push(id);
            }
            setSelected(list);
          }}
          onSelectAll={() => {
            setSelected(data.filter(item=>item.amount>10000).map((item) => item.aroId));
          }}
          onUnselectAll={() => {
            setSelected([]);
          }}
          data={listData}
          headers={headers}
          showAction={['detail']}
          totalData={totalData}
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          onChange={handleOnChangePage}
          onRowsPerPageChange={(value: any) => onChangeRowsPerPage(value)}
          isExpandable={true}
          expanded={expanded}
          onExpandAll={()=>setExpanded(data.filter(item=>item.amount>10000).map((item)=>item.aroId))}
          onCollapseAll={()=>setExpanded([])}
          onExpanded={(id: string) => {
            let list: any[] = [...expanded];
            if (list.includes(id)) {
              list = list.filter((item) => item !== id);
            } else {
              list.push(id);
            }
            setExpanded(list);
          }}
          renderExpanded={_renderExpandedContent}
        />
      )}

      {activeTab === 1 && (
        <CustomizedTable
          selected={selected}
          selection={true}
          onSelected={(id: string) => {
            let list: any[] = [...selected];
            if (list.includes(id)) {
              list = list.filter((item) => item !== id);
            } else {
              list.push(id);
            }
            setSelected(list);
          }}
          onSelectAll={() => {
            setSelected(data.filter(item=>item.totalPrice>10000).map((item) => item.aroDisbursementId));
          }}
          onUnselectAll={() => {
            setSelected([]);
          }}
          data={listData}
          headers={headers}
          showAction={['detail']}
          totalData={totalData}
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          onChange={handleOnChangePage}
          onRowsPerPageChange={(value: any) => onChangeRowsPerPage(value)}
          isExpandable={true}
          expanded={expanded}
          onExpandAll={()=>setExpanded(data.filter(item=>item.totalPrice>10000).map((item)=>item.aroDisbursementId))}
          onCollapseAll={()=>setExpanded([])}
          onExpanded={(id: string) => {
            let list: any[] = [...expanded];
            if (list.includes(id)) {
              list = list.filter((item) => item !== id);
            } else {
              list.push(id);
            }
            setExpanded(list);
          }}
          renderExpanded={_renderExpandedContentFailed}
        />
      )}

      {activeTab === 2 && (
        <CustomizedTable
          selected={selected}
          selection={true}
          onSelected={(id: string) => {
            let list: any[] = [...selected];
            if (list.includes(id)) {
              list = list.filter((item) => item !== id);
            } else {
              list.push(id);
            }
            setSelected(list);
          }}
          onSelectAll={() => {
            setSelected(data.filter(item=>item.totalPrice>10000).map((item) => item.aroDisbursementId));
          }}
          onUnselectAll={() => {
            setSelected([]);
          }}
          data={listData}
          headers={headersHistorical}
          totalData={totalData}
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          onChange={handleOnChangePage}
          onRowsPerPageChange={(value: any) => onChangeRowsPerPage(value)}
        />
      )}
    </Grid>
  </Grid>

  <Modal
    open={showDisburseModal !== false}
    onClose={()=>setShowDisburseModal(false)}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className={classes.containerModal}>
      <div className={classes.contentModal}>
        <Typography variant="h3">Disburse Confirmation</Typography>
        <Typography>Apakah anda yakin ingin disburse {selected.length} Agent region ini?</Typography>
        {showDisburseModal!== false ?
      	data.filter((v)=>selected.includes(v.aroId)).map((item)=>{
      		let notaPrice = (disburseValue[item.aroId]
  					? Number(disburseValue[item.aroId].notaPrice)
  					: 0)
      		let remark = (disburseValue[item.aroId]
  					? disburseValue[item.aroId].remark
  					: '')
      		return (
	      		<div className={classes.disbursementItem}>
	      			<Grid container spacing={1} style={{width: '100%'}}>
	      				<Grid item xs={12} md={4}>
		      				<Typography variant="body1" color="textSecondary">
										ARO
									</Typography>
									<Typography variant="h5">{item.labelName}</Typography>
	      				</Grid>
	      				<Grid item xs={12} md={4}>
		      				<Typography variant="body1" color="textSecondary">
										Potong Nota
									</Typography>
									<Typography variant="body1">{convertNumToCurrency(notaPrice)}</Typography>
	      				</Grid>
	      				<Grid item xs={12} md={4}>
		      				<Typography variant="body1" color="textSecondary">
										Disburse
									</Typography>
									<Typography variant="body1">{convertNumToCurrency(item.amount-notaPrice)}</Typography>
	      				</Grid>
	      				<Grid item xs={12}>
	      					<Typography variant="body1" color="textSecondary">
										Remark Potong Nota
									</Typography>
									<Typography variant="body1">{remark}</Typography>
	      				</Grid>
	      			</Grid>
	      		</div>
	    		);
      	}):<></>}
      	<Grid container spacing={1} className={classes.buttonContainer}>
      		<Grid item xs={6}>
      			<AppButton
							style={{ marginBottom: 8 }}
							color="secondary"
							onClick={()=>setShowDisburseModal(false)}
							fullWidth={true}
						>
							<Typography>
								Cancel
							</Typography>
						</AppButton>
      		</Grid>
      		<Grid item xs={6}>
      			<AppButton
							style={{ marginBottom: 8 }}
							loading={isLoadingDisburse}
							disabled={
								isLoadingDisburse || selected.length === 0 || activeTab > 1
							}
							color="primary"
							onClick={doDisburse}
							fullWidth={true}
						>
							<Disburse
							color={
								isLoadingDisburse || selected.length === 0
								? COLORS.greyDark
								: COLORS.accent
							}
							size={18}
							/>
							<Typography style={{marginLeft: 10}}>
								Disburse
							</Typography>
						</AppButton>
      		</Grid>
      	</Grid>
      </div>
    </div>
  </Modal>

  <Modal
    open={showFailedDisburseModal !== false}
    onClose={()=>setShowFailedDisburseModal(false)}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className={classes.containerModal}>
      <div className={classes.contentModal}>
        <Typography variant="h3">Disburse Confirmation</Typography>
        <Typography>Apakah anda yakin ingin disburse {selected.length} Agent region ini?</Typography>
        {showFailedDisburseModal!== false ?
        data.filter((v)=>selected.includes(v.aroDisbursementId)).map((item)=>{
          return (
            <div className={classes.disbursementItem}>
              <Grid container spacing={1} style={{width: '100%'}}>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" color="textSecondary">
                    ARO
                  </Typography>
                  <Typography variant="h5">{item.aroMetadata.labelName}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" color="textSecondary">
                    Potong Nota
                  </Typography>
                  <Typography variant="body1">{convertNumToCurrency(item.notaPrice)}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="body1" color="textSecondary">
                    Disburse
                  </Typography>
                  <Typography variant="body1">{convertNumToCurrency(item.payoutPrice)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    Remark Potong Nota
                  </Typography>
                  <Typography variant="body1">{item.remark}</Typography>
                </Grid>
              </Grid>
            </div>
          );
        }):<></>}
        <Grid container spacing={1} className={classes.buttonContainer}>
          <Grid item xs={6}>
            <AppButton
              style={{ marginBottom: 8 }}
              color="secondary"
              onClick={()=>setShowFailedDisburseModal(false)}
              fullWidth={true}
            >
              <Typography>
                Cancel
              </Typography>
            </AppButton>
          </Grid>
          <Grid item xs={6}>
            <AppButton
              style={{ marginBottom: 8 }}
              loading={isLoadingDisburse}
              disabled={
                isLoadingDisburse || selected.length === 0 || activeTab > 1
              }
              color="primary"
              onClick={doReDisburse}
              fullWidth={true}
            >
              <Disburse
              color={
                isLoadingDisburse || selected.length === 0
                ? COLORS.greyDark
                : COLORS.accent
              }
              size={18}
              />
              <Typography style={{marginLeft: 10}}>
                Disburse
              </Typography>
            </AppButton>
          </Grid>
        </Grid>
      </div>
    </div>
  </Modal>

  <Modal
    open={showRemark !== null}
    onClose={()=>setShowRemark(null)}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className={classes.containerModal}>
      <div className={classes.contentModal} style={{maxWidth:400}}>
        <Typography variant="h3">Remark</Typography>
        <div className={classes.remarkContainer}>
          <Typography variant="body1">{showRemark}</Typography>
        </div>
        <AppButton
          style={{ marginBottom: 8 }}
          color="primary"
          onClick={()=>setShowRemark(null)}
          fullWidth={true}
        >
          <Typography>
            Tutup
          </Typography>
        </AppButton>
      </div>
    </div>
  </Modal>

	</Layout>);
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			margin: theme.spacing(0, 0, 3, 0),
			padding: theme.spacing(2, 2, 2.5, 2),
		},
		paperSlim: {
			margin: theme.spacing(0, 0, 3, 0),
			padding: theme.spacing(0.5, 1, 0, 1),
		},
		formStatus: {
			width: '100%',
			margin: theme.spacing(1, 0, 2, 0),
		},
		cardMedia: {
			borderColor: COLORS.grey,
			borderWidth: 1,
			borderStyle: 'solid',
			width: 69,
			height: 69,
			borderRadius: 10,
			backgroundColor: COLORS.accent,
		},
		processCircle: {
			width: 25,
			height: 25,
			backgroundColor: COLORS.accent,
			borderRadius: '50%',
			borderStyle: 'solid',
			borderWidth: 1,
			borderColor: COLORS.grey,
		},
		containerContent: {
			padding: theme.spacing(1),
		},
		containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalFormCreategnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
    disbursementItem:{
    	borderBottom: '2px dotted rgb(169 169 169)',
      padding: theme.spacing(2, 0),
    },
    buttonContainer:{
    	margin: theme.spacing(2, 0, 0, 0),
    	width: '100%'
    },
    remarkContainer:{
      margin: theme.spacing(2,0,2,0),
      borderBottom: '2px solid rgb(169 169 169)',
    }
	})
);

export default AroDisbursement;