import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Breadcrumbs,
  Modal,
  CircularProgress,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuIcon from '@material-ui/icons/Menu';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar';
import Power from '../Icon/Power';
import COLORS from '../../styles/colors';
import Logo from '../Icon/Logo';
import useAuth from '../../hooks/useAuth';
import AppButton from '../Form/AppButton';
import { Close } from '@material-ui/icons';
import CustomLink from '../CustomLink';
import useWindowSize from '../../hooks/useWindowSize';
import { LoadingIndicator } from '../LoadingBar';
import { useIdleTimer } from 'react-idle-timer';
import SessionTimeout from '../SessionTime';
import useStock from '../../hooks/useStock';
import { ChevronLeft } from 'react-feather';

const drawerWidth = 240;

type IProps = {
  title?: string;
  children?: any;
  loading?: boolean;
  unauthorized?: boolean;
};

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

let countdownInterval;
let timeout;

export default function Layout(props: IProps) {
  const auth = useAuth();
  const classes = useStyles();

  const windowSize = useWindowSize();

  const [timeNow, setTimeNow] = useState('')

  const { fetchLimitedStock } = useStock()
  useEffect(() => {
    fetchLimitedStock()
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (windowSize === 'sm' || windowSize === 'md') {
  //       handleDrawerClose();
  //     } else {
  //       handleDrawerOpen();
  //     }
  //   }, 300);
  // }, [windowSize]);

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    auth.signout(() => {
      window.location.href = '/';
    });
  };

  const [
    isShowModalLogoutConfirmation,
    setIsShowModalLogoutConfirmation,
  ] = useState(false);

  const handleCloseModalLogoutConfirmation = () => {
    //
    setIsShowModalLogoutConfirmation(false);
  };
  const handleOpenModalLogoutConfirmation = () => {
    //
    setIsShowModalLogoutConfirmation(true);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, props.unauthorized ? classes.appBarShiftUnauthorized : (open && classes.appBarShift))}
      >
        <Toolbar className={classes.toolbar}>
          {props.loading && <LoadingIndicator className={classes.loadingIndicator} />}
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          // className={clsx(
          //   classes.menuButton,
          //   open && classes.menuButtonHidden
          // )}
          >
            <MenuIcon />
          </IconButton> */}

          {/* <div style={{ flex: 2 }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <CustomLink color="inherit" to="/dashboard" onClick={handleClick}>
                Home
              </CustomLink>
              <CustomLink
                color="inherit"
                to="/getting-started/installation/"
                onClick={handleClick}
              >
                Core
              </CustomLink>
              <Typography color="textPrimary">Breadcrumb</Typography>
            </Breadcrumbs>
          </div> */}

          <Box
            flex={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {(windowSize === 'lg' || windowSize === 'xlg') && (
              <Box>
                {/* <Typography variant="body1">Hello {auth.user.name}</Typography> */}
                <Typography variant="body1">Hi, {auth.user ? `${auth.user.name}` : 'Loading...'}</Typography>
              </Box>
            )}
            <CustomLink to="/my-profile">
              <IconButton color="inherit">
                <PersonOutlineOutlinedIcon />
              </IconButton>
            </CustomLink>
            <Divider
              orientation="vertical"
              flexItem={true}
              component="hr"
              className={classes.dividerVertical}
            />
            <IconButton
              onClick={handleOpenModalLogoutConfirmation}
              color="inherit"
            >
              <Power color={COLORS.accent} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {!props.unauthorized &&
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          className={classes.drawerContainer}
        >
          <div className={classes.toolbarHeader}>
            <div style={{ flex: 1 }}>
              <Logo size={24} />
              <span style={{ marginLeft: 8 }}>v{process.env.REACT_APP_VERSION_NAME}</span>
            </div>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeft />
              </IconButton>
            </div>
          </div>
          {auth.user && auth.user.permissions.length > 0 && <Sidebar permissions={auth.user.permissions} />}
        </Drawer>
      }

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {props.children}
        </Container>
      </main>

      <SessionTimeout />

      <Modal
        open={isShowModalLogoutConfirmation}
        onClose={handleCloseModalLogoutConfirmation}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Confirmation</Typography>

              <CustomLink onClick={() => handleCloseModalLogoutConfirmation()}>
                <Close />
              </CustomLink>
            </Box>

            <Typography variant="body1">
              Are you sure want to logout?
            </Typography>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalLogoutConfirmation}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                {
                  auth.loadingLogout ?
                    <AppButton>
                      <CircularProgress color="secondary" size="22px" />
                    </AppButton> :
                    <AppButton onClick={logout}>Yes</AppButton>
                }
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftUnauthorized: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: COLORS.primary,
    color: COLORS.accent,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  dividerVertical: {
    backgroundColor: COLORS.greyLight,
    margin: 10,
  },
  containerModal: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alihandleCloseModalLogoutgnContentConfirmation: 'center',
  },
  contentModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    width: 400,
    margin: '0 auto',
    marginTop: '10%',
    borderRadius: 10,
  },
  drawerContainer: {
    height: '100vh',
    overflow: 'auto',
  },
  loadingIndicator: {
    position: 'absolute',
    zIndex: 9999,
    top: 0,
    left: 0,
    width: '100%',
  }
}));
