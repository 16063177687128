import { EStorefrontType } from '../reducer/storefront.reducer';

export function setDataProductStorefront(payload: any) {
  return {
    type: EStorefrontType.SET_DATA_PRODUCTS,
    payload
  }
}

export function setDataRelatedProductStorefront(payload: any) {
  return {
    type: EStorefrontType.SET_DATA_RELATED_PRODUCT,
    payload
  }
}