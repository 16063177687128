import axios from "axios";
import { getHeaders } from "../../helpers/auth";

export async function apiGetLoyalty() {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/loyalty",
    method: "GET",
    headers,
  });
}

export async function apiGetLoyaltyDetail(loyaltyId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/loyalty/" + loyaltyId,
    method: "GET",
    headers,
  });
}

export async function apiGetLoyaltyHistory(loyaltyId: string, params: string) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/loyalty/" +
      loyaltyId +
      "/history" +
      query,
    method: "GET",
    headers,
  });
}

export async function apiUpdateLoyalty(data: any) {
  const headers = await getHeaders();

  return axios({
    url: process.env.REACT_APP_BASE_URL + "/loyalty/" + data.loyaltyId,
    method: "PATCH",
    headers: headers,
    data,
  });
}
