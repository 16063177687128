import { useDispatch } from 'react-redux'
import { apiCreateNews, apiDeleteNews, apiFindAllNews, apiFindOneNews, apiUpdateNews } from '../api/content/news';
import { findAllNews, setLoadingNews, setErrorNews, findOneNews, createNews, deleteNews, updateNews } from '../redux/action/content/news.action';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { generateQueryString } from '../helpers/functions/generateQueryString';

export default function useNews() {
  const dispatch = useDispatch()

  const history = useHistory()

  const fetchAllNews = async (params?: any) => {
    try {
      dispatch(setLoadingNews(true))

      let query = generateQueryString(params)

      const result = await apiFindAllNews(query)
      dispatch(findAllNews(result.data))

      dispatch(setLoadingNews(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingNews(false))
      dispatch(setErrorNews(error.response))
    }
  }

  const fetchOneNews = async (newsId: string) => {
    try {
      dispatch(setLoadingNews(true))

      const result = await apiFindOneNews(newsId)
      dispatch(findOneNews(result.data.payload))

      dispatch(setLoadingNews(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingNews(false))
      dispatch(setErrorNews(error.response))
    }
  }

  const createNewNews = async (data: any) => {
    try {
      dispatch(setLoadingNews(true))

      const result = await apiCreateNews(data)

      dispatch(createNews(result.data.payload))

      dispatch(setLoadingNews(false))

      toast.success("Success create news", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingNews(false))
      dispatch(setErrorNews(error.response))
    }
  }


  const updateCurrentNews = async (data: any) => {
    try {
      dispatch(setLoadingNews(true))

      const result = await apiUpdateNews(data)

      dispatch(updateNews(result.data.payload))

      dispatch(setLoadingNews(false))

      toast.success("Success update news", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingNews(false))
      dispatch(setErrorNews(error.response))
    }
  }

  const removeNews = async (bannerId: string) => {
    try {
      dispatch(setLoadingNews(true))

      const result = await apiDeleteNews(bannerId)

      dispatch(deleteNews(result.data.payload))
      await fetchAllNews({ limit: 25, offset: 0, search: '' })

      dispatch(setLoadingNews(false))

      toast.success("Success remove news", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingNews(false))
      dispatch(setErrorNews(error.response))
    }

  }

  return {
    fetchAllNews,
    fetchOneNews,
    createNewNews,
    removeNews,
    updateCurrentNews
  }
}