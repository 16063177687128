import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import COLORS from "../styles/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

type Props = {
  isFulfillmentSparepart?: boolean;
  status:
    | "created"
    | "paid"
    | "process"
    | "sent"
    | "pickup"
    | "rejected"
    | "canceled"
    | "expired";
};

export default function StatusTransaction(props: Props) {
  if (props.status === "paid") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} active />
          <IconStatus state={2} />
          <IconStatus state={3} />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "process") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} active />
          <IconStatus state={2} active />
          <IconStatus state={3} />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "sent") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} active />
          <IconStatus state={2} active />
          <IconStatus state={3} active />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "pickup") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} active />
          <IconStatus state={2} active />
          <IconStatus state={3} active />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "rejected") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} active />
          <IconStatus state={2} active />
          <IconStatus state={3} active />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "canceled") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} />
          <IconStatus state={2} />
          <IconStatus state={3} />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "expired") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} />
          <IconStatus state={2} />
          <IconStatus state={3} />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  } else if (props.status === "created") {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <IconStatus state={1} active={props.isFulfillmentSparepart} />
          <IconStatus state={2} />
          <IconStatus state={3} />
        </Box>
        <Box marginTop={1}>
          <LabelStatusTransaction status={props.status} />
        </Box>
      </>
    );
  }
  return (
    <>
      <Box display="flex" flexDirection="row">
        <IconStatus state={1} />
        <IconStatus state={2} />
        <IconStatus state={3} />
      </Box>
      <Box marginTop={1}>
        <LabelStatusTransaction status={"undefined"} />
      </Box>
    </>
  );
}

type PropsLabel = {
  status:
    | "paid"
    | "process"
    | "sent"
    | "pickup"
    | "rejected"
    | "canceled"
    | "undefined"
    | "created"
    | "expired"
    | "waiting"
    | "pending"
    | "payment_failed";
};

export const LabelStatusTransaction = (props: PropsLabel) => {
  return (
    <Typography
      variant="subtitle1"
      component="span"
      style={{
        backgroundColor:
          props.status === "expired"
            ? COLORS.greyLight
            : props.status === "paid" ||
              props.status === "process" ||
              props.status === "sent" ||
              props.status === "pickup" ||
              props.status === "created"
            ? COLORS.warnLight
            : props.status === "rejected" ||
              props.status === "canceled" ||
              props.status === "undefined"
            ? COLORS.errorLight
            : COLORS.warnLight,
        color:
          props.status === "rejected" ||
          props.status === "canceled" ||
          props.status === "undefined"
            ? COLORS.error
            : "#000000",
        borderRadius: 5,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      {props.status === "paid"
        ? "Sudah Dibayar"
        : props.status === "process"
        ? "Pesanan Diproses"
        : props.status === "sent"
        ? "Barang Dikirim"
        : props.status === "pickup"
        ? "Barang Dipickup"
        : props.status === "created"
        ? "Pesanan dibuat"
        : props.status === "payment_failed"
        ? "Pembayaran Gagal"
        : props.status === "rejected"
        ? "Dibatalkan"
        : props.status === "canceled"
        ? "Canceled"
        : props.status === "expired"
        ? "Expired"
        : props.status === "waiting" || props.status === "pending"
        ? "Menunggu Pembayaran"
        : "-" + props.status + "-"}
    </Typography>
  );
};

type PropsIconStatus = {
  active?: boolean;
  state?: number;
};

const IconStatus = (props: PropsIconStatus) => {
  const classes = useStyles();

  if (props.active) {
    return (
      <Tooltip
        title={
          props.state === 1
            ? "Sudah Dibayar"
            : props.state === 2
            ? "Pesanan Diproses"
            : "Pesanan Dikirim"
        }
        aria-label="add"
        placement="top"
      >
        <Box
          marginRight={1}
          className={classes.processCircle}
          style={{
            backgroundColor: COLORS.primary,
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            borderColor: COLORS.primary,
          }}
        >
          <CheckCircleIcon fontSize="inherit" style={{ color: "#fff" }} />
        </Box>
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={
        props.state === 1
          ? "Sudah Dibayar"
          : props.state === 2
          ? "Pesanan Diproses"
          : "Pesanan Dikirim"
      }
      aria-label="add"
      placement="top"
    >
      <Box marginRight={1} className={classes.processCircle}>
        <Typography align="center" variant="subtitle1">
          {props.state}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    processCircle: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
  })
);
