import { Reducer } from 'redux';
import { AdminState, AdminTypes } from '../../types/admin.type';

const initialState: AdminState = {
  isLoadingAdmin: false,
  data: {
    code: '',
    message: '',
    payload: {
      next: '',
      prev: '',
      count: 0,
      results: []
    },
  },
  admin: {
    name: '',
    email: '',
    phoneNumber: '',
    role: 'admin',
    status: 'active',
  }
}

export const admin: Reducer<AdminState> = (state = initialState, action) => {
  switch (action.type) {
    case AdminTypes.FIND_ALL_ADMIN:
      return {
        ...state,
        data: action.payload
      }
    case AdminTypes.FIND_ONE_ADMIN:
      return {
        ...state,
        admin: action.payload
      }
    case AdminTypes.SET_LOADING_ADMIN:
      return {
        ...state,
        isLoadingAdmin: action.payload
      }

    default:
      return state
  }
} 