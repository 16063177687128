import { Typography, Link } from '@material-ui/core';
import React from 'react';

const APP_VERSION = process.env.REACT_APP_VERSION_NAME;

type Props = {
  color?:
    | 'inherit'
    | 'initial'
    | 'textSecondary'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'error'
    | undefined;
};

export default function Copyright(props: Props) {
  return (
    <Typography
      variant="body2"
      color={props.color || 'textSecondary'}
      align="center"
    >
      {'Copyright © '}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="/">
        Oxone v{APP_VERSION}.
      </Link>
      <br />
      All Rights Reserved.
    </Typography>
  );
}
