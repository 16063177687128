import { Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isArray } from 'util'
import FilterDate from '../../components/Form/FilterDate'
import Search from '../../components/Form/Search'
import HeaderPage from '../../components/Layout/HeaderPage'
import Layout from '../../components/Layout/Layout'
import CustomizedTable, { ITableHeader } from '../../components/Table/CustomizedTable'
import { convertNumToCurrency } from '../../helpers/functions/currency'
import { convertDateTimeToText } from '../../helpers/functions/datetime'
import useFetch from '../../hooks/useFetch'
import { ApplicationState } from '../../redux/store'
import { VoucherEventReport } from '../../types/voucher.type'

const headers: ITableHeader[] = [
  {
    title: 'Code',
    column: 'id',
    type: 'id',
  },
  {
    title: 'Voucher Code',
    column: 'voucherCode',
  },
  {
    title: 'Detail Voucher Generate',
    column: 'voucherDetail',
  },
  {
    title: 'Created at',
    column: 'createdAt',
  },
  {
    title: 'Created by',
    column: 'createdBy',
  },
  // {
  //   title: 'Used by',
  //   column: 'usedBy',
  // },
  // {
  //   title: 'Used at',
  //   column: 'usedAt',
  // },
  // {
  //   title: 'Period',
  //   column: 'period',
  // },
  {
    title: 'Detail Voucher Validate',
    column: 'usedInfo',
  },
];

const initialQuery: any = {
  limit: 25,
  page: 1,
  // voucherType: EVoucherType.generate
}

export const ReportVoucherEvent: React.FC = () => {
  const { state: locationState, activeTab: currentActiveTab } = useSelector((state: ApplicationState) => state.configApp)
  const {
    data,
    loading,
    pagination,
    query,
    search,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    fetchDataTableAlt,
  } = useFetch<VoucherEventReport>({
    url: 'voucher/event/report',
    query: initialQuery,
  })

  const getValidatorRole = (value: string | string[]) => {
    if (Array.isArray(value)) {
      return '(Customer)'
    }
  }

  const dataMapped = data.map(item => {
    return {
      id: item.voucherCode,
      voucherCode: item.voucherCode,
      voucherDetail: (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>No. HP:</div>
            <div style={{ flex: 1 }}>{item.voucherGenerate ? item.voucherGenerate.phoneNumber : '-'}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>No. Invoice:</div>
            <div style={{ flex: 1 }}>{item.voucherGenerate ? item.voucherGenerate.invoiceId : '-'}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>Nominal:</div>
            <div style={{ flex: 1 }}>{item.discountValue ? convertNumToCurrency(Number(item.discountValue)) : '-'}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>Min. belanja:</div>
            <div style={{ flex: 1 }}>{item.requirement.minTotalTransaction && item.requirement.minTotalTransaction > 0 ? convertNumToCurrency(item.requirement.minTotalTransaction) : '-'}</div>
          </div>
        </div>
      ),
      createdAt: convertDateTimeToText(item.createdAt, 'dd mmm yyyy hh:mm'),
      createdBy: item.voucherGenerate ? item.voucherGenerate.createdByMetadata.name : '-',
      usedInfo: (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>Total. Belanja:</div>
            <div style={{ flex: 1 }}>{item.voucherValidator ? convertNumToCurrency(item.voucherValidator.totalTransaction) : '-'}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>Di pakai oleh:</div>
            <div style={{ flex: 1 }}>{item.voucherValidator ? item.voucherValidator.usedByMetadata.name : '-'} {item.voucherValidator ? getValidatorRole(item.voucherValidator.usedByMetadata.role) : ''}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
            <div style={{ width: 100 }}>Di pada:</div>
            <div style={{ flex: 1 }}>{item.voucherValidator ? convertDateTimeToText(item.voucherValidator.usedAt, 'dd mmm yyyy hh:mm') : '-'}</div>
          </div>
        </div>
      ),
    }
  })

  return (
    <Layout loading={loading} title="Report Voucher Event">
      <HeaderPage title="Report Voucher Event" subtitle="List of all report voucher event" />

      <Grid
        container
        spacing={3}
        alignItems="flex-start"
        justify="space-between"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={6}>
          <Search
            placeholder='Search by Voucher Code'
            onChange={changeHandler}
            value={locationState && locationState.search ? locationState.search : search}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FilterDate
            start_at={locationState && locationState.startAt ? locationState.startAt : query.startAt}
            end_at={locationState && locationState.endAt ? locationState.endAt : query.endAt}
            onChange={(start_at: string, end_at: string) => {
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }}
          />
        </Grid>
      </Grid>

      <CustomizedTable
        data={dataMapped}
        headers={headers}
        totalData={pagination.totalData}
        showAction={undefined}
        onPressDetail={() => {
          // dispatch(setLatestState(query))
        }}
        // onPressEdit={(item: VoucherGenerateProperties) => {
        //   console.info(item)
        // }}
        page={pagination.page}
        totalPage={pagination.countPage}
        rowsPerPage={pagination.perPage}
        onPressEditDialog={(data: any) => {
        }}
        onChange={(newPage: number) => handlePageChange(newPage)}
        onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
      />

    </Layout>
  )
}

export default ReportVoucherEvent