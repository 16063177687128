import { useDispatch, useSelector } from 'react-redux';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { ApplicationState } from '../redux/store';
import { apiCreateServiceCenter, apiFetchListServiceCenter, apiFetchOneServiceCenter, apiRemoveServiceCenter, apiUpdateServiceCenter } from '../api/service-center';
import { createServiceCenter, findAllServiceCenter, findOneServiceCenter, removeServiceCenter, setErrorServiceCenter, setLoadingServiceCenter, updateServiceCenter } from '../redux/action/service-center.action';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

export default function useServiceCenter() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllServiceCenter = async (params: any) => {
    try {
      let query = generateQueryString(params);

      const result = await apiFetchListServiceCenter(query);
      console.log(result);

      dispatch(findAllServiceCenter(result.data));

      dispatch(setLoadingServiceCenter(false));
    } catch (error: any) {
      dispatch(setLoadingServiceCenter(false));
      dispatch(setErrorServiceCenter(error));
    }
  };

  const fetchOneServiceCenter = async (id: any) => {
    try {

      const result = await apiFetchOneServiceCenter(id);

      console.log(result);

      dispatch(findOneServiceCenter(result.data.payload));

      dispatch(setLoadingServiceCenter(false));
    } catch (error: any) {
      dispatch(setLoadingServiceCenter(false));
      dispatch(setErrorServiceCenter(error));
    }
  };

  const createDataServiceCenter = async (data: any) => {
    try {
      dispatch(setLoadingServiceCenter(true))

      const result = await apiCreateServiceCenter(data)

      if (result.data.payload) {
        dispatch(createServiceCenter(result.data.payload))
        dispatch(setLoadingServiceCenter(false))
        history.push('/service-center-list')
      } 
    } catch (error) {
      dispatch(setLoadingServiceCenter(false));
      dispatch(setErrorServiceCenter(error));
      console.log(error)
        toast.error("Failed create service center", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
      });
    }
  }

  const updateDataServiceCenter = async (serviceCenterId: string, data: any, isStatus?: boolean) => {

    try {
      dispatch(setLoadingServiceCenter(true))

      const result = await apiUpdateServiceCenter(serviceCenterId, data)

      dispatch(updateServiceCenter(result.data.payload))

      dispatch(setLoadingServiceCenter(false))

      if(isStatus){
        toast.success("Success update data", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
        });

        console.log(data);

        const query = {
          offset: 0,
          limit: 25,
          search: '',
          status: '',
          startAt: '',
          endAt:'',
          orderBy: 'ASC'
        }

        fetchAllServiceCenter(generateQueryString(query));
      } else {
        history.push('/service-center-list')
      }
    } catch (error) {
      dispatch(setLoadingServiceCenter(false));
      dispatch(setErrorServiceCenter(error));
      console.log(error)
        toast.error("Failed update service center", {
          position: toast.POSITION.TOP_CENTER,
          pauseOnHover: false,
      });
    }
  }

  const removeDataServiceCenter = async (serviceCenterId: string) => {
    try {
      dispatch(setLoadingServiceCenter(true))

      const result = await apiRemoveServiceCenter(serviceCenterId)

      dispatch(removeServiceCenter(result.data.payload))

      await fetchAllServiceCenter({ limit: 25, offset: 0, search: '' })

      dispatch(setLoadingServiceCenter(false))

      toast.success("Success remove service center", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingServiceCenter(false))
      dispatch(setErrorServiceCenter(error.response))
    }
  }

  return {
    fetchAllServiceCenter,
    fetchOneServiceCenter,
    createDataServiceCenter,
    updateDataServiceCenter,
    removeDataServiceCenter
  };
}
