import { Reducer } from 'redux';
import { FeedbackState, FeedbackTypes } from '../../types/feedback.types';

const initialState: FeedbackState = {
  isLoadingFeedback: false,
  data: {
    code: '',
    message: '',
    payload: {
      prev: '',
      next: '',
      count: 0,
      results: []
    }
  },
  feedback: {
    feedback: '',
    deviceInformation: {
      brand: '',
      deviceApi: '',
      fontScale: '',
      appVersion: '',
      systemName: '',
      systemVersion: ''
    },
    userId: '',
    userMetaData: {
      role: '',
      email: '',
      phoneNumber: '',
      customerName: ''
    },
    feedbackId: '',
    status: '',
    createdAt: '',
    updatedAt: '',
    adminId: '',
    adminMetaData: {}
  },
  error: {},
}

export const feedback: Reducer<FeedbackState> = (state = initialState, action) => {
  switch (action.type) {
    case FeedbackTypes.FIND_ALL_FEEDBACK:
      return {
        ...state,
        data: action.payload
      }
    case FeedbackTypes.FIND_ONE_FEEDBACK:
      return {
        ...state,
        feedback: action.payload
      }
    case FeedbackTypes.SET_LOADING_FEEDBACK:
      return {
        ...state,
        isLoadingFeedback: action.payload
      }

    default:
      return state
  }
}