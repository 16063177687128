import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import HeaderPage from "../../components/Layout/HeaderPage";
import {
  headerOrder,
  headerOrderAll,
  headerReplacement,
  headerSalesReturn,
  headerSalesReturnAll,
  headerReplacementAll,
} from "./headers";

import Search from "../../components/Form/Search";
import FilterDate from "../../components/Form/FilterDate";
import AppTabs from "../../components/Tabs/AppTabs";
import {
  EB2BStatusRequest,
  ISalesOrderQuery,
  SalesOrderProperties,
} from "../../types/b2b/order/sales-order.type";
import useDebounce from "../../hooks/useDebounce";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import useFetch from "../../hooks/useFetch";
import { SalesReturnProperties } from "../../types/b2b/return/sales-return.type";
import { ReplacementProperties } from "../../types/b2b/replacement/replacement.type";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import {
  setLatestActiveTab,
  setLatestState,
} from "../../redux/action/config-app.action";
import { setB2BID } from "../../redux/action/b2b.action";
import debounce from "lodash.debounce";

const initialQuery: ISalesOrderQuery = {
  limit: 25,
  page: 1,
  status: EB2BStatusRequest.posted,
  sort: "createdAt,DESC",
};

const initialActiveTab = 0;

const tabs = [
  "Posted",
  "Approved",
  "Partial",
  "Closed",
  "Cancel",
  "Cancel by System",
  "ALL",
];

type Props = {
  type: "sales-order" | "sales-return" | "replacement";
};

export default function Order(props: Props) {
  let title = "";
  let subtitle = "";
  let headers: ITableHeader[] = [];

  if (props.type === "sales-order") {
    headers = headerOrder;
    title = "Sales Order";
    subtitle = "List of sales order";
  } else if (props.type === "sales-return") {
    headers = headerSalesReturn;
    title = "Retur Order";
    subtitle = "List of retur order";
  } else if (props.type === "replacement") {
    headers = headerReplacement;
    title = "Replacement Order";
    subtitle = "List of replacement";
  }

  const dispatch = useDispatch();
  const { state: locationState, activeTab: currentActiveTab } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const {
    data,
    loading,
    pagination,
    query,
    search,
    activeTab,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    setActiveTab,
  } = useFetch<
    SalesOrderProperties & SalesReturnProperties & ReplacementProperties
  >({
    url: props.type,
    query: initialQuery,
    activeTab: initialActiveTab,
  });

  const changeTab = (value: number) => {
    setActiveTab(value);

    let status: EB2BStatusRequest | null | undefined = null;

    if (value === 0) {
      status = EB2BStatusRequest.posted;
    } else if (value === 1) {
      status = EB2BStatusRequest.approved;
    } else if (value === 2) {
      status = EB2BStatusRequest.partial;
    } else if (value === 3) {
      status = EB2BStatusRequest.closed;
    } else if (value === 4) {
      status = EB2BStatusRequest.cancel;
    } else if (value === 5) {
      status = EB2BStatusRequest.cancel_by_system;
    }

    setQuery((val: ISalesOrderQuery) => {
      return {
        ...val,
        offset: 0,
        status: status,
      };
    });
  };

  const parseIdTable = (
    item: SalesOrderProperties & SalesReturnProperties & ReplacementProperties
  ) => {
    let id = "";
    if (props.type === "sales-order") {
      id = item.orderId;
    } else if (props.type === "sales-return") {
      id = item.returnId;
    } else if (props.type === "replacement") {
      id = item.rplId;
    }

    return id;
  };

  let dataB2B = data.map((item) => {
    return {
      id: parseIdTable(item),
      orderId: item.orderId,
      soId: item.soId,
      returnId: item.returnId,
      roId: item.roId,
      rplId: item.rplId,
      rploId: item.rploId,
      orderDate: item.createdAt
        ? convertDateTimeToText(item.createdAt, "dd mmm yyyy")
        : null,
      returnDate: item.createdAt
        ? convertDateTimeToText(item.createdAt, "dd mmm yyyy")
        : null,
      rplDate: item.createdAt
        ? convertDateTimeToText(item.createdAt, "dd mmm yyyy")
        : null,
      distributor: item.distributorMetadata.name,
      note: item.note,
      status: item.status,
    };
  });

  const _renderSearchPlaceholder = () => {
    let text = "";
    if (props.type === "sales-order") {
      text = "Search by Distributor name";
    } else if (props.type === "sales-return") {
      text = "Search by Distributor name";
    } else if (props.type === "replacement") {
      text = "Search by Distributor name";
    }
    return text;
  };

  useEffect(() => {
    changeTab(0);
  }, []);

  return (
    <Layout title={title} loading={loading}>
      <HeaderPage title={title} subtitle={subtitle} />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={12} lg={5} />
        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={
              locationState && locationState.startAt
                ? locationState.startAt
                : query.startAt
            }
            end_at={
              locationState && locationState.endAt
                ? locationState.endAt
                : query.endAt
            }
            onChange={(start_at: string, end_at: string) => {
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder={_renderSearchPlaceholder()}
            onChange={changeHandler}
            value={
              locationState && locationState.search
                ? locationState.search
                : search
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppTabs
            active={currentActiveTab ? currentActiveTab : activeTab}
            tabs={tabs}
            onChange={(value: number) => changeTab(value)}
          />
          <CustomizedTable
            data={dataB2B}
            headers={
              activeTab !== 6
                ? headers
                : props.type === "sales-order"
                ? headerOrderAll
                : props.type === "sales-return"
                ? headerSalesReturnAll
                : props.type === "replacement"
                ? headerReplacementAll
                : headerOrderAll
            }
            totalData={pagination.totalData}
            showAction={["detail"]}
            onPressDetail={(data) => {
              dispatch(setLatestState(query));
              dispatch(setLatestActiveTab(activeTab));
              dispatch(
                setB2BID(
                  props.type === "sales-order"
                    ? data.orderId
                    : props.type === "sales-return"
                    ? data.returnId
                    : data.rplId
                )
              );
            }}
            page={pagination.page}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            onChange={(page: number) => handlePageChange(page)}
            onRowsPerPageChange={(rowPerPaage: number) =>
              handlePerPageChange(rowPerPaage)
            }
          />
        </Grid>
      </Grid>
    </Layout>
  );
}
