import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Box, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';
import AppButton from '../../components/Form/AppButton';
import HeaderPage from '../../components/Layout/HeaderPage';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, { ITableHeader } from '../../components/Table/CustomizedTable';
import useFetch, { DefaultResponse } from '../../hooks/useFetch';
import { BankProperties, CustomerProperties } from '../../types/customer.type';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { setLatestState } from '../../redux/action/config-app.action';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../components/Form/FormInput';
import { Autocomplete } from '@material-ui/lab';
import { cancelTokenSource, httpRequest } from '../../helpers/api/api';
import SimpleCard2 from '../DiscountReferral/components/SimpleCard2';
import { Download, User } from 'react-feather';
import VoucherGenerateIcon from '../../components/Icon/VoucherGenerate';
import VoucherDisbursementIcon from '../../components/Icon/VoucherDisbursement';
import VoucherCommissionIcon from '../../components/Icon/VoucherCommission';
import { ApplicationState } from '../../redux/store';
import Search from '../../components/Form/Search';
import { InputLabel } from '@material-ui/core';
import FilterDate from '../../components/Form/FilterDate';
import { setChefName } from '../../redux/action/voucher.action';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { EVoucherType, VoucherTagProperties } from '../../types/voucher.type';
import { httpRequestExport } from '../../helpers/api/apiExport';
import { format } from 'date-fns';

const headers: ITableHeader[] = [
  {
    title: 'id',
    column: 'id',
    type: 'id'
  },
  {
    title: 'Name',
    column: 'name',
  },
  {
    title: 'Total Komisi',
    column: 'totalCommission',
  },
  {
    title: 'Status',
    column: 'status',
  },
  {
    title: 'Active Voucher',
    column: 'totalVoucher',
  },
  {
    title: 'Disbursement',
    column: 'disbursement',
  },
]

interface InitialQueryPartner {
  limit: number
  offset?: number
  page: number,
  status?: string
  keyword?: string
}

const initialQuery: InitialQueryPartner = {
  limit: 25,
  offset: 0,
  page: 1,
}

const Partner = () => {

  const dispatch = useDispatch()

  const { state: locationState, activeTab: currentActiveTab } = useSelector((state: ApplicationState) => state.configApp)

  const {
    data,
    loading,
    query,
    pagination,
    search,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    fetchDataTableAlt
  } = useFetch<CustomerProperties>({
    url: 'partner',
    query: initialQuery
  })

  const convertStatus = (status: string) => {

    const newStatus = status.charAt(0).toUpperCase() + status.slice(1)

    if (status === 'active') {
      return (
        <div style={{
          paddingTop: 2,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
          fontSize: 12,
          display: 'inline-block',
          paddingBottom: 2,
          borderRadius: 100,
          border: '1px solid #0AC46B',
          backgroundColor: '#e0fff0',
          color: "#059650"
        }}>{newStatus}</div>
      )
    } else if (status === 'inactive') {
      return (
        <div style={{
          paddingTop: 2,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
          fontSize: 12,
          display: 'inline-block',
          paddingBottom: 2,
          borderRadius: 100,
          border: '1px solid #E92548',
          backgroundColor: '#ffdde4',
          color: "#a50c28"
        }}>{newStatus}</div>
      )
    }
  }

  const dataMapped = data.map((item) => {
    return {
      ...item,
      id: item.customerId,
      name: item.customerName,
      totalCommission: convertNumToCurrency(Number(item.totalCommission)),
      status: convertStatus(item.status),
      totalVoucher: item.totalActiveVoucher,
      disbursement: convertNumToCurrency(Number(item.totalDisbursement))
    }
  })

  const handleAddPartner = () => {
    setIsShowDialog(true)
  }

  const handleCloseDialog = () => {
    setIsShowDialog(false)
    resetData()
  }

  const [isShowDialog, setIsShowDialog] = React.useState(false)
  const [customerId, setCustomerId] = React.useState('')
  const [name, setName] = React.useState('')
  const [bio, setBio] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [accountNumber, setAccountNumber] = React.useState('')
  const [customerBankId, setCustomerBankId] = React.useState('')
  const [loadingCreate, setLoadingCreate] = React.useState<boolean>(false)

  const [activePartner, setActivePartner] = React.useState<{ active: number, total: number }>({ active: 0, total: 0 })
  const [totalVoucher, setTotalVoucher] = React.useState<{ active: number, total: number }>({ active: 0, total: 0 })
  const [totalVoucherCommissionDisbursement, setTotalVoucherCommissionDisbursement] = React.useState<{ totalDisbursement: number, totalCommission: number }>({ totalDisbursement: 0, totalCommission: 0 })
  const [filterStatus, setFilterStatus] = React.useState<'all' | 'active' | 'inactive'>('all')

  const [loadingVoucherPartner, setLoadingVoucherPartner] = React.useState<boolean>(false)

  const resetData = () => {
    setIsShowDialog(false)
    setName('')
    setBio('')
    setPhoneNumber('')
    setEmail('')
    setAccountNumber('')
    setCustomerId('')
    setCustomerBankId('')
  }

  const handleSetDataPartner = (item: CustomerProperties) => {
    setCustomerId(item.customerId)
    setName(item.customerName)
    setBio(item.customerMetadata.bio)
    setPhoneNumber(item.phoneNumber || '')
    setEmail(item.correspondenceEmail || '')
    setAccountNumber(item.bankAccount?.accountNumber || '')
    setCustomerBankId(item.bankAccount?.customerBankId || '')
  }

  const submit = async () => {
    const data = {
      name,
      bio,
      phoneNumber,
      email,
      bankAccount: {
        bankId: 'bca',
        accountNumber,
        accountName: name,
        customerBankId
      }
    }
    setLoadingCreate(true)
    try {
      if (customerId) {
        await httpRequest.patch<DefaultResponse<{ isSuccess: boolean }>>('partner/' + customerId, data)
      } else {
        delete (data.bankAccount as any).customerBankId
        await httpRequest.post<DefaultResponse<{ isSuccess: boolean }>>('partner', data)
      }
      await fetchDataTableAlt(cancelTokenSource)
      resetData()
    } catch (error) {
      console.error('Failed create partner', JSON.stringify(error))
    } finally {
      setLoadingCreate(false)
    }
  }

  React.useEffect(() => {
    const getSummary = async () => {
      try {
        // get total available
        const summaryTotalActivePartner = await httpRequest.get<DefaultResponse<{ active: number, total: number }>>('voucher/summary/total-active-partner', { cancelToken: cancelTokenSource.token })
        setActivePartner({
          active: summaryTotalActivePartner.data.payload.active,
          total: summaryTotalActivePartner.data.payload.total
        })

        // get total voucher
        const summaryTotalVoucher = await httpRequest.get<DefaultResponse<{ active: number, total: number }>>('voucher/summary/total-partner-voucher', { cancelToken: cancelTokenSource.token })
        setTotalVoucher({
          active: summaryTotalVoucher.data.payload.active,
          total: summaryTotalVoucher.data.payload.total
        })

        // get total commission & disbursement
        const summaryTotalCommissionDisbursement = await httpRequest.get<DefaultResponse<{ totalCommission: number, totalDisbursement: number }>>('partner/summary/total-commission-disbursement', { cancelToken: cancelTokenSource.token })
        setTotalVoucherCommissionDisbursement({
          totalDisbursement: summaryTotalCommissionDisbursement.data.payload.totalDisbursement,
          totalCommission: summaryTotalCommissionDisbursement.data.payload.totalCommission
        })
      } catch (error) {
        console.error('Failed getSummary', JSON.stringify(error))
      }
    }

    getSummary()

  }, [])

  const exportVoucherPartner = async () => {
    try {
      setLoadingVoucherPartner(true);

      const res: any = await httpRequestExport.post('voucher/export-voucher-partner/xlsx', { voucherType: EVoucherType.partner })

      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        const downloadDate = format(new Date(), 'dd/MM/yyyy');
        link.setAttribute('download', `oxone-voucher-partner-${downloadDate}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoadingVoucherPartner(false);
      }
    } catch (error) {
      console.error('Error exportVoucherPartner', JSON.stringify(error))
      setLoadingVoucherPartner(false);
    }
  }

  return (
    <Layout loading={loading}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <HeaderPage title="Voucher Partner" subtitle="List of all voucher partner" />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AppButton
            onClick={handleAddPartner}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Add Partner
          </AppButton>
          <AppButton
            onClick={() => exportVoucherPartner()}
            variant="contained"
            color="primary"
            startIcon={<Download size={20} />}
          >
            Export
          </AppButton>
        </div>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={12} md={3} lg={3}>
          <SimpleCard2
            loading={false}
            title="Active Partner"
            value={`${activePartner.active}/${activePartner.total}`}
            topRightIconComponent={<User size={18} />}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <SimpleCard2
            loading={false}
            title="Total Voucher"
            value={`${totalVoucher.active}/${totalVoucher.total}`}
            topRightIconComponent={<VoucherGenerateIcon color="#000" size={18} />}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <SimpleCard2
            loading={false}
            title="Total Komisi"
            value={convertNumToCurrency(totalVoucherCommissionDisbursement.totalCommission)}
            topRightIconComponent={<VoucherCommissionIcon color="#000" size={18} />}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <SimpleCard2
            loading={false}
            title="Disbursement"
            value={convertNumToCurrency(totalVoucherCommissionDisbursement.totalDisbursement)}
            topRightIconComponent={<VoucherDisbursementIcon color="#000" size={18} />}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} md={4} style={{ marginTop: 15 }}>
          <Search
            placeholder='Search by Partner Name'
            onChange={changeHandler}
            value={locationState && locationState.search ? locationState.search : search}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl style={{ width: '100%', marginTop: 15 }}>
            <InputLabel id="select-status" shrink>
              Type
                </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                const value = event.target.value
                setFilterStatus(value)
                if (value === 'all') {
                  setQuery((oldVal: InitialQueryPartner) => {
                    return {
                      ...oldVal,
                      status: false
                    }
                  })
                } else {
                  setQuery((oldVal: InitialQueryPartner) => {
                    return {
                      ...oldVal,
                      status: value
                    }
                  })
                }
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={3}></Grid>
        <Grid item xs={12} md={4}>
          <FilterDate
            start_at={locationState && locationState.startAt ? locationState.startAt : query.startAt}
            end_at={locationState && locationState.endAt ? locationState.endAt : query.endAt}
            onChange={(start_at: string, end_at: string) => {
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }}
          />
        </Grid> */}
      </Grid>

      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <CustomizedTable
            data={dataMapped}
            headers={headers}
            totalData={pagination.totalData}
            showAction={['detail', 'edit-dialog']}
            onPressDetail={(data) => {
              dispatch(setChefName(data.name))
              // dispatch(setLatestState(query))
            }}
            page={pagination.page}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            onPressEditDialog={(data: any) => {
              setIsShowDialog(true)
              handleSetDataPartner(data)
            }}
            onChange={(newPage: number) => handlePageChange(newPage)}
            onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
          />
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth={'lg'}
        open={isShowDialog}
        onClose={handleCloseDialog}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{customerId ? 'Edit data partner' : 'Add new partner'}</DialogTitle>
        <DialogContent>
          <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setName(value)}
                  value={name}
                  fullWidth
                  label="Nama"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setBio(value)}
                  multiline
                  type="address"
                  value={bio}
                  fullWidth
                  label="Description"
                />
              </Grid>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setAccountNumber(value)}
                  value={accountNumber}
                  fullWidth
                  label="No. Rekening (BCA)"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setPhoneNumber(value)}
                  value={phoneNumber}
                  fullWidth
                  label="Nomor Telepon"
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  onChange={(value: any) => setEmail(value)}
                  value={email}
                  fullWidth
                  label="Email"
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AppButton
            disabled={loadingCreate}
            color="secondary"
            onClick={handleCloseDialog}
          >
            Cancel
          </AppButton>
          <AppButton
            disabled={loadingCreate}
            loading={loadingCreate}
            onClick={() => submit()}
          >Save</AppButton>
        </DialogActions>
      </Dialog>

    </Layout>
  )
}

export default Partner