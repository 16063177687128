import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";
import COLORS from "./colors";

const defaultTheme: ThemeOptions = {
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.greyDark,
    },
    error: {
      main: COLORS.error,
    },
    background: {
      // default: COLORS.accent,
      default: "#F6F6F6",
    },
    text: {
      primary: COLORS.primary,
      secondary: COLORS.grey500,
    },
  },
  typography: {
    fontFamily: ["Open Sans"].join(","),
    h2: {
      fontSize: 21,
      lineHeight: 2,
      fontWeight: "bold",
    },
    h3: {
      fontSize: 20,
      lineHeight: 2,
      fontWeight: "bold",
    },
    h4: {
      fontSize: 18,
      lineHeight: 2,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 16,
      lineHeight: 2,
      fontWeight: "bold",
    },
    body1: {
      fontSize: 14,
      lineHeight: 2,
    },
    body2: {
      fontSize: 13,
      lineHeight: 2,
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: 11,
      lineHeight: 1,
    },
  },
};

// Create a theme instance.
export const theme = createMuiTheme(defaultTheme);

export const themeAuth = createMuiTheme({
  ...defaultTheme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.77), 
            rgba(0, 0, 0, 0.77)
          ), url(${"assets/background/bg-original.png"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          minHeight: "100vh",
        },
      },
    },
  },
});

const output = { theme, themeAuth };
export default output;
