export enum EStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum StatusTransaction {
  FINISHED = "FINISHED",
  NOT_FINISHED_YET = "NOT_FINISHED_YET",
}

export type metaProductType = {
  productId: string;
  productName: string;
  noSerial: string;
  sku: string;
  initialWarranty?: Date | any;
  categories: {
    categoryId: string;
    categoryName: string;
  }[];
};

export type metaUserType = {
  customerId?: string;
  phoneNumber: string;
  customerName: string;
  profilePic: string;
};

type metaAddressType = {
  province: string;
  city: string;
  subdistrict: string;
  address: string;
  location: {
    lat: any;
    lng: any;
  };
  status: EStatus;
};

export type ServiceCenterType = {
  serviceCenterId: string;
  name: string;
  phoneNumber: string;
  status: EStatus;
  metaAddress: metaAddressType;
  createdAt?: Date | any;
  updatedAt?: Date | any;
  deletedAt?: Date | any;
};

export type ServiceCenterTransactionType = {
  serviceCenterTransactionId: string;
  serviceStartAt: Date | any;
  serviceFinishAt: Date | any;
  serviceCenterName: string;
  problem: string;
  note?: string;
  status: StatusTransaction;
  solution: string;
  metaProduct: any;
  metaUser: metaUserType;
  createdAt?: Date | any;
  updatedAt?: Date | any;
  images?: [];
};

export enum ServiceCenterTypes {
  FIND_ALL_SERVICE_CENTER = "FIND_ALL_SERVICE_CENTER",
  FIND_ONE_SERVICE_CENTER = "FIND_ONE_SERVICE_CENTER",
  CREATE_SERVICE_CENTER = "CREATE_SERVICE_CENTER",
  UPDATE_SERVICE_CENTER = "UPDATE_SERVICE_CENTER",
  REMOVE_SERVICE_CENTER = "REMOVE_SERVICE_CENTER",
  SET_LOADING_SERVICE_CENTER = "SET_LOADING_SERVICE_CENTER",
  SET_ERROR_SERVICE_CENTER = "SET_ERROR_SERVICE_CENTER",
  SET_LAST_PATH_SERVICE_CENTER = "SET_LAST_PATH_SERVICE_CENTER",
  SET_LAST_QUERY_SERVICE_CENTER = "SET_LAST_QUERY_SERVICE_CENTER",
}

export type ServiceCenterTransactionResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ServiceCenterTransactionType[];
  };
};

export type ServiceCenterTransactionState = {
  isLoadingTransaction: boolean;
  data: ServiceCenterTransactionResponsePayload;
  serviceCenterTransaction: any;
  error: any;
  isSuccess: boolean;
};

export type ServiceCenterResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ServiceCenterType[];
  };
};

export type ServiceCenterState = {
  isLoadingServiceCenter: boolean;
  data: ServiceCenterResponsePayload;
  serviceCenter: any;
  error: any;
  isSuccess: boolean;
};
