import { Reducer } from 'redux';
import {
  initialStateInvoice,
  initialStateTransaction,
  TransactionState,
  TransactionTypes,
} from '../../types/transaction.types';

const initialState: TransactionState = {
  isLoadingTransaction: false,
  isLoadingProcess: false,
  data: {
    code: '',
    message: '',
    payload: {
      offset: 0,
      limit: 0,
      totalPage: 0,
      count: 0,
      results: [],
    },
  },
  transaction: { ...initialStateTransaction },
  invoice: { ...initialStateInvoice },
  transactionHistory: {
    code: '',
    message: '',
    payload: {
      page: 0,
      perPage: 0,
      totalPage: 0,
      totalData: 0,
      data: [],
    },
  },
  error: {},
  lastPathQuery: {
    limit: 0,
    offset: 0,
    search: '',
    filterStatus: '',
    startAt: '',
    endAt: '',
  },
  isSuccess: false,
  totalRefundedAmount: 0,
};

export const transaction: Reducer<TransactionState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TransactionTypes.FIND_ALL_TRANSACTION:
      return {
        ...state,
        isSuccess: false,
        data: action.payload,
      };
    case TransactionTypes.FIND_ONE_TRANSACTION:
      return {
        ...state,
        isSuccess: false,
        transaction: {
          ...action.payload.transaction,
        },
        invoice: {
          ...action.payload.invoice,
        },
      };
    case TransactionTypes.SET_ONE_TRANSACTION:
      return {
        ...state,
        transaction: {
          ...action.payload,
        },
        invoice: {
          ...action.payload.invoice,
        },
      };
    case TransactionTypes.FIND_HISTORY_TRANSACTION:
      const results = action.payload.results.sort((a: any, b: any) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
      return {
        ...state,
        transactionHistory: {
          ...action.payload,
          results
        },
      };
    case TransactionTypes.SET_TRANSACTION_PROCESS_RESULT:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case TransactionTypes.SET_REFUND_TRANSACTION:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case TransactionTypes.SET_REFUND_AMOUNT:
      return {
        ...state,
        totalRefundedAmount: action.payload,
      };
    case TransactionTypes.SET_LOADING_TRANSACTION:
      return {
        ...state,
        isLoadingTransaction: action.payload,
      };
    case TransactionTypes.SET_LOADING_PROCESS:
      return {
        ...state,
        isSuccess: false,
        isLoadingProcess: action.payload,
      };
    case TransactionTypes.SET_LAST_QUERY_TRANSACTION:
      return {
        ...state,
        lastPathQuery: action.payload,
      };
    case TransactionTypes.SET_ERROR_TRANSACTION:
      return {
        ...state,
        isSuccess: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
