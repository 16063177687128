import { Reducer } from 'redux';
import { TagResponse, TagState, TagTypes } from '../../../types/tag.type';

const initialState: TagState = {
  isLoadingTag: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: null,
      prev: null,
      count: null,
      results: []
    }
  },
  tag: {
    tagId: '',
    tagName: ''
  },
  error: {}
}

export const tag: Reducer<TagState> = (state = initialState, action) => {
  switch (action.type) {
    case TagTypes.FIND_ALL_TAG:
      const results = action.payload.payload.results.map((tag: TagResponse) => {
        return {
          tagName: tag.tagName,
          tagId: tag.tagId
        }
      })
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results
          }
        }
      }
    case TagTypes.FIND_ONE_TAG:
      return {
        ...state,
        tag: action.payload
      }
    case TagTypes.SET_LOADING_TAG:
      return {
        ...state,
        isLoadingTag: action.payload
      }
    case TagTypes.SET_ERROR_TAG:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default tag