/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useEffect, useState } from "react";
import { Edit2, Eye, Trash, Trash2 } from "react-feather";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ImagePreview from "../../components/Card/ImagePreview";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import { LoadingScreen } from "../../components/LoadingScreen";
import AppTabs from "../../components/Tabs/AppTabs";
import { convertNumToCurrency } from "../../helpers/functions/currency";
import { EPermission } from "../../helpers/role";
import useAuth from "../../hooks/useAuth";
import useProduct from "../../hooks/useProduct";
import { ApplicationState } from "../../redux/store";
import COLORS from "../../styles/colors";
import { ImageProduct, ProductCategory } from "../../types/product.type";
import ProductPriceLog from "./ProductPriceLog";

export default function ProductEdit() {
  const auth = useAuth();

  const location = useLocation();
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const tabs = ["Product Detail", "Product Price Log"];

  const [mode, setMode] = useState<"detail" | "edit" | "create">("detail");
  const [dialogUploadImage, setDialogUploadImage] = useState(false);
  const [dialogUploadVideo, setDialogUploadVideo] = useState(false);
  const [dialogEditVideo, setDialogEditVideo] = useState(false);
  const [indexEditVideo, setIndexEditVideo] = useState(0);
  const [dialogConfirmDelete, setDialogConfirmDelete] = useState(false);
  const [dialogUpdateDiscount, setDialogUpdateDiscount] = useState(false);
  const [dialogEditInitialWarranty, setDialogEditInitialWarranty] =
    useState(false);
  const [fileId, setFileId] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [youtubeURL, setYoutubeURL] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [discountData, setDiscountData] = useState<{
    catalogPrice: number;
    retailDiscountAmount: number;
    retailDiscountNote: string;
    goldPrice?: number;
    goldDiscountAmount: number;
    goldDiscountNote: string;
    platinumPrice?: number;
    platinumDiscountAmount: number;
    platinumDiscountNote: string;
    type?: string;
    note: string;
  }>({
    catalogPrice: 0,
    retailDiscountAmount: 0,
    retailDiscountNote: "",
    type: "silver",
    goldDiscountAmount: 0,
    goldDiscountNote: "",
    platinumDiscountAmount: 0,
    platinumDiscountNote: "",
    note: "",
  });
  const [videoData, setVideoData] = useState<{
    title: string;
    type: string;
    url: string;
  }>({
    title: "",
    type: "",
    url: "",
  });
  const [initialWarrantyEndAt, setInitialWarrantyEndAt] = useState<string>("");
  const [formErrors, setFormErrors] = useState({
    retailDiscountNote: false,
    goldDiscountNote: false,
    platinumDiscountNote: false,
    retailDiscountAmount: false,
    goldDiscountAmount: false,
    platinumDiscountAmount: false,
  });
  const [nominalError, setNominalError] = useState("");

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const { product, isLoadingImageAdd, isLoadingImageRemove, isLoadingProduct } =
    useSelector((state: ApplicationState) => state.product);

  const {
    removeProductImage,
    addProductImage,
    fetchOneProduct,
    updateProductDiscount,
    addProductVideo,
    updateProductVideo,
    updateProductInitialWarranty,
    removeProductVideo,
  } = useProduct();

  const YoutubeEmbed = () => (
    <div style={{ marginTop: 10 }}>
      <iframe
        width="580"
        height="246"
        src={youtubeURL}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );

  useEffect(() => {
    fetchOneProduct(id);
    if (id && location.pathname.includes("edit")) {
      // edit
      setMode("edit");
    } else if (id) {
      // detail
      setMode("edit");
    } else {
      // create
      setMode("create");
    }
  }, []);

  let title = "";
  let subtitle = "";

  if (mode === "create") {
    title = "Create Product";
    subtitle = "Create new product";
  } else if (mode === "edit") {
    title = "Edit Product";
    subtitle = "Edit product";
  } else if (mode === "detail") {
    title = "Detail Product";
    subtitle = "Detailed information about product";
  }

  function handleCloseDialogUpdateDiscount() {
    setFormErrors({
      retailDiscountNote: false,
      goldDiscountNote: false,
      platinumDiscountNote: false,
      retailDiscountAmount: false,
      goldDiscountAmount: false,
      platinumDiscountAmount: false,
    });
    setDialogUpdateDiscount(false);
    setDiscountData({
      catalogPrice: 0,
      retailDiscountAmount: 0,
      retailDiscountNote: "",
      goldDiscountAmount: 0,
      goldDiscountNote: "",
      platinumDiscountAmount: 0,
      platinumDiscountNote: "",
      note: "",
    });
    setNominalError("");
  }

  function handleCloseDialogUploadVideo() {
    setDialogUploadVideo(false);
    setVideoData({
      title: "",
      type: "",
      url: "",
    });
  }

  function handleCloseDialogEditVideo() {
    setDialogEditVideo(false);
    setVideoData({
      title: "",
      type: "",
      url: "",
    });
  }

  function handleCloseDialogEditInitialWarranty() {
    setDialogEditInitialWarranty(false);
  }

  function handleCloseDialog() {
    setDialogUploadImage(false);
    setFile(null);
  }

  function uploadImage() {
    const formData = new FormData();

    formData.append("image", file);
    formData.append("productId", product.productId);

    addProductImage(formData);
  }

  async function submitDiscount() {
    if (
      (discountData.type === "silver" &&
        discountData.retailDiscountNote &&
        (!/^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(
          discountData.retailDiscountNote
        ) ||
          !(
            parseInt(
              (discountData?.retailDiscountNote || "0").replace("%", "")
            ) <= 100
          ) ||
          !(
            parseInt(
              (discountData?.retailDiscountNote || "0").replace("%", "")
            ) >= 0
          ))) ||
      ((discountData?.retailDiscountAmount || 0) === 0 &&
        !discountData.retailDiscountNote)
    ) {
      setFormErrors({
        retailDiscountAmount: false,
        goldDiscountAmount: false,
        platinumDiscountAmount: false,
        retailDiscountNote: true,
        goldDiscountNote: false,
        platinumDiscountNote: false,
      });

      return;
    }
    if (
      (discountData.type === "gold" &&
        discountData.goldDiscountNote &&
        (!/^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(
          discountData.goldDiscountNote
        ) ||
          !(
            parseInt(
              (discountData?.goldDiscountNote || "0").replace("%", "")
            ) <= 100
          ) ||
          !(
            parseInt(
              (discountData?.goldDiscountNote || "0").replace("%", "")
            ) >= 0
          ))) ||
      ((discountData?.goldDiscountAmount || 0) === 0 &&
        !discountData.goldDiscountNote)
    ) {
      setFormErrors({
        retailDiscountAmount: false,
        goldDiscountAmount: false,
        platinumDiscountAmount: false,
        retailDiscountNote: false,
        goldDiscountNote: true,
        platinumDiscountNote: false,
      });

      return;
    }
    if (
      (discountData.type === "platinum" &&
        discountData.platinumDiscountNote &&
        (!/^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(
          discountData.platinumDiscountNote
        ) ||
          !(
            parseInt(
              (discountData?.platinumDiscountNote || "0").replace("%", "")
            ) <= 100
          ) ||
          !(
            parseInt(
              (discountData?.platinumDiscountNote || "0").replace("%", "")
            ) >= 0
          ))) ||
      ((discountData?.platinumDiscountAmount || 0) === 0 &&
        !discountData.platinumDiscountNote)
    ) {
      setFormErrors({
        retailDiscountAmount: false,
        goldDiscountAmount: false,
        platinumDiscountAmount: false,
        retailDiscountNote: false,
        goldDiscountNote: false,
        platinumDiscountNote: true,
      });

      return;
    }

    if (
      discountData.retailDiscountAmount < discountData.catalogPrice &&
      discountData.goldDiscountAmount < discountData.catalogPrice &&
      discountData.platinumDiscountAmount < discountData.catalogPrice
    ) {
      await updateProductDiscount({
        productId: id,
        catalogPrice: discountData.catalogPrice,
        retailDiscountAmount: discountData.retailDiscountAmount,
        retailDiscountNote: discountData.retailDiscountNote,
        goldDiscountAmount: discountData.goldDiscountAmount,
        goldDiscountNote: discountData.goldDiscountNote,
        platinumDiscountAmount: discountData.platinumDiscountAmount,
        platinumDiscountNote: discountData.platinumDiscountNote,
        updatedBy: auth.user.name,
        note: discountData.note,
      });

      handleCloseDialogUpdateDiscount();
      setFormErrors({
        retailDiscountNote: false,
        goldDiscountNote: false,
        platinumDiscountNote: false,
        retailDiscountAmount: false,
        goldDiscountAmount: false,
        platinumDiscountAmount: false,
      });
    } else {
      setFormErrors({
        retailDiscountAmount: true,
        goldDiscountAmount: true,
        platinumDiscountAmount: true,
        retailDiscountNote: false,
        goldDiscountNote: false,
        platinumDiscountNote: false,
      });
      setNominalError("Diskon tidak boleh melebihi harga catalog");
    }
  }

  async function submitVideo() {
    await addProductVideo({
      productId: id,
      title: videoData.title,
      type: videoData.type,
      url: videoData.url,
    });
    handleCloseDialogUploadVideo();
    setVideoData({
      title: "",
      type: "",
      url: "",
    });
  }

  async function editVideo(index: number) {
    await updateProductVideo({
      productId: id,
      index: index,
      title: videoData.title,
      type: videoData.type,
      url: videoData.url,
    });
    handleCloseDialogEditVideo();

    setVideoData({
      title: "",
      type: "",
      url: "",
    });
  }

  async function editInitialWarranty() {
    await updateProductInitialWarranty({
      productId: id,
      date: initialWarrantyEndAt,
    });

    handleCloseDialogEditInitialWarranty();
    setInitialWarrantyEndAt("");
  }

  useEffect(() => {
    function validateYouTubeUrl() {
      var url = videoData.url;
      if (url != undefined || url != "") {
        var regExp =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
          setIsValid(true);
          setYoutubeURL(`https://www.youtube.com/embed/${match[2]}?autoplay=0`);
        } else {
          console.log("error");
          setIsValid(false);
        }
      }
    }

    if (videoData.url.trim() !== "") {
      validateYouTubeUrl();
    } else {
      setIsValid(false);
    }
  }, [videoData.url]);

  useEffect(() => {
    if (!isLoadingImageAdd) {
      handleCloseDialog();
    }
  }, [isLoadingImageAdd]);

  async function deleteImage() {
    await removeProductImage({
      fileId: fileId,
      productId: product.productId,
    });

    setDialogConfirmDelete(false);
  }

  async function deleteVideo(index: number) {
    await removeProductVideo({ productId: id, index: index });
  }

  const handleEditVideo = (index: number) => {
    setVideoData({
      title: product.videoMetaData[index].title,
      type: product.videoMetaData[index].type,
      url: product.videoMetaData[index].url,
    });
    setIndexEditVideo(index);
    setDialogEditVideo(true);
  };

  const handleEditInitialWarranty = () => {
    setInitialWarrantyEndAt(product.initialWarranty);
    setDialogEditInitialWarranty(true);
  };

  function createData(
    name: string,
    discountNote: string,
    discountAmount: string,
    price: string,
    action: any
  ) {
    return { name, discountNote, discountAmount, price, action };
  }

  const rows = [
    createData(
      "Silver",
      product.price.retailDiscountNote || "Not Set",
      convertNumToCurrency(product.price.retailDiscountAmount),
      convertNumToCurrency(product.price.retailPrice),
      <Edit2
        color={COLORS.primary}
        size={18}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setDiscountData({
            catalogPrice: product.price.catalogPrice,
            retailDiscountAmount: product.price.retailDiscountAmount,
            retailDiscountNote: product.price.retailDiscountNote || "0%",
            goldDiscountAmount: product.price.goldDiscountAmount,
            goldDiscountNote: product.price.goldDiscountNote || "0%",
            platinumDiscountAmount: product.price.platinumDiscountAmount,
            platinumDiscountNote: product.price.platinumDiscountNote || "0%",
            type: "silver",
            note: "",
          });
          setDialogUpdateDiscount(true);
        }}
      />
    ),
    createData(
      "Gold",
      product.price.goldDiscountNote || "Not Set",
      convertNumToCurrency(product.price.goldDiscountAmount),
      convertNumToCurrency(product.price.goldPrice),
      <Edit2
        color={COLORS.primary}
        size={18}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setDiscountData({
            catalogPrice: product.price.catalogPrice,
            retailDiscountAmount: product.price.retailDiscountAmount,
            retailDiscountNote: product.price.retailDiscountNote || "0%",
            goldDiscountAmount: product.price.goldDiscountAmount,
            goldDiscountNote: product.price.goldDiscountNote || "0%",
            platinumDiscountAmount: product.price.platinumDiscountAmount,
            platinumDiscountNote: product.price.platinumDiscountNote || "0%",
            type: "gold",
            note: "",
          });
          setDialogUpdateDiscount(true);
        }}
      />
    ),
    createData(
      "Platinum",
      product.price.platinumDiscountNote || "Not Set",
      convertNumToCurrency(product.price.platinumDiscountAmount),
      convertNumToCurrency(product.price.platinumPrice),
      <Edit2
        color={COLORS.primary}
        size={18}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setDiscountData({
            catalogPrice: product.price.catalogPrice,
            retailDiscountAmount: product.price.retailDiscountAmount,
            retailDiscountNote: product.price.retailDiscountNote || "0%",
            goldDiscountAmount: product.price.goldDiscountAmount,
            goldDiscountNote: product.price.goldDiscountNote || "0%",
            platinumDiscountAmount: product.price.platinumDiscountAmount,
            platinumDiscountNote: product.price.platinumDiscountNote || "0%",
            type: "platinum",
            note: "",
          });
          setDialogUpdateDiscount(true);
        }}
      />
    ),
  ];

  const handleDateEndChange = (duration: any) => {
    setInitialWarrantyEndAt(duration);
  };

  if (!isLoadingProduct) {
    return (
      <Layout title={title}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={12} lg={11}>
            <HeaderPage title={title} subtitle={subtitle} showBackButton />
          </Grid>

          {mode === "detail" && <Grid item xs={6} md={6} lg={2}></Grid>}
          {mode === "detail" &&
            (Object.keys(auth.user.permissions).includes(
              EPermission.PRODUCT_FULL_ACCESS
            ) ||
              Object.keys(auth.user.permissions).includes(
                EPermission.PRODUCT_EDIT
              )) && (
              <Grid item xs={6} md={6} lg={2}>
                <AppButton
                  fullWidth
                  onClick={() => setMode("edit")}
                  color="primary"
                >
                  Edit
                </AppButton>
              </Grid>
            )}
        </Grid>

        <AppTabs
          active={activeTab}
          tabs={tabs}
          onChange={(value: number) => setActiveTab(value)}
        />
        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={3} className={classes.containerForm}>
            {activeTab == 0 && (
              <>
                <Grid item xs={12} md={12} lg={4} className={classes.input}>
                  <div
                    style={{
                      paddingLeft: 50,
                      paddingRight: 50,
                    }}
                  >
                    <ImagePreview
                      type="preview-product"
                      image={product.images[selectedImageIndex]}
                    />

                    <Grid container spacing={3} style={{ paddingTop: 20 }}>
                      {product.images.map(
                        (image: ImageProduct, index: number) => (
                          <Grid
                            key={index}
                            item
                            xs={4}
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <Box onClick={() => setSelectedImageIndex(index)}>
                              <ImagePreview image={image} />
                            </Box>
                            {mode === "edit" && (
                              <Box
                                className={classes.boxDelete}
                                onClick={() => {
                                  setDialogConfirmDelete(true);
                                  setFileId(image.fileId);
                                }}
                              >
                                <Trash size={14} color="white" />
                              </Box>
                            )}
                          </Grid>
                        )
                      )}
                      {mode === "edit" && (
                        <Grid item xs={12} style={{ cursor: "pointer" }}>
                          <AppButton
                            fullWidth
                            color="primary"
                            onClick={() => setDialogUploadImage(true)}
                          >
                            Add New Image
                          </AppButton>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={8} className={classes.input}>
                  <div className={classes.row}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Product Name
                    </Typography>
                    <Typography variant="h3" style={{ fontWeight: "normal" }}>
                      {product.sku} {product.productName}
                    </Typography>
                  </div>

                  <div className={classes.row}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Categories
                    </Typography>

                    <Box display="flex" flexDirection="row">
                      {product.categories.length > 0 ? (
                        product.categories.map(
                          (item: ProductCategory, index: number) => (
                            <Box key={item.categoryId} marginRight={1}>
                              <Chip size="small" label={item.categoryName} />
                            </Box>
                          )
                        )
                      ) : (
                        <Chip size="small" label="Uncategorized" />
                      )}
                    </Box>
                  </div>

                  <div className={classes.row}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ color: COLORS.grey, marginBottom: 5 }}
                        >
                          Catalog Price
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "normal" }}
                        >
                          {convertNumToCurrency(product.price.catalogPrice)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ color: COLORS.grey, marginBottom: 5 }}
                        >
                          Agent Price
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "normal" }}
                        >
                          {convertNumToCurrency(product.price.distributorPrice)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{ marginBottom: 50 }}
                  >
                    <Table
                      size="small"
                      aria-label="a dense table"
                      component={Table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }}>
                            Loyalty Type
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold" }}
                            align="left"
                          >
                            Discount (%)
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold" }}
                            align="left"
                          >
                            Discount (Rp)
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold" }}
                            align="left"
                          >
                            Price After Discount
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: "bold" }}
                            align="left"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow
                            key={index}
                            component={TableRow}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f2f2f2" : "white",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">
                              {row.discountNote}
                            </TableCell>
                            <TableCell align="left">
                              {row.discountAmount}
                            </TableCell>
                            <TableCell align="left">{row.price}</TableCell>
                            <TableCell align="center">{row.action}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className={classes.row}>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ color: COLORS.grey, marginBottom: 5 }}
                        >
                          Status
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "normal" }}
                        >
                          {product.published ? "Published" : "Unpublished"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ color: COLORS.grey, marginBottom: 5 }}
                        >
                          Product SKU
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "normal" }}
                        >
                          {product.sku}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ color: COLORS.grey, marginBottom: 5 }}
                        >
                          Initial Warranty
                        </Typography>
                        <Typography
                          variant="h5"
                          style={{ fontWeight: "normal" }}
                        >
                          {product.initialWarranty} Months
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={classes.row}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Video Lainnya
                    </Typography>

                    <AppButton
                      color="primary"
                      onClick={() => setDialogUploadVideo(true)}
                    >
                      Tambah Video
                    </AppButton>

                    <div>
                      <TableContainer
                        component={Paper}
                        style={{ marginBottom: 50, marginTop: 10 }}
                      >
                        <Table
                          size="small"
                          aria-label="a dense table"
                          component={Table}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>
                                Title
                              </TableCell>
                              <TableCell
                                style={{ fontWeight: "bold" }}
                                align="left"
                              >
                                Type
                              </TableCell>
                              <TableCell
                                style={{ fontWeight: "bold" }}
                                align="left"
                              >
                                URL Youtube
                              </TableCell>

                              <TableCell
                                style={{ fontWeight: "bold" }}
                                align="left"
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {product.videoMetaData?.map((row, index) => (
                              <TableRow
                                key={index}
                                component={TableRow}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#f2f2f2" : "white",
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.title}
                                </TableCell>
                                <TableCell align="left">{row.type}</TableCell>

                                <TableCell align="left">
                                  <a href={row.url}>{row.url}</a>
                                </TableCell>
                                <TableCell align="left">
                                  <Edit2
                                    color={COLORS.primary}
                                    size={18}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    onClick={() => handleEditVideo(index)}
                                  />
                                  <Trash2
                                    color={COLORS.primary}
                                    size={18}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => deleteVideo(index)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>

                  <div style={{ marginTop: -20, paddingBottom: 20 }}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Initial Warranty
                    </Typography>

                    <AppButton
                      color="primary"
                      onClick={() => handleEditInitialWarranty()}
                    >
                      Edit Initial Warranty
                    </AppButton>
                  </div>

                  <div className={classes.row}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Specification
                    </Typography>

                    <div>
                      {product.metadata.specifications.map(
                        (item: any, index: number) => (
                          <Box
                            key={index}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            className={
                              index < product.metadata.specifications.length - 1
                                ? classes.spesificationItemRowWithBorderBottom
                                : classes.spesificationItemRow
                            }
                          >
                            <Box width="35%">
                              <Typography variant="body1" style={{ margin: 0 }}>
                                {item.key === "DIMENSIONS"
                                  ? "PACKAGE DIMENSION"
                                  : item.key.split("_").join(" ")}
                              </Typography>
                            </Box>
                            <Box width="65%">
                              <Typography variant="body1" style={{ margin: 0 }}>
                                {item.key === "WEIGHT"
                                  ? item.value + " gram"
                                  : item.value}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      )}
                    </div>
                  </div>

                  <div className={classes.row}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Product Details
                    </Typography>

                    <div>
                      {product.metadata.details.map(
                        (item: any, index: number) => (
                          <Box
                            key={index}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            className={
                              index < product.metadata.details.length - 1
                                ? classes.spesificationItemRowWithBorderBottom
                                : classes.spesificationItemRow
                            }
                          >
                            <Box width="100%">
                              <Typography variant="body1" style={{ margin: 0 }}>
                                {item}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      )}
                    </div>
                  </div>

                  <div className={classes.row}>
                    <Typography
                      variant="body1"
                      style={{ color: COLORS.grey, marginBottom: 5 }}
                    >
                      Description
                    </Typography>

                    <Typography variant="body1" style={{ margin: 0 }}>
                      {product.description}
                    </Typography>
                  </div>
                </Grid>
              </>
            )}
            {activeTab == 1 && <ProductPriceLog />}
          </Grid>
        </Paper>

        <Dialog
          onClose={handleCloseDialogUpdateDiscount}
          aria-labelledby="simple-dialog-title"
          open={dialogUpdateDiscount}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">
                  {discountData.type == "silver"
                    ? "Edit Silver Discount"
                    : discountData.type == "gold"
                    ? "Edit Gold Discount"
                    : "Edit Platinum Discount"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 5 }}>
              <Typography style={{ color: "#9E9E9E", fontSize: 10 }}>
                Catalog Price
              </Typography>
              <Typography variant="body1">
                {product.price.catalogPrice}
              </Typography>
            </Grid>
            <div
              className={classes.row}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {discountData.type == "silver" && (
                <FormInput
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    setDiscountData({
                      ...discountData,
                      retailDiscountNote: value,
                    });
                  }}
                  type="text"
                  value={discountData.retailDiscountNote}
                  error={formErrors.retailDiscountNote}
                  helperText={
                    formErrors.retailDiscountNote
                      ? "Cek kembali penulisan anda. Ex. 7% atau 7%+2%"
                      : "Diskon dalam persen, Ex. 7% atau 7%+2%"
                  }
                  required
                  label="Discount Percentage"
                />
              )}
              {discountData.type == "gold" && (
                <FormInput
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    setDiscountData({
                      ...discountData,
                      goldDiscountNote: value,
                    });
                  }}
                  type="text"
                  value={discountData.goldDiscountNote}
                  error={formErrors.goldDiscountNote}
                  helperText={
                    formErrors.goldDiscountNote
                      ? "Cek kembali penulisan anda. Ex. 7% atau 7%+2%"
                      : "Diskon dalam persen, Ex. 7% atau 7%+2%"
                  }
                  required
                  label="Discount Percentage"
                />
              )}
              {discountData.type == "platinum" && (
                <FormInput
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    setDiscountData({
                      ...discountData,
                      platinumDiscountNote: value,
                    });
                  }}
                  type="text"
                  value={discountData.platinumDiscountNote}
                  error={formErrors.platinumDiscountNote}
                  helperText={
                    formErrors.platinumDiscountNote
                      ? "Cek kembali penulisan anda. Ex. 7% atau 7%+2%"
                      : "Diskon dalam persen, Ex. 7% atau 7%+2%"
                  }
                  required
                  label="Discount Percentage"
                />
              )}
              <Box width="5%" />
              {discountData.type == "silver" && (
                <FormInput
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    setDiscountData({
                      ...discountData,
                      retailDiscountAmount: value,
                    });
                  }}
                  type="currency"
                  error={formErrors.retailDiscountAmount}
                  value={discountData.retailDiscountAmount}
                  required
                  label="Discount (Rp)"
                  helperText={
                    nominalError
                      ? nominalError
                      : "Jika nominal diisi, maka diskon Retail Price akan diambil dari nominal ini"
                  }
                />
              )}
              {discountData.type == "gold" && (
                <FormInput
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    setDiscountData({
                      ...discountData,
                      goldDiscountAmount: value,
                    });
                  }}
                  type="currency"
                  value={discountData.goldDiscountAmount}
                  error={formErrors.goldDiscountAmount}
                  required
                  label="Discount (Rp)"
                  helperText={
                    nominalError
                      ? nominalError
                      : "Jika nominal diisi, maka diskon Gold Price akan diambil dari nominal ini"
                  }
                />
              )}
              {discountData.type == "platinum" && (
                <FormInput
                  style={{ width: "100%" }}
                  onChange={(value: any) => {
                    setDiscountData({
                      ...discountData,
                      platinumDiscountAmount: value,
                    });
                  }}
                  type="currency"
                  value={discountData.platinumDiscountAmount}
                  error={formErrors.platinumDiscountAmount}
                  required
                  label="Discount (Rp)"
                  helperText={
                    nominalError
                      ? nominalError
                      : "Jika nominal diisi, maka diskon Platinum Price akan diambil dari nominal ini"
                  }
                />
              )}
            </div>
            <Grid item xs={12} style={{ marginTop: -20 }}>
              {discountData.type == "silver" && (
                <>
                  <Typography style={{ color: "#9E9E9E", fontSize: 10 }}>
                    Price After Discount
                  </Typography>
                  <Typography variant="body1">
                    {discountData.retailDiscountNote !== "" &&
                    discountData.retailDiscountNote !== "%" ? (
                      <>
                        {discountData.retailDiscountAmount
                          ? product.price.catalogPrice -
                            discountData.retailDiscountAmount
                          : product.price.catalogPrice -
                            product.price.catalogPrice *
                              (parseInt(discountData.retailDiscountNote) / 100)}
                      </>
                    ) : (
                      product.price.catalogPrice
                    )}
                  </Typography>
                </>
              )}
              {discountData.type == "gold" && (
                <>
                  <Typography style={{ color: "#9E9E9E", fontSize: 10 }}>
                    Price After Discount
                  </Typography>
                  <Typography variant="body1">
                    {discountData.goldDiscountNote !== "" &&
                    discountData.goldDiscountNote !== "%" ? (
                      <>
                        {discountData.goldDiscountAmount
                          ? product.price.catalogPrice -
                            discountData.goldDiscountAmount
                          : product.price.catalogPrice -
                            product.price.catalogPrice *
                              (parseInt(discountData.goldDiscountNote) / 100)}
                      </>
                    ) : (
                      product.price.catalogPrice
                    )}
                  </Typography>
                </>
              )}
              {discountData.type == "platinum" && (
                <>
                  <Typography style={{ color: "#9E9E9E", fontSize: 10 }}>
                    Price After Discount
                  </Typography>
                  <Typography variant="body1">
                    {discountData.platinumDiscountNote !== "" &&
                    discountData.platinumDiscountNote !== "%" ? (
                      <>
                        {discountData.platinumDiscountAmount
                          ? product.price.catalogPrice -
                            discountData.platinumDiscountAmount
                          : product.price.catalogPrice -
                            product.price.catalogPrice *
                              (parseInt(discountData.platinumDiscountNote) /
                                100)}
                      </>
                    ) : (
                      product.price.catalogPrice
                    )}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={(e: any) => {
                  setDiscountData({
                    ...discountData,
                    note: e.target.value,
                  });
                }}
                id="outlined-multiline-static"
                label="Masukan Catatan"
                style={{
                  width: "100%",
                }}
                multiline
                rows={4}
                value={discountData.note ? discountData.note : ""}
              />
            </Grid>

            <DialogActions style={{ marginTop: 20, marginLeft: "auto" }}>
              <AppButton
                fullWidth
                color="secondary"
                onClick={handleCloseDialogUpdateDiscount}
              >
                Cancel
              </AppButton>
              <AppButton fullWidth color="primary" onClick={submitDiscount}>
                Save
              </AppButton>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog
          onClose={handleCloseDialog}
          aria-labelledby="simple-dialog-title"
          open={dialogUploadImage}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <Grid style={{ padding: 20 }}>
            <UploadImage
              title="Add new product image"
              imageResolution="1000x1000"
              onFileSelected={(e: any) => setFile(e)}
              editable={true}
            />
            <DialogActions style={{ marginTop: 20 }}>
              <AppButton
                fullWidth
                color="secondary"
                loading={isLoadingImageAdd}
                onClick={handleCloseDialog}
              >
                Cancel
              </AppButton>
              <AppButton
                fullWidth
                color="primary"
                loading={isLoadingImageAdd}
                onClick={uploadImage}
              >
                Upload
              </AppButton>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog
          open={dialogConfirmDelete}
          onClose={() => {
            setDialogConfirmDelete(false);
            setFileId("");
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: "8px 16px" }}>
            <Typography variant="h5">Delete product image</Typography>
          </DialogTitle>
          <DialogContent style={{ padding: "0px 16px" }}>
            <DialogContentText id="alert-dialog-description">
              <Typography color="primary" variant="body1">
                Are you sure want to delete this product image?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <AppButton
              loading={isLoadingImageRemove}
              color="secondary"
              onClick={() => {
                setDialogConfirmDelete(false);
                setFileId("");
                setFormErrors({
                  retailDiscountNote: false,
                  goldDiscountNote: false,
                  platinumDiscountNote: false,
                  retailDiscountAmount: false,
                  goldDiscountAmount: false,
                  platinumDiscountAmount: false,
                });
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              loading={isLoadingImageRemove}
              onClick={() => deleteImage()}
              color="primary"
            >
              Delete
            </AppButton>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="simple-dialog-title"
          open={dialogUploadVideo}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Add Link Youtube</Typography>
              </Box>
            </Grid>
            <FormInput
              onChange={(value: any) => {
                setVideoData({
                  ...videoData,
                  title: value,
                });
              }}
              type="text"
              value={videoData.title}
              fullWidth
              placeholder="Masukan Judul"
              label="Judul"
            />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="select-status">Type</InputLabel>
              <Select
                style={{ marginBottom: 10 }}
                required
                label="Status"
                placeholder="Status"
                labelId="select-status"
                id="demo-customized-select"
                value={videoData.type}
                onChange={(e: any) => {
                  setVideoData({
                    ...videoData,
                    type: e.target.value,
                  });
                }}
              >
                <MenuItem value={"Intro"}>Intro</MenuItem>
                <MenuItem value={"Tutorial"}>Tutorial</MenuItem>
                <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
              </Select>
            </FormControl>
            {isValid && <YoutubeEmbed />}
            <FormInput
              onChange={(value: any) => {
                setVideoData({
                  ...videoData,
                  url: value,
                });
              }}
              type="text"
              value={videoData.url}
              fullWidth
              placeholder="Masukan url youtube"
              label="URL Youtube"
            />
            {!isValid && videoData.url.length > 0 && (
              <p style={{ color: "red", marginTop: -15 }}>
                URL Youtube tidak valid
              </p>
            )}
            <DialogActions style={{ marginTop: 20, marginLeft: "auto" }}>
              <AppButton
                fullWidth
                color="secondary"
                onClick={handleCloseDialogUploadVideo}
              >
                Cancel
              </AppButton>
              <AppButton
                fullWidth
                color="primary"
                onClick={submitVideo}
                disabled={
                  videoData.title.length === 0 ||
                  videoData.type.length === 0 ||
                  videoData.url.length === 0
                }
              >
                Save
              </AppButton>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog
          aria-labelledby="simple-dialog-title"
          open={dialogEditVideo}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Edit Link Youtube</Typography>
              </Box>
            </Grid>
            <FormInput
              onChange={(value: any) => {
                setVideoData({
                  ...videoData,
                  title: value,
                });
              }}
              type="text"
              value={videoData.title}
              fullWidth
              placeholder="Masukan Judul"
              label="Judul"
            />
            <FormControl style={{ width: "100%" }}>
              <InputLabel id="select-status">Type</InputLabel>
              <Select
                style={{ marginBottom: 10 }}
                required
                label="Status"
                placeholder="Status"
                labelId="select-status"
                id="demo-customized-select"
                value={videoData.type}
                onChange={(e: any) => {
                  setVideoData({
                    ...videoData,
                    type: e.target.value,
                  });
                }}
              >
                <MenuItem value={"Intro"}>Intro</MenuItem>
                <MenuItem value={"Tutorial"}>Tutorial</MenuItem>
                <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
              </Select>
            </FormControl>
            {isValid && <YoutubeEmbed />}
            <FormInput
              onChange={(value: any) => {
                setVideoData({
                  ...videoData,
                  url: value,
                });
              }}
              type="text"
              value={videoData.url}
              fullWidth
              placeholder="Masukan url youtube"
              label="URL Youtube"
            />
            {!isValid && (
              <p style={{ color: "red", marginTop: -15 }}>
                URL Youtube tidak valid
              </p>
            )}
            <DialogActions style={{ marginTop: 20, marginLeft: "auto" }}>
              <AppButton
                fullWidth
                color="secondary"
                onClick={handleCloseDialogEditVideo}
              >
                Cancel
              </AppButton>
              <AppButton
                fullWidth
                color="primary"
                onClick={() => editVideo(indexEditVideo)}
              >
                Save
              </AppButton>
            </DialogActions>
          </Grid>
        </Dialog>

        <Dialog
          aria-labelledby="simple-dialog-title"
          open={dialogEditInitialWarranty}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">Edit Initial Warranty</Typography>
              </Box>
            </Grid>
            <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Grid item xs={1}>
                <FormInput
                  value={initialWarrantyEndAt}
                  onChange={(value: any) => setInitialWarrantyEndAt(value)}
                  type="number"
                  required
                  // label="Value Warranty"
                  placeholder="6"
                />
              </Grid>
              <Grid item xs={11}>
                <p style={{ marginLeft: 3, marginTop: -1 }}>Bulan</p>
              </Grid>
            </Grid>

            <DialogActions style={{ marginLeft: "auto" }}>
              <AppButton
                fullWidth
                color="secondary"
                onClick={handleCloseDialogEditInitialWarranty}
              >
                Cancel
              </AppButton>
              <AppButton
                fullWidth
                color="primary"
                onClick={() => editInitialWarranty()}
              >
                Save
              </AppButton>
            </DialogActions>
          </Grid>
        </Dialog>
      </Layout>
    );
  }
  return <LoadingScreen />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
    row: {
      marginBottom: 15,
    },
    spesificationItemRow: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    spesificationItemRowWithBorderBottom: {
      paddingTop: 10,
      paddingBottom: 10,
      borderBottomColor: COLORS.greyLight,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
    },
    boxDelete: {
      position: "absolute",
      bottom: 16,
      right: 16,
      height: 24,
      width: 24,
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#E92548",
    },
  })
);
