import React, {
	useEffect,
	useState,
	useMemo
} from 'react';
import {
	useParams,
	useLocation
} from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import HeaderPage from '../../components/Layout/HeaderPage';
import {
	apiFindAllTransaction
} from '../../api/transaction';
import {
	apiGetSingleAroDisbursementAvailable,
	SingleAroDisbursementAvailableResult,
	apiGetSingleAroDisbursementHistory,
	AroDisbursementProperties
} from '../../api/aro-disbursement';
import usePaginateTable, {
	IPaginateTable
} from '../../hooks/usePaginateTable';
import CustomizedTable, {
  ITableHeader
} from '../../components/Table/CustomizedTable';
import { parseURLSearch } from '../../helpers/functions/url';
import {
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Grid
} from "@material-ui/core";
import { convertNumToCurrency } from '../../helpers/functions/currency';

interface ParamsType {
	id: string;
}
let headers: ITableHeader[] = [
  {
    title:'Customer',
    type:'nested',
    column: JSON.stringify(['transactionMetadata','buyer','customerName'])
  },
  {
    title:'Transaction Date',
    type:'date',
    column: 'createdAt'
  },
  {
    title:'Transaction ID',
    column: 'transactionId'
  },
  {
    title:'Final Transaction Price',
    type: 'currency',
    column: 'finalTransactionPrice'
  }
];

const DetailAroDisbursement: React.FC = ()=>{
	const location = useLocation();
	const classes = useStyles();
	const { id } = useParams<ParamsType>();
	const { startAt, endAt, type } = parseURLSearch(location.search);
	const [isLoading, setIsLoading] = useState(true);
	const [disbursementData, setDisbursementData] = useState<null|SingleAroDisbursementAvailableResult>(null);
	const [disbursementHistorycal, setDisbursementHistorycal] = useState<null|AroDisbursementProperties>(null)
	

	const transactionIds = useMemo(()=>(
		type === 'historical'
		? disbursementHistorycal?.aroDisbursementItem
			? disbursementHistorycal.aroDisbursementItem.map((item)=>item.transactionId).join(',')
			: null
		: disbursementData?.transactionIds
			? disbursementData.transactionIds
			: null
	),[type,disbursementData,disbursementHistorycal]);

	useEffect(()=>{
		if(type === 'historical'){
			apiGetSingleAroDisbursementHistory(id)
			.then(({data:{payload}})=>{
				setDisbursementHistorycal(payload);
			})
			.finally(()=>setIsLoading(false))
		}else{
			apiGetSingleAroDisbursementAvailable({aroId:id,startAt,endAt})
			.then(({data:{payload}})=>{
				setDisbursementData(payload);
			})
			.finally(()=>setIsLoading(false))
		}
	},[id,startAt, endAt, type]);

	const {
	    onChangePage,
	    onChangeRowsPerPage,
	    fetchList,
	    loading,
	    params,
	    data,
	    page,
	    totalPage,
	    totalData,
	    rowsPerPage,
	}: IPaginateTable = usePaginateTable(
	    apiFindAllTransaction,
	    {
	    	showAro:'true',
	    	filterStatus: 'sent',
	    	aroId: id,
			limit: 25,
			offset: 0,
			startAt: startAt ? startAt : '',
			endAt: endAt ? endAt : '',
	    },25,undefined,false,
	    (transactionIds!==null)
	);

	useEffect(()=>{
		if(transactionIds!==null){
			fetchList({
				transactionIds,
				aroId: disbursementHistorycal?.aroId
			})
		}
	},[transactionIds]);

	return (
		<Layout loading={loading||isLoading} title="Detail ARO Disburse">
			<HeaderPage
		    title="Detail ARO Disburse"
		    subtitle="Detail agent region disbursement"
		    showBackButton={true}
		    />
		    <Paper elevation={3} className={classes.paper}>
		    	{type === 'historical'
		    		?(<Grid container spacing={4} style={{marginBottom:10}}>
			    		<Grid item>
							<Typography variant="body1">Agent Region</Typography>
							<Typography
							component="span"
							variant="body1"
							style={{ fontWeight: 'bold' }}
							>
								{disbursementHistorycal?disbursementHistorycal.aroMetadata.labelName:'-'}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">Total Transaction</Typography>
							<Typography
							component="span"
							variant="body1"
							style={{ fontWeight: 'bold' }}
							>
								{disbursementHistorycal?disbursementHistorycal.aroDisbursementItem?.length:'-'}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">Amount</Typography>
							<Typography
							component="span"
							variant="body1"
							style={{ fontWeight: 'bold' }}
							>
								{disbursementHistorycal?convertNumToCurrency(disbursementHistorycal.totalPrice):'-'}
							</Typography>
						</Grid>
					</Grid>)
		    		:(<Grid container spacing={4} style={{marginBottom:10}}>
			    		<Grid item>
							<Typography variant="body1">Agent Region</Typography>
							<Typography
							component="span"
							variant="body1"
							style={{ fontWeight: 'bold' }}
							>
								{disbursementData?disbursementData.labelName:'-'}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">Total Transaction</Typography>
							<Typography
							component="span"
							variant="body1"
							style={{ fontWeight: 'bold' }}
							>
								{disbursementData?disbursementData.transactionIds.length:'-'}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1">Amount</Typography>
							<Typography
							component="span"
							variant="body1"
							style={{ fontWeight: 'bold' }}
							>
								{disbursementData?convertNumToCurrency(disbursementData.amount):'-'}
							</Typography>
						</Grid>
					</Grid>)}
			    <CustomizedTable
	            data={data}
	            headers={headers}
	            totalData={totalData}
	            page={page}
	            totalPage={totalPage}
	            rowsPerPage={rowsPerPage}
	            onChange={onChangePage}
	            onRowsPerPageChange={onChangeRowsPerPage}
				/>
			</Paper>
	    </Layout>
	);
}
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			margin: theme.spacing(2, 0, 0, 0),
			padding: theme.spacing(2),
		}
	})
);
export default DetailAroDisbursement;