export function generateQueryString(params?: any) {
  if (!params) return '';

  let query = ''

  query = '?' + Object.keys(params)
    .map((key) => {
      return key + '=' + params[key] + '&'
    })
    .join('')
    .slice(0, -1)

  return query;
}
