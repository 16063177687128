import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import { Link } from 'react-router-dom';
import AppButton from '../../components/Form/AppButton';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import Search from '../../components/Form/Search';
import FilterDate from '../../components/Form/FilterDate';
import AppTabs from '../../components/Tabs/AppTabs';
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import useTransaction from '../../hooks/useTransaction';
import { ApplicationState } from '../../redux/store';
import {
  findAllTransaction,
  setOneTransaction,
} from '../../redux/action/transaction.action';
import {
  convertDateTimeToText,
} from '../../helpers/functions/datetime';
import FormInput from '../../components/Form/FormInput';
import {
  TransactionListResponse,
  TransactionLog,
} from '../../types/transaction.types';
import StatusTransaction from '../../components/StatusTransaction';
import { Alert } from '@material-ui/lab';
import { useAuth } from '../../hooks/useAuth';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import { useHistory } from 'react-router-dom';
import { ISingleAro } from '../../types/aro.type';
import { httpRequest } from "../../helpers/api/api";
import { parseURLSearch } from '../../helpers/functions/url';

let headers: ITableHeader[] = [
  {
    title:'Id',
    type:'id',
    column: 'transactionId'
  },
  {
    title: 'Aro',
    align: 'left',
    sortable: true,
    column: 'aroLabel'
  },
  {
    title: 'Name',
    align: 'left',
    sortable: true,
    column: 'customName',
  },
  {
    title: 'Order',
    align: 'left',
    column: 'order',
  },
  {
    title: 'Process',
    align: 'left',
    column: 'process',
    width: 130,
  },
  {
    title: 'Shipment',
    align: 'left',
    column: 'shipment',
  }
];

let data = [];

const tabs = [
  'Sudah Dibayar',
  'Diproses',
  'Barang Dikirim',
  'Dibatalkan',
  'Semua Transaksi',
];

export default function AroTransaction() {
  const location = useLocation();
  const history = useHistory();
  const { aroId } = parseURLSearch(location.search);

  const classes = useStyles();
  const auth = useAuth();

  const transactionState = useSelector(
    (state: ApplicationState) => state.transaction
  );

  const [activeTab, setActiveTab] = useState(0);
  const [lastActiveTab, setLastActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [aroList, setAroList] = useState<ISingleAro[]>([]);

  const [filterStatus, setFilterStatus] = useState('ASC');
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({
    showAro: 'true',
    aroId: aroId? aroId:'',
    offset: 0,
    limit: 25,
    filterStatus: 'paid',
    startAt: '',
    endAt:'',
    // filterStatusExclude: '',
    filterStatusExclude: 'canceled,expired,payment_failed,created,refunded',
    orderBy: 'CREATED_AT_ASC'
  });
  const [dateTemp, setDateTemp] = useState({
    startAt: '',
    endAt: '',
  })
  const [selectedAro, setSelectedAro] = useState(aroId?aroId.split(','):[]);
  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  const {
    fetchAllTransaction,
    fetchOneTransaction,
    processTransaction,
    rejectTransaction,
    updateWaybillTransaction,
  } = useTransaction();
  const dispatch = useDispatch();

  const debouncedTitle = useDebounce(search, 300);

  const debouncedAro = useDebounce(selectedAro, 300);

  useEffect(()=>{
    httpRequest.get('/aros')
    .then(({data})=>{
      setAroList(data.payload.results)
    })
  },[]);

  useEffect(()=>{
    if(debouncedAro.join(',') !== query.aroId){
      setQuery((prev)=>({
        ...prev,
        aroId: debouncedAro.join(',')
      }))
    }
  },[debouncedAro])

  useEffect(() => {
    searchAroTransaction(search);
  }, [debouncedTitle]);

  const searchAroTransaction = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          limit: 25,
          search: value,
        };
      });
      setPage(1)
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: '',
        };
      });
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    if (updateParams) {
      // console.info('trigger 1')
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          ...latestParams.query,
          ...query,
          limit: query['limit'],
          offset: query['offset'],
          page,
        },
        activeTab: activeTab
      }))
      fetchAllTransaction(query)
      setDateTemp({
        startAt: '',
        endAt: ''
      })
    } else {
      // console.info('trigger 2')
      // console.info(latestParams)
      dispatch(enableUpdateParams(true))
      setActiveTab(latestParams.activeTab || 0)
      setPage(latestParams.query.page || page || 1)
      setRowsPerPage(latestParams.query.limit || 25)
      setSelectedAro(latestParams.query.aroId ? latestParams.query.aroId.split(',') : [])
      setQuery(latestParams.query);
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt
      })
    }
  }, [query]);

  const changeTab = (value: number) => {
    // setQuery(latestParams.query)
    setActiveTab(value);
    setPage(1)

    dispatch(setLatestParams({
      ...latestParams,
      query: {
        ...latestParams.query,
      },
      activeTab: value
    }))

    if (value === 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: 'paid',
        };
      });
    }
    if (value === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: 'process',
        };
      });
    }
    if (value === 2) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: 'sent',
        };
      });
    }
    if (value === 3) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: 'rejected',
        };
      });
    }
    if (value === 4) {
      setFilterStatus('DESC')
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          filterStatus: '',
          orderBy: 'CREATED_AT_DESC'
        };
      });
    }
  }

  useEffect(() => {
    setTotalData(transactionState.data.payload.count);
    if (transactionState.data.payload.count > 0) {
      setTotalPage(
        Math.ceil(transactionState.data.payload.count / rowsPerPage)
      );
    } else {
      setTotalPage(1)
    }
  }, [transactionState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        startAt: dateTemp.startAt || oldVal.startAt,
        endAt: dateTemp.endAt || oldVal.endAt,
        offset: 0,
        limit: value,
      };
    });
  }

  data = transactionState.data.payload.results.map(
    (item: TransactionListResponse) => {
      return {
        ...item,
        aroLabel:item?.aroMetadata?.labelName,
        customName: (
          <div>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {item.transactionMetadata && item.transactionMetadata.buyer
                ? item.transactionMetadata.buyer.name
                : '---'}
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1" style={{ width: 90 }}>No. Transaksi</Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: 'bold' }}
              >
                : {item.invoice.invoiceId}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1" style={{ width: 90 }}>Tgl. Pembelian</Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: 'bold' }}
              >
                : {convertDateTimeToText(item.createdAt, 'dd mmm yyyy hh:mm')}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1" style={{ width: 90 }}>Voucher</Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: 'bold' }}
              >
                : {item.voucherMetadata ? item.voucherMetadata.voucherCode : '-'}
              </Typography>
            </div>
          </div>
        ),
        order: (
          <Box display="flex" flexDirection="row">
            <Box marginRight={1}>
              {item.items &&
                item.items.length > 0 &&
                item.items[0].productMetadata.images &&
                item.items[0].productMetadata.images.length > 0 ? (
                  <CardMedia
                    className={classes.cardMedia}
                    image={item.items[0].productMetadata.images[0]}
                  />
                ) : (
                  <CardMedia
                    className={classes.cardMedia}
                    image={'/assets/default_500x500.png'}
                  />
                )}
            </Box>
            <Box>
              <Typography variant="body1">
                {item.items &&
                  item.items.length > 0 &&
                  (item.items[0].productMetadata.sku ? item.items[0].productMetadata.sku + ' ' : '') + item.items[0]
                    .productMetadata.name}
              </Typography>

              {item.items.length - 1 > 0 && (
                <Typography variant="subtitle1">
                  + {item.items.length - 1} barang lainnya
                </Typography>
              )}

              <Link
                style={{
                  textDecoration: 'none',
                  borderBottom: '2px dotted rgb(169 169 169)',
                  display: 'inline-block',
                }}
                to={'/aro-transactions/' + item.transactionId}
              >
                <Typography variant="subtitle1" style={{ color: COLORS.primary }}>
                  Lihat Detail Pesanan
                </Typography>
              </Link>
            </Box>
          </Box>
        ),
        process: (
          <Box display="flex" justifyContent="center" flexDirection="column">
            <StatusTransaction status={item.transactionStatus} />
          </Box>
        ),
        shipment: (
          <Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1" style={{ width: 60 }}>Penerima</Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: 'bold' }}
              >
                : {item.shipping && item.shipping.expeditionMetadata
                  ? item.shipping.expeditionMetadata.receiverName
                  : '---'}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1" style={{ width: 60 }}>Kurir</Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: 'bold' }}
              >
                : {item.shipping && item.shipping.expeditionMetadata
                  ? item.shipping.expeditionMetadata.expeditionName
                  : '---'}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="subtitle1" style={{ width: 60 }}>No Resi</Typography>
              <Typography
                variant="subtitle1"
                component="span"
                style={{ fontWeight: 'bold' }}
              >
                : {item.shipping && item.shipping.waybill
                  ? item.shipping.waybill
                  : '---'}
              </Typography>
            </div>
          </Box>
        ),
      };
    }
  );

  function setFilter(sort: 'ASC' | 'DESC') {
    setFilterStatus(sort)
    if (sort === 'ASC') {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: 'CREATED_AT_ASC'
        }
      })
    } else if (sort === 'DESC') {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: 'CREATED_AT_DESC'
        }
      })
    }
  }

  return (
    <Layout
      title="Transaction"
      loading={
        transactionState.isLoadingTransaction ||
        transactionState.isLoadingProcess
      }
    >
      <HeaderPage title="Transaction ARO" subtitle="List of all Transaction" />

      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="select-status" shrink>
              Agent Region
            </InputLabel>
            <Select
              label="Agent Region"
              placeholder="All"
              labelId="select-aro"
              multiple={true}
              value={selectedAro}
              onChange={(event: any) => {
                setSelectedAro(event.target.value)
              }}
              displayEmpty={true}
              renderValue={(values: any)=>values.length<1
                ? (<Typography>ALL</Typography>)
                : values.map((value: any)=>{
                  const label = aroList.find((item)=>item.aroId===value);
                  return label?.labelName ? (
                    <Chip label={label.labelName} variant="outlined" className={classes.aroChip}/>
                  ) : <></>;
              })}
            >
              {aroList.map((aro: ISingleAro)=><MenuItem value={aro.aroId}>{aro.labelName}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="select-status" shrink>
              Sort
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value="ASC">ASC</MenuItem>
              <MenuItem value="DESC">DESC</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }
            notSetDefaultDate={true}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by Name, Transaction ID"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              dispatch(
                findAllTransaction({
                  code: '',
                  message: '',
                  payload: {
                    offset: 0,
                    limit: 0,
                    totalPage: 0,
                    count: 0,
                    results: [],
                  },
                })
              );
              changeTab(value)
            }}
          />

          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={changePagination}
            onRowsPerPageChange={changeRowsPerPage}
            showAction={["detail"]}
          />

        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: 'solid',
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    aroChip:{
      borderColor: COLORS.warn,
      backgroundColor: COLORS.accent,
      borderRadius: 10,
      marginRight: 5,
      padding: 1
    }
  })
);
