import axios from 'axios';
import { getHeaders } from '../../helpers/auth';

export async function apiCurrentReferralCommission() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/referral-commission/current-value',
    method: 'GET',
    headers
  });
}

export async function apiLogReferralCommission(params: string) {
  const headers = await getHeaders()
  const query = params || null
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/referral-commission/log' + query,
    method: 'GET',
    headers
  });
}

export async function apiUpdateReferralCommission(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/referral-commission',
    method: 'POST',
    headers,
    data
  });
}
