/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  StepIconProps,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ListBranch from "./ListBranch";
import ListUser from "./ListUser";
import { useParams } from "react-router-dom";
import HeaderPage from "../../components/Layout/HeaderPage";
import AppMap, { Marker } from '../../components/AppMap';
import IconMarker from '../../assets/distributor-marker.png';
import { Alert } from '@material-ui/lab';
import { Info } from 'react-feather';

export default function DistributorEdit() {
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const steps = id
    ? ["Distributor Information", "Create Branches", "Create Users"]
    : ["Create Distributor", "Create Branches", "Create Users"];
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [markers, setMarkers] = useState<Array<Marker>>([]);
  const [discount, setDiscount] = useState("");

  const submitInfoDistributor = () => {
    setActiveStep(1);
  };

  const handleChangeMarker = (value: { lat: number, lng: number }) => {
    setMarkers([{
      lat: value.lat,
      lng: value.lng,
      content: (
        <img
          style={{
            transform: 'translateZ(0) translate(-50%, -100%)',
            backfaceVisibility: 'hidden',
            height: 50
          }}
          src={IconMarker}
        />
      )
    }])
  }

  useEffect(() => {
    if (id) {
      // edit
    }
  }, []);

  return (
    <Layout title="Distributor">
      <HeaderPage
        title={id ? "Edit Distributor" : "Create Distributor"}
        subtitle="Please enter distributor information"
        showBackButton
      />
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepper}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>

      {activeStep === 0 && (
        <Paper elevation={3}>
          <div className={classes.paperTitle}>
            <Typography variant="h5">Distributor Information</Typography>
          </div>

          <Grid container className={classes.containerForm}>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                fullWidth
                required
                label="Distributor Name"
                value={name}
                onChange={(newValue: any) => setName(newValue)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input} style={{ marginBottom: 15 }}>
              <AppMap
                handleOnClick={(value) => handleChangeMarker(value)}
                markers={markers}
                height={500}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                fullWidth
                type="phone"
                required
                label="Phone"
                value={phone}
                onChange={(newValue: any) => setPhone(newValue)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                fullWidth
                type="address"
                required
                label="Address"
                value={address}
                onChange={(newValue: any) => setAddress(newValue)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                type="discount"
                fullWidth
                label="Distributor Discount"
                value={discount}
                onChange={(newValue: any) => setDiscount(newValue)}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            justifyContent="flex-end"
            className={classes.containerButton}
          >
            <AppButton variant="contained" color="secondary">
              Cancel
            </AppButton>
            <AppButton
              onClick={() => submitInfoDistributor()}
              variant="contained"
              color="primary"
              disabled={!name || !address || !phone || !markers || !discount}
            >
              Next
            </AppButton>
          </Box>
        </Paper>
      )}

      {activeStep === 1 && (
        <ListBranch
          distributorId={id}
          showButton={true}
          onNext={() => setActiveStep(2)}
          onBack={() => setActiveStep(0)}
        />
      )}

      {activeStep === 2 && (
        <ListUser
          distributorId={id}
          showButton={true}
          onNext={() => setActiveStep(3)}
          onBack={() => setActiveStep(1)}
        />
      )}
    </Layout>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    stepper: {
      background: "transparent",
    },
    containerForm: {
      // padding: theme.spacing(0, 2, 0, 2),
    },
    input: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
  })
);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: COLORS.accent,
    zIndex: 1,
    color: COLORS.primary,
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    borderColor: COLORS.primary,
    borderWidth: 1,
    borderStyle: "solid",
    fontSize: 16,
  },
  active: {
    background: COLORS.primary,
    color: COLORS.accent,
    fontWeight: "bold",
  },
  completed: {
    background: COLORS.primary,
    color: COLORS.accent,
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <CheckCircleIcon /> : props.icon}
    </div>
  );
}
