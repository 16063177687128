

export enum BankApprovalTypes {
  FIND_ALL_BANK_APPROVAL = 'FIND_ALL_BANK_APPROVAL',
  SET_LOADING_BANK_APPROVAL = 'SET_LOADING_BANK_APPROVAL',
  UPDATE_BANK_APPROVAL = 'UPDATE_BANK_APPROVAL',
}

export type BankApprovalRequest = {
  customerId: string,
  customerBankId: string,
  status: 'approved' | 'rejected' | 'pending',
  remark: string
}

export type BankApprovalResponse = {
  bankApprovalId: string,
  userId: string,
  customerMetadata: {
    customerId: string,
    customerName: string,
    customerBankId: string,
    customerPhoneNumber: string
  },
  customerBankMetadata: {
    bank: {
      bankId: string,
      bankName: string
    },
    accountName: string,
    accountNumber: string
  },
  status: string,
  actionDate: string,
  remark: string,
  adminId: string,
  adminMetadata: {
    adminName: string,
    role: string,
  },
  bankAccount: string,
  createdAt?: Date | string,
  updatedAt?: Date | string
}

export type BankApprovalResponsePayload = {
  code: string,
  message: string,
  payload: {
    next: string,
    prev: string,
    count: number,
    results: BankApprovalResponse[]
  }
}

export type BankApprovalState = {
  isLoadingBankApproval: boolean;
  data: BankApprovalResponsePayload;
  // bankApproval: BankApprovalResponse;
}