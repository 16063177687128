import {
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable from '../../components/Table/CustomizedTable';
import HeaderPage from '../../components/Layout/HeaderPage';
import Search from '../../components/Form/Search';
import AppTabs from '../../components/Tabs/AppTabs';
import AppButton from '../../components/Form/AppButton';
import {
  headersWaitingApproval,
  headersRejected,
  headersApproved,
} from './headers';
import { Close } from '@material-ui/icons';
import FormInput from '../../components/Form/FormInput';
import COLORS from '../../styles/colors';
import { ApplicationState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import useBankApproval from '../../hooks/useBankApproval';
import { BankApprovalResponse } from '../../types/bank-approval.types';
import { findAllBankApproval } from '../../redux/action/bank-approval.action';
import { initialState } from '../../redux/reducer/bank-approval.reducer';
import useDebounce from '../../hooks/useDebounce';

const tabs = ['Waiting Approval', 'Approved', 'Rejected'];

export default function BankAccountApproval() {
  const classes = useStyles();

  const [isShowModalApproval, setIsShowModalApproval] = useState(false);
  const [isShowModalReject, setIsShowModalReject] = useState(false);

  const bankApprovalState = useSelector((state: ApplicationState) => state.bankApproval)
  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 25, offset: 0, status: 'pending', search: '' });
  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('ALL');
  const [activeTab, setActiveTab] = useState(0);
  const [note, setNote] = useState('');
  const [tempBankData, setTempBankData] = useState<BankApprovalResponse>();

  const debouncedTitle = useDebounce(search, 300);

  useEffect(() => {
    searchBank(search)
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: value
        }
      })
    } else {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: ''
        }
      })
    }
  }

  const renderAction = (item: BankApprovalResponse) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <AppButton
          onClick={() => {
            setTempBankData(item)
            handleOpenModalApproval()
          }}
          size="small"
          color="primary"
        >
          Approve
          </AppButton>
        <AppButton
          onClick={() => {
            setTempBankData(item)
            handleOpenModalReject()
          }}
          size="small"
          color="secondary"
        >
          Reject
          </AppButton>
      </Box>
    )
  }

  const data = bankApprovalState.data.payload.results.map((item: BankApprovalResponse) => {
    return {
      id: 1,
      name: item.customerMetadata.customerName,
      phone: item.customerMetadata.customerPhoneNumber,
      bankAccount: `${item.customerBankMetadata.bank.bankName} ${item.customerBankMetadata.accountNumber} a.n. ${item.customerBankMetadata.accountName}`,
      note: item.remark,
      createdAt: item.updatedAt,
      updatedAt: item.updatedAt,
      approvedBy: '',
      rejectedBy: '',
      role: '',
      actions: renderAction(item),
      adminMetadata: item.adminMetadata
    }
  })

  const { fetchAllBankApproval, updateBankApproval } = useBankApproval()

  useEffect(() => {
    fetchAllBankApproval(query)
  }, [query])

  useEffect(() => {
    if (bankApprovalState.data.payload.count > 0) {
      setTotalData(bankApprovalState.data.payload.count)
      setTotalPage(Math.ceil(bankApprovalState.data.payload.count / rowsPerPage))
    }
  }, [bankApprovalState.data.payload])

  function changePagination(value: number) {
    setPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      }
    })
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      }
    })
  }

  useEffect(() => {
    if (activeTab === 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: 'pending'
        }
      })
    }
    if (activeTab === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: 'approved'
        }
      })
    }
    if (activeTab === 2) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: 'rejected'
        }
      })
    }
  }, [activeTab])

  const handleCloseModalApproval = () => {
    //
    setIsShowModalApproval(false);
  };
  const handleOpenModalApproval = () => {
    //
    setIsShowModalApproval(true);
  };

  const handleCloseModalReject = () => {
    //
    setIsShowModalReject(false);
  };
  const handleOpenModalReject = () => {
    //
    setIsShowModalReject(true);
  };

  function submit(type: 'approved' | 'rejected') {
    if (type === 'approved') {
      updateBankApproval({
        status: type,
        bankApprovalId: tempBankData?.bankApprovalId,
        customerBankId: tempBankData?.customerMetadata.customerBankId,
        customerId: tempBankData?.customerMetadata.customerId,
      })
      handleCloseModalApproval()
    }
    if (type === 'rejected') {
      updateBankApproval({
        status: type,
        bankApprovalId: tempBankData?.bankApprovalId,
        customerBankId: tempBankData?.customerMetadata.customerBankId,
        customerId: tempBankData?.customerMetadata.customerId,
        remark: note,
      })
      handleCloseModalReject()
    }
  }

  return (
    <Layout title="Approval Bank Account" loading={bankApprovalState.isLoadingBankApproval}>
      <HeaderPage
        title="Bank Acct. Approval"
        subtitle="List of all approval bank account"
      />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={9}></Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              setActiveTab(value)
              dispatch(findAllBankApproval({
                ...initialState['data']
              }))
            }}
          />

          {activeTab === 0 && (
            <CustomizedTable
              data={data}
              headers={headersWaitingApproval}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
          {activeTab === 1 && (
            <CustomizedTable
              data={data}
              headers={headersApproved}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
          {activeTab === 2 && (
            <CustomizedTable
              data={data}
              headers={headersRejected}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
        </Grid>
      </Grid>

      <Modal
        open={isShowModalApproval}
        onClose={handleCloseModalApproval}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Approve Confirmation</Typography>

              <button onClick={() => handleCloseModalApproval()}>
                <Close />
              </button>
            </Box>

            <Typography>
              Apakah anda yakin ingin approve rekening bank ini?
            </Typography>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton color="secondary" onClick={handleCloseModalApproval}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={() => submit('approved')}>Approve</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      <Modal
        open={isShowModalReject}
        onClose={handleCloseModalReject}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Reject Bank Account</Typography>

              <button onClick={() => handleCloseModalReject()}>
                <Close />
              </button>
            </Box>

            <Grid container>
              <Grid item xs={3}>
                <Typography>Rekening Bank</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{tempBankData?.customerBankMetadata.bank.bankName}</Typography>
                <Typography>{tempBankData?.customerBankMetadata.accountNumber}</Typography>
                <Typography>a.n {tempBankData?.customerBankMetadata.accountName}</Typography>
              </Grid>
            </Grid>

            <hr color={COLORS.greyLight} />

            <Grid container>
              <Grid item xs={3}>
                <Typography>Alasan Pembatalan</Typography>
              </Grid>
              <Grid item xs={9}>
                <FormInput
                  onChange={(value: any) => setNote(value)}
                  type="address"
                  fullWidth
                  required
                  placeholder="Alasan Pembatalan"
                />
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton color="secondary" onClick={handleCloseModalReject}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={() => submit('rejected')}>Save</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    paperTitle: {
      // padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: 'row',
      margin: theme.spacing(2, 0, 1, 0),
    },
    containerTable: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);
