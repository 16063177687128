import React from "react";
import "./App.css";
import RootNavigator from "./navigation/RootNavigator";
import { ProvideAuth } from "./hooks/useAuth";
import { Provider } from 'react-redux';
import store from './redux/store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Provider store={store}>
      <ProvideAuth>
        <ToastContainer />
        <RootNavigator />
      </ProvideAuth>
    </Provider>
  );
}

export default App;
