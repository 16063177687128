
export enum TagTypes {
  FIND_ALL_TAG = 'FIND_ALL_TAG',
  FIND_ONE_TAG = 'FIND_ONE_TAG',
  SET_LOADING_TAG = 'SET_LOADING_TAG',
  SET_ERROR_TAG = 'SET_ERROR_TAG',
}

export type TagResponse = {
  tagId?: string;
  tagName: string;
}

export type TagResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string | null;
    prev: string | null;
    count: number | null;
    results: TagResponse[];
  }
}

export type TagState = {
  isLoadingTag: boolean;
  data: TagResponsePayload;
  tag: TagResponse;
  error: any
}