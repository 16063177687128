import { Reducer } from 'redux';
import { DashboardState, DashboardTypes } from '../../types/dashboard.type';

const initialState: DashboardState = {
  isLoadingDashboard: false,
  listTransaction: [],
  totalTransaction: [],
  mostActiveTransaction: [],
  mostActiveUpline: [],
  customerSummary: {
    today: 0,
    month: 0,
    count: 0,
  }
};

export const dashboard: Reducer<DashboardState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DashboardTypes.SET_LIST_TRANSACTION:
      return {
        ...state,
        listTransaction: action.payload,
      };
    case DashboardTypes.SET_TOTAL_TRANSACTION:
      return {
        ...state,
        totalTransaction: action.payload,
      };
    case DashboardTypes.SET_MOST_ACTIVE_TRANSACTION:
      return {
        ...state,
        mostActiveTransaction: action.payload,
      };
    case DashboardTypes.SET_MOST_ACTIVE_UPLINE:
      return {
        ...state,
        mostActiveUpline: action.payload,
      };
    case DashboardTypes.SET_LOADING_DASHBOARD:
      return {
        ...state,
        isLoadingDashboard: action.payload,
      };
    case DashboardTypes.SET_CUSTOMER_SUMMARY:
      return {
        ...state,
        customerSummary: action.payload,
      };

    default:
      return state;
  }
};
