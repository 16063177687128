import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  Paper,
  Modal,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import { Link } from 'react-router-dom';
import AppButton from '../../components/Form/AppButton';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import FilterDate from '../../components/Form/FilterDate';
import AppTabs from '../../components/Tabs/AppTabs';
import { useDispatch, useSelector } from 'react-redux';
import useTransaction from '../../hooks/useTransaction';
import { ApplicationState } from '../../redux/store';
import { useEffect } from 'react';
import {
  CustomerBankMetadata,
  initialStateTransaction,
  TransactionListResponse,
  TransactionLog,
  TransactionResponse,
  initialStateInvoice,
} from '../../types/transaction.types';
import { convertDateTimeToString, convertDateTimeToText } from '../../helpers/functions/datetime';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { Close } from '@material-ui/icons';
import {
  findAllTransaction,
  setRefundAmount,
} from '../../redux/action/transaction.action';
import UploadImage from '../../components/Form/UploadImage';
import ImagePreview from '../../components/Card/ImagePreview';

let headers: ITableHeader[] = [
  {
    title: 'Name',
    align: 'left',
    sortable: true,
    column: 'customName',
  },
  {
    title: 'Transaction ID',
    align: 'left',
    column: 'transactionId',
  },
  {
    title: 'Cancel Note',
    align: 'left',
    column: 'cancelNote',
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bankAccount',
  },
  {
    title: 'Cancel Date',
    align: 'left',
    sortable: true,
    column: 'cancelDate',
    width: '10%'
  },
  {
    title: 'Amount',
    align: 'left',
    alignContent: 'right',
    column: 'amount',
    type: 'currency',
  },
  {
    title: 'Actions',
    align: 'left',
    column: 'actions',
  },
];

let headersRefunded: ITableHeader[] = [
  {
    title: 'Name',
    align: 'left',
    // sortable: true,
    column: 'customName',
  },
  {
    title: 'Transaction ID',
    align: 'left',
    column: 'transactionId',
  },
  {
    title: 'Cancel Note',
    align: 'left',
    column: 'cancelNote',
    width: '30%'
  },
  {
    title: 'Bank Number',
    align: 'left',
    column: 'bankAccount',
    width: '30%'
  },
  {
    title: 'Refund Date',
    align: 'left',
    // sortable: true,
    column: 'cancelDate',
    width: '15%'
  },
  {
    title: 'Bukti Transfer',
    align: 'left',
    // sortable: true,
    column: 'transferReceipt',
    width: '18%'
  },
  {
    title: 'Amount',
    align: 'right',
    alignContent: 'right',
    column: 'amount',
    type: 'currency',
  },
];

let data: any[] = [];

const tabs = ['Waiting for Refund', 'Refunded'];

export default function Refund() {
  const classes = useStyles();

  const date = new Date();
  const defaultParamsStartAt = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  const defaultParamsEndAt = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );

  const transactionState = useSelector(
    (state: ApplicationState) => state.transaction
  );

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [filterStatus, setFilterStatus] = useState('ASC');
  const [dialogTransferReciept, setDialogTransferReciept] = useState(false);
  const [dialogRefund, setDialogRefund] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [tmpTransaction, setTmpDataTransaction] =
    useState<TransactionListResponse | null>();
  const [query, setQuery] = useState({
    offset: 0,
    limit: 25,
    filterStatus: 'rejected',
    startAt: '',
    endAt: '',
    orderBy: ''
  });

  const { fetchAllTransaction, fetchOneTransaction, refundTransaction } =
    useTransaction();

  useEffect(() => {
    dispatch(
      findAllTransaction({
        code: '',
        message: '',
        payload: {
          offset: 0,
          limit: 0,
          totalPage: 0,
          count: 0,
          results: [],
        },
      })
    );
    dispatch(setRefundAmount(0));
    fetchAllTransaction(query);
  }, [query]);

  useEffect(() => {
    if (activeTab === 0) {
      setQuery((val: any) => {
        return {
          ...val,
          filterStatus: 'rejected',
          orderBy: 'CREATED_AT_ASC'
        };
      });
    }
    if (activeTab === 1) {
      setFilterStatus('DESC')
      setQuery((val: any) => {
        return {
          ...val,
          filterStatus: 'refunded',
          orderBy: 'CREATED_AT_DESC'
        };
      });
    }
  }, [activeTab]);

  useEffect(() => {
    setTotalData(transactionState.data.payload.count);
    if (transactionState.data.payload.count > 0) {
      setTotalPage(
        Math.ceil(transactionState.data.payload.count / rowsPerPage)
      );
    } else {
      setTotalPage(1)
    }
  }, [transactionState.data]);

  useEffect(() => {
    if (!transactionState.isLoadingProcess) {
      setDialogRefund(false);
      setTmpDataTransaction(null);
      setFile(null);
    }
  }, [transactionState.isLoadingProcess]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  function renderNote(statusLog: TransactionLog[]) {
    const log = statusLog.find(
      (log: TransactionLog) => log.statusAfter === 'rejected'
    );
    if (log && log.note) {
      return log.note;
    }
    return '';
  }

  function renderBankAccount(bankAccount: CustomerBankMetadata) {
    return (
      <>
        <Typography variant="body1">{bankAccount.bankName}</Typography>
        <Typography variant="body1">{bankAccount.accountNumber}</Typography>
        <Typography variant="body1">a.n. {bankAccount.accountName}</Typography>
      </>
    );
  }

  data = transactionState.data.payload.results.map(
    (item: TransactionListResponse) => {
      return {
        customName: (
          item.transactionMetadata && item.transactionMetadata.buyer
            ? (<Typography variant="body1" style={{ fontWeight: 'bold', lineHeight: 1.7 }}>
              {item.transactionMetadata.buyer.name}
            </Typography>) : null
        ),
        transactionId: item.invoice.invoiceId,
        cancelNote: renderNote(item.update_status_logs),
        bankAccount:
          item.transactionMetadata &&
            item.transactionMetadata.buyer &&
            item.transactionMetadata.buyer.bankAccount
            ? renderBankAccount(item.transactionMetadata.buyer.bankAccount)
            : null,
        cancelDate: convertDateTimeToText(item.updatedAt, 'dd mmm yyyy'),
        amount: item.finalTransactionPrice,
        transferReceipt: (
          <Box marginBottom={1}>
            <AppButton
              size="small"
              color="primary"
              onClick={() => {
                setDialogTransferReciept(true);
                setTmpDataTransaction(item);
              }}
              disabled={!item.refundMetadata || !item.refundMetadata.images}
            >
              Bukti Transfer
            </AppButton>
          </Box>
        ),
        actions: (
          <Box marginBottom={1}>
            <AppButton
              size="small"
              color="primary"
              onClick={() => {
                setDialogRefund(true);
                setTmpDataTransaction(item);
              }}
            >
              Refund
            </AppButton>
          </Box>
        ),
      };
    }
  );

  function submit() {
    const formData = new FormData();

    formData.append('image', file);
    formData.append('transactionId', tmpTransaction?.transactionId || '');

    refundTransaction(formData);
  }

  function setFilter(sort: 'ASC' | 'DESC') {
    setFilterStatus(sort)
    if (sort === 'ASC') {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: 'CREATED_AT_ASC'
        }
      })
    } else if (sort === 'DESC') {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: 'CREATED_AT_DESC'
        }
      })
    }
  }

  return (
    <Layout title="Refund" loading={transactionState.isLoadingTransaction}>
      <HeaderPage title="Refund" subtitle="List of all refund product" />

      <Paper elevation={3} className={classes.paper}>
        <Grid
          container
          spacing={3}
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12} md={12} lg={5}>
            <Typography variant="subtitle1" color="textSecondary">
              Total Refund
            </Typography>
            <Typography
              style={{ fontWeight: 'bold', lineHeight: 1.4, fontSize: 28 }}
            >
              {convertNumToCurrency(transactionState.totalRefundedAmount)}
            </Typography>
          </Grid>

          <Grid item xs={12} md={1} lg={2}></Grid>

          <Grid item xs={12} md={1} lg={1}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="select-status" shrink>
                Sort
            </InputLabel>
              <Select
                label="Status"
                placeholder="Status"
                labelId="select-status"
                id="demo-customized-select"
                value={filterStatus}
                onChange={(event: any) => {
                  setFilter(event.target.value);
                }}
              >
                <MenuItem value="ASC">ASC</MenuItem>
                <MenuItem value="DESC">DESC</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <FilterDate
              start_at={query.startAt}
              end_at={query.endAt}
              onChange={(start_at: string, end_at: string) =>
                setQuery((val: any) => {
                  return {
                    ...val,
                    startAt: start_at,
                    endAt: end_at,
                  };
                })
              }
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3} className={classes.containerContent}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => setActiveTab(value)}
          />

          {activeTab === 0 && (
            <CustomizedTable
              data={data}
              headers={headers}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => setPage(newPage)}
              onRowsPerPageChange={(value: any) => setRowsPerPage(value)}
            />
          )}

          {activeTab === 1 && (
            <CustomizedTable
              data={data}
              headers={headersRefunded}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => setPage(newPage)}
              onRowsPerPageChange={(value: any) => setRowsPerPage(value)}
            />
          )}
        </Grid>
      </Grid>

      <Dialog
        open={dialogRefund}
        onClose={() => {
          setDialogRefund(false);
          setTmpDataTransaction(null);
        }}
        scroll={'body'}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent dividers={true}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h5">Refund Biaya</Typography>

            <div
              onClick={() => {
                setDialogRefund(false);
                setTmpDataTransaction(null);
              }}
            >
              <Close />
            </div>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            style={{ paddingTop: 5, paddingBottom: 5 }}
          >
            <Box width="30%">
              <Typography>No.Transaksi</Typography>
            </Box>
            <Box width="70%">
              <Typography style={{ fontWeight: 'bold' }}>
                {tmpTransaction?.invoice.invoiceId}
              </Typography>
            </Box>
          </Box>

          <hr color={COLORS.greyLight} />

          <Box
            display="flex"
            flexDirection="row"
            style={{ paddingTop: 5, paddingBottom: 5 }}
          >
            <Box width="30%">
              <Typography>Pembeli</Typography>
            </Box>
            <Box width="70%">
              <Typography>
                {tmpTransaction?.transactionMetadata &&
                  tmpTransaction?.transactionMetadata.buyer &&
                  tmpTransaction?.transactionMetadata.buyer.name
                  ? tmpTransaction?.transactionMetadata.buyer.name
                  : null}
              </Typography>
            </Box>
          </Box>

          <hr color={COLORS.greyLight} />

          <Box
            display="flex"
            flexDirection="row"
            style={{ paddingTop: 5, paddingBottom: 5 }}
          >
            <Box width="30%">
              <Typography>Rekening Bank</Typography>
            </Box>
            <Box width="70%">
              {tmpTransaction?.transactionMetadata &&
                tmpTransaction.transactionMetadata.buyer &&
                tmpTransaction.transactionMetadata.buyer.bankAccount ? (
                  <>
                    <Typography>
                      {
                        tmpTransaction.transactionMetadata.buyer.bankAccount
                          .bankName
                      }
                    </Typography>
                    <Typography>
                      {
                        tmpTransaction.transactionMetadata.buyer.bankAccount
                          .accountNumber
                      }
                    </Typography>
                    <Typography>
                      {
                        tmpTransaction.transactionMetadata.buyer.bankAccount
                          .accountName
                      }
                    </Typography>
                  </>
                ) : (
                  <Typography>---</Typography>
                )}
            </Box>
          </Box>

          <hr color={COLORS.greyLight} />

          <Box
            display="flex"
            flexDirection="row"
            style={{ paddingTop: 5, paddingBottom: 5 }}
          >
            <Box width="30%">
              <Typography>Total Refund</Typography>
            </Box>
            <Box width="70%">
              <Typography>
                {convertNumToCurrency(
                  tmpTransaction?.finalTransactionPrice || 0
                )}
              </Typography>
            </Box>
          </Box>

          <hr color={COLORS.greyLight} />

          <UploadImage
            title="Add transfer reciept*"
            imageResolution="500x500"
            onFileSelected={(e: any) => setFile(e)}
            editable={true}
          />

          <hr color={COLORS.greyLight} />

          <Box display="flex" justifyContent="flex-end">
            <Box>
              <AppButton
                color="secondary"
                onClick={() => {
                  setDialogRefund(false);
                  setTmpDataTransaction(null);
                }}
              >
                Cancel
                </AppButton>
            </Box>
            <Box>
              <AppButton
                disabled={!file}
                loading={transactionState.isLoadingProcess}
                onClick={() => {
                  submit();
                }}
              >
                Save
                </AppButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={dialogTransferReciept}
        onClose={() => {
          setDialogTransferReciept(false);
          setTmpDataTransaction(null);
        }}
        scroll={'body'}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent dividers={true}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography variant="h5">Bukti Transfer</Typography>

            <div
              onClick={() => {
                setDialogTransferReciept(false);
                setTmpDataTransaction(null);
              }}
            >
              <Close />
            </div>
          </Box>
          <Box
            paddingTop={2}
            style={{ width: 400, minWidth: 300 }}
          >
            <ImagePreview
              type="preview-product"
              image={tmpTransaction && (tmpTransaction?.refundMetadata as any).images[0]}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(0, 0, 3, 0),
      padding: theme.spacing(3, 2, 0.5, 2),
    },
    formStatus: {
      width: '100%',
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: 'solid',
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    containerContent: {
      padding: theme.spacing(1),
    },
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalFormCreategnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);
