/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  apiFetchListCommissionHistorical,
  apiGetTotalCommission,
  SingleCommission,
} from "../../../api/commission";
import FilterDate from "../../../components/Form/FilterDate";
import CustomizedTable, {
  ITableHeader,
} from "../../../components/Table/CustomizedTable";
import { generateQueryString } from "../../../helpers/functions/generateQueryString";
import usePaginateTable, {
  IPaginateTable,
} from "../../../hooks/usePaginateTable";
import GeneralInformation from "../../B2BTransaction/GeneralInformation";
import { convertNumToCurrency } from "../../../helpers/functions/currency";
import AppButton from "../../../components/Form/AppButton";
import { SaveAlt } from "@material-ui/icons";
import { format } from "date-fns";
import { apiExportCustomerComission } from "../../../api/commission";

const headers: ITableHeader[] = [
  {
    title: "Upline",
    align: "left",
    column: "upline",
  },
  {
    title: "Downline",
    align: "left",
    column: "customerName",
  },
  {
    title: "Transaction Date",
    align: "left",
    sortable: true,
    type: "dd mmm yyyy hh:mm",
    column: "createdAt",
  },
  {
    title: "Transaction ID",
    align: "left",
    column: "invoiceId",
  },
  {
    title: "Total Transaction",
    align: "left",
    type: "currency",
    alignContent: "right",
    column: "totalTransaction",
  },
  {
    title: "Commision",
    align: "left",
    alignContent: "center",
    column: "commisionPercentage",
  },
  {
    title: "Amount Receive",
    align: "left",
    type: "currency",
    alignContent: "right",
    column: "amount",
  },
];

export default function ReferralCommision(props: {
  customerId: string;
  customerName: string;
  customerUplineName?: string;
  onLoading: (value: boolean) => void;
}) {
  const [loadingExport, setLoadingExport] = useState(false);
  const classes = useStyles();

  const date = new Date();
  const defaultParamsStartAt = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  const defaultParamsEndAt = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );
  const {
    onChangePage,
    onChangeRowsPerPage,
    fetchList,
    loading,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable & { data: SingleCommission[] } = usePaginateTable(
    apiFetchListCommissionHistorical,
    {
      userId: props.customerId,
      limit: 25,
      offset: 0,
      startAt: defaultParamsStartAt.toISOString(),
      endAt: defaultParamsEndAt.toISOString(),
      // filterSource: 'downline_transaction',
    }
  );

  useEffect(() => {
    props.onLoading(loading);
  }, [loading]);

  let listCommission: any[] = [];
  if (data.length > 0) {
    listCommission = data.map((item: SingleCommission) => ({
      ...item,
      invoiceId: item.invoice ? item.invoice.invoiceId : null,
      upline: item.user ? item.user.name : null,
      customerName: item.commissionMetadata
        ? (item.commissionMetadata.sourceUserName || "-") +
          (item.commissionMetadata.source !== "downline_transaction"
            ? "(You)"
            : "")
        : null,
      totalTransaction: item.txMetadata.transactionPrice,
      commisionPercentage: item.commissionMetadata.commissionPercentage,
    }));
  }

  // totalCommission
  const [totalCommission, setTotalCommission] = useState(0);
  const [paramsTotalCommission, setParamsTotalCommission] = useState({
    userId: props.customerId,
    startAt: defaultParamsStartAt.toISOString(),
    endAt: defaultParamsEndAt.toISOString(),
    // filterSource: 'downline_transaction'
  });

  useEffect(() => {
    getTotalCommission();
  }, []);

  const getTotalCommission = (incomingParams: any = {}) => {
    const newParams = {
      ...paramsTotalCommission,
      ...incomingParams,
    };
    setParamsTotalCommission(newParams);
    apiGetTotalCommission(generateQueryString(newParams))
      .then((res) => {
        setTotalCommission(res.totalCommission);
      })
      .catch((err) => {
        console.error("Error getTotalCommission", err);
      });
  };

  const onChangeFilterData = (startAt: string, endAt: string) => {
    console.info("onChangeFilterData", startAt, endAt);
    fetchList({ startAt, endAt });
    getTotalCommission({ startAt, endAt });
  };

  async function exportCustomer() {
    try {
      setLoadingExport(true);
      const excel = await apiExportCustomerComission(listCommission);

      if (excel.data) {
        const url = window.URL.createObjectURL(new Blob([excel.data]));
        const link = document.createElement("a");
        link.href = url;
        const downloadDate = format(new Date(), "dd/MM/yyyy");
        link.setAttribute(
          "download",
          `oxone-${props.customerName}-referral-${downloadDate}.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoadingExport(false);
      }
    } catch (error) {
      setLoadingExport(false);
    }
  }

  return (
    <Box className={classes.containerForm}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={8}>
          <AppButton
            loading={loadingExport}
            color="primary"
            onClick={() => exportCustomer()}
          >
            <SaveAlt />
            <Typography component="span" style={{ marginLeft: 10 }}>
              Export All
            </Typography>
          </AppButton>
          <GeneralInformation
            itemPerRow={3}
            data={[
              {
                label: "Customer",
                value: props.customerName,
              },
              {
                label: "Referral Amount",
                value: convertNumToCurrency(totalCommission),
              },
              {
                label: "Upline",
                value: props.customerUplineName,
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <FilterDate
            start_at={params.startAt}
            end_at={params.endAt}
            onChange={(start_at: string, end_at: string) =>
              onChangeFilterData(start_at, end_at)
            }
          />
        </Grid>
      </Grid>

      <CustomizedTable
        data={listCommission}
        headers={headers}
        totalData={totalData}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    containerForm: {
      padding: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);
