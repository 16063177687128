import { DashboardTypes } from '../../types/dashboard.type';

export function setSummaryListTransaction(payload: any) {
  return {
    type: DashboardTypes.SET_LIST_TRANSACTION,
    payload,
  };
}

export function setSummaryTotalTransaction(payload: any) {
  return {
    type: DashboardTypes.SET_TOTAL_TRANSACTION,
    payload,
  };
}

export function setSummaryMostActiveTransaction(payload: any) {
  return {
    type: DashboardTypes.SET_MOST_ACTIVE_TRANSACTION,
    payload,
  };
}

export function setSummaryMostActiveUpline(payload: any) {
  return {
    type: DashboardTypes.SET_MOST_ACTIVE_UPLINE,
    payload,
  };
}

export function setSummaryLoading(payload: boolean) {
  return {
    type: DashboardTypes.SET_LOADING_DASHBOARD,
    payload,
  };
}

export function setSummaryCustomer(payload: any) {
  return {
    type: DashboardTypes.SET_CUSTOMER_SUMMARY,
    payload,
  };
}
