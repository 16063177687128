import { useDispatch } from "react-redux";
import {
  apiCurrentExtraWarranty,
  apiLogExtraWarranty,
  apiUpdateExtraWarranty,
} from "../api/master-data/extra-warranty";
import { generateQueryString } from "../helpers/functions/generateQueryString";
import {
  currentExtraWarranty,
  findLogExtraWarranty,
  setLoadingExtraWarranty,
} from "../redux/action/master-data-referral.action";

export default function useWarranty() {
  const dispatch = useDispatch();

  const fetchCurrentWarranty = async () => {
    try {
      dispatch(setLoadingExtraWarranty(true));
      const result = await apiCurrentExtraWarranty();

      if (result.data && result.data.payload) {
        dispatch(currentExtraWarranty(result.data.payload));
      } else {
        dispatch(currentExtraWarranty({ year: "" }));
      }

      dispatch(setLoadingExtraWarranty(false));
    } catch (error) {
      dispatch(setLoadingExtraWarranty(false));
    }
  };

  const fetchLogExtraWarranty = async (params: any) => {
    try {
      dispatch(setLoadingExtraWarranty(true));

      let query = generateQueryString(params);
      const result = await apiLogExtraWarranty(query);

      dispatch(findLogExtraWarranty(result.data));

      dispatch(setLoadingExtraWarranty(false));
    } catch (error) {
      dispatch(setLoadingExtraWarranty(false));
    }
  };

  const updateExtraWarranty = async (data: any) => {
    try {
      dispatch(setLoadingExtraWarranty(true));
      const result = await apiUpdateExtraWarranty(data);

      dispatch(currentExtraWarranty(result.data));

      await fetchLogExtraWarranty({ limit: 25, offset: 0 });
      await fetchCurrentWarranty();

      dispatch(setLoadingExtraWarranty(false));
    } catch (error) {
      dispatch(setLoadingExtraWarranty(false));
    }
  };

  return {
    fetchCurrentWarranty,
    fetchLogExtraWarranty,
    updateExtraWarranty,
  };
}
