import axios from 'axios';
import { getHeaders } from '../helpers/auth';

const headers = getHeaders()

export async function apiFindAllBankApproval(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/bank-approval' + query,
    method: 'GET',
    headers
  });
}

export async function apiUpdateBankApproval(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/bank-approval/' + data.bankApprovalId,
    method: 'PATCH',
    headers,
    data
  });
}
