import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Box, Typography } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import AppButton from './Form/AppButton';


const SessionTimeout = () => {
  let countdownInterval: any = null;
  let timeout: any = null;

  const auth = useAuth();
  const classes = useStyles();

  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);
  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };
  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };
  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      logout();
    } catch (err) {
      console.error(err);
    }
  };
  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };
  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };
  const onIdle = () => {
    const delay = 1000 * 1;
    if (auth.userSignIn && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  const logout = () => {
    auth.signout(() => {
      window.location.href = '/login';
    });
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 60 * 8}
      />
      {/* <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
      /> */}
      <Modal
        open={timeoutModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Confirmation</Typography>
            </Box>

            <Typography variant="body1">
              Are you sure want to logout? {timeoutCountdown}
            </Typography>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleContinue}
                >
                  Continue
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={logout}>Logout</AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  containerModal: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alihandleCloseModalLogoutgnContentConfirmation: 'center',
  },
  contentModal: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 2),
    width: 400,
    margin: '0 auto',
    marginTop: '10%',
    borderRadius: 10,
  },
}))

export default SessionTimeout;