import axios from "axios";
import { getHeadersMultiPart, getHeaders } from "../helpers/auth";

export async function apiFindAllProduct(params: string) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/product" + query,
    method: "GET",
    headers,
  });
}

export async function apiFindHistoryProductPrices(params: string) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/product-prices-history" + query,
    method: "GET",
    headers,
  });
}

export async function apiFindOneProduct(productId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/product/" + productId,
    method: "GET",
    headers,
  });
}

export async function apiAddImageProduct(data: FormData) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/product/" +
      data.get("productId") +
      "/add-image",
    method: "POST",
    headers,
    data,
  });
}

export async function apiRemoveImageProduct(fileId: string) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/product/image/" + fileId,
    method: "DELETE",
    headers,
  });
}

export async function apiUpdateProduct(data: {
  productId: string;
  catalogPrice: number;
  retailDiscountAmount: number;
  retailDiscountNote: string;
  goldDiscountAmount: number;
  goldDiscountNote: string;
  platinumDiscountAmount: number;
  platinumDiscountNote: string;
  note?: string;
  updatedBy?: string;
}) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/product/" + data.productId,
    method: "PATCH",
    headers,
    data: {
      price: {
        catalogPrice: data.catalogPrice,
        retailDiscountAmount: Number(data.retailDiscountAmount) || 0,
        retailDiscountNote: data.retailDiscountNote || "",
        goldDiscountAmount: Number(data.goldDiscountAmount) || 0,
        goldDiscountNote: data.goldDiscountNote || "",
        platinumDiscountAmount: Number(data.platinumDiscountAmount) || 0,
        platinumDiscountNote: data.platinumDiscountNote || "",
        note: data.note || "",
        updatedBy: data.updatedBy || "",
      },
    },
  });
}

export async function apiAddProductVideo(data: {
  productId: string;
  title: string;
  type: string;
  url: string;
}) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/product/" +
      data.productId +
      "/add-video",
    method: "POST",
    headers,
    data: {
      title: data.title,
      type: data.type,
      url: data.url,
    },
  });
}

export async function apiRemoveProductVideo(data: {
  productId: string;
  index: number;
}) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/product/" +
      data.productId +
      "/delete-video/" +
      data.index,
    method: "DELETE",
    headers,
  });
}

export async function apiUpdateProductVideo(data: {
  productId: string;
  index: number;
  title: string;
  type: string;
  url: string;
}) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/product/" +
      data.productId +
      "/update-video/" +
      data.index,
    method: "PATCH",
    headers,
    data: {
      title: data.title,
      type: data.type,
      url: data.url,
    },
  });
}

export async function apiUpdateProductInitialWarranty(data: {
  productId: string;
  date: Date | string | undefined;
}) {
  const headers = await getHeaders();
  return axios({
    url:
      process.env.REACT_APP_BASE_URL +
      "/product/" +
      data.productId +
      "/update-initial-warranty",
    method: "PATCH",
    headers,
    data: {
      date: data.date,
    },
  });
}
