import { useDispatch } from "react-redux";
import {
  apiCreateCategory,
  apiDeleteCategory,
  apiFindAllCategory,
  apiFindOneCategory,
  apiUpdateCategory,
} from "../api/category";
import {
  setLoadingCategory,
  setErrorCategory,
  findAllCategory,
  findOneCategory,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../redux/action/category.action";
import { useHistory } from "react-router-dom";
import { setUpladImageStatus } from "../redux/action/config-app.action";
import { generateQueryString } from "../helpers/functions/generateQueryString";

export default function useCategory() {
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAllCategory = async (params: any) => {
    try {
      dispatch(setLoadingCategory(true));
      let query = generateQueryString(params);

      const result = await apiFindAllCategory(query);

      dispatch(findAllCategory(result.data));

      dispatch(setLoadingCategory(false));
    } catch (error: any) {
      dispatch(setLoadingCategory(false));
      dispatch(setErrorCategory(error.response));
    }
  };

  const fetchOneCategory = async (categoryId: string) => {
    try {
      dispatch(setLoadingCategory(true));

      const result = await apiFindOneCategory(categoryId);

      dispatch(findOneCategory(result.data.payload));

      dispatch(setLoadingCategory(false));
    } catch (error: any) {
      dispatch(setLoadingCategory(false));
      dispatch(setErrorCategory(error.response));
    }
  };

  const addCategory = async (data: FormData) => {
    try {
      dispatch(setLoadingCategory(true));
      const result = await apiCreateCategory(data);

      if (result.data.payload) {
        dispatch(createCategory(result.data.payload));
        history.go(-1);
      }

      dispatch(setLoadingCategory(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingCategory(false));
      dispatch(setErrorCategory(error.response));
    }
  };

  const updateOneCategory = async (data: FormData) => {
    try {
      dispatch(setLoadingCategory(true));
      const result = await apiUpdateCategory(data);

      if (result.data.payload) {
        dispatch(updateCategory(result.data.payload));
        history.go(-1);
      }

      dispatch(setLoadingCategory(false));
      dispatch(setUpladImageStatus(true));
    } catch (error: any) {
      dispatch(setLoadingCategory(false));
      dispatch(setErrorCategory(error.response));
    }
  };

  const removeCategory = async (categoryId: string) => {
    try {
      dispatch(setLoadingCategory(true));

      await apiDeleteCategory(categoryId);

      dispatch(deleteCategory(categoryId));

      dispatch(setLoadingCategory(false));
    } catch (error: any) {
      dispatch(setLoadingCategory(false));
      dispatch(setErrorCategory(error.response));
    }
  };

  return {
    fetchAllCategory,
    fetchOneCategory,
    addCategory,
    updateOneCategory,
    removeCategory,
  };
}
