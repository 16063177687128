/* eslint-disable react-hooks/exhaustive-deps */
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { apiFetchListCommission, SingleCommissionResult } from '../../../api/commission';
import FilterDate from '../../../components/Form/FilterDate';
import CustomizedTable, {
  ITableHeader,
} from '../../../components/Table/CustomizedTable';
import usePaginateTable, { IPaginateTable } from '../../../hooks/usePaginateTable';
import GeneralInformation from '../../B2BTransaction/GeneralInformation';
import { convertDateTimeToString } from '../../../helpers/functions/datetime';
import { convertDateTimeToText } from '../../../helpers/functions/datetime';

const headers: ITableHeader[] = [
  {
    title: 'Status',
    align: 'left',
    sortable: true,
    column: 'status',
  },
  {
    title: 'Period',
    align: 'left',
    sortable: true,
    column: 'period',
  },
  {
    title: 'Disburse Date',
    align: 'left',
    sortable: true,
    column: 'createdAt',
  },
  {
    title: 'Disburse',
    align: 'left',
    type: 'currency',
    alignContent: 'center',
    column: 'totalAmount',
  },
];

export default function DisbursementHistory(props: {
  customerId: string;
  customerName: string;
  onLoading: (value: boolean) => void;
}) {
  const classes = useStyles();

  const date = new Date();
  const defaultParamsStartAt = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
    0,
    0,
    0,
    0
  );
  const defaultParamsEndAt = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
    23,
    59,
    59,
    999
  );
  const {
    onChangePage,
    onChangeRowsPerPage,
    fetchList,
    loading,
    params,
    data,
    page,
    totalPage,
    totalData,
    rowsPerPage,
  }: IPaginateTable & { data: SingleCommissionResult[] } = usePaginateTable(
    apiFetchListCommission,
    {
      userId: props.customerId,
      limit: 25,
      offset: 0,
      filterStatus: 'processed,done',
      startAt: defaultParamsStartAt.toISOString(),
      endAt: defaultParamsEndAt.toISOString(),
    }
  );

  useEffect(() => {
    props.onLoading(loading);
  }, [loading]);

  const listDisbursement = data.map(item => ({
    ...item,
    status: item.status.toUpperCase(),
    createdAt: convertDateTimeToText(item.createdAt, 'dd mmm yyyy hh:mm'),
    period: convertDateTimeToText(item.periodStart, 'dd mmm yyyy') + ' - ' + convertDateTimeToText(item.periodEnd, 'dd mmm yyyy')
  }))

  const onChangeFilterData = (startAt: string, endAt: string) => {
    console.info('onChangeFilterData', startAt, endAt);
    fetchList({ startAt, endAt });
  };

  return (
    <Box className={classes.containerForm}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={2}>
          <GeneralInformation
            itemPerRow={1}
            data={[
              {
                label: 'Customer',
                value: props.customerName,
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <FilterDate
            start_at={params.startAt}
            end_at={params.endAt}
            onChange={onChangeFilterData}
          />
        </Grid>
      </Grid>

      <CustomizedTable
        data={listDisbursement}
        headers={headers}
        totalData={totalData}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    containerForm: {
      padding: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);
