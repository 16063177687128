import { Reducer } from "redux";
import {
  CollectionResponse,
  CollectionState,
  CollectionTypes,
} from "../../types/collection.type";

export const initCollection = {
  collectionId: "",
  invoiceId: "",
  productId: "",
  userId: "",
  sku: "",
  serialNumber: "",
  remark: "",
  status: "",
  updatedAt: "",
  createdAt: "",
  invoice: {
    invoiceId: "",
  },
  product: {
    productId: "",
    productName: "",
    sku: "",
  },
  customer: {
    customerId: "",
    customerName: "",
    phoneNumber: "",
  },
  images: [],
  invoiceImages: []
};

export const initialState: CollectionState = {
  isLoadingCollection: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  collection: { ...initCollection },
  isSuccess: false,
};

export const collection: Reducer<CollectionState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CollectionTypes.FIND_ALL_COLLECTION:
      const results = action.payload.payload.results.map(
        (item: CollectionResponse) => {
          return {
            ...item,
          };
        }
      );
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case CollectionTypes.UPDATE_COLLECTION:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};
