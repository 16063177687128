
export enum FeaturedProductTypes {
  FIND_ALL_FEATURED_PRODUCT = 'FIND_ALL_FEATURED_PRODUCT',
  FIND_ONE_FEATURED_PRODUCT = 'FIND_ONE_FEATURED_PRODUCT',
  CREATE_FEATURED_PRODUCT = 'CREATE_FEATURED_PRODUCT',
  UPDATE_FEATURED_PRODUCT = 'UPDATE_FEATURED_PRODUCT',
  REMOVE_FEATURED_PRODUCT = 'REMOVE_FEATURED_PRODUCT',
  SET_LOADING_FEATURED_PRODUCT = 'SET_LOADING_FEATURED_PRODUCT',
  SET_ERROR_FEATURED_PRODUCT = 'SET_ERROR_FEATURED_PRODUCT',
  SET_LAST_PATH_FEATURED_PRODUCT = 'SET_LAST_PATH_FEATURED_PRODUCT',
}

export type FeaturedProductResponse = {
  productId: string;
  createdByUserId: string;
  productName: string;
  sku: string;
  metadata: string;
  description: string;
  published: boolean;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  featuredPublished: boolean;
  imageUrl: string;
  featuredImageUrl: string;
}

export type FeaturedProductRequest = {
  productId: string;
  published: string;
  image: File;
}

export type FeaturedProductResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: FeaturedProductResponse[]
  }
}

export type FeaturedProductState = {
  isLoadingFeaturedProduct: boolean;
  data: FeaturedProductResponsePayload;
  featuredProduct: FeaturedProductResponse;
  error: any;
  isSuccess: boolean;
}