import { Box, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputLabel, Paper, Radio, RadioGroup, Snackbar, Theme, Tabs, Tab } from "@material-ui/core";
import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import Layout from "../../components/Layout/Layout";
import HeaderPage from "../../components/Layout/HeaderPage";
import { useParams } from 'react-router-dom';
import GeneralInformationSO from '../../components/B2B/sales-order/GeneralInformationSO';
import useFetchOne from '../../hooks/useFetchOne';
import { ApprovedProductProperties, EB2BStatus, initialSalesOrder, SalesOrderItemProperties, SalesOrderProperties } from '../../types/b2b/order/sales-order.type';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import InfoProductSO from '../../components/B2B/sales-order/InfoProductSO';
import { DeliveryOrderProperties, IDeliveryPickupQuery } from '../../types/b2b/order/delivery-order.type';
import useFetch from '../../hooks/useFetch';
import CustomizedTable from '../../components/Table/CustomizedTable';
import { headerDODetail, headerSOItemDetail, headerSRItemDetail, headerPODetail, headerRplpDetail, headerRpldDetail, headerRPLItemDetail } from './headers';
import ApprovalNote from '../../components/B2B/ApprovalNote';
import AppButton from '../../components/Form/AppButton';
import { httpRequest } from '../../helpers/api/api';
import FormInput from '../../components/Form/FormInput';
import ApprovalItem from '../../components/B2B/ApprovalItem';
import { X } from 'react-feather';
import { SalesReturnProperties, SalesReturnItemProperties } from '../../types/b2b/return/sales-return.type';
import { ReplacementItemProperties, ReplacementProperties } from '../../types/b2b/replacement/replacement.type';
import { ReplacementPickupProperties } from '../../types/b2b/replacement/replacement-pickup.type';
import { ReplacementDeliveryProperties } from '../../types/b2b/replacement/replacement-delivery.type';
import { PickupOrderProperties } from '../../types/b2b/return/pickup-order.type';
import GeneralInformationSR from '../../components/B2B/sales-return/GeneralInformationSR';
import InfoProductSR from '../../components/B2B/sales-return/InfoProductSR';
import axios from 'axios';
import InfoProductRepl from '../../components/B2B/replacement/InfoProductRepl';
import GeneralInformationRpl from '../../components/B2B/replacement/GeneralInformationRepl';
import { setB2BID } from '../../redux/action/b2b.action';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
  type: 'sales-order' | 'sales-return' | 'replacement'
}

let urlDeliveryPickup = ''

export default function B2BTransactionDetail(props: Props) {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [queryDataProduct, setQueryDataProduct] = React.useState<IDeliveryPickupQuery>({
    limit: 25,
    page: 1,
    status: null,
    id,
  })
  const [queryDataDO, setQueryDataDO] = React.useState<IDeliveryPickupQuery>({
    limit: 25,
    page: 1,
    status: null,
    sort: 'createdAt,DESC',
    id: id
  })

  const {
    fetchOneData,
    data: dataSO,
    loading: loadingSO
  } = useFetchOne<SalesOrderProperties & SalesReturnProperties & ReplacementProperties>({
    url: props.type,
    initialData: initialSalesOrder
  })

  const {
    data: dataProducts,
    pagination: paginationDataProduct,
    fetchDataTable: fetchDataProducts
  } = useFetch<SalesOrderItemProperties & SalesReturnItemProperties & ReplacementItemProperties>({
    url: props.type + '/data-products/all',
    query: queryDataProduct,
    staticUrl: true
  })

  if (props.type !== 'replacement') {
    if (props.type === 'sales-order') {
      urlDeliveryPickup = 'delivery-order'
    } else {
      urlDeliveryPickup = 'pickup-order'
    }
  }

  const {
    data: dataDeliveryPickup,
    pagination: paginationDeliveryPickup,
    fetchDataTable: fetchDataDeliveryPickup
  } = useFetch<DeliveryOrderProperties & PickupOrderProperties>({
    url: urlDeliveryPickup,
    query: queryDataDO,
    staticUrl: true
  })

  const {
    data: dataReplacementPickup,
    pagination: paginationReplacementPickup,
    fetchDataTable: fetchDataReplacementPickup
  } = useFetch<ReplacementPickupProperties>({
    url: 'replacement-pickup',
    query: queryDataDO,
    staticUrl: true
  })

  const {
    data: dataReplacementDelivery,
    pagination: paginationReplacementDelivery,
    fetchDataTable: fetchDataReplacementDelivery
  } = useFetch<ReplacementDeliveryProperties>({
    url: 'replacement-delivery',
    query: queryDataDO,
    staticUrl: true
  })

  React.useEffect(() => {
    fetchOneData(id)
    fetchDataProducts()
    if (props.type !== 'replacement') {
      fetchDataDeliveryPickup()
    } else {
      fetchDataReplacementPickup()
    }
  }, [id])

  const handlePageChange = (page: number, type: 'products' | 'delivery-order') => {
    if (type === 'products') {
      setQueryDataProduct((query) => {
        return {
          ...query,
          page,
          offset: (page - 1) * paginationDataProduct.perPage
        }
      })
    } else if (type === 'delivery-order') {
      setQueryDataDO((query) => {
        return {
          ...query,
          page,
          offset: (page - 1) * paginationDeliveryPickup.perPage
        }
      })
    }
  }

  const handlePerPageChange = (limit: number, type: 'products' | 'delivery-order') => {
    if (type === 'products') {
      setQueryDataProduct((query) => {
        return {
          ...query,
          limit
        }
      })
    } else if (type === 'delivery-order') {
      setQueryDataDO((query) => {
        return {
          ...query,
          limit
        }
      })
    }
  }

  const dataProductsMapped = dataProducts.map(item => {
    return {
      sku: item.productMetadata.sku,
      name: item.productMetadata.name,
      qtyOrdered: item.qtyOrdered,
      qtyApproved: item.qtyApproved,
      qtyReceived: item.qtyReceived,
      qtyLeftover: item.qtyLeftover,

      qtyReturned: item.qtyReturned,
      qtyReplaced: item.qtyReplaced,
      note: item.note
    }
  })

  const dataDOMapped = dataDeliveryPickup.map(item => {
    return {
      orderId: item.orderId,
      doId: item.doId,
      returnId: item.returnId,
      poId: item.poId,
      lastUpdated: convertDateTimeToText(item.updatedAt, 'dd mmm yyyy hh:mm'),
      status: item.status,
    }
  })

  const dataRplPickupMapped = dataReplacementPickup.map(item => {
    return {
      rplId: item.rplId,
      rplpId: item.rplpId,
      lastUpdated: convertDateTimeToText(item.updatedAt, 'dd mmm yyyy hh:mm'),
      status: item.status,
    }
  })

  const dataRplDeliveryMapped = dataReplacementDelivery.map(item => {
    return {
      rplId: item.rplId,
      rpldId: item.rpldId,
      lastUpdated: convertDateTimeToText(item.updatedAt, 'dd mmm yyyy hh:mm'),
      status: item.status,
    }
  })

  const [loadingApproval, setLoadingApproval] = React.useState<boolean>(false)
  const [statusApproval, setStatusApproval] = React.useState<string>(EB2BStatus.approved)
  const [totalQtyApproved, setTotalQtyApproved] = React.useState<number>(0)
  const [approvalNote, setApprovalNote] = React.useState<string>('')
  const [approvedProducts, setApprovedProducts] = React.useState<Array<ApprovedProductProperties>>([])
  const [isShowDialog, setIsShowDialog] = React.useState<boolean>(false)
  const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState<Array<ApprovedProductProperties>>([])
  const [activeRplTab, setActiveRplTab] = React.useState<number>(0)

  React.useEffect(() => {
    if (dataProducts && dataProducts.length > 0) {
      setApprovedProducts(() => {
        return dataProducts.map(item => {
          return {
            id: props.type === 'sales-order' ? item.orderItemId : props.type === 'sales-return' ? item.returnItemId : item.rplItemId,
            qtyRequested: props.type === 'sales-order' ? item.qtyOrdered : props.type === 'sales-return' ? item.qtyReturned : item.qtyReplaced,
            qtyApproved: null,
            productMetadata: item.productMetadata,
            note: item.note
          }
        })
      })
    }
  }, [dataProducts])

  React.useEffect(() => {
    const mappingQtyApproval = approvedProducts.reduce((acc, curr) => acc + Number(curr.qtyApproved), 0)
    setTotalQtyApproved(mappingQtyApproval)
  }, [approvedProducts])

  const handleOpenDialog = () => {
    setIsShowDialog(true)
  }

  const handleCloseDialog = () => {
    setIsShowDialog(false)
  }

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true)
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const submit = async () => {

    const validationResults = checkDataValidation()

    if (validationResults && validationResults.length > 0) {
      handleOpenSnackbar()
      setSnackbarMessage(validationResults)
    } else {
      try {

        const products = approvedProducts.map(item => {
          return {
            orderItemId: item.id,
            qtyApproved: Number(item.qtyApproved),
            qtyRequested: item.qtyRequested,
            productMetadata: item.productMetadata
          }
        })

        setLoadingApproval(true)
        await httpRequest.patch<{ isSuccess: boolean }>(props.type + '/' + id, {
          status: statusApproval,
          totalQtyApproved,
          approvalNote,
          products,
          soId: props.type !== 'sales-order' ? dataSO.soId : null
        })

      } catch (error) {
        throw error;
      } finally {
        handleCloseDialog()
        setLoadingApproval(false)
        setApprovalNote('')

        await fetchOneData(id)

        setQueryDataProduct((query) => {
          return {
            ...query,
          }
        })

        await fetchDataProducts()
      }
    }
  }

  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setStatusApproval(value);
  };

  const onChangeApprovalValue = (data: ApprovedProductProperties) => {
    const found = approvedProducts.find(product => product.id === data.id)
    let dataApproved = approvedProducts

    if (found) {
      const validateData: ApprovedProductProperties = {
        ...data,
        qtyApproved: data.qtyApproved && (data.qtyApproved > found.qtyRequested) ? found.qtyRequested : data.qtyApproved && data.qtyApproved < 0 ? 0 : Number(data.qtyApproved)
      }

      dataApproved = approvedProducts.map(product => {
        if (product.id === data.id) {
          return {
            ...validateData
          }
        } else {
          return {
            ...product
          }
        }
      })
    }
    setApprovedProducts(dataApproved)
  }

  const checkDataValidation = () => {
    const founds = approvedProducts.filter(item => !item.qtyApproved)
    if (founds) {
      return founds
    } else {
      return false
    }
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <X fontSize="small" color="white" />
      </IconButton>
    </React.Fragment>
  );

  const handleChangeRplTab = (_: any, newValue: number) => {
    setActiveRplTab(newValue)
    if (newValue === 0) {
      fetchDataReplacementPickup()
    } else {
      fetchDataReplacementDelivery()
    }
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  let subtitle = ''

  if (props.type === 'sales-order') {
    subtitle = 'Detailed information about sales order'
  } else if (props.type === 'sales-return') {
    subtitle = 'Detailed information about sales return'
  } else if (props.type === 'replacement') {
    subtitle = 'Detailed information about replacement'
  }

  const dispatch = useDispatch()
  const history = useHistory()

  const handleToDetail = (data: any) => {
    if (props.type === 'sales-order') {
      dispatch(setB2BID(data.orderId))
      history.push('/delivery-order/' + data.doId)
    } else if (props.type === 'sales-return') {
      dispatch(setB2BID(data.returnId))
      history.push('/pickup-order/' + data.poId)
    } else {
      dispatch(setB2BID(data.rplId))
      if (activeRplTab === 0) {
        history.push('/replacement-pickup/' + data.rplpId)
      } else if (activeRplTab === 1) {
        history.push('/replacement-delivery/' + data.rpldId)
      }
    }
  }

  return (
    <Layout title="Sales Order Detail" loading={loadingSO}>
      <div
        style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}
      >
        <HeaderPage
          title={id}
          subtitle={subtitle}
          showBackButton
        />
        {dataSO.status === EB2BStatus.posted && (
          <AppButton
            onClick={() => handleOpenDialog()}
          >
            Approve {props.type === 'sales-order' ? 'Order' : props.type === 'sales-return' ? 'Return' : 'Replacement'}
          </AppButton>
        )}
      </div>

      <Paper elevation={3} className={classes.paper}>

        {props.type === 'sales-order' && (
          <GeneralInformationSO
            orderId={dataSO.orderId}
            soId={dataSO.soId}
            orderStatus={dataSO.status}
            distributor={dataSO.distributorMetadata.name}
            orderDate={dataSO.createdAt ? convertDateTimeToText(dataSO.createdAt, 'dd mmm yyyy hh:mm') : null}
            approveDate={dataSO.approvedAt ? convertDateTimeToText(dataSO.approvedAt, 'dd mmm yyyy hh:mm') : null}
            orderNote={dataSO.note}
          />
        )}

        {props.type === 'sales-return' && (
          <GeneralInformationSR
            returnId={dataSO.returnId}
            roId={dataSO.roId}
            status={dataSO.status}
            distributor={dataSO.distributorMetadata.name}
            returnDate={dataSO.createdAt ? convertDateTimeToText(dataSO.createdAt, 'dd mmm yyyy hh:mm') : null}
            approveDate={dataSO.approvedAt ? convertDateTimeToText(dataSO.approvedAt, 'dd mmm yyyy hh:mm') : null}
            returnNote={dataSO.note}
            productImages={dataSO.productImages}
          />
        )}

        {props.type === 'replacement' && (
          <GeneralInformationRpl
            rplId={dataSO.rplId}
            rploId={dataSO.rploId}
            rplStatus={dataSO.status}
            distributor={dataSO.distributorMetadata.name}
            rplDate={dataSO.createdAt ? convertDateTimeToText(dataSO.createdAt, 'dd mmm yyyy hh:mm') : null}
            approveDate={dataSO.approvedAt ? convertDateTimeToText(dataSO.approvedAt, 'dd mmm yyyy hh:mm') : null}
            rplNote={dataSO.note}
            productImages={dataSO.productImages}
          />
        )}
        <Divider />

        <div className={classes.boxRow} style={{ paddingBottom: 0 }}>
          <Typography variant="h5">{props.type === 'sales-order' ? 'Ordered Products' : props.type === 'sales-return' ? 'Returned Products' : 'Replacement Products'}</Typography>
          {props.type === 'sales-order' && (
            <InfoProductSO
              totalOrder={dataSO.totalQtyOrdered}
              totalApproved={dataSO.totalQtyApproved}
              totalReceive={dataSO.totalQtyReceived}
              totalLeftover={dataSO.totalQtyLeftover}
            />
          )}

          {props.type === 'sales-return' && (
            <InfoProductSR
              totalReturn={dataSO.totalQtyReturned}
              totalApproved={dataSO.totalQtyApproved}
              totalPickup={dataSO.totalQtyPickup}
            />
          )}

          {props.type === 'replacement' && (
            <InfoProductRepl
              totalReplaced={dataSO.totalQtyReplaced}
              totalApproved={dataSO.totalQtyApproved}
              totalPickuped={dataSO.totalQtyPickup}
              totalDelivered={dataSO.totalQtyDelivered}
            />
          )}
        </div>


        <div className={classes.boxRow} style={{ paddingBottom: 0 }}>
          <CustomizedTable
            data={dataProductsMapped}
            headers={
              props.type === 'sales-order' ? headerSOItemDetail
                : props.type === 'sales-return' ? headerSRItemDetail
                  : headerRPLItemDetail}
            totalData={paginationDataProduct.totalData}
            page={paginationDataProduct.page}
            totalPage={paginationDataProduct.countPage}
            rowsPerPage={paginationDataProduct.perPage}
            onChange={(page) => handlePageChange(page, 'products')}
            onRowsPerPageChange={(rowPerPage: any) => handlePerPageChange(rowPerPage, 'products')}
          />
        </div>

        <div className={classes.boxRow} style={{ paddingBottom: 0 }}>
          <Typography variant="h5" style={{ paddingBottom: 10 }}>Approval Note</Typography>
          <ApprovalNote note={dataSO.approvalNote} />
        </div>

        <div className={classes.boxRow}>
          <Typography variant="h5" style={{ paddingBottom: 10 }}>{props.type === 'sales-order' ? 'Delivery Order List' : props.type === 'sales-return' ? 'Pickup Order List' : 'Replacement Pickup / Delivery'}</Typography>

          {props.type !== 'replacement' ? (
            <CustomizedTable
              data={dataDOMapped}
              headers={
                props.type === 'sales-order' ? headerDODetail
                  : props.type === 'sales-return' ? headerPODetail : headerPODetail}
              totalData={paginationDeliveryPickup.totalData}
              page={paginationDeliveryPickup.page}
              showAction={['detail-dialog']}
              onPressDetail={(data) => handleToDetail(data)}
              totalPage={paginationDeliveryPickup.countPage}
              rowsPerPage={paginationDeliveryPickup.perPage}
              onChange={(page) => handlePageChange(page, 'delivery-order')}
              onRowsPerPageChange={(rowPerPage: any) => handlePerPageChange(rowPerPage, 'delivery-order')}
            />
          ) : (
              <>
                <Box style={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={activeRplTab} onChange={handleChangeRplTab} aria-label="basic tabs example">
                    <Tab label="Replacement Pickup" {...a11yProps(0)} />
                    <Tab label="Replacement Delivery" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                {activeRplTab === 0 && (
                  <CustomizedTable
                    data={dataRplPickupMapped}
                    headers={headerRplpDetail}
                    totalData={paginationReplacementPickup.totalData}
                    page={paginationReplacementPickup.page}
                    totalPage={paginationReplacementPickup.countPage}
                    rowsPerPage={paginationReplacementPickup.perPage}
                    onChange={(page) => handlePageChange(page, 'delivery-order')}
                    onRowsPerPageChange={(rowPerPage: any) => handlePerPageChange(rowPerPage, 'delivery-order')}
                    showAction={['detail-dialog']}
                    onPressDetail={(data) => handleToDetail(data)}
                  />
                )}

                {activeRplTab === 1 && (
                  <CustomizedTable
                    data={dataRplDeliveryMapped}
                    headers={headerRpldDetail}
                    totalData={paginationReplacementDelivery.totalData}
                    page={paginationReplacementDelivery.page}
                    totalPage={paginationReplacementDelivery.countPage}
                    rowsPerPage={paginationReplacementDelivery.perPage}
                    onChange={(page) => handlePageChange(page, 'delivery-order')}
                    onRowsPerPageChange={(rowPerPage: any) => handlePerPageChange(rowPerPage, 'delivery-order')}
                    showAction={['detail-dialog']}
                    onPressDetail={(data) => handleToDetail(data)}
                  />
                )}
              </>
            )}
        </div>
      </Paper>

      <Dialog
        fullWidth
        maxWidth={props.type !== 'sales-order' ? 'lg' : 'md'}
        open={isShowDialog}
        onClose={handleCloseDialog}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Approval {props.type === 'sales-order' ? 'Sales Order' : props.type === 'sales-return' ? 'Sales Return' : 'Replacement'}</DialogTitle>
        <DialogContent>
          <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ marginRight: 10 }}>Total {props.type === 'sales-order' ? 'Order' : props.type === 'sales-return' ? 'Return' : 'Replacement'}</Typography>
              <Typography variant="h3"><b>{props.type === 'sales-order' ? dataSO.totalQtyOrdered : props.type === 'sales-return' ? dataSO.totalQtyReturned : dataSO.totalQtyReplaced}</b></Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ marginRight: 10 }}>Total Approved</Typography>
              <Typography variant="h3"><b>{totalQtyApproved}</b></Typography>
            </Grid>

            <div style={{
              maxHeight: 500,
              width: '100%',
              overflowY: 'auto',
              padding: 15,
              borderRadius: 10,
              marginTop: 10,
              marginBottom: 10,
              border: '1px solid #e0e0e0'
            }}>
              <Grid container style={{ paddingTop: 0, paddingBottom: 10 }}>
                <Grid item xs={2}>
                  <Typography><b>Product Code</b></Typography>
                </Grid>
                <Grid item xs={props.type !== 'sales-order' ? 3 : 4}>
                  <Typography><b>Name</b></Typography>
                </Grid>
                <Grid item xs={props.type !== 'sales-order' ? 2 : 3}>
                  <Typography><b>Qty Requested</b></Typography>
                </Grid>
                <Grid item xs={props.type !== 'sales-order' ? 2 : 3}>
                  <Typography><b>Qty Approved</b></Typography>
                </Grid>
                {props.type !== 'sales-order' && (
                  <Grid item xs={3}>
                    <Typography><b>{props.type === 'sales-return' ? 'Retur Note' : 'Replacement Note'}</b></Typography>
                  </Grid>
                )}
              </Grid>
              {approvedProducts.map(item => (
                <ApprovalItem
                  key={item.id}
                  showNote={props.type === 'sales-return' || props.type === 'replacement'}
                  data={item}
                  onChangeValue={onChangeApprovalValue}
                />
              ))}
            </div>

            <Grid item xs={12}>
              <InputLabel id="select-status" shrink={true}>
                Status {props.type === 'sales-order' ? 'Sales Order' : props.type === 'sales-return' ? 'Sales Return' : 'Replacement'}
              </InputLabel>
              <RadioGroup style={{ flexDirection: 'row', alignItems: 'center' }} aria-label="gender" name="gender1" value={statusApproval} onChange={handleChangeStatus}>
                <FormControlLabel value="approved" control={<Radio color="primary" />} label="Approved" />
                <FormControlLabel value="cancel" control={<Radio color="primary" />} label="Reject" />
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <FormInput
                onChange={(value: any) => setApprovalNote(value)}
                type="address"
                value={approvalNote}
                fullWidth
                multiline
                label="Approval Note"
                placeholder="Tulis catatan approval disini"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%' }}>

          </div>
          <AppButton
            color="secondary"
            onClick={handleCloseDialog}
          >
            Cancel
          </AppButton>
          <AppButton
            disabled={loadingApproval || !approvalNote}
            loading={loadingApproval}
            onClick={() => submit()}
          >Save</AppButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={`Ada ${snackbarMessage.length} data produk yang belum diisi qty approval. Lengkapi data approval terlebih dahulu`}
        action={action}
      />

    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    boxRow: {
      padding: theme.spacing(2, 0, 2, 0),
    },
  })
);
