import { IQueryTable } from '../../../hooks/useFetch';
import { initialSalesOrder, SalesOrderProperties } from './sales-order.type';

export enum ETripStatus {
  created = 'created',
  loading = 'loading',
  loading_complete = 'loading_complete',
  pending = 'pending',
  otw = 'otw',
  change_route = 'change_route',
  arrived = 'arrived',
  confirmed = 'confirmed',
  cancel_by_system = 'cancel_by_system',
}

export interface IDeliveryPickupQuery extends IQueryTable {
  status: ETripStatus | null
  id?: string | null
}

export interface SummaryProductImages {
  id: string,
  imageLink: string
}

export interface DeliveryOrderItemProperties {
  deliveryOrderItemId: string
  doId: string
  productMetadata: {
    productName: string,
    productCode: string,
  },
  qtyDelivered: number,
  qtyReceived: number | null,
  qtyLeftover: number | null,
  createdAt?: string | Date,
  updatedAt?: string | Date,
  deliveryOrder?: DeliveryOrderProperties
}

export interface DeliveryOrderProperties {
  doId: string
  soId: string
  orderId: string
  status: ETripStatus
  distributorId: string
  distributorMetadata: any
  branchId: string | null
  branchMetadata: any
  driverId: string | null
  driverMetadata: any
  tripId: string | null
  note: string
  confirmNote: string | null
  totalQtySend: number
  totalQtyReceive: number | null
  podImages: SummaryProductImages[] | null
  confirmAt: Date | string 
  createdAt: Date | string
  updatedAt: Date | string
  receiverName?: string | null 
  salesOrder?: SalesOrderProperties
  data_products?: DeliveryOrderItemProperties[]
}

export const initialDeliveryOrder: DeliveryOrderProperties = {
  doId: '',
  soId: '',
  orderId: '',
  status: ETripStatus.created,
  distributorId: '',
  distributorMetadata: {},
  branchId: null,
  branchMetadata: {},
  driverId: null,
  driverMetadata: {},
  tripId: null,
  note: '',
  confirmNote: null,
  totalQtySend: 0,
  totalQtyReceive: null,
  podImages: null,
  confirmAt: '',
  createdAt: '',
  updatedAt: '',
  receiverName: null,
  salesOrder: initialSalesOrder,
  data_products: []
}