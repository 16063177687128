import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import COLORS from "../../styles/colors";
import AppTabs from "../../components/Tabs/AppTabs";
import { TripAllItemsCheck } from "../../types/b2b/trip.type";

const headersDO: ITableHeader[] = [
  {
    title: "id",
    column: "doId",
    type: "id",
  },
  {
    title: "DO ID",
    column: "doId",
  },
  {
    title: "SO ID",
    column: "soId",
  },
  {
    title: "DO Created Date",
    column: "createdAtConvert",
  },
  {
    title: "Total Product",
    column: "qtyProduct",
  },
  {
    title: "Total Qty Send",
    column: "totalQtySend",
  },
];

const headersPO: ITableHeader[] = [
  {
    title: "id",
    column: "poId",
    type: "id",
  },
  {
    title: "PO ID",
    column: "poId",
  },
  {
    title: "RO ID",
    column: "roId",
  },
  {
    title: "PO Created Date",
    column: "createdAtConvert",
  },
  {
    title: "Total Product",
    column: "qtyProduct",
  },
  {
    title: "Total Qty Pickup",
    column: "totalQtyPickup",
  },
];

const headersRPLP: ITableHeader[] = [
  {
    title: "id",
    column: "rplpId",
    type: "id",
  },
  {
    title: "Rpl.P ID",
    column: "rplpId",
  },
  {
    title: "Rpl.O ID",
    column: "rploId",
  },
  {
    title: "Rpl.P Created Date",
    column: "createdAtConvert",
  },
  {
    title: "Total Product",
    column: "qtyProduct",
  },
  {
    title: "Total Qty",
    column: "totalQtyPickup",
  },
];

const headersRPLD: ITableHeader[] = [
  {
    title: "id",
    column: "rpldId",
    type: "id",
  },
  {
    title: "Rpl.D ID",
    column: "rpldId",
  },
  {
    title: "Rpl.O ID",
    column: "rploId",
  },
  {
    title: "Rpl.D Created Date",
    column: "createdAtConvert",
  },
  {
    title: "Total Product",
    column: "qtyProduct",
  },
  {
    title: "Total Qty",
    column: "totalQtyDelivery",
  },
];

type Props = {
  edit?: boolean;
  selection?: boolean;
  selected?: string[];
  data: TripAllItemsCheck;
  onSelected?: (id: string, type: number) => void;
  onSelectAll?: (activeTab: number) => void;
  onUnselectAll?: (activeTab: number) => void;
  onPressDetail: (data: { value: any; activeTab: number }) => void;
  type?: 'pickup' | 'trip'
};

export default function ListDO(props: Props) {
  let tabs = [
    "Delivery Order" + " (" + props.data.deliveryOrders.length + ")",
    "Pickup Order" + " (" + props.data.pickupOrders.length + ")",
    "Replacement Pickup" + " (" + props.data.replacementPickups.length + ")",
    "Replacement Delivery" +
    " (" +
    props.data.replacementDeliveries.length +
    ")",
  ];

  let pickupTabs = [
    "Delivery Order" + " (" + props.data.deliveryOrders.length + ")",
    // "Pickup Order" + " (" + props.data.pickupOrders.length + ")",
    // "Replacement Pickup" + " (" + props.data.replacementPickups.length + ")",
    "Replacement Delivery" +
    " (" +
    props.data.replacementDeliveries.length +
    ")",
  ];

  const [page, setPage] = useState(1);
  const [totalData] = useState(145);
  const [totalPage] = useState(15);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      style={{
        borderColor: COLORS.greyLight,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        style={{
          backgroundColor: COLORS.greyLight,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Box width="80%">
          <AppTabs
            active={activeTab}
            tabs={props.type && props.type === 'trip' ? tabs : pickupTabs}
            onChange={(value: number) => setActiveTab(value)}
          />
        </Box>
        {props.selection && props.selected && (
          <Box
            width="20%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            style={{ paddingRight: 20 }}
          >
            <Typography variant="body1" component="span">
              Total Selected{" "}
              <Typography
                variant="body1"
                component="span"
                style={{ fontWeight: "bold" }}
              >
                {props.selected.length}
              </Typography>
            </Typography>
          </Box>
        )}
      </Box>

      <CustomizedTable
        selection={props.selection}
        data={
          activeTab === 0
            ? props.data.deliveryOrders
            : activeTab === 1
              ? props.data.pickupOrders
              : activeTab === 2
                ? props.data.replacementPickups
                : props.data.replacementDeliveries
        }
        headers={
          activeTab === 0
            ? headersDO
            : activeTab === 1
              ? headersPO
              : activeTab === 2
                ? headersRPLP
                : headersRPLD
        }
        totalData={totalData}
        showAction={["detail-dialog"]}
        onPressDetail={(value) => props.onPressDetail({ value, activeTab })}
        page={page}
        totalPage={totalPage}
        rowsPerPage={rowsPerPage}
        onChange={(newPage) => setPage(newPage)}
        selected={props.selected}
        onRowsPerPageChange={(value: any) => setRowsPerPage(value)}
        onSelected={(id: string) => {
          props.onSelected && props.onSelected(id, activeTab);
        }}
        onSelectAll={() => props.onSelectAll && props.onSelectAll(activeTab)}
        onUnselectAll={() =>
          props.onUnselectAll && props.onUnselectAll(activeTab)
        }
        hidePagination
      />
    </div>
  );
}
