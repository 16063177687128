import { Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React from 'react';
import Layout from '../../components/Layout/Layout';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import { Link } from 'react-router-dom';
import useGlobalStyles from '../../styles/globalStyles';
import AppButton from '../../components/Form/AppButton';
import HeaderPage from '../../components/Layout/HeaderPage';
import Search from '../../components/Form/Search';
import AppTabs from '../../components/Tabs/AppTabs';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../hooks/useFetch';
import { ApplicationState } from '../../redux/store';
import { IDeliveryPickupQuery, ETripStatus, DeliveryOrderProperties } from '../../types/b2b/order/delivery-order.type';
import { ReplacementDeliveryProperties } from '../../types/b2b/replacement/replacement-delivery.type';
import { ReplacementPickupProperties } from '../../types/b2b/replacement/replacement-pickup.type';
import { PickupOrderProperties } from '../../types/b2b/return/pickup-order.type';
import { setLatestActiveTab, setLatestState } from '../../redux/action/config-app.action';
import { convertDateTimeToText } from '../../helpers/functions/datetime';
import { PickupProperties, TripProperties } from '../../types/b2b/trip.type';

const headersTrip: ITableHeader[] = [
  {
    title: 'ID',
    column: 'tripId',
    type: 'id'
  },
  {
    title: 'Trip ID',
    column: 'tripId',
  },
  {
    title: 'Created Date',
    column: 'createdAt',
  },
  {
    title: 'Distributor',
    column: 'distributor',
  },
  {
    title: 'Lokasi',
    column: 'branch',
  },
  {
    title: 'Driver',
    column: 'driver',
  },
];

const headersPickup: ITableHeader[] = [
  {
    title: 'ID',
    column: 'pickupId',
    type: 'id'
  },
  {
    title: 'Pickup ID',
    column: 'pickupId',
  },
  {
    title: 'Created Date',
    column: 'createdAt',
  },
  {
    title: 'Distributor',
    column: 'distributor',
  },
  {
    title: 'Lokasi',
    column: 'branch',
  },
  {
    title: 'Pickup PIC',
    column: 'pickupPic',
  },
];

const headersAllTrip: ITableHeader[] = [
  {
    title: 'ID',
    column: 'tripId',
    type: 'id'
  },
  {
    title: 'Trip ID',
    column: 'tripId',
  },
  {
    title: 'Created Date',
    column: 'createdAt',
  },
  {
    title: 'Distributor',
    column: 'distributor',
  },
  {
    title: 'Lokasi',
    column: 'branch',
  },
  {
    title: 'Driver',
    column: 'driver',
  },
  {
    title: 'Status',
    column: 'status',
  },
];

const tabs = [
  'Created',
  'Loading',
  'Loading Selesai',
  'OTW',
  'Ganti Rute',
  'Tiba Di Penerima',
  'Confirmed',
  // 'Cancel',
  'ALL',
];

type Props = {
  type: 'trip' | 'pickup';
};

const initialQuery = {
  limit: 25,
  page: 1,
  status: ETripStatus.created,
  sort: 'createdAt,DESC'
}

const initialActiveTab = 0

export default function Trip(props: Props) {
  const globalClasses = useGlobalStyles();

  let headers: ITableHeader[] = [];
  let headersAll: ITableHeader[] = [];

  let title = 'Trip';
  let subtitle = 'List of all trip groups';

  if (props.type === 'pickup') {
    title = 'Pickup';
    subtitle = 'List of all pickup';
    headers = headersPickup
  } else {
    title = 'Trip';
    subtitle = 'List of all trip groups';
    headers = headersTrip
    headersAll = headersAllTrip
  }

  const dispatch = useDispatch()
  const { state: locationState, activeTab: currentActiveTab } = useSelector((state: ApplicationState) => state.configApp)
  const {
    data,
    loading,
    pagination,
    query,
    search,
    activeTab,
    handlePerPageChange,
    handlePageChange,
    setQuery,
    changeHandler,
    setActiveTab
  } = useFetch<TripProperties & PickupProperties>({
    url: props.type,
    query: initialQuery,
    activeTab: initialActiveTab
  })

  const changeTab = (value: number) => {
    setActiveTab(value);

    let status: ETripStatus | null = null

    if (value === 0) {
      status = ETripStatus.created
    } else if (value === 1) {
      status = ETripStatus.loading
    } else if (value === 2) {
      status = ETripStatus.loading_complete
    } else if (value === 3) {
      status = ETripStatus.otw
    } else if (value === 4) {
      status = ETripStatus.change_route
    } else if (value === 5) {
      status = ETripStatus.arrived
    } else if (value === 6) {
      status = ETripStatus.confirmed
    }
    // else if (value === 7) {
    //   status = ETripStatus.cancel_by_system
    // }

    setQuery((val: IDeliveryPickupQuery) => {
      return {
        ...val,
        offset: 0,
        status: status,
      };
    });
  }

  const dataMapped = data.map(item => {
    return {
      id: props.type === 'trip' ? item.tripId : item.pickupId,
      tripId: item.tripId,
      pickupId: item.pickupId,
      createdAt: item.createdAt ? convertDateTimeToText(item.createdAt, 'dd mmm yyyy') : null,
      distributor: item.distributorMetadata.name,
      branch: item.branchMetadata.name,
      driver: item.driverMetadata ? item.driverMetadata.name : null,
      status: item.status,
      pickupPic: item.receiverName,
    }
  })

  return (
    <Layout title={title} loading={loading}>
      <HeaderPage title={title} subtitle={subtitle} />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={6} lg={6}>
          <Link
            to={{
              pathname:
                props.type === 'pickup' ? 'create-pickup' : 'create-trip',
            }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Create {props.type === 'pickup' ? 'Pickup' : 'Trip Group'}
            </AppButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Search
            placeholder="Search by Trip ID, Distributor, Lokasi and Driver name"
            onChange={changeHandler}
            value={locationState && locationState.search ? locationState.search : search}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          {props.type === 'trip' && (
            <AppTabs
              active={activeTab}
              tabs={tabs}
              onChange={(value: number) => changeTab(value)}
            />
          )}

          {props.type === 'trip' && (
            <CustomizedTable
              data={dataMapped}
              headers={activeTab === 8 ? headersAll : headers}
              totalData={pagination.totalData}
              showAction={['detail']}
              onPressDetail={() => {
                dispatch(setLatestState(query))
                dispatch(setLatestActiveTab(activeTab))
              }}
              page={pagination.page}
              totalPage={pagination.countPage}
              rowsPerPage={pagination.perPage}
              onChange={(newPage: number) => handlePageChange(newPage)}
              onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
            />
          )}

          {props.type === 'pickup' && (
            <CustomizedTable
              data={dataMapped}
              headers={headers}
              totalData={pagination.totalData}
              showAction={['detail']}
              onPressDetail={() => {
                dispatch(setLatestState(query))
              }}
              page={pagination.page}
              totalPage={pagination.countPage}
              rowsPerPage={pagination.perPage}
              onChange={(newPage: number) => handlePageChange(newPage)}
              onRowsPerPageChange={(value: number) => handlePerPageChange(value)}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
}
