import { BranchProperties, initialBranch } from "./b2b/branch/branch.type";
import {
  DistributorProperties,
  initialDistributor,
} from "./b2b/distributor/distributor.type";

export enum CustomerTypes {
  FIND_ALL_CUSTOMER = "FIND_ALL_CUSTOMER",
  FIND_ALL_CUSTOMER_BY_SERVICE_CENTER = "FIND_ALL_CUSTOMER_BY_SERVICE_CENTER",
  CREATE_CUSTOMER_BY_SERVICE_CENTER = "CREATE_CUSTOMER_BY_SERVICE_CENTER",
  FIND_ONE_CUSTOMER = "FIND_ONE_CUSTOMER",
  SET_LOADING_CUSTOMER = "SET_LOADING_CUSTOMER",
  SET_ERROR_CUSTOMER = "SET_ERROR_CUSTOMER",
  SET_LAST_PATH_CUSTOMER = "SET_LAST_PATH_CUSTOMER",
}

export enum EGender {
  MALE = "male",
  FEMALE = "female",
}

export enum EMembershipStatus {
  REGULER = "reguler",
  PREMIUM = "premium",
}

export enum EStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum ESocialMedia {
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  TIKTOK = "tiktok",
  TWITTER = "twitter",
}

export type SocialMedia = {
  channel: ESocialMedia;
  username: string;
};

export enum ECustomerRole {
  customer = "customer",
  warehouse = "warehouse",
  purchaser = "purchaser",
  partner = "partner",
}

export type CustomerAddressProperties = {
  customerAddressId: string;
  customerId: string;
  alias: string;
  name: string;
  phoneNumber: string;
  provinceId: string;
  subdistrictId: string;
  cityId: string;
  postalCode: string;
  address: string;
  createdAt: Date;
  updatedAt: Date;
};

export type CustomerProperties = {
  readonly customerId: string;
  readonly customerName: string;
  readonly customerAgentName: string;
  readonly dateOfBirth?: Date;
  readonly correspondenceEmail?: string;
  readonly socialMedia?: SocialMedia[];
  readonly status: EStatus;
  readonly membershipStatus: EMembershipStatus;
  readonly gender?: string;
  readonly phoneNumber?: string;
  readonly customerType?: string;
  readonly customerRole: string[];
  readonly customerMetadata?: any;
  readonly customerProperties?: string;
  readonly addresses?: CustomerAddressProperties[];
  readonly profilePic?: any;
  readonly mainAddressId?: string;
  readonly distributorId?: string;
  readonly branchId?: string;
  readonly distributor?: DistributorProperties;
  readonly branch?: BranchProperties;
  readonly createdAt?: Date | string;
  readonly updatedAt?: Date | string;
  readonly totalVoucher?: number;
  readonly totalActiveVoucher?: number;
  readonly totalCommission?: number;
  readonly totalDisbursement?: number;
  readonly totalInActiveVoucher?: number;
  readonly bankAccount?: BankAccount;
};

export enum EStatusApproval {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export interface BankAccount {
  customerBankId: string;
  customerId: string;
  bankId: string;
  accountNumber: string;
  accountName: string;
  bankName?: string;
  status: EStatusApproval;
  createdAt: Date;
  updatedAt: Date;
}

export interface BankProperties {
  bankId: string;
  bankName: string;
  vendorMetadata: object;
  updatedAt?: Date;
  createdAt?: Date;
}

export type CustomerResponse = {
  customerId?: string;
  customerName: string;
  dateOfBirth: Date | string;
  correspondenceEmail: string;
  status: string;
  membershipStatus: string;
  gender: string;
  addresses?: any;
  bankAccount?: any;
  phoneNumber: string;
  customerType?: string | null;
  profilePic: string;
  mainAddressId?: string;
  socialMedia?: [];
  transactionSummary?: CustomerTransactionSummary;
  province?: string;
  city?: string;
  upline?: {
    customerId: string;
    customerName: string;
  };
  serviceCenterId: string;
  lastLoginAt?: Date;
};

export type CustomerResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: CustomerResponse[];
  };
};

export type CustomerState = {
  isLoadingCustomer: boolean;
  data: CustomerResponsePayload;
  customer: CustomerResponse;
  error: any;
  lastPathCustomer: string;
};

export type CustomerTransactionSummary = {
  countTransaction: string;
  externalId: string;
  totalFinalTransactionPrice: string;
  totalTransactionPrice: string;
};

export type SummaryRequest = {
  startOfDay: string;
  endOfDay: string;
  startOfMonth: string;
  endOfMonth: string;
};

export type CustomerBankProperties = {
  customerBankId: string;
  customerId: string;
  bankId: string;
  accountNumber: string;
  accountName: string;
  bankName?: string;
  status: EStatusApproval;
  createdAt: Date;
  updatedAt: Date;
};

export const initialCustomerBank: CustomerBankProperties = {
  customerBankId: "",
  customerId: "",
  bankId: "",
  accountNumber: "",
  accountName: "",
  bankName: "",
  status: EStatusApproval.APPROVED,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export const initialCustomer: CustomerProperties = {
  customerId: "",
  customerName: "",
  customerAgentName: "",
  correspondenceEmail: "",
  socialMedia: [],
  status: EStatus.ACTIVE,
  membershipStatus: EMembershipStatus.REGULER,
  gender: EGender.MALE,
  phoneNumber: "",
  customerType: "",
  customerRole: [ECustomerRole.customer],
  profilePic: "",
  mainAddressId: "",
  distributorId: "",
  branchId: "",
  addresses: [],
  bankAccount: initialCustomerBank,
  distributor: initialDistributor,
  branch: initialBranch,
  createdAt: new Date(),
  updatedAt: new Date(),
};
