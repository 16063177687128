import { Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import useFetch from '../../hooks/useFetch';
import { DistributorProperties } from '../../types/b2b/distributor/distributor.type';
import { setLatestState } from '../../redux/action/config-app.action';

const headers: ITableHeader[] = [
  {
    title: "id",
    column: "id",
    type: "id",
  },
  {
    title: "Distributor Name",
    column: "name",
  },
  {
    title: "Address",
    column: "address",
  },
  {
    title: "Phone",
    column: "phone",
  },
  {
    title: "Branch",
    column: "branch",
  },
];

const initialQuery = {
  limit: 25,
  page: 1,
  sort: 'name,ASC'
}

export default function Distributor() {
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch()
  const { state: locationState } = useSelector((state: ApplicationState) => state.configApp)
  const {
    data,
    loading,
    pagination,
    query,
    search,
    handlePerPageChange,
    handlePageChange,
    changeHandler,
  } = useFetch<DistributorProperties>({
    url: 'distributor',
    query: initialQuery,
  })

  const dataDistributors = data.map((item) => {
    return {
      id: item.distributorId,
      name: item.name,
      address: item.address,
      phone: item.phoneNumber,
      branch: item.branches ? item.branches.length : 0,
    }
  })

  return (
    <Layout title="Distributor" loading={loading}>
      <HeaderPage title="Distributor" subtitle="List of all distributor" />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={6} lg={3}>
          {/* <Link
            to={{ pathname: "create-distributor" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Create
            </AppButton>
          </Link> */}
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by distributor name"
            onChange={changeHandler}
            value={locationState && locationState.search ? locationState.search : search}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>

        <Grid item xs={12} md={12} lg={12}>
          <CustomizedTable
            data={dataDistributors}
            headers={headers}
            totalData={pagination.totalData}
            showAction={["detail"]}
            onPressDetail={() => {
              dispatch(setLatestState(query))
            }}
            page={pagination.page}
            totalPage={pagination.countPage}
            rowsPerPage={pagination.perPage}
            onChange={(newPage) => handlePageChange(newPage)}
            onRowsPerPageChange={(value: any) => handlePerPageChange(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}
