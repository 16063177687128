import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import useBanner from '../../hooks/useBanner';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import { BannerChangeOrderRequest } from '../../types/banner.type';
import CustomizedDnDTable, { ITableHeaderDnd } from '../../components/Table/CustomizedDnDTable';
import CustomizedTable, { ITableHeader } from '../../components/Table/CustomizedTable';

const headers: ITableHeader[] = [
  {
    title: "bannerId",
    column: "bannerId",
    type: "id",
  },
  // {
  //   title: "Order Handle",
  //   column: "order",
  //   type: "order-handle"
  // },
  // {
  //   title: "Order",
  //   column: "order",
  // },
  {
    title: "Image",
    sortable: true,
    column: "imageLink",
    imageSize: "medium",
    imageResolution: '1200x840',
    type: "image",
    width: "15%",
  },
  {
    title: "Action Type",
    sortable: true,
    column: "actionType",
    type: 'escape-string'
  },
  {
    title: "Status",
    sortable: true,
    column: "status",
    type: 'status'
  },
  {
    title: "Created At",
    sortable: true,
    column: "createdAt",
    type: 'date'
  },
  {
    title: "Created By",
    column: JSON.stringify(['adminMetadata', 'adminName']),
    type: 'nested'
  },
];

export default function Banner() {
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch()

  const bannerState = useSelector((state: ApplicationState) => state.banner)

  const [filterStatus, setFilterStatus] = useState('all');
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 100, offset: 0, search: '' });

  const { fetchAllBanner, removeBanner, changeOrderBanner } = useBanner()

  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  useEffect(() => {
    if (updateParams) {
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          page
        }
      }))
      fetchAllBanner(query)
    } else {
      setPage(latestParams.query.page || page || 1)
      fetchAllBanner(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
  }, [query])

  useEffect(() => {
    setTotalData(bannerState.data.payload.count)
    if (bannerState.data.payload.count > 0) {
      setTotalPage(Math.ceil(bannerState.data.payload.count / rowsPerPage))
    } else {
      setTotalPage(1)
    }
  }, [bannerState.data])

  function changePagination(value: number) {
    setPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      }
    })
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      }
    })
  }

  function setFilter(value: string) {
    setFilterStatus(value);
    setQuery((val: any) => {
      return {
        ...val,
        status: value !== 'all' ? value : null,
      }
    })
  }

  return (
    <Layout title="Banner" loading={bannerState.isLoadingBanner}>
      <HeaderPage title="Banner" subtitle="List of all banner" />

      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} md={6} lg={7}>
          <Link
            to={{ pathname: "create-banner" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Banner
            </AppButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="select-status" required shrink>
              Status
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="unpublished">Unpublished</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by banner name"
            onChange={(value: string) => console.info("value", value)}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          {/* <CustomizedDnDTable */}
          <CustomizedTable
            data={bannerState.data.payload.results}
            headers={headers}
            totalData={totalData}
            showAction={["detail", "edit", "delete"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            // hidePagination={true}
            onDeleteItem={(data: any) => {
              removeBanner(data.bannerId)
            }}
            // onChangeOrder={(data: BannerChangeOrderRequest) => changeOrderBanner(data, query)}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}
