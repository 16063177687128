import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import COLORS from '../../../styles/colors';

type Props = {
  loading: boolean;
  title: string;
  value: string;
  topRightIconComponent: any;
  bottomRightComponent?: any;
};

export default function SimpleCard2(props: Props) {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography variant="body1" color="textSecondary">
            {props.title}
          </Typography>
          {
            props.loading ? <CircularProgress color="primary" /> :
              <Typography variant="body1" style={{ fontSize: 36 }}>
                {props.value}
              </Typography>
          }
        </Box>
        <Box width={50} display="flex" flexDirection="row">
          <Box
            className={classes.containerTopRightIcon}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {props.topRightIconComponent}
          </Box>
        </Box>
      </Box>

      {props.bottomRightComponent ? (
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {props.bottomRightComponent}
        </Box>
      ) : (
          false
        )}
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerIconSuccess: {
      backgroundColor: COLORS.successLight,
      color: COLORS.success,
      fontWeight: 'bold',
      textAlign: 'center',
      padding: 2,
      borderRadius: 10,
    },
    containerIconError: {
      backgroundColor: COLORS.errorLight,
      color: COLORS.error,
      fontWeight: 'bold',
      textAlign: 'center',
      padding: 2,
      borderRadius: 10,
    },
    containerTopRightIcon: {
      width: 40,
      height: 40,
      backgroundColor: COLORS.greyLighten,
      borderRadius: 50,
    },
  })
);
