import {
  ProductResponsePayload,
  ProductTypes,
  ProductResponse,
  HistoryProductPriceResponsePayload,
} from "../../types/product.type";

export function findAllProduct(payload: ProductResponsePayload) {
  return {
    type: ProductTypes.FIND_ALL_PRODUCT,
    payload,
  };
}

export function findHistoryProductPrices(
  payload: HistoryProductPriceResponsePayload
) {
  return {
    type: ProductTypes.FIND_HISTORY_PRODUCT_PRICES,
    payload,
  };
}

export function findOneProduct(payload: ProductResponse) {
  return {
    type: ProductTypes.FIND_ONE_PRODUCT,
    payload,
  };
}

export function setLoadingProduct(payload: boolean) {
  return {
    type: ProductTypes.SET_LOADING_PRODUCT,
    payload,
  };
}

export function setErrorProduct(payload: any) {
  return {
    type: ProductTypes.SET_ERROR_PRODUCT,
    payload,
  };
}

export function addProductImage(payload: any) {
  return {
    type: ProductTypes.ADD_PRODUCT_IMAGE,
    payload,
  };
}

export function removeProductImage(payload: any) {
  return {
    type: ProductTypes.REMOVE_PRODUCT_IMAGE,
    payload,
  };
}

export function setLoadingProductImageAdd(payload: boolean) {
  return {
    type: ProductTypes.SET_LOADING_PRODUCT_IMAGE_ADD,
    payload,
  };
}

export function setLoadingProductImageRemove(payload: boolean) {
  return {
    type: ProductTypes.SET_LOADING_PRODUCT_IMAGE_REMOVE,
    payload,
  };
}

export function setLastPathProduct(payload: string) {
  return {
    type: ProductTypes.SET_LAST_PATH_PRODUCT,
    payload,
  };
}
