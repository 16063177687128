import { Redirect, Route, RouteProps } from 'react-router';
import useAuth from '../hooks/useAuth';

export interface IProps {
  isAuthenticated: boolean;
  exact?: boolean;
  path: string;
  component?: React.ComponentType<any>;
  restricted?: boolean;
};

const PublicRoute: React.FC<IProps> = ({ children, restricted, isAuthenticated, ...rest }) => {

  let auth = useAuth();

  return (
    <Route {...rest} render={() => (auth.userSignIn && restricted ?
      <Redirect to="/dashboard-b2c" /> : children)
    } />)
};

export default PublicRoute;