import {
  PickupPointResponsePayload,
  PickupPointTypes,
} from "../../types/pickup-point";

export function findAllPickupPoint(payload: PickupPointResponsePayload) {
  return {
    type: PickupPointTypes.FIND_ALL_PICKUP_POINT,
    payload,
  };
}

export function findAllPickupPointByAro(payload: PickupPointResponsePayload) {
  return {
    type: PickupPointTypes.FIND_ALL_PICKUP_POINT_BY_ARO,
    payload,
  };
}

export function findOnePickupPoint(payload: any) {
  return {
    type: PickupPointTypes.FIND_ONE_PICKUP_POINT,
    payload,
  };
}

export function createPickupPoint(payload: any) {
  return {
    type: PickupPointTypes.CREATE_PICKUP_POINT,
    payload,
  };
}

export function updatePickupPoint(payload: any) {
  return {
    type: PickupPointTypes.UPDATE_PICKUP_POINT,
    payload,
  };
}

export function deletePickupPoint(payload: any) {
  return {
    type: PickupPointTypes.REMOVE_PICKUP_POINT,
    payload,
  };
}

export function setLoadingPickupPoint(payload: boolean) {
  return {
    type: PickupPointTypes.SET_LOADING_PICKUP_POINT,
    payload,
  };
}

export function setErrorPickupPoint(payload: any) {
  return {
    type: PickupPointTypes.SET_ERROR_PICKUP_POINT,
    payload,
  };
}
