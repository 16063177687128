import {
  ExtraWarrantyResponse,
  ExtraWarrantyTypes,
  LoyaltyHistoryResponse,
  LoyaltyResponse,
  LoyaltyTypes,
  ReferralComissionTypes,
  ReferralCommissionResponse,
  ReferralCommissionResponsePayload,
  ReferralInformationTypes,
  ShipmentDiscountResponse,
  ShipmentDiscountResponsePayload,
  ShipmentDiscountTypes,
} from "../../types/master-data-referral.type";

export function findLogReferralCommission(
  payload: ReferralCommissionResponsePayload
) {
  return {
    type: ReferralComissionTypes.FIND_LOG_REFERRAL_COMMISSION,
    payload,
  };
}

export function currentReferralCommission(payload: ReferralCommissionResponse) {
  return {
    type: ReferralComissionTypes.CURRENT_REFERRAL_COMMISSION,
    payload,
  };
}

export function createReferralCommission(payload: ReferralCommissionResponse) {
  return {
    type: ReferralComissionTypes.CREATE_REFERRAL_COMMISSION,
    payload,
  };
}

export function setLoadingReferralCommission(payload: boolean) {
  return {
    type: ReferralComissionTypes.SET_LOADING_REFERRAL_COMMISSION,
    payload,
  };
}

export function setLoadingRetailCommission(payload: boolean) {
  return {
    type: ReferralComissionTypes.SET_LOADING_RETAIL_COMMISSION,
    payload,
  };
}

export function setLoadingSPGCommission(payload: boolean) {
  return {
    type: ReferralComissionTypes.SET_LOADING_SPG_COMMISSION,
    payload,
  };
}

export function setLoadingMemberPremium(payload: boolean) {
  return {
    type: ReferralComissionTypes.SET_LOADING_MEMBER_COMMISSION,
    payload,
  };
}

export function currentReferralInformation(payload: any) {
  return {
    type: ReferralInformationTypes.CURRENT_INFORMATION,
    payload,
  };
}

export function createReferralInformation(payload: any) {
  return {
    type: ReferralInformationTypes.CREATE_INFORMATION,
    payload,
  };
}

export function setLoadingReferralInformation(payload: boolean) {
  return {
    type: ReferralInformationTypes.SET_LOADING_INFORMATION,
    payload,
  };
}

export function findLogShipmentDiscount(
  payload: ShipmentDiscountResponsePayload
) {
  return {
    type: ShipmentDiscountTypes.FIND_LOG_SHIPMENT_DISCOUNT,
    payload,
  };
}

export function currentShipmentDiscount(payload: { amount: number }) {
  return {
    type: ShipmentDiscountTypes.CURRENT_SHIPMENT_DISCOUNT,
    payload,
  };
}

export function createShipmentDiscount(payload: ShipmentDiscountResponse) {
  return {
    type: ShipmentDiscountTypes.CREATE_SHIPMENT_DISCOUNT,
    payload,
  };
}

export function setLoadingShipmentDiscount(payload: boolean) {
  return {
    type: ShipmentDiscountTypes.SET_LOADING_SHIPMENT_DISCOUNT,
    payload,
  };
}

export function findLogExtraWarranty(payload: ExtraWarrantyResponse) {
  return {
    type: ExtraWarrantyTypes.FIND_LOG_EXTRA_WARRANTY,
    payload,
  };
}

export function currentExtraWarranty(payload: { year: string }) {
  return {
    type: ExtraWarrantyTypes.CURRENT_EXTRA_WARRANTY,
    payload,
  };
}

export function createExtraWarranty(payload: ExtraWarrantyResponse) {
  return {
    type: ExtraWarrantyTypes.CREATE_EXTRA_WARRANTY,
    payload,
  };
}

export function setLoadingExtraWarranty(payload: boolean) {
  return {
    type: ExtraWarrantyTypes.SET_LOADING_EXTRA_WARRANTY,
    payload,
  };
}

export function findLoyalty(payload: LoyaltyResponse) {
  return {
    type: LoyaltyTypes.FIND_LOG_LOYALTY,
    payload,
  };
}

export function findOneLoyalty(payload: LoyaltyResponse) {
  return {
    type: LoyaltyTypes.FIND_ONE_LOYALTY,
    payload,
  };
}

export function findLogLoyalty(payload: LoyaltyHistoryResponse) {
  return {
    type: LoyaltyTypes.FIND_LOG_LOYALTY_HISTORY,
    payload,
  };
}

export function findUpdateLoyalty(payload: LoyaltyResponse) {
  return {
    type: LoyaltyTypes.FIND_UPDATE_LOYALTY,
    payload,
  };
}

export function setLoadingLoyalty(payload: boolean) {
  return {
    type: LoyaltyTypes.SET_LOADING_LOYALTY,
    payload,
  };
}
