/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLatestParams } from '../redux/action/config-app.action';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../redux/store';

export interface IPaginateTable {
  fetchList: (incomingParams?: any) => void;
  saveData?: (newData: any[], count: number, page: number) => void;
  onChangePage?: (newPage: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  loading: boolean;
  params: any;
  data: any[];
  page: number;
  totalData: number;
  totalPage: number;
  rowsPerPage: number;
}

export default function usePaginateTable(
  apiFunction: Function,
  initialParams: any,
  initialRowsPerPage: number = 25,
  specialParams?: any,
  useLatestParams: boolean = true,
  fetchable: boolean = true
) {
  const dispatch = useDispatch()
  const { latestParams } = useSelector((state: ApplicationState) => state.configApp)

  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState(initialParams);

  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalData, setTotalData] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  useEffect(() => {
    if(fetchable===false) return ;
    console.info(initialParams, initialRowsPerPage, specialParams, useLatestParams)
    if (useLatestParams && latestParams && latestParams.query) {
      setPage(latestParams.query.page || page)
      fetchList({ ...latestParams.query });
    } else {
      fetchList();
    }
  }, []);

  const fetchList = (incomingParams: any = {}, isReset: boolean = false) => {
    setLoading(true);
    let newParams: any = {};
    if (isReset) {
      newParams = {
        ...incomingParams,
      };
    } else {
      newParams = {
        ...params,
        ...incomingParams,
      };
    }

    console.info('fetchList', newParams, page, rowsPerPage);
    setParams(newParams);
    apiFunction(generateQueryString(newParams), specialParams)
      .then((res: any) => {
        setLoading(false);
        setData(res.data.payload.results);
        setTotalData(res.data.payload.count);
        setTotalPage(Math.ceil(res.data.payload.count / rowsPerPage));
        // setPage(page);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error('error', err);
      });
  };

  const saveData = (newData: any[], count: number, page: number) => {
    setData(newData);
    setTotalData(count);
    setTotalPage(Math.ceil(count / rowsPerPage));
    setPage(page);
  };

  const onChangePage = (newPage: number) => {
    setPage(newPage);
    dispatch(setLatestParams({
      pathname: '',
      params: '',
      query: {
        ...params,
        offset: (newPage - 1) * params.limit,
        page: newPage
      }
    }))
    fetchList({ offset: (newPage - 1) * params.limit });
  };

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  return {
    fetchList,
    saveData,
    onChangePage,
    onChangeRowsPerPage,
    loading,
    params,
    data,
    page,
    totalData,
    totalPage,
    rowsPerPage,
  };
}
