import React from "react";
import GoogleMapReact from "google-map-react";
import MapMarker from "./MapMarker";

// const API_KEY = "AIzaSyD0TxIXTkhshZIIOkvm3h9RW9a-sCs8lXM";
const API_KEY = "AIzaSyB6jDerrlPOFgp7PVrBdo0li34o4GtsWtc";

export type Marker = {
  lat: number;
  lng: number;
  content: any;
};

type Coords = { lat: number, lng: number }

type Props = {
  markers: Marker[];
  handleOnClick?: (params: Coords) => void;
  centerMap?: Coords,
  height?: any
};

const AppMap = (props: Props) => {
  const [center, setCenter] = React.useState<Coords>({ lat: -6.208753, lng: 106.846175 })
  const [draggable, setDraggable] = React.useState<boolean>(true)

  const onMouseDown = () => {
    setDraggable(false)
  }

  const onMouseMove = (_: any, __: any, mouse: Coords) => {
    if (props.handleOnClick) {
      props.handleOnClick(mouse)
    }
  }

  const onMouseUp = (_: any, __: any, mouse: Coords) => {
    setCenter(mouse)
    if (props.handleOnClick) {
      props.handleOnClick(mouse)
    }
    setDraggable(true)
  }

  React.useEffect(() => {
    if (props.centerMap) {
      setCenter(props.centerMap)
    }
  }, [props.centerMap])

  return (
    <div style={{ height: props.height || "90vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={center}
        center={center}
        defaultZoom={13}
        yesIWantToUseGoogleMapApiInternals
        draggable={draggable}
        onChildMouseDown={props.handleOnClick && onMouseDown}
        onChildMouseMove={props.handleOnClick && onMouseMove}
        onChildMouseUp={props.handleOnClick && onMouseUp}
        onClick={(event) => props.handleOnClick && props.handleOnClick({ lat: event.lat, lng: event.lng })}
      >
        {props.markers.length > 0 && props.markers.map((item, key) => (
          <MapMarker
            key={key}
            lat={item.lat}
            lng={item.lng}
            content={item.content}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default AppMap;
