import { ServiceCenterTransactionTypes } from "../../types/service-center-transaction.types";

export function findAllServiceCenterTransaction(payload: any) {
  return {
    type: ServiceCenterTransactionTypes.FIND_ALL_SERVICE_CENTER_TRANSACTION,
    payload,
  };
}

export function findOneServiceCenterTransaction(payload: any) {
  return {
    type: ServiceCenterTransactionTypes.FIND_ONE_SERVICE_CENTER_TRANSACTION,
    payload,
  };
}

export function updateServiceCenterTransaction(payload: any) {
  return {
    type: ServiceCenterTransactionTypes.UPDATE_SERVICE_CENTER_TRANSACTION,
    payload,
  };
}

export function setLoadingServiceCenterTransaction(payload: boolean) {
  return {
    type: ServiceCenterTransactionTypes.SET_LOADING_SERVICE_CENTER_TRANSACTION,
    payload,
  };
}

export function setErrorServiceCenterTransaction(payload: any) {
  return {
    type: ServiceCenterTransactionTypes.SET_ERROR_SERVICE_CENTER_TRANSACTION,
    payload,
  };
}

export function setLastQueryServiceCenterTransaction(payload: any) {
  return {
    type: ServiceCenterTransactionTypes.SET_LAST_QUERY_SERVICE_CENTER_TRANSACTION,
    payload,
  };
}
