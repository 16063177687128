import { Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import usePromotion from '../../hooks/usePromotion';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import useNews from '../../hooks/useNews';
import { findOnePromotion } from '../../redux/action/content/promotion.action';
import { findOneNews } from '../../redux/action/content/news.action';
import useDebounce from '../../hooks/useDebounce';
import { NewsResponse } from '../../types/news.type';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { useLocation } from 'react-router-dom';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';

const headers: ITableHeader[] = [
  {
    title: "newsId",
    column: "newsId",
    type: "id",
  },
  {
    title: "Image",
    sortable: true,
    column: "imageLink",
    imageSize: "medium",
    imageResolution: '1200x600',
    type: "image",
    width: "15%",
  },
  {
    title: "Title",
    sortable: true,
    column: "title",
    width: "40%",
  },
  {
    title: "Tags",
    type: "tags",
    sortable: true,
    column: "tags",
    width: "15%",
  },
  {
    title: "Status",
    type: 'status',
    sortable: true,
    column: "status",
  },
  {
    title: "Youtube Link",
    sortable: true,
    column: "youtubeLink",
    width: "8%",
    type: "checkmark",
    alignContent: "center"
  },
  {
    title: "Created At",
    sortable: true,
    column: "createdAt",
    width: "8%",
    type: 'date'
  },
];

type Props = {
  type: "promotion" | "news-event";
};

export default function Content(props: Props) {
  // const navigation = useNavigation()
  const globalClasses = useGlobalStyles();

  const promotionState = useSelector((state: ApplicationState) => state.promotion)
  const newsState = useSelector((state: ApplicationState) => state.news)

  const dispatch = useDispatch()

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [keyword, setKeyword] = useState('');
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '' });
  const [search, setSearch] = useState<string | null>(null);

  const { fetchAllPromotion, removePromotion, fetchOnePromotion } = usePromotion()
  const { fetchAllNews, removeNews } = useNews()

  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  const debouncedTitle = useDebounce(search, 300);

  useEffect(() => {
    searchBank(search)
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          limit: 25,
          offset: 0,
          search: value
        }
      })
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: '' })
    }
  }

  useEffect(() => {
    if (updateParams) {
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          page
        }
      }))
      if (props.type === 'promotion') {
        fetchAllPromotion(query)
      } else if (props.type === 'news-event') {
        fetchAllNews(query)
      }
    } else {
      setPage(latestParams.query.page || page || 1)
      if (props.type === 'promotion') {
        fetchAllPromotion(query)
      } else if (props.type === 'news-event') {
        fetchAllNews(query)
      }
      dispatch(enableUpdateParams(true))
    }
  }, [query])

  // useEffect(() => {
  //   if (props.type === 'promotion') {
  //     fetchAllPromotion(query)
  //   } else if (props.type === 'news-event') {
  //     fetchAllNews(query)
  //   }
  // }, [query, props.type])

  useEffect(() => {
    setTotalData(promotionState.data.payload.count)
    if (promotionState.data.payload.count > 0) {
      setTotalPage(Math.ceil(promotionState.data.payload.count / rowsPerPage))
    } else {
      setTotalPage(1)
    }
  }, [promotionState.data])

  useEffect(() => {
    if (newsState.data.payload.count > 0) {
      setTotalData(newsState.data.payload.count)
      setTotalPage(Math.ceil(newsState.data.payload.count / rowsPerPage))
    }
  }, [newsState.data])

  function changePagination(value: number) {
    setPage(value)
    if (props.type === 'promotion') {
      fetchAllPromotion(`?offset=${(value - 1) * rowsPerPage}&limit=${rowsPerPage}`)
    } else if (props.type === 'news-event') {
      fetchAllNews(`?offset=${(value - 1) * rowsPerPage}&limit=${rowsPerPage}`)
    }
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    if (props.type === 'promotion') {
      fetchAllPromotion(`?limit=${value}`)
    } else if (props.type === 'news-event') {
      fetchAllNews(`?limit=${value}`)
    }
  }

  return (
    <Layout title="Promotion" loading={newsState.isLoadingNews || promotionState.isLoadingPromotion}>
      <HeaderPage
        title={
          props.type === "promotion"
            ? "Promotion"
            : props.type === "news-event"
              ? "News & Event"
              : "Content"
        }
        subtitle={
          "List of all " +
          (props.type === "promotion"
            ? "promotion"
            : props.type === "news-event"
              ? "news & event"
              : "content")
        }
      />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={7}>
          <Link
            to={{ pathname: props.type === 'promotion' ? 'create-promotion' : props.type === 'news-event' ? 'create-news-event' : 'create-promotion' }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add{" "}
              {props.type === "promotion"
                ? "Promotion"
                : props.type === "news-event"
                  ? "News & Event"
                  : "Content"}
            </AppButton>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by title"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={
              props.type === 'promotion' ? promotionState.data.payload.results :
                props.type === 'news-event' ? newsState.data.payload.results :
                  newsState.data.payload.results
            }
            headers={headers}
            totalData={totalData}
            showAction={["detail", "edit", "delete"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onDeleteItem={(item: any) => {
              if (item.newsType === 'promotion') {
                removePromotion(item.newsId)
              }
              if (item.newsType === 'news') {
                removeNews(item.newsId)
              }
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}
