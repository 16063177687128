import {
  Grid,
  Typography,
  Modal,
  Box,
  makeStyles,
  createStyles,
  Theme,
  Link,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import { ApplicationState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import useFeedback from "../../hooks/useFeedback";
import { findOneFeedback } from "../../redux/action/feedback.action";
import useDebounce from "../../hooks/useDebounce";
import { FeedbackResponse } from "../../types/feedback.types";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";

const headers: ITableHeader[] = [
  {
    title: "Name",
    column: JSON.stringify(["userMetaData", "customerName"]),
    type: "nested",
  },
  {
    title: "Feedback",
    column: "feedback",
    width: "20%",
  },
  {
    title: "Email",
    column: JSON.stringify(["userMetaData", "email"]),
    type: "nested",
  },
  {
    title: "Phone",
    column: JSON.stringify(["userMetaData", "phoneNumber"]),
    type: "nested",
  },
  {
    title: "Status",
    column: "status",
    width: "15%",
  },
  {
    title: "Created At",
    sortable: true,
    column: "createdAt",
    type: "date",
  },
];

let data = [];

export default function Feedback() {
  const classes = useStyles();

  const feedbackState = useSelector(
    (state: ApplicationState) => state.feedback
  );
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({
    limit: 25,
    offset: 0,
    search: "",
    status: "all",
  });
  const [filterStatus, setFilterStatus] = useState("all");
  const [search, setSearch] = useState<string | null>(null);

  const { fetchAllFeedback, updateFeedback } = useFeedback();

  const location = useLocation();

  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const debouncedTitle = useDebounce(search, 300);

  useEffect(() => {
    searchCustomerName(search);
  }, [debouncedTitle]);

  const searchCustomerName = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value,
        };
      });
      setPage(1);
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: "", status: "all" });
    }
  };

  useEffect(() => {
    if (updateParams) {
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllFeedback(query);
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchAllFeedback(latestParams.query);
      dispatch(enableUpdateParams(true));
    }
  }, [query]);

  useEffect(() => {
    setTotalData(feedbackState.data.payload.count);
    if (feedbackState.data.payload.count > 0) {
      setTotalPage(Math.ceil(feedbackState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1);
    }
  }, [feedbackState.data.payload]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  function setFilter(value: string) {
    setFilterStatus(value);
    setQuery((val: any) => {
      return {
        ...val,
        status: value,
      };
    });
  }

  const [isShowModalDetail, setIsShowModalDetail] = useState(false);

  const handleCloseModalDetail = () => {
    //
    setIsShowModalDetail(false);
  };
  const handleOpenModalDetail = (item: any) => {
    //
    dispatch(findOneFeedback(item));
    setIsShowModalDetail(true);
  };

  function changeStatusFeedback(status: string, feedbackId: string) {
    updateFeedback({ status, feedbackId });
  }

  data = feedbackState.data.payload.results.map((item: FeedbackResponse) => {
    return {
      ...item,
      status: (
        <>
          <FormControl className={classes.formStatus}>
            <Select
              required
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={item.status}
              onChange={(event: any) => {
                changeStatusFeedback(event.target.value, item.feedbackId);
              }}
            >
              <MenuItem value="waiting">Waiting for response</MenuItem>
              <MenuItem value="responded">Responded</MenuItem>
            </Select>
          </FormControl>
        </>
      ),
    };
  });

  return (
    <Layout title="Feedback" loading={feedbackState.isLoadingFeedback}>
      <HeaderPage title="Feedback" subtitle="List of all feedback" />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={6} lg={7} />
        <Grid item xs={12} md={2} lg={2}>
          <FormControl className={classes.formStatus}>
            <InputLabel id="select-status" required>
              Status
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="waiting">Waiting for response</MenuItem>
              <MenuItem value="responded">Responded</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by customer name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            showAction={["detail-dialog"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            onPressDetail={(data: any) => handleOpenModalDetail(data)}
          />
        </Grid>
      </Grid>

      <Modal
        open={isShowModalDetail}
        onClose={handleCloseModalDetail}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Detail Feedback</Typography>

              <button onClick={() => handleCloseModalDetail()}>
                <Close />
              </button>
            </Box>

            <Box display="flex" flexWrap="wrap" flexDirection="row">
              <Box width="50%" className={classes.rowDetail}>
                <Typography color="textSecondary">Name</Typography>
                <Typography>
                  {feedbackState.feedback.userMetaData.customerName}
                </Typography>
              </Box>

              <Box width="50%" className={classes.rowDetail}>
                <Typography color="textSecondary">Phone</Typography>
                <Typography>
                  {feedbackState.feedback.userMetaData.phoneNumber}
                </Typography>
              </Box>

              <Box width="50%" className={classes.rowDetail}>
                <Typography color="textSecondary">User Role</Typography>
                <Typography>
                  {feedbackState.feedback.userMetaData.role}
                </Typography>
              </Box>

              <Box width="50%" className={classes.rowDetail}>
                <Typography color="textSecondary">Email</Typography>
                <Typography>
                  {feedbackState.feedback.userMetaData.email}
                </Typography>
              </Box>

              {/* <Box width="50%" className={classes.rowDetail}>
                <Typography color="textSecondary">Distributor</Typography>
                <Typography>PT XYZABC</Typography>
              </Box>

              <Box width="50%" className={classes.rowDetail}>
                <Typography color="textSecondary">Lokasi</Typography>
                <Typography>Nusa Indah 1</Typography>
              </Box> */}

              <Box width="100%" className={classes.rowDetail}>
                <Typography color="textSecondary">Feedback</Typography>
                <Typography>{feedbackState.feedback.feedback}</Typography>
              </Box>
            </Box>

            <hr />

            <Typography>Device Information</Typography>
            {feedbackState.feedback.deviceInformation && (
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="row"
                className={classes.rowDetail}
              >
                <Box width="33%">
                  <Typography color="textSecondary">Device</Typography>
                  <Typography>
                    {feedbackState.feedback.deviceInformation.brand}
                  </Typography>
                </Box>
                <Box width="33%">
                  <Typography color="textSecondary">App Version</Typography>
                  <Typography>
                    {feedbackState.feedback.deviceInformation.appVersion}
                  </Typography>
                </Box>
                <Box width="33%">
                  <Typography color="textSecondary">OS Version</Typography>
                  <Typography>
                    {feedbackState.feedback.deviceInformation.systemName}{" "}
                    {feedbackState.feedback.deviceInformation.deviceApi}{" "}
                    {feedbackState.feedback.deviceInformation.systemVersion}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton color="secondary" onClick={handleCloseModalDetail}>
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton>Add Branch</AppButton>
              </Box>
            </Box> */}
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
      marginBottom: 10,
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalDetailgnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
    rowDetail: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);
