import { Box, Typography } from '@material-ui/core';
import React from 'react'
import COLORS from '../../../styles/colors';

interface Props {
  totalOrder: number
  totalApproved: number | null
  totalReceive: number | null
  totalLeftover: number | null
}

const InfoProductSO: React.FC<Props> = (props) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Order
          </Typography>
        <Typography><b>{props.totalOrder}</b></Typography>
      </Box>
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Approved
          </Typography>
        <Typography
          style={!props.totalApproved
            ? { color: COLORS.grey, fontStyle: 'italic' }
            : { color: COLORS.primary }}
        >
          <b>{!props.totalApproved ? 'Not set' : props.totalApproved}</b>
        </Typography>
      </Box>
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Receive
          </Typography>
        <Typography
          style={!props.totalReceive
            ? { color: COLORS.grey, fontStyle: 'italic' }
            : { color: COLORS.primary }}
        >
          <b>{!props.totalReceive ? 'Not set' : props.totalReceive}</b>
        </Typography>
      </Box>
      <Box width="25%" display="flex" flexDirection="row">
        <Typography color="textSecondary" style={{ marginRight: 20 }}>
          Total Sisa
          </Typography>
        <Typography
          style={!props.totalLeftover && props.totalLeftover !== 0
            ? { color: COLORS.grey, fontStyle: 'italic' }
            : props.totalLeftover < props.totalOrder && props.totalLeftover !== 0
              ? { color: COLORS.error }
              : { color: COLORS.primary }}
        ><b>{!props.totalLeftover && props.totalLeftover !== 0 ? 'Not set' : props.totalLeftover}</b></Typography>
      </Box>
    </Box>
  )
}

export default InfoProductSO