export enum ReferralComissionTypes {
  FIND_LOG_REFERRAL_COMMISSION = "FIND_LOG_REFERRAL_COMMISSION",
  CURRENT_REFERRAL_COMMISSION = "CURRENT_REFERRAL_COMMISSION",
  CREATE_REFERRAL_COMMISSION = "CREATE_REFERRAL_COMMISSION",
  SET_LOADING_REFERRAL_COMMISSION = "SET_LOADING_REFERRAL_COMMISSION",
  SET_LOADING_RETAIL_COMMISSION = "SET_LOADING_RETAIL_COMMISSION",
  SET_LOADING_SPG_COMMISSION = "SET_LOADING_SPG_COMMISSION",
  SET_LOADING_MEMBER_COMMISSION = "SET_LOADING_MEMBER_COMMISSION",
  SET_ERROR_REFERRAL_COMMISSION = "SET_ERROR_REFERRAL_COMMISSION",
  SET_LAST_PATH_REFERRAL_COMMISSION = "SET_LAST_PATH_REFERRAL_COMMISSION",
}

export enum ReferralInformationTypes {
  CURRENT_INFORMATION = "CURRENT_INFORMATION",
  CREATE_INFORMATION = "CREATE_INFORMATION",
  SET_LOADING_INFORMATION = "SET_LOADING_INFORMATION",
  SET_ERROR_INFORMATION = "SET_ERROR_INFORMATION",
  SET_LAST_PATH_INFORMATION = "SET_LAST_PATH_INFORMATION",
}

export enum ShipmentDiscountTypes {
  FIND_LOG_SHIPMENT_DISCOUNT = "FIND_LOG_SHIPMENT_DISCOUNT",
  CURRENT_SHIPMENT_DISCOUNT = "CURRENT_SHIPMENT_DISCOUNT",
  CREATE_SHIPMENT_DISCOUNT = "CREATE_SHIPMENT_DISCOUNT",
  SET_LOADING_SHIPMENT_DISCOUNT = "SET_LOADING_SHIPMENT_DISCOUNT",
  SET_ERROR_SHIPMENT_DISCOUNT = "SET_ERROR_SHIPMENT_DISCOUNT",
  SET_LAST_PATH_SHIPMENT_DISCOUNT = "SET_LAST_PATH_SHIPMENT_DISCOUNT",
}

export enum ExtraWarrantyTypes {
  FIND_LOG_EXTRA_WARRANTY = "FIND_LOG_EXTRA_WARRANTY",
  CURRENT_EXTRA_WARRANTY = "CURRENT_EXTRA_WARRANTY",
  CREATE_EXTRA_WARRANTY = "CREATE_EXTRA_WARRANTY",
  SET_LOADING_EXTRA_WARRANTY = "SET_LOADING_EXTRA_WARRANTY",
  SET_ERROR_EXTRA_WARRANTY = "SET_ERROR_EXTRA_WARRANTY",
  SET_LAST_PATH_EXTRA_WARRANTY = "SET_LAST_PATH_EXTRA_WARRANTY",
}

export enum LoyaltyTypes {
  FIND_LOG_LOYALTY = "FIND_LOG_LOYALTY",
  FIND_LOG_LOYALTY_HISTORY = "FIND_LOG_LOYALTY_HISTORY",
  SET_LOADING_LOYALTY = "SET_LOADING_LOYALTY",
  FIND_ONE_LOYALTY = "FIND_ONE_LOYALTY",
  FIND_UPDATE_LOYALTY = "FIND_UPDATE_LOYALTY",
}

type AdminMetadata = {
  adminName: string;
  role: string;
};

type Metadata = {
  loyaltyName: string;
  coin: number;
  coinExpired: number;
  minTotalTransaction: number;
  maxTotalTransaction: number;
};

export type ShipmentDiscountResponse = {
  shipmentDiscountId: string;
  amount: number;
  adminMetadata: any;
  createdAt?: Date | string;
  updatedAt?: Date | string;
};

export type ExtraWarrantyResponse = {
  warrantyId: string;
  year: string;
  adminMetadata: any;
  createdAt?: Date | string;
  updatedAt?: Date | string;
};

export type LoyaltyResponse = {
  loyaltyId: string;
  loyaltyName: string;
  minTotalTransaction: number;
  maxTotalTransaction: any;
  coin: number;
  coinExpired: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type LoyaltyHistoryResponse = {
  loyaltyHistoryId: string;
  loyalty: LoyaltyResponse;
  loyaltyId: string;
  metaData: Metadata;
  adminMetaData: AdminMetadata;
  remark: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type ReferralCommissionResponse = {
  referralCommissionId: string;
  amount: number;
  referralType:
  | "premium-membership"
  | "retail-commission"
  | "referral-commission"
  | "spg-commission";
  createdAt?: Date | string;
  updatedAt?: Date | string;
};

export type ReferralCommissionResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ReferralCommissionResponse[];
  };
};

export type ShipmentDiscountResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ShipmentDiscountResponse[];
  };
};

export type ExtraWarrantyResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: ExtraWarrantyResponse[];
  };
};

export type LoyaltyResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: LoyaltyResponse[];
  };
};

export type LoyaltyHistoryResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: LoyaltyHistoryResponse[];
  };
};

export type ReferralComissionState = {
  isLoadingReferralComission: boolean;
  isLoadingRetailComission: boolean;
  isLoadingSPGComission: boolean;
  isLoadingMemberPremium: boolean;
  isLoadingReferralInformation: boolean;
  isLoadingShipmentDiscount: boolean;
  isLoadingExtraWarranty: boolean;

  logReferralComission: ReferralCommissionResponsePayload;
  logShipmentDiscount: ShipmentDiscountResponsePayload;
  logExtraWarranty: ExtraWarrantyResponsePayload;

  logLoyalty: LoyaltyHistoryResponsePayload;
  loyaltyDetail: LoyaltyResponse;
  listLoyaltyLevel: LoyaltyResponsePayload;

  referralComission: {
    amountRetail: number;
    amountReferral: number;
    amountSPG: number;
    amountPremiumMembership: number;
  };
  referralInformation: {
    content: string;
  };
  shipmentDiscount: {
    amount: number;
  };
  extraWarranty: {
    year: string;
  };

  error: any;
  isSuccess: boolean;
};
