export enum StockRetailTypes {
  FIND_STOCK_HISTORY = 'FIND_STOCK_HISTORY',
  CHANGE_STOCK = 'CHANGE_STOCK',
  SET_LOADING_STOCK = 'SET_LOADING_STOCK',
  SET_ERROR_STOCK = 'SET_ERROR_STOCK',
  SET_LIMITED_STOCK = 'SET_LIMITED_STOCK',
}

export type StockRequest = {
  changes: number;
  note: string;
  productId: string;
};

export type StockChangeResponse = {
  code: string | null;
  message: string | null;
  payload: {
    isSuccess: boolean;
  };
};

export type StockHistoryResponse = {
  id: string;
  productId: string;
  stockBefore: number;
  stockChanges: number;
  stockAfter: number;
  note: string;
  createdById: string;
  createdByCustomer?: {
    customerId: string;
    customerName: string;
  };
  createdByAdmin?: {
    adminId: string;
    name: string;
  };
  createdAt?: Date | string;
  updatedAt?: Date | string;
};

export type StockHistoryResponsePayload = {
  code: string | null;
  message: string | null;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: StockHistoryResponse[];
  };
};

export type StockState = {
  isLoadingStock: boolean;
  data: StockHistoryResponsePayload;
  stock: StockChangeResponse;
  error: any;
  limitedStock: number;
};
