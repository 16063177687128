import axios from 'axios';
import { getHeaders } from '../../helpers/auth';

export async function apiCurrentShipmentDiscount() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/shipment-discount/current-discount',
    method: 'GET',
    headers
  });
}

export async function apiLogShipmentDiscount(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/shipment-discount/log' + query,
    method: 'GET',
    headers
  });
}

export async function apiUpdateShipmentDiscount(data: any) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/shipment-discount',
    method: 'POST',
    headers,
    data
  });
}
