import { Reducer } from "redux";
import {
  ServiceCenterTransactionState,
  ServiceCenterTransactionTypes,
} from "../../types/service-center-transaction.types";

export const initServiceCenterTransaction = {
  serviceCenterTransactionId: "",
  serviceStartAt: "",
  serviceFinishAt: "",
  serviceCenterName: "",
  problem: "",
  solution: "",
  note: "",
  metaProduct: {
    productId: "",
    productName: "",
    noSerial: "",
    sku: "",
    initialWarranty: new Date(),
    categories: [
      {
        categoryId: "",
        categoryName: "",
      },
    ],
    images: [],
  },
  metaUser: {
    customerId: "",
    phoneNumber: "",
    customerName: "",
    profilePic: "",
  },
  createdAt: new Date(),
  updatedAt: new Date(),
};
export const initialState: ServiceCenterTransactionState = {
  isLoadingTransaction: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  serviceCenterTransaction: { ...initServiceCenterTransaction },
  isSuccess: false,
};

export const serviceCenterTransaction: Reducer<
  ServiceCenterTransactionState
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceCenterTransactionTypes.FIND_ALL_SERVICE_CENTER_TRANSACTION:
      const results = action.payload.payload.results.map((item: any) => {
        return {
          ...item,
        };
      });
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case ServiceCenterTransactionTypes.FIND_ONE_SERVICE_CENTER_TRANSACTION:
      return {
        ...state,
        serviceCenterTransaction: action.payload,
      };
    default:
      return state;
  }
};
