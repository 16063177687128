import { AdminSingle } from '../../types/admin.type'
import { BranchProperties } from '../../types/b2b/branch/branch.type'
import { DistributorProperties } from '../../types/b2b/distributor/distributor.type'
import { CustomerProperties } from '../../types/customer.type'

export enum B2BTypes {
  SET_B2B_ID = 'SET_B2B_ID',
  SET_DISTRIBUTOR = 'SET_DISTRIBUTOR',
  SET_BRANCH = 'SET_BRANCH',
  SET_BRANCHES = 'SET_BRANCHES',
  SET_USER_B2B = 'SET_USER_B2B',
  SET_AGENT = 'SET_AGENT',
}

export function setB2BID(payload: string) {
  return {
    type: B2BTypes.SET_B2B_ID,
    payload
  }
}

export function setDataDistributor(payload: DistributorProperties) {
  return {
    type: B2BTypes.SET_DISTRIBUTOR,
    payload
  }
}

export function setDataBranch(payload: BranchProperties) {
  return {
    type: B2BTypes.SET_BRANCH,
    payload
  }
}

export function setDataBranches(payload: BranchProperties[]) {
  return {
    type: B2BTypes.SET_BRANCHES,
    payload
  }
}

export function setUserB2B(payload: AdminSingle[]) {
  return {
    type: B2BTypes.SET_USER_B2B,
    payload
  }
}

export function setAgentB2B(payload: CustomerProperties) {
  return {
    type: B2BTypes.SET_AGENT,
    payload
  }
}
