import { AdminPayloadResponse, AdminSingle, AdminTypes } from '../../types/admin.type';

export function findAllAdmin(payload: AdminPayloadResponse) {
  return {
    type: AdminTypes.FIND_ALL_ADMIN,
    payload
  }
}

export function findOneAdmin(payload: AdminSingle) {
  return {
    type: AdminTypes.FIND_ONE_ADMIN,
    payload
  }
}

export function setLoadingAdmin(payload: boolean) {
  return {
    type: AdminTypes.SET_LOADING_ADMIN,
    payload
  }
}
