import { Reducer } from 'redux'
import { ERetailDiscount, ReferralDiscountState, ReferralDiscountTypes } from '../../types/referral-discount.type'


const initialState: ReferralDiscountState = {
  isLoadingReferralDiscount: false,
  logReferralDiscount: {
    code: '',
    message: '',
    payload: {
      prev: '',
      next: '',
      count: 0,
      results: [],
    },
  },
  current: {
    referral: {
      retailDiscountId: '',
      discountNote: '',
      discountType: ERetailDiscount.NON_REFERRAL,
    },
    nonReferral: {
      retailDiscountId: '',
      discountNote: '',
      discountType: ERetailDiscount.NON_REFERRAL,
    },
  },
  data: {
    retailDiscountId: '',
    discountNote: '',
    discountFormula: '',
    discountType: ERetailDiscount.NON_REFERRAL,
    adminMetadata: {}
  },
  error: {},
  isSuccess: false,
}

export const referralDiscount: Reducer<ReferralDiscountState> = (state = initialState, action) => {
  switch (action.type) {
    case ReferralDiscountTypes.FIND_LOG_REFERRAL_DISCOUNT:
      return {
        ...state,
        logReferralDiscount: action.payload
      }
    case ReferralDiscountTypes.CURRENT_REFERRAL_DISCOUNT:
      return {
        ...state,
        current: action.payload
      }
    case ReferralDiscountTypes.FIND_REFERRAL_DISCOUNT:
      return {
        ...state,
        data: action.payload
      }
    case ReferralDiscountTypes.CREATE_REFERRAL_DISCOUNT:
      return {
        ...state,
        current: action.payload
      }
    case ReferralDiscountTypes.SET_LOADING_REFERRAL_DISCOUNT:
      return {
        ...state,
        isLoadingReferralDiscount: action.payload
      }
    case ReferralDiscountTypes.SET_ERROR_REFERRAL_DISCOUNT:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}