import { CardMedia, Theme, makeStyles, createStyles } from "@material-ui/core";
import React from "react";
import COLORS from "../../styles/colors";
import { ImageProduct } from '../../types/product.type';
import placeholder from '../../assets/default_500x500.png'
import placeholderProduct from '../../assets/default_1000x1000.png'

type Props = {
  type?: "preview" | "add" | "preview-product";
  image?: ImageProduct;
};

export default function ImagePreview(props: Props) {
  const type = props.type || "preview";
  const classes = useStyles();

  if (type === "add") {
    return <div className={classes.cardMedia2}>+</div>;
  } else {
    return (
      <CardMedia
        className={classes.cardMedia}
        image={props.image?.imageUrl ? props.image.imageUrl : props.type === 'preview-product' ? placeholderProduct : placeholder}
      />
    )
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      paddingTop: "100%",
      width: "100%",
      alignSelf: "center",
      backgroundColor: COLORS.greyLight,
      borderColor: COLORS.greyLight,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardMedia2: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: COLORS.greyLight,
      borderColor: COLORS.greyLight,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
      fontSize: 50,
    },
  })
);
