import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Layout from '../../components/Layout/Layout';
import SimpleCard from '../../components/Card/SimpleCard';
import { ShoppingBag, User, ShoppingCart, Star } from 'react-feather';
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import COLORS from '../../styles/colors';
import HeaderPage from '../../components/Layout/HeaderPage';
import { LIST_MONTH } from '../../helpers/constants/datetime';
import TransactionActivity from './TransactionActivity';
import {
  MostActiveUpline,
  SummaryMostActiveTransaction,
  SummaryTransaction,
} from '../../types/dashboard.type';
import useDashboard from '../../hooks/useDashboard';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';
import { convertNumToCurrency } from '../../helpers/functions/currency';

const date = new Date();

export default function DashboardB2C() {
  const classes = useStyles();

  const { defaultStartDate, defaultEndDate } = useDashboard();

  const {
    isLoadingDashboard,
    listTransaction,
    mostActiveTransaction,
    mostActiveUpline,
    customerSummary
  } = useSelector((state: ApplicationState) => state.dashboard);

  const _renderLoading = () => {
    return (
      <Box
        width="100%"
        height="100"
        alignItems="center"
        justifyContent="center"
        display="flex"
        marginTop={10}
      >
        <CircularProgress size={50} thickness={6} color="primary" />
      </Box>
    );
  };

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const findCurrentTransaction = listTransaction.find(item => Number(item.year) === nowYear && Number(item.month) === (nowMonth + 1));
  const currentTotalTransaction = findCurrentTransaction
    ? findCurrentTransaction.countTransaction
    : 0;

  const listTransactionBefore = listTransaction.filter(item => !(Number(item.year) === nowYear && Number(item.month) === (nowMonth + 1))).slice(0, 5);

  const beforeTotalTransaction =
    listTransactionBefore && listTransactionBefore.length > 1
      ? listTransactionBefore[0].countTransaction
      : 0;
  const _renderListTransaction = () => {
    return (
      <Box width="100%" height={400} className={classes.containerSaleOrderData}>
        <Box>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Transaction Activity past 5 Month
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Based on total transaction
          </Typography>
        </Box>

        {isLoadingDashboard ? (
          _renderLoading()
        ) : (
            <Box className={classes.containerList}>
              {listTransactionBefore.map((item: SummaryTransaction, index: number) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  className={classes.containerListItem}
                >
                  <Box width="50%">
                    <Typography>
                      {LIST_MONTH[item.month - 1]} {item.year}
                    </Typography>
                  </Box>
                  <Box width="50%">
                    <Typography style={{ fontWeight: 'bold' }}>
                      {item.countTransaction}
                    </Typography>
                  </Box>
                  <Box width="50%">
                    <Typography style={{ fontWeight: 'bold' }}>
                      {convertNumToCurrency(item.totalFinalTransactionPrice)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
      </Box>
    );
  };

  const _renderListMostActiveUpline = () => {
    return (
      <Box
        width="100%"
        height={500}
        className={classes.containerActiveDistributorData}
      >
        <Box className={classes.containerList}>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.containerHeaderList}
          >
            <Box width="60%">
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Name
              </Typography>
            </Box>
            <Box width="40%">
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold' }}
                align="center"
              >
                Downline
              </Typography>
            </Box>
          </Box>
          {isLoadingDashboard ? (
            _renderLoading()
          ) : (
              <Box className={classes.containerList}>
                {mostActiveUpline.map((item, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="row"
                    className={classes.containerListItemActiveDistributor}
                  >
                    <Box width="60%">
                      <Typography>
                        {item.customer
                          ? item.customer.customerName
                          : item.customerId}
                      </Typography>
                    </Box>
                    <Box width="40%">
                      <Typography align="center">{item.totalDownline}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
        </Box>
      </Box>
    );
  };

  const _renderListMostActiveTransaction = () => {
    return (
      <Box
        width="100%"
        height={500}
        className={classes.containerActiveDistributorData}
      >
        <Box className={classes.containerList}>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.containerHeaderList}
          >
            <Box width="60%">
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                Name
              </Typography>
            </Box>
            <Box width="40%">
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold' }}
                align="center"
              >
                Transactions
              </Typography>
            </Box>
          </Box>
          {isLoadingDashboard ? (
            _renderLoading()
          ) : (
              <Box className={classes.containerList}>
                {mostActiveTransaction.map(
                  (item: SummaryMostActiveTransaction, index: number) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      className={classes.containerListItemActiveDistributor}
                    >
                      <Box width="60%">
                        <Typography>
                          {item.customer && item.customer.customerName
                            ? item.customer.customerName
                            : item.externalId}
                        </Typography>
                      </Box>
                      <Box width="40%">
                        <Typography align="center">
                          {item.countTransaction}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            )}
        </Box>
      </Box>
    );
  };

  return (
    <Layout title="Dashboard" loading={isLoadingDashboard}>
      <HeaderPage
        title="Dashboard"
        subtitle="Summary information of the system"
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard
            valueOnly={true}
            title="Customer Today"
            count_before={customerSummary.today}
            count_current={customerSummary.today}
            topRightIconComponent={
              <User style={{ width: 20, height: 20 }} />
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard
            valueOnly={true}
            title={'Customer in ' + LIST_MONTH[date.getMonth()]}
            count_before={customerSummary.month}
            count_current={customerSummary.month}
            topRightIconComponent={
              <User style={{ width: 20, height: 20 }} />
            }
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <SimpleCard
            valueOnly={true}
            title="All Active Customer"
            count_before={customerSummary.count}
            count_current={customerSummary.count}
            topRightIconComponent={
              <User style={{ width: 20, height: 20 }} />
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <SimpleCard
            title="Transaction"
            count_before={beforeTotalTransaction}
            count_current={currentTotalTransaction}
            topRightIconComponent={
              <ShoppingBag style={{ width: 20, height: 20 }} />
            }
            totalCurrentMonth={findCurrentTransaction ? findCurrentTransaction?.totalFinalTransactionPrice : 0}
            bottomComponent={() => _renderListTransaction()}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <Paper elevation={3} className={classes.paper}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Box className={classes.containerTitleActiveDistributor}>
                  <Typography variant="body1">Most Active Upline</Typography>
                </Box>
                <Box className={classes.containerTitleActiveDistributor}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Based on total downline
                  </Typography>
                </Box>
              </Box>
              <Box width={50} display="flex" flexDirection="row">
                <Box
                  className={classes.containerTopRightIcon}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Star style={{ width: 20, height: 20 }} />
                </Box>
              </Box>
            </Box>
            {_renderListMostActiveUpline()}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Paper elevation={3} className={classes.paper}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Box className={classes.containerTitleActiveDistributor}>
                  <Typography variant="body1">
                    Most Active Transaction
                  </Typography>
                </Box>
                <Box className={classes.containerTitleActiveDistributor}>
                  <Typography variant="subtitle1" color="textSecondary">
                    Based on total transaction of all time
                  </Typography>
                </Box>
              </Box>
              <Box width={50} display="flex" flexDirection="row">
                <Box
                  className={classes.containerTopRightIcon}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ShoppingCart style={{ width: 20, height: 20 }} />
                </Box>
              </Box>
            </Box>
            {_renderListMostActiveTransaction()}
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <HeaderPage
            title={'Transaction Activity in ' + LIST_MONTH[date.getMonth()] + ' ' + date.getFullYear()}
            subtitle="Summary information of the system"
          />

          <Paper elevation={3}>
            <TransactionActivity />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2, 0, 2, 0),
    },
    containerSaleOrderData: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2, 0, 0, 0),
      borderTopColor: COLORS.greyLight,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
    },
    containerActiveDistributorData: {
      overflowY: 'auto',
    },
    containerHeaderList: {
      backgroundColor: COLORS.greyLight,
      padding: theme.spacing(1, 2, 1, 2),
    },
    containerList: {
      padding: theme.spacing(2, 0, 0, 0),
    },
    containerListItem: {
      padding: theme.spacing(1.58, 0, 1.58, 0),
    },
    containerListItemActiveDistributor: {
      padding: theme.spacing(1.58, 2, 1.58, 2),
    },
    containerTitleActiveDistributor: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    containerTitleTripActivity: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    containerTitleTripActivityPaper: {
      padding: theme.spacing(2, 2, 1, 2),
    },
    containerIconSuccess: {
      backgroundColor: COLORS.successLight,
      color: COLORS.success,
      fontWeight: 'bold',
      textAlign: 'center',
      padding: 2,
      borderRadius: 10,
    },
    containerIconError: {
      backgroundColor: COLORS.errorLight,
      color: COLORS.error,
      fontWeight: 'bold',
      textAlign: 'center',
      padding: 2,
      borderRadius: 10,
    },
    containerTopRightIcon: {
      width: 40,
      height: 40,
      backgroundColor: COLORS.greyLighten,
      borderRadius: 50,
    },
  })
);
