export enum CoinTypes {
  FIND_LOG_COIN_HISTORY = "FIND_LOG_COIN_HISTORY",
  SET_LOADING_COIN_HISTORY = "SET_LOADING_COIN_HISTORY",
}

export type CoinHistoryResponse = {
  coinHistoryId: string;
  coinId: string;
  invoiceId: string;
  loyaltyName: string;
  coinReceived: number;
  coinUsed: number;
  coinLeftAmount: number;
  updatedAt?: Date;
  createdAt?: Date;
};

export type CoinHistoryResponsePayload = {
  code: string;
  message: string;
  payload: {
    next: string;
    prev: string;
    count: number;
    results: CoinHistoryResponse[];
  };
};

export type CoinHistoryState = {
  isLoadingCoinHistory: boolean;
  coinHistory: CoinHistoryResponsePayload;

  error: any;
};
