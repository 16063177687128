import { combineReducers } from "redux";
import { promotion } from "./content/promotion.reducer";
import { news } from "./content/news.reducer";
import { tag } from "./content/tag.reducer";
import { banner } from "./content/banner.reducer";
import { product } from "./product.reducer";
import { stockRetail } from "./stock-retail.reducer";
import { category } from "./category.reducer";
import { collection } from "./collection.reducer";
import { featuredProduct } from "./featured-product.reducer";
import { transaction } from "./transaction.reducer";
import { customer } from "./customer.reducer";
import { masterDataReferral } from "./master-data-referral.reducer";
import { feedback } from "./feedback.reducer";
import { bankApproval } from "./bank-approval.reducer";
import { dashboard } from "./dashboard.reducer";
import { admin } from "./admin.reducer";
import { configApp } from "./config.reducer";
import { version } from "./version.reducer";
import { referralDiscount } from "./referral-discount.reducer";
import { voucher } from "./voucher.reducer";
import { storefront } from "./storefront.reducer";
import { b2b } from "./b2b.reducer";
import { serviceCenter } from "./service-center.reducer";
import { serviceCenterTransaction } from "./service-center-transaction.reducer";
import { coin } from "./coin.reducer";
import { pickupPoint } from "./pickup-point.reducer";

export default combineReducers({
  promotion,
  news,
  tag,
  banner,
  product,
  stockRetail,
  category,
  collection,
  featuredProduct,
  transaction,
  customer,
  masterDataReferral,
  feedback,
  bankApproval,
  configApp,
  dashboard,
  admin,
  version,
  referralDiscount,
  voucher,
  storefront,
  b2b,
  serviceCenter,
  serviceCenterTransaction,
  coin,
  pickupPoint,
});
