import axios from 'axios';
import { getHeadersMultiPart, getHeaders } from '../helpers/auth';

export async function apiFindAllFeaturedProduct(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/featured-product' + query,
    method: 'GET',
    headers
  });
}

export async function apiCreateFeaturedProduct(data: FormData) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/featured-product',
    method: 'POST',
    headers: headers,
    data
  });
}

export async function apiUpdateFeaturedProduct(data: FormData) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/featured-product/' + data.get('productId'),
    method: 'PATCH',
    headers: headers,
    data
  });
}

export async function apiDeleteFeaturedProduct(featuredProductId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/featured-product/' + featuredProductId,
    method: 'DELETE',
    headers
  });
}