import axios from "axios";
import { getHeaders } from "../../helpers/auth";

export async function apiCurrentExtraWarranty() {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/warranty/current-warranty",
    method: "GET",
    headers,
  });
}

export async function apiLogExtraWarranty(params: string) {
  const query = params || null;
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/warranty" + query,
    method: "GET",
    headers,
  });
}

export async function apiUpdateExtraWarranty(data: any) {
  const headers = await getHeaders();
  return axios({
    url: process.env.REACT_APP_BASE_URL + "/warranty",
    method: "POST",
    headers,
    data,
  });
}
