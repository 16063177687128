import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import HeaderPage from "../../components/Layout/HeaderPage";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import { Info } from "react-feather";
import COLORS from "../../styles/colors";
import { useHistory } from "react-router-dom";
import { httpRequest } from "../../helpers/api/api";
import { Autocomplete } from "@material-ui/lab";
import { BankProperties } from "../../types/customer.type";
import { DistributorProperties } from "../../types/b2b/distributor/distributor.type";
import { apiCreateAro } from "../../api/aro";
import { toast } from "react-toastify";
import PickupPoint from "../PickupPoint";

const CreateAgentRegion: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [labelName, setLabelName] = useState("");
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState<any>({});
  const [distributorList, setDistributorList] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState<any>({});

  const [isDistributorOpen, setIsDistributorOpen] = useState(false);
  const [isBankOpen, setIsBankOpen] = useState(false);

  const getBankList = useCallback(() => {
    if (bankList.length > 0) return true;
    setLoading(true);
    return httpRequest
      .get("/bank")
      .then(({ data }) => {
        let banks = data.payload.results;
        setBankList(banks);
      })
      .finally(() => setLoading(false));
  }, [bankList]);

  const getDistributorList = useCallback(() => {
    if (distributorList.length > 0) return true;
    setLoading(true);
    return httpRequest
      .get("/distributor?limit=100&page=1&sort=name,ASC")
      .then(({ data }) => {
        let distributors = data.payload.results;
        setDistributorList(distributors);
      })
      .finally(() => setLoading(false));
  }, [distributorList]);

  const [formData, setFormData] = useState({
    labelName: "",
    phoneNumber: "",
    status: "active",
    voucherParticipation: true,
    shipmentDiscount: {
      amount: 0,
    },
    aroDiscount: {
      referral: "",
      nonReferral: "",
    },
    bankAccount: {
      accountNumber: "",
      accountName: "",
    },
  });

  const doSubmitForm = async () => {
    if (
      /^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(
        formData.aroDiscount.referral
      ) === false
    ) {
      return toast.error("Format diskon referral tidak valid!", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    }
    if (
      /^(\d+(\.\d+)?[%]([+]\d+(\.\d+)*[%])?)$/gm.test(
        formData.aroDiscount.referral
      ) === false
    ) {
      return toast.error("Format diskon non referral tidak valid!", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    }
    setLoading(true);
    try {
      const body = {
        ...formData,
        distributorId: selectedDistributor?.distributorId,
        bankAccount: {
          ...formData.bankAccount,
          bankId: selectedBank?.bankId,
        },
      };
      apiCreateAro(body)
        .then(() => history.go(-1))
        .finally(() => setLoading(false));
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const isSubmitable = useMemo(
    () =>
      formData.labelName &&
      formData.phoneNumber.length > 10 &&
      formData.bankAccount.accountName &&
      formData.bankAccount.accountName &&
      formData.shipmentDiscount.amount !== null &&
      formData.aroDiscount.referral !== null &&
      formData.aroDiscount.nonReferral !== null &&
      selectedBank &&
      selectedDistributor &&
      !loading,
    [formData, selectedDistributor, selectedBank]
  );

  const handleChange = (name: string, value: any) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeNested = (
    parent: "shipmentDiscount" | "aroDiscount" | "bankAccount",
    name: string,
    value: any
  ) => {
    let nested = formData[parent];
    setFormData({
      ...formData,
      [parent]: {
        ...nested,
        [name]: value,
      },
    });
  };

  return (
    <Layout loading={loading} title="Add Agent Region">
      <Box display="flex" justifyContent="space-between">
        <HeaderPage
          title="Add Agent"
          subtitle="Add New Agent Region Oxone"
          showBackButton={true}
        />
        <Box
          display="flex"
          justifyContent="flex-start"
          className={classes.containerButton}
        >
          <AppButton
            variant="contained"
            color="secondary"
            onClick={() => history.go(-1)}
          >
            Cancel
          </AppButton>
          <AppButton
            onClick={doSubmitForm}
            variant="contained"
            color="primary"
            disabled={!isSubmitable}
          >
            Save
          </AppButton>
        </Box>
      </Box>

      <Paper elevation={0} className={classes.paper}>
        <div className={classes.paperTitle}>
          <Typography variant="h5">Agent Profile</Typography>
        </div>
        <Grid container className={classes.containerForm}>
          <Grid item xs={12} md={12} lg={12} className={classes.input}>
            <Autocomplete
              open={isDistributorOpen}
              className={classes.field}
              options={distributorList}
              getOptionLabel={(option: DistributorProperties) => option.name}
              id="select-on-focus-branch"
              onOpen={async () => {
                await getDistributorList();
                setIsDistributorOpen(true);
              }}
              onClose={() => setIsDistributorOpen(false)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Choose Distributor"
                />
              )}
              value={selectedDistributor}
              onChange={(
                event: any,
                newValue: DistributorProperties | null
              ) => {
                setSelectedDistributor(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.input}>
            <FormInput
              fullWidth
              required
              label="Label Agent Region"
              value={formData.labelName}
              onChange={(v) => handleChange("labelName", v)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.input}>
            <FormInput
              type="phone"
              fullWidth
              required
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={(v) => handleChange("phoneNumber", v)}
              placeholder="08xxxxxxxxxx"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.input}>
            <Paper elevation={2} className={classes.infoContainer}>
              <Box display="flex" alignItems="center">
                <Info
                  color={COLORS.warn}
                  size={20}
                  style={{ marginRight: 10 }}
                />
                Nomor HP tersebut akan digunakan untuk akses login CMS Agent
                Region.
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className={classes.input}>
            <RadioGroup
              value={formData.status}
              row
              aria-label="status"
              name="status"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                value: string
              ) => handleChange("status", value)}
            >
              <FormControlLabel
                value="active"
                control={<Radio color="primary" />}
                label="Active"
              />
              <FormControlLabel
                value="inactive"
                control={<Radio color="primary" />}
                label="Inactive"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </Paper>

      {/* <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <div className={classes.paperTitle}>
              <Typography variant="h5">Pickup Point</Typography>
            </div>
            <Grid container className={classes.containerForm}>
              <Grid item xs={12} md={12} lg={12} className={classes.input}>
                <PickupPoint aroId="null" type="component" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid> */}

      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={6}>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.paperTitle}>
              <Typography variant="h5">Shipment Discount</Typography>
            </div>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                type="currency"
                fullWidth
                required
                label="Nominal"
                value={formData.shipmentDiscount.amount}
                onChange={(v) =>
                  handleChangeNested("shipmentDiscount", "amount", Number(v))
                }
              />
            </Grid>
            <div className={classes.paperTitle}>
              <Typography variant="h5">Agent Referral Discount</Typography>
            </div>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                type="text"
                fullWidth
                required
                label="Nominal Diskon Referral"
                value={formData.aroDiscount.referral}
                onChange={(v) =>
                  handleChangeNested("aroDiscount", "referral", v)
                }
                helperText="Diskon dalam persen, Ex. 5% atau 5%+2%"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                type="text"
                fullWidth
                required
                label="Nominal Diskon Non Referral"
                value={formData.aroDiscount.nonReferral}
                onChange={(v) =>
                  handleChangeNested("aroDiscount", "nonReferral", v)
                }
                helperText="Diskon dalam persen, Ex. 5% atau 5%+2%"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <Typography variant="caption" color="textSecondary">
                Voucher Participation
              </Typography>
              <RadioGroup
                value={formData.voucherParticipation ? "active" : "inactive"}
                row
                aria-label="voucherParticipation"
                name="voucherParticipation"
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement>,
                  value: string
                ) => handleChange("voucherParticipation", value === "active")}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio color="primary" />}
                  label="Active"
                />
                <FormControlLabel
                  value="inactive"
                  control={<Radio color="primary" />}
                  label="Inactive"
                />
              </RadioGroup>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.paperTitle}>
              <Typography variant="h5">Bank Account</Typography>
            </div>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <Autocomplete
                className={classes.field}
                options={bankList}
                getOptionLabel={(option: BankProperties) => option.bankName}
                id="select-on-focus-branch"
                open={isBankOpen}
                onOpen={async () => {
                  await getBankList();
                  setIsBankOpen(true);
                }}
                onClose={() => setIsBankOpen(false)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Bank Selection"
                  />
                )}
                value={selectedBank}
                onChange={(event: any, newValue: BankProperties | null) => {
                  setSelectedBank(newValue);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                fullWidth
                required
                label="Bank Account Name"
                value={formData.bankAccount.accountName}
                onChange={(v) =>
                  handleChangeNested("bankAccount", "accountName", v)
                }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className={classes.input}>
              <FormInput
                type="number-only"
                fullWidth
                required
                label="Bank Account Number"
                value={formData.bankAccount.accountNumber}
                onChange={(v) =>
                  handleChangeNested("bankAccount", "accountNumber", v)
                }
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(3),
    },
    infoContainer: {
      padding: theme.spacing(0.5, 1, 0.5, 1),
      marginBottom: theme.spacing(3),
    },
    paper: {
      margin: theme.spacing(2, 0, 2, 0),
      padding: theme.spacing(0, 0, 3, 0),
    },
    stepper: {
      background: "transparent",
    },
    containerForm: {
      // padding: theme.spacing(0, 2, 0, 2),
    },
    input: {
      padding: theme.spacing(0, 3, 0, 3),
    },
    paperTitle: {
      padding: theme.spacing(3),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
  })
);

export default CreateAgentRegion;
