import { Reducer } from "redux";
import {
  ServiceCenterState,
  ServiceCenterTypes,
} from "../../types/service-center.types";

export const initServiceCenter = {
  serviceCenterTransactionId: "",
  serviceStartAt: "",
  serviceFinishAt: "",
  serviceCenterName: "",
  problem: "",
  solution: "",
  note: "",
  metaProduct: {
    productId: "",
    productName: "",
    noSerial: "",
    sku: "",
    initialWarranty: new Date(),
    statusWarranty: "",
    categories: [
      {
        categoryId: "",
        categoryName: "",
      },
    ],
    images: [],
  },
  metaUser: {
    customerId: "",
    phoneNumber: "",
    customerName: "",
    profilePic: "",
  },
  createdAt: new Date(),
  updatedAt: new Date(),
};
export const initialState: ServiceCenterState = {
  isLoadingServiceCenter: false,
  data: {
    code: null,
    message: null,
    payload: {
      next: "",
      prev: "",
      count: 0,
      results: [],
    },
  },
  error: {},
  serviceCenter: { ...initServiceCenter },
  isSuccess: false,
};

export const serviceCenter: Reducer<ServiceCenterState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ServiceCenterTypes.FIND_ALL_SERVICE_CENTER:
      const results = action.payload.payload.results.map((item: any) => {
        return {
          ...item,
        };
      });
      return {
        ...state,
        data: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            results,
          },
        },
      };
    case ServiceCenterTypes.FIND_ONE_SERVICE_CENTER:
      return {
        ...state,
        serviceCenter: {
          ...action.payload,
        },
      };
    case ServiceCenterTypes.CREATE_SERVICE_CENTER:
      return {
        ...state,
        serviceCenter: action.payload,
        isSuccess: true,
      };
    case ServiceCenterTypes.UPDATE_SERVICE_CENTER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ServiceCenterTypes.REMOVE_SERVICE_CENTER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};
