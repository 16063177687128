import { Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import { SummaryProductImages } from '../../../types/b2b/order/delivery-order.type'
import DetailGeneralInformation from '../DetailGeneralInformation'
import PODImagePreview from '../PODImagePreview'

interface Props {
  orderId: string
  soId: string | null
  createdAt: string | null
  status: string
  distributor: string
  branchName: string | null
  confirmDate: string | null
  driverName: string | null
  receivedBy: string | null
  podImages: SummaryProductImages[] | null
  note: string | null
  tripId: string | null
}

const GeneralInformationDO: React.FC<Props> = (props) => {

  const history = useHistory();
  const [showImages, setShowImages] = React.useState<boolean>(false)

  const handleOpenImage = () => {
    setShowImages(true)
  }

  const handleCloseDialog = () => {
    setShowImages(false)
  }

  const handleOpenTrip = () => {
    history.push('/trip/' + props.tripId)
  }

  return (
    <>
      <DetailGeneralInformation
        data={[
          {
            label: "DO ID",
            value: props.orderId,
          },
          {
            label: "SO ID",
            value: props.soId,
          },
          {
            label: "Created At",
            value: props.createdAt,
          },
          {
            label: "Status",
            value: props.status,
          },
          {
            label: "Distributor",
            value: props.distributor,
          },
          {
            label: 'Lokasi Pengiriman',
            value: props.branchName,
          },
          {
            label: 'Confirm Date',
            value: props.confirmDate,
          },
          {},
          {
            label: 'Trip ID',
            value: props.tripId,
            onPressAction: (handleOpenTrip)
          },
          {
            label: 'Nama Driver',
            value: props.driverName,
          },
          {
            label: 'Nama Penerima',
            value: props.receivedBy,
          },
          {
            label: 'Foto Surat Jalan',
            value: props.podImages && props.podImages.length > 0 ? 'Lihat foto surat jalan' : null,
            onPressAction: (handleOpenImage)
          },
          {},
        ]}
      />

      <div style={{ marginBottom: 15 }}>
        <Typography color="textSecondary">DO Note</Typography>
        {props.note ? (
          <Typography>{props.note}</Typography>
        ) : (
            <Typography color="textSecondary" style={{ fontStyle: 'italic' }}>Not set</Typography>
          )}
      </div>

      {props.podImages && (
        <PODImagePreview
          handleCloseDialog={() => handleCloseDialog()}
          title="Foto Surat Jalan"
          isShow={showImages}
          podImages={props.podImages}
        />
      )}
    </>
  )
}

export default GeneralInformationDO