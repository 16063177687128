import { Typography } from '@material-ui/core'
import React from 'react'

interface Props {
  note: string | null
}

const ApprovalNote: React.FC<Props> = ({ note }) => {
  return (
    <div style={{
      border: '1px solid #BDBDBD',
      borderRadius: 10,
      padding: 15
    }}>
      <Typography variant="body1" color={!note ? 'textSecondary' : 'textPrimary'} style={{
        fontStyle: !note ? 'italic' : 'normal'
      }}>
        {note || 'Approval note empty'}
      </Typography>
    </div>
  )
}

export default ApprovalNote