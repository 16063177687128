import { useDispatch } from 'react-redux';
import { apiLogShipmentDiscount, apiCurrentShipmentDiscount, apiUpdateShipmentDiscount } from '../api/master-data/shipment-discount';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { setLoadingShipmentDiscount, findLogShipmentDiscount, currentShipmentDiscount } from '../redux/action/master-data-referral.action';


export default function useShipmentDiscount() {
  const dispatch = useDispatch()

  const fetchLogShipmentDiscount = async (params: any) => {
    try {
      dispatch(setLoadingShipmentDiscount(true))

      let query = generateQueryString(params)
      const result = await apiLogShipmentDiscount(query)

      dispatch(findLogShipmentDiscount(result.data))

      dispatch(setLoadingShipmentDiscount(false))
    } catch (error) {
      dispatch(setLoadingShipmentDiscount(false))
    }
  }

  const fetchCurrentShipmentDiscount = async () => {
    try {
      dispatch(setLoadingShipmentDiscount(true))
      const result = await apiCurrentShipmentDiscount()

      if (result.data && result.data.payload) {
        dispatch(currentShipmentDiscount(result.data.payload))
      } else {
        dispatch(currentShipmentDiscount({ amount: 0 }))
      }


      dispatch(setLoadingShipmentDiscount(false))
    } catch (error) {
      dispatch(setLoadingShipmentDiscount(false))
    }
  }

  const updateShipmentDiscount = async (data: any) => {
    try {
      dispatch(setLoadingShipmentDiscount(true))
      const result = await apiUpdateShipmentDiscount(data)

      dispatch(currentShipmentDiscount(result.data))

      await fetchLogShipmentDiscount({ limit: 25, offset: 0 })
      await fetchCurrentShipmentDiscount()

      dispatch(setLoadingShipmentDiscount(false))
    } catch (error) {
      dispatch(setLoadingShipmentDiscount(false))
    }
  }

  return {
    fetchLogShipmentDiscount,
    fetchCurrentShipmentDiscount,
    updateShipmentDiscount
  }
}