import { TransactionResponsePayload, TransactionTypes } from '../../types/transaction.types'


export function findAllTransaction(payload: TransactionResponsePayload) {
  return {
    type: TransactionTypes.FIND_ALL_TRANSACTION,
    payload
  }
}

export function findOneTransaction(payload: any) {
  return {
    type: TransactionTypes.FIND_ONE_TRANSACTION,
    payload
  }
}

export function setOneTransaction(payload: any) {
  return {
    type: TransactionTypes.SET_ONE_TRANSACTION,
    payload
  }
}

export function findHistoryTransaction(payload: any) {
  return {
    type: TransactionTypes.FIND_HISTORY_TRANSACTION,
    payload
  }
}

export function setProcessTransaction(payload: boolean) {
  return {
    type: TransactionTypes.SET_TRANSACTION_PROCESS_RESULT,
    payload
  }
}

export function setRefundAmount(payload: number) {
  return {
    type: TransactionTypes.SET_REFUND_AMOUNT,
    payload
  }
}

export function setRefundTransaction(payload: any) {
  return {
    type: TransactionTypes.SET_TRANSACTION_PROCESS_RESULT,
    payload
  }
}

export function setLoadingTransaction(payload: boolean) {
  return {
    type: TransactionTypes.SET_LOADING_TRANSACTION,
    payload
  }
}

export function setLoadingProcessTransaction(payload: boolean) {
  return {
    type: TransactionTypes.SET_LOADING_PROCESS,
    payload
  }
}

export function setLastQueryTransaction(payload: any) {
  return {
    type: TransactionTypes.SET_LAST_QUERY_TRANSACTION,
    payload
  }
}
