import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import FilterDate from "../../components/Form/FilterDate";
import AppTabs from "../../components/Tabs/AppTabs";
import { useSelector, useDispatch } from "react-redux";
import useDebounce from "../../hooks/useDebounce";
import useTransaction from "../../hooks/useTransaction";
import { ApplicationState } from "../../redux/store";
import {
  findAllTransaction,
  setOneTransaction,
} from "../../redux/action/transaction.action";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import FormInput from "../../components/Form/FormInput";
import {
  TransactionListResponse,
  TransactionLog,
} from "../../types/transaction.types";
import StatusTransaction from "../../components/StatusTransaction";
import { Alert } from "@material-ui/lab";
import { useAuth } from "../../hooks/useAuth";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { useLocation } from "react-router-dom";
import {
  setLatestParams,
  enableUpdateParams,
} from "../../redux/action/config-app.action";
import { useHistory } from "react-router-dom";
import { ISingleAro } from "../../types/aro.type";
import { httpRequest } from "../../helpers/api/api";
import { parseURLSearch } from "../../helpers/functions/url";
import {
  apiFetchListServiceCenter,
  apiFetchListServiceCenterTransaction,
  ServiceCenterTransactionType,
  ServiceCenterType,
} from "../../api/service-center";
import useServiceCenterTransaction from "../../hooks/useServiceCenterTransaction";
import {
  findAllServiceCenterTransaction,
  findOneServiceCenterTransaction,
} from "../../redux/action/service-center-transaction.action";
import { initialState } from "../../redux/reducer/service-center-transaction.reducer";
import { validateMaxText } from "../../helpers/functions/text";

let headers: ITableHeader[] = [
  {
    title: "serviceCenterTransactionId",
    column: "serviceCenterTransactionId",
    type: "id",
  },
  {
    title: "Service Center",
    column: "serviceCenterName",
  },
  {
    title: "Customer",
    align: "left",
    sortable: true,
    column: "customerName",
  },
  {
    title: "Kategori",
    align: "left",
    sortable: true,
    column: "problem",
  },
  {
    title: "Produk",
    align: "left",
    column: "productName",
  },
  {
    title: "Created At",
    align: "left",
    column: "createdAt",
  },
  {
    title: "Tanggal Service",
    align: "left",
    column: "serviceStartAt",
  },
];

const tabs = ["Proses Pengerjaan", "Selesai", "Semua"];

export default function ServiceCenterTransaction() {
  const location = useLocation();
  const history = useHistory();
  const { aroId } = parseURLSearch(location.search);

  const classes = useStyles();
  const auth = useAuth();

  const [activeTab, setActiveTab] = useState<number>(0);

  const [selectedServiceCenter, setSelectedServiceCenter] = useState("");

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const serviceCenterStateTransaction = useSelector(
    (state: ApplicationState) => state.serviceCenterTransaction
  );
  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const dispatch = useDispatch();
  const [serviceCenter, setServiceCenter] = useState<ServiceCenterType[]>([]);

  const [filterStatus, setFilterStatus] = useState("DESC");
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({
    offset: 0,
    limit: 25,
    search: "",
    startAt: "",
    serviceCenterName: "",
    endAt: "",
    orderBy: "DESC",
    status: "NOT_FINISHED_YET",
  });
  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });

  const { fetchAllServiceCenterTransaction, fetchOneServiceCenterTransaction } =
    useServiceCenterTransaction();

  const debouncedCustomerName = useDebounce(search, 300);

  const debouncedServicecenter = useDebounce(selectedServiceCenter, 300);

  useEffect(() => {
    setQuery((prev) => ({
      ...prev,
      serviceCenterName: debouncedServicecenter,
    }));
  }, [debouncedServicecenter]);

  useEffect(() => {
    searchCustomerName(search);
  }, [debouncedCustomerName]);

  const searchCustomerName = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          offset: 0,
          limit: 25,
          search: value,
        };
      });
      setPage(1);
    } else if (value !== null) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: "",
        };
      });
    }
  };

  const getServiceCenter = async () => {
    try {
      httpRequest.get("/service-center").then(({ data }) => {
        setServiceCenter(data.payload.results);
      });
    } catch (error) {
      console.log(error);
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    getServiceCenter();
    if (updateParams) {
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllServiceCenterTransaction(query);
      setDateTemp({
        startAt: "",
        endAt: "",
      });
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchAllServiceCenterTransaction(latestParams.query);
      dispatch(enableUpdateParams(true));
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt,
      });
    }
  }, [query, updateParams]);

  useEffect(() => {
    setTotalData(serviceCenterStateTransaction.data.payload.count);
    if (serviceCenterStateTransaction.data.payload.count > 0) {
      setTotalPage(
        Math.ceil(
          serviceCenterStateTransaction.data.payload.count / rowsPerPage
        )
      );
    } else {
      setTotalPage(1);
    }
  }, [serviceCenterStateTransaction.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        startAt: dateTemp.startAt || oldVal.startAt,
        endAt: dateTemp.endAt || oldVal.endAt,
        offset: 0,
        limit: value,
      };
    });
  }

  const data = serviceCenterStateTransaction.data.payload.results.map(
    (item: any) => {
      return {
        ...item,
        // aroLabel:item?.aroMetadata?.labelName,
        serviceCenterName: (
          <div>
            <Typography variant="body1">{item?.serviceCenterName}</Typography>
          </div>
        ),
        customerName: (
          <Typography variant="body1">
            {validateMaxText(12, item?.metaUser.customerName)}
          </Typography>
        ),
        problem: (
          <Box display="flex" justifyContent="center" flexDirection="column">
            {item?.problem}
          </Box>
        ),
        productName: (
          <Typography variant="body1">
            {item?.metaProduct?.product?.productName}
          </Typography>
        ),
        createdAt: (
          <Typography variant="body1">
            {convertDateTimeToText(item.createdAt, "dd mmm yyyy hh:mm")}
          </Typography>
        ),
        serviceStartAt: (
          <Typography variant="body1">
            {convertDateTimeToText(item?.serviceStartAt, "dd mmm yyyy")} -{" "}
            {item?.serviceFinishAt !== null ? (
              <>{convertDateTimeToText(item?.serviceFinishAt, "dd mmm yyyy")}</>
            ) : (
              "Belum Selesai"
            )}
          </Typography>
        ),
      };
    }
  );

  function setFilter(sort: "ASC" | "DESC") {
    setFilterStatus(sort);
    if (sort === "ASC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "ASC",
        };
      });
    } else if (sort === "DESC") {
      setQuery((val: any) => {
        return {
          ...val,
          orderBy: "DESC",
        };
      });
    }
  }

  useEffect(() => {
    if (activeTab === 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: "NOT_FINISHED_YET",
        };
      });
    }
    if (activeTab === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: "FINISHED",
        };
      });
    }
    if (activeTab === 2) {
      setQuery((oldVal: any) => {
        const { status, ...rest } = oldVal;
        return rest;
      });
    }
  }, [activeTab]);

  return (
    <Layout
      title="Service Center Transaction"
      loading={serviceCenterStateTransaction.isLoadingTransaction}
    >
      <HeaderPage
        title="Service Center Transaction"
        subtitle="List of all Service Center Transaction"
      />

      <Grid
        container
        spacing={3}
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 10 }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-status" shrink>
              Service Center
            </InputLabel>
            <Select
              label="Service Center"
              placeholder="All"
              labelId="select-service-center"
              // multiple={true}
              value={selectedServiceCenter}
              onChange={(event: any) => {
                setSelectedServiceCenter(event.target.value);
              }}
              displayEmpty={true}
            >
              <MenuItem value="">ALL</MenuItem>
              {serviceCenter.map((item: any) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1} lg={1}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="select-status" shrink>
              Sort
            </InputLabel>
            <Select
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilter(event.target.value);
              }}
            >
              <MenuItem value="ASC">ASC</MenuItem>
              <MenuItem value="DESC">DESC</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }
            notSetDefaultDate={true}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by Customer Name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: COLORS.grey200,
            padding: 0,
            borderRadius: 10,
          }}
        >
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              setActiveTab(value);
              dispatch(
                findAllServiceCenterTransaction({
                  ...initialState["data"],
                })
              );
            }}
          />

          <CustomizedTable
            data={data}
            headers={headers}
            totalData={totalData}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={changePagination}
            onRowsPerPageChange={changeRowsPerPage}
            showAction={["detail"]}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: "solid",
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
    aroChip: {
      borderColor: COLORS.warn,
      backgroundColor: COLORS.accent,
      borderRadius: 10,
      marginRight: 5,
      padding: 1,
    },
  })
);
