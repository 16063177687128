import {
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Box,
  Modal,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import AppButton from '../../components/Form/AppButton';
import { useLocation, useParams } from 'react-router-dom';
import HeaderPage from '../../components/Layout/HeaderPage';
import { Link } from 'react-router-dom';
import COLORS from '../../styles/colors';
import { Close } from '@material-ui/icons';
import FormInput from '../../components/Form/FormInput';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import useStock from '../../hooks/useStock';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../redux/store';

let headers: ITableHeader[] = [
  {
    title: 'Date',
    align: 'left',
    sortable: true,
    column: 'createdAt',
    type: 'date',
    format: 'dd mmm yyyy hh:mm',
  },
  {
    title: 'Qty',
    align: 'left',
    column: 'stockChanges',
  },
  {
    title: 'Stok Awal',
    align: 'left',
    column: 'stockBefore',
  },
  {
    title: 'Stok Akhir',
    align: 'left',
    column: 'stockAfter',
  },
  {
    title: 'Updated By',
    align: 'left',
    column: 'createdByName',
  },
  {
    title: 'Role',
    align: 'left',
    column: 'role',
  },
  {
    title: 'Note',
    align: 'left',
    column: 'note',
    width: '25%',
  },
];

type IStockRetailDetail = {
  id: string;
  product_code: string;
  name: string;
};

export default function StockRetailDetail() {
  const location = useLocation();
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();

  const stockState = useSelector(
    (state: ApplicationState) => state.stockRetail
  );
  const productState = useSelector((state: ApplicationState) => state.product);
  const { fetchStockHistory } = useStock();

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '' });

  const [detail] = useState<IStockRetailDetail>({
    id: '',
    product_code: '',
    name: '',
  });

  let title = 'Detail Stok ' + detail.name;
  let subtitle = 'Detailed information about stock';

  useEffect(() => {
    fetchStockHistory(id, query);
  }, [query]);

  useEffect(() => {
    if (stockState.data.payload.count > 0) {
      setTotalData(stockState.data.payload.count);
      setTotalPage(Math.ceil(stockState.data.payload.count / rowsPerPage));
    }
  }, [stockState.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  let listHistory = stockState.data.payload.results.map((item) => ({
    ...item,
    createdByName:
      item.createdByAdmin && item.createdByAdmin.name
        ? item.createdByAdmin.name
        : item.createdByCustomer && item.createdByCustomer.customerName
          ? item.createdByCustomer.customerName
          : item.createdById || 'SYSTEM',
    role: item.createdByAdmin
      ? 'Admin'
      : item.createdByCustomer
        ? 'Customer'
        : 'SYSTEM',
    stockBefore: <Typography>{item.stockBefore}</Typography>,
    stockAfter: <Typography>{item.stockAfter}</Typography>,
    stockChanges: (
      <Typography>
        {item.stockChanges > 0 && '+'}{item.stockChanges}
      </Typography>
    ),
    note: item.note
      ? item.note
      : !item.createdByAdmin && !item.createdByAdmin
        ? 'Initialize by system'
        : null,
  }));

  return (
    <Layout title={title} loading={stockState.isLoadingStock}>
      <HeaderPage
        title={'Detail Stok (' + productState.product.sku + ' ' + productState.product.productName + ')'}
        subtitle={subtitle}
        showBackButton
        marginBottom={20}
      />

      <Box>
        <CustomizedTable
          data={listHistory}
          headers={headers}
          totalData={totalData}
          page={page}
          totalPage={totalPage}
          rowsPerPage={rowsPerPage}
          onChange={(newPage) => changePagination(newPage)}
          onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
        />
      </Box>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    processCircle: {
      width: 25,
      height: 25,
      backgroundColor: COLORS.accent,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: COLORS.grey,
    },
    cardMedia: {
      borderColor: COLORS.grey,
      borderWidth: 1,
      borderStyle: 'solid',
      width: 69,
      height: 69,
      borderRadius: 10,
      backgroundColor: COLORS.accent,
    },
  })
);
