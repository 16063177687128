/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-comment-textnodes */
import {
  Box,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import ImagePreview from "../../components/Card/ImagePreview";
import AppButton from "../../components/Form/AppButton";
import FilterDate from "../../components/Form/FilterDate";
import FormInput from "../../components/Form/FormInput";
import Search from "../../components/Form/Search";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import AppTabs from "../../components/Tabs/AppTabs";
import {
  convertDateTimeToString,
  convertDateTimeToText,
} from "../../helpers/functions/datetime";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import useCollection from "../../hooks/useCollection";
import useDebounce from "../../hooks/useDebounce";
import { findAllCollection } from "../../redux/action/collection.action";
import {
  enableUpdateParams,
  setLatestParams,
} from "../../redux/action/config-app.action";
import { initialState } from "../../redux/reducer/collection.reducer";
import { ApplicationState } from "../../redux/store";
import { CollectionResponse } from "../../types/collection.type";
import { getHeaders, getToken } from "../../helpers/auth";
import { validateMaxText } from "../../helpers/functions/text";
const tabs = ["Waiting Approval", "Approved", "Rejected", "Semua Collection"];
export default function Collection() {
  const classes = useStyles();
  const [query, setQuery] = useState({
    limit: 25,
    offset: 0,
    search: "",
    status: "waiting_approval",
    startAt: "",
    endAt: "",
  });
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const collectionState = useSelector(
    (state: ApplicationState) => state.collection
  );
  const { updateParams, latestParams } = useSelector(
    (state: ApplicationState) => state.configApp
  );
  const { fetchAllCollection, updateCollectionApproval } = useCollection();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [tempCollectionData, setTempCollectionData] =
    useState<CollectionResponse>();
  const [isRemark, setIsRemark] = useState<string>();
  const [search, setSearch] = useState("");
  const [isWarranty, setIsWarranty] = useState<string>("yes");
  const [isShowModalApproval, setIsShowModalApproval] = useState(false);
  const [isShowModalApprovalWarranty, setIsShowModalApprovalWarranty] =
    useState(false);
  const [isShowModalRejectedWarranty, setIsShowModalRejectedWarranty] =
    useState(false);
  const debouncedTitle = useDebounce(search, 300);
  const [dateTemp, setDateTemp] = useState({
    startAt: "",
    endAt: "",
  });

  const [invoiceUrl, setInvoiceUrl] = useState("");

  const headersWaitingApproval: ITableHeader[] = [
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Phone",
      column: "phoneNumber",
    },
    {
      title: "Created Date",
      column: "createdAt",
      type: "date",
    },
    {
      title: "Actions",
      align: "center",
      column: "actions",
    },
  ];

  const headersApproval: ITableHeader[] = [
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Phone",
      column: "phoneNumber",
    },
    {
      title: "Approval Date",
      column: "updatedAt",
      type: "date",
    },
    {
      title: "Approval By",
      column: "admin",
    },
    {
      title: "Role",
      column: "admin",
    },
    {
      title: "Note",
      column: "remark",
    },
  ];

  const headersRejected: ITableHeader[] = [
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Phone",
      column: "phoneNumber",
    },
    {
      title: "Rejected Date",
      column: "updatedAt",
      type: "date",
    },
    {
      title: "Rejected By",
      column: "admin",
    },
    {
      title: "Role",
      column: "admin",
    },
    {
      title: "Note",
      column: "remark",
    },
  ];

  const headersAll: ITableHeader[] = [
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Phone",
      column: "phoneNumber",
    },
    {
      title: "Invoice",
      column: "invoiceId",
    },
    {
      title: "Created Date",
      column: "createdAt",
      type: "date",
    },

    {
      title: "Note",
      column: "remark",
    },
    {
      title: "Actions",
      align: "center",
      column: "actions",
    },
  ];

  useEffect(() => {
    if (updateParams) {
      console.info("update true");
      dispatch(
        setLatestParams({
          pathname: location.pathname,
          params: generateQueryString(query),
          query: {
            limit: query["limit"],
            offset: query["offset"],
            page,
          },
        })
      );
      fetchAllCollection(query);
      setDateTemp({
        startAt: "",
        endAt: "",
      });
    } else {
      console.info("update false");
      setPage(latestParams.query.page || page || 1);
      fetchAllCollection(latestParams.query);
      dispatch(enableUpdateParams(true));
      setDateTemp({
        startAt: latestParams.query.startAt,
        endAt: latestParams.query.endAt,
      });
    }
  }, [query]);

  useEffect(() => {
    setTotalData(collectionState.data.payload.count);
    if (collectionState.data.payload.count > 0) {
      setTotalPage(Math.ceil(collectionState.data.payload.count / rowsPerPage));
    } else {
      setTotalPage(1);
    }
  }, [collectionState.data]);

  useEffect(() => {
    searchCollection(search);
  }, [debouncedTitle]);

  const searchCollection = async (value: any) => {
    if (value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: value,
        };
      });
    } else {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          search: "",
        };
      });
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: "waiting_approval",
        };
      });
    }
    if (activeTab === 1) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: "approved",
        };
      });
    }
    if (activeTab === 2) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: "rejected",
        };
      });
    }

    if (activeTab === 3) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          status: "all",
        };
      });
    }
  }, [activeTab]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
        startAt: dateTemp.startAt || oldVal.startAt,
        endAt: dateTemp.endAt || oldVal.endAt,
      };
    });
  }

  const renderAction = (item: CollectionResponse) => {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <AppButton
          onClick={() => {
            setTempCollectionData(item);
            handleOpenModalApproval();
          }}
          size="small"
          color="primary"
        >
          {activeTab !== 3 ? "Approve" : "Detail"}
        </AppButton>
      </Box>
    );
  };

  const handleOpenModalApproval = () => {
    setIsShowModalApproval(true);
  };

  const handleCloseModalApproval = () => {
    setIsShowModalApproval(false);
  };

  const handleOpenModalWarranty = (value: string) => {
    if (value == "approved") {
      setIsShowModalApprovalWarranty(true);
    } else {
      setIsShowModalRejectedWarranty(true);
    }
  };

  const handleCloseModalApprovalWarranty = () => {
    setIsShowModalApprovalWarranty(false);
  };

  const handleCloseModalRejectedWarranty = () => {
    setIsShowModalRejectedWarranty(false);
  };

  const data = collectionState.data.payload.results
    .filter((item: any) => activeTab !== 0 || item.isFromOPA !== true)
    .map((item: CollectionResponse) => {
      return {
        name: validateMaxText(20, item.customer.customerName),
        phoneNumber: item.customer.phoneNumber,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        invoiceId: item.invoiceId,
        isFromOpa: item.isFromOPA,
        admin: "admin",
        remark: item.remark,
        actions: renderAction(item),
      };
    });

  const submit = (type: "approved" | "rejected") => {
    if (type === "approved") {
      updateCollectionApproval({
        status: type,
        collectionId: tempCollectionData?.collectionId,
        remark: isRemark,
        warranty: isWarranty,
        createdAt: tempCollectionData?.createdAt,
      });
      handleCloseModalApproval();
      handleCloseModalApprovalWarranty();
      fetchAllCollection(query);
    }
    if (type === "rejected") {
      updateCollectionApproval({
        status: type,
        collectionId: tempCollectionData?.collectionId,
        remark: isRemark,
        warranty: isWarranty,
        createdAt: tempCollectionData?.createdAt,
      });
      handleCloseModalApproval();
      handleCloseModalRejectedWarranty();
      fetchAllCollection(query);
    }
  };

  useEffect(() => {
    setInvoiceUrl(
      `${process.env.REACT_APP_WEBSITE_URL}/invoice/${tempCollectionData?.invoiceId}`
    );
  }, [tempCollectionData?.invoiceId]);

  return (
    <Layout
      title="Approval Oxone Collections"
      loading={collectionState.isLoadingCollection}
    >
      <HeaderPage
        title="Approval Oxone Collections"
        subtitle="List of all Approval Oxone Collections"
      />
      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={12} lg={5}></Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FilterDate
            start_at={dateTemp.startAt || query.startAt}
            end_at={dateTemp.endAt || query.endAt}
            onChange={(start_at: string, end_at: string) =>
              setQuery((val: any) => {
                return {
                  ...val,
                  startAt: start_at,
                  endAt: end_at,
                };
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Search
            placeholder="Search by name"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppTabs
            active={activeTab}
            tabs={tabs}
            onChange={(value: number) => {
              setActiveTab(value);
              dispatch(
                findAllCollection({
                  ...initialState["data"],
                })
              );
            }}
          />
          {activeTab == 0 && (
            <CustomizedTable
              data={data}
              headers={headersWaitingApproval}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
          {activeTab == 1 && (
            <CustomizedTable
              data={data}
              headers={headersApproval}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
          {activeTab == 2 && (
            <CustomizedTable
              data={data}
              headers={headersRejected}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
          {activeTab == 3 && (
            <CustomizedTable
              data={data}
              headers={headersAll}
              totalData={totalData}
              page={page}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              onChange={(newPage) => changePagination(newPage)}
              onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            />
          )}
        </Grid>
      </Grid>

      <Modal
        open={isShowModalApproval}
        onClose={handleCloseModalApproval}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className={`${classes.containerModal} ${classes.scrollableContent}`}
        >
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Detail Approval</Typography>

              <button onClick={() => handleCloseModalApproval()}>
                <Close />
              </button>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={1}
            >
              <TextField
                label="Name"
                variant="standard"
                fullWidth
                style={{ marginRight: 15 }}
                value={tempCollectionData?.customer.customerName}
                disabled={activeTab === 3 ? true : false}
              />
              <TextField
                label="Phone Number"
                variant="standard"
                fullWidth
                value={tempCollectionData?.customer.phoneNumber}
                disabled={activeTab === 3 ? true : false}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={2}
            >
              <TextField
                label="Nomer Serial"
                variant="standard"
                fullWidth
                style={{ marginRight: 15 }}
                value={tempCollectionData?.serialNumber}
                disabled={activeTab === 3 ? true : false}
              />
              <TextField
                label="Nomer Invoice"
                variant="standard"
                fullWidth
                value={tempCollectionData?.invoiceId}
                disabled={activeTab === 3 ? true : false}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginTop={2}
            >
              <TextField
                label="SKU"
                variant="standard"
                fullWidth
                style={{ marginRight: 15 }}
                value={tempCollectionData?.sku}
                disabled={activeTab === 3 ? true : false}
              />
              <TextField
                label="Tanggal Transaksi"
                variant="standard"
                fullWidth
                value={convertDateTimeToString(tempCollectionData?.createdAt)}
                disabled={activeTab === 3 ? true : false}
              />
            </Box>

            <FormControl style={{ marginTop: 15 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Extra Warranty
              </FormLabel>

              {activeTab === 3 ? (
                <Typography style={{ color: "#9e9e9e" }}>
                  {tempCollectionData?.extraWarranty
                    ? convertDateTimeToText(tempCollectionData?.extraWarranty)
                    : "Tidak dapat extra warranty"}
                </Typography>
              ) : (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={isWarranty}
                  onChange={(e: any) => {
                    setIsWarranty(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              )}
            </FormControl>

            {activeTab === 0 || !tempCollectionData?.isFromOPA ? (
              <Box
                paddingTop={2}
                marginLeft={10}
                style={{ width: 400, minWidth: 300 }}
              >
                <ImagePreview
                  type="preview-product"
                  image={
                    tempCollectionData && tempCollectionData.invoiceImages[0]
                  }
                />
              </Box>
            ) : (
              <Box mt={2}>
                <a href={`${invoiceUrl}`} target="_blank">
                  Link Detail Invoice
                </a>
              </Box>
            )}

            {activeTab === 3 ? (
              <Box mt={2}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Note
                </FormLabel>
                <Typography
                  style={{
                    color: "#9E9E9E",
                  }}
                >
                  {tempCollectionData?.remark}
                </Typography>
              </Box>
            ) : (
              <TextField
                multiline
                rows={4}
                label="Note"
                fullWidth
                placeholder="Masukan Catatan"
                onChange={(e: any) => setIsRemark(e.target.value)}
                value={isRemark}
              />
            )}

            <Box display="flex" justifyContent="flex-end" marginTop={3}>
              {activeTab === 3 ? (
                <Box>
                  <AppButton onClick={() => handleCloseModalApproval()}>
                    Cancel
                  </AppButton>
                </Box>
              ) : (
                <>
                  <Box>
                    <AppButton
                      color="secondary"
                      onClick={() => handleOpenModalWarranty("rejected")}
                    >
                      Reject
                    </AppButton>
                  </Box>
                  <Box>
                    <AppButton
                      onClick={() => handleOpenModalWarranty("approved")}
                    >
                      Approve
                    </AppButton>
                  </Box>
                </>
              )}
            </Box>
          </div>
        </div>
      </Modal>

      <Modal
        open={isShowModalApprovalWarranty}
        onClose={handleCloseModalApprovalWarranty}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Approve Extra Warranty?</Typography>

              <button onClick={() => handleCloseModalApprovalWarranty()}>
                <Close />
              </button>
            </Box>

            <Typography>Are you sure want to approve this?</Typography>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalApprovalWarranty}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={() => submit("approved")}>
                  Yes, Sure
                </AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>

      <Modal
        open={isShowModalRejectedWarranty}
        onClose={handleCloseModalRejectedWarranty}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.containerModal}>
          <div className={classes.contentModal}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5">Reject Extra Warranty?</Typography>

              <button onClick={() => handleCloseModalRejectedWarranty()}>
                <Close />
              </button>
            </Box>

            <Typography>Are you sure want to reject this?</Typography>

            <Box display="flex" justifyContent="flex-end">
              <Box>
                <AppButton
                  color="secondary"
                  onClick={handleCloseModalRejectedWarranty}
                >
                  Cancel
                </AppButton>
              </Box>
              <Box>
                <AppButton onClick={() => submit("rejected")}>
                  Yes, Sure
                </AppButton>
              </Box>
            </Box>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    paperTitle: {
      // padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      margin: theme.spacing(2, 0, 1, 0),
    },
    containerTable: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalApprovalgnContent: "center",
    },
    scrollableContent: {
      maxHeight: "600px",
      overflowY: "auto",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);
