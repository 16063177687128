import {
  Grid,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useHistory, useParams } from "react-router-dom";
import HeaderPage from "../../components/Layout/HeaderPage";
import { Autocomplete } from "@material-ui/lab";
import ListDO from "./ListDO";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import useFetch from "../../hooks/useFetch";
import { DistributorProperties } from "../../types/b2b/distributor/distributor.type";
import axios from "axios";
import { BranchProperties } from "../../types/b2b/branch/branch.type";
import { AdminSingle } from "../../types/admin.type";
import {
  TripAllItemsCheck,
  TripCreateRequest,
  TripReturnAllItems,
} from "../../types/b2b/trip.type";
import { httpRequest } from "../../helpers/api/api";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import { convertDateTimeToText } from "../../helpers/functions/datetime";
import { WarehouseProperties } from "../../types/b2b/warehouse.type";
import { setB2BID } from "../../redux/action/b2b.action";

type Props = {
  type?: "trip" | "pickup";
};

const initialQuery = {
  limit: 100,
  page: 1,
  sort: "name,ASC",
};

const initialQueryDriver = {
  limit: 100,
  page: 1,
  sort: "name,ASC",
  role: "driver",
};

const initialQueryWarehouse = {
  limit: 100,
  page: 1,
};

export default function TripCreate(props: Props) {
  let title = props.type === "trip" ? "Create Trip" : "Create Pickup";
  let subtitle = props.type === "trip" ? "Create a trip" : "Create a pickup";

  let { id } = useParams<{ id: string }>();
  const history = useHistory();

  const classes = useStyles();

  const { state: locationState } = useSelector(
    (state: ApplicationState) => state.configApp
  );

  const [openDistributor, setOpenDistributor] = React.useState<boolean>(false);
  const [selectedDistributor, setSelectedDistributor] =
    React.useState<DistributorProperties | null>(null);
  const [branches, setBranches] = React.useState<BranchProperties[]>([]);
  const [selectedBranch, setSelectedBranch] =
    React.useState<BranchProperties | null>(null);
  const [openDriver, setOpenDriver] = React.useState<boolean>(false);
  const [selectedDriver, setSelectedDriver] =
    React.useState<AdminSingle | null>(null);
  const [selectedWarehouse, setSelectedWarehouse] =
    React.useState<WarehouseProperties | null>(null);
  const [loadingAllItems, setLoadingAllItems] = React.useState<boolean>(false);
  const [currentDataItems, setCurrentDataItems] =
    React.useState<TripAllItemsCheck>({
      deliveryOrders: [],
      pickupOrders: [],
      replacementPickups: [],
      replacementDeliveries: [],
    });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [pickupPIC, setPickupPIC] = React.useState<string>("");
  const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);
  const [deliveryOrderIds, setDeliveryOrderIds] = React.useState<string[]>([]);
  const [pickupOrderIds, setPickupOrderIds] = React.useState<string[]>([]);
  const [replacementPickupIds, setReplacementPickupIds] = React.useState<
    string[]
  >([]);
  const [replacementDeliveryIds, setReplacementDeliveryIds] = React.useState<
    string[]
  >([]);

  const cancel = () => {
    history.go(-1);
  };

  const submit = async () => {
    try {
      setLoadingSubmit(true);
      const dataTrip: TripCreateRequest = {
        distributorMetadata: selectedDistributor,
        warehouseMetadata: selectedWarehouse,
        branchMetadata: selectedBranch,
        agentMetadata: null,
        doIds: deliveryOrderIds,
        poIds: pickupOrderIds,
        rplpIds: replacementPickupIds,
        rpldIds: replacementDeliveryIds,
        driverId: selectedDriver?.adminId || "",
      };

      await httpRequest.post("trip", { ...dataTrip });

      setLoadingSubmit(false);

      history.go(-1);
    } catch (error) {
      setLoadingSubmit(false);
      throw error;
    }
  };

  const submitPickup = async () => {
    try {
      const data = {
        distributorMetadata: selectedDistributor,
        branchMetadata: selectedBranch,
        agentMetadata: null,
        doIds: deliveryOrderIds,
        poIds: pickupOrderIds,
        rplpIds: replacementPickupIds,
        rpldIds: replacementDeliveryIds,
        pickupPIC: pickupPIC,
      };

      setLoadingSubmit(true);

      await httpRequest.post("pickup", { ...data });

      setLoadingSubmit(false);

      history.go(-1);
    } catch (error) {
      setLoadingSubmit(false);
      throw error;
    }
  };

  const {
    data: distributors,
    loading: loadingDistributors,
    fetchDataTableAlt: fetchDataDistributor,
  } = useFetch<DistributorProperties>({
    url: "distributor",
    query: initialQuery,
    staticUrl: true,
  });

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    if (openDistributor) {
      fetchDataDistributor(source);
    }

    return () => {
      source.cancel();
    };
  }, [openDistributor]);

  const {
    data: drivers,
    loading: loadingDrivers,
    fetchDataTableAlt: fetchDataDriver,
  } = useFetch<AdminSingle>({
    url: "admin",
    query: initialQueryDriver,
    staticUrl: true,
  });

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    if (openDriver) {
      fetchDataDriver(source);
    }

    return () => {
      source.cancel();
    };
  }, [openDriver]);

  const {
    data: warehouses,
    loading: loadingWarehouses,
    fetchDataTableAlt: fetchDataWarehouse,
  } = useFetch<WarehouseProperties>({
    url: "warehouse",
    query: initialQueryWarehouse,
    staticUrl: true,
  });

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    fetchDataWarehouse(source);

    return () => {
      source.cancel();
    };
  }, []);

  React.useEffect(() => {
    if (warehouses && warehouses.length > 0) {
      setSelectedWarehouse(warehouses[0]);
    }
  }, [warehouses]);

  React.useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchAllItems = async () => {
      try {
        setLoadingAllItems(true);
        const query = generateQueryString({
          branchId: selectedBranch ? selectedBranch?.branchId : null,
        });
        await httpRequest
          .get<{ payload: TripReturnAllItems }>("trip/items/all/" + query, {
            cancelToken: source.token,
          })
          .then((res) => {
            if (res && res.status === 200) {
              const deliveryOrders = res.data.payload.deliveryOrders.map(
                (item) => {
                  return {
                    ...item,
                    id: item.doId,
                    createdAtConvert: convertDateTimeToText(
                      item.createdAt,
                      "dd mmm yyyy hh:mm"
                    ),
                    qtyProduct: item.data_products
                      ? item.data_products.length
                      : 0,
                  };
                }
              );

              const pickupOrders = res.data.payload.pickupOrders.map((item) => {
                return {
                  ...item,
                  id: item.poId,
                  createdAtConvert: convertDateTimeToText(
                    item.createdAt,
                    "dd mmm yyyy hh:mm"
                  ),
                  qtyProduct: item.data_products
                    ? item.data_products.length
                    : 0,
                };
              });

              const replacementPickups =
                res.data.payload.replacementPickups.map((item) => {
                  return {
                    ...item,
                    id: item.rplpId,
                    createdAtConvert: convertDateTimeToText(
                      item.createdAt,
                      "dd mmm yyyy hh:mm"
                    ),
                    qtyProduct: item.data_products
                      ? item.data_products.length
                      : 0,
                  };
                });

              const replacementDeliveries =
                res.data.payload.replacementDeliveries.map((item) => {
                  return {
                    ...item,
                    id: item.rpldId,
                    createdAtConvert: convertDateTimeToText(
                      item.createdAt,
                      "dd mmm yyyy hh:mm"
                    ),
                    qtyProduct: item.data_products
                      ? item.data_products.length
                      : 0,
                  };
                });

              setCurrentDataItems({
                deliveryOrders,
                pickupOrders,
                replacementPickups,
                replacementDeliveries,
              });
            }
          });

        setLoadingAllItems(false);
      } catch (error) {
        setLoadingAllItems(false);
        throw error;
      }
    };

    selectedBranch && fetchAllItems();

    return () => {
      source.cancel();
    };
  }, [selectedBranch]);

  const handleChangeSelection = (currId: string, type: number) => {
    const found = selected.find((item) => item === currId);
    if (found) {
      const newData = selected.filter((item) => item !== currId);
      setSelected(newData);

      if (type === 0) {
        const foundDOIds = deliveryOrderIds.find((item) => item === currId);
        if (foundDOIds) {
          setDeliveryOrderIds(
            deliveryOrderIds.filter((item) => item !== currId)
          );
        }
      } else if (type === 1) {
        const foundPOIds = pickupOrderIds.find((item) => item === currId);
        if (foundPOIds) {
          setPickupOrderIds(pickupOrderIds.filter((item) => item !== currId));
        }
      } else if (type === 2) {
        const foundRPLPIds = replacementPickupIds.find(
          (item) => item === currId
        );
        if (foundRPLPIds) {
          setReplacementPickupIds(
            replacementPickupIds.filter((item) => item !== currId)
          );
        }
      } else if (type === 3) {
        const foundRPLDIds = replacementDeliveryIds.find(
          (item) => item === currId
        );
        if (foundRPLDIds) {
          setReplacementDeliveryIds(
            replacementDeliveryIds.filter((item) => item !== currId)
          );
        }
      }
    } else {
      setSelected([...selected, currId]);

      if (type === 0) {
        setDeliveryOrderIds([...deliveryOrderIds, currId]);
      } else if (type === 1) {
        setPickupOrderIds([...pickupOrderIds, currId]);
      } else if (type === 2) {
        setReplacementPickupIds([...replacementPickupIds, currId]);
      } else if (type === 3) {
        setReplacementDeliveryIds([...replacementDeliveryIds, currId]);
      }
    }
  };

  const handleSelectAll = (activeTab: number, checkAll: boolean) => {
    if (activeTab === 0) {
      const deliveryOrdersIds = currentDataItems.deliveryOrders.map(
        (item) => item.doId
      );
      let newSelected = [...selected];
      for (let i = 0; i < deliveryOrdersIds?.length; i++) {
        newSelected = newSelected?.filter(
          (item) => item !== deliveryOrdersIds[i]
        );
      }
      if (checkAll) {
        setSelected([...newSelected, ...deliveryOrdersIds]);
        setDeliveryOrderIds(deliveryOrdersIds);
      } else {
        setSelected([...newSelected]);
        setDeliveryOrderIds([]);
      }
    } else if (activeTab === 1) {
      const pickupOrdersIds = currentDataItems.pickupOrders.map(
        (item) => item.poId
      );
      let newSelected = [...selected];
      for (let i = 0; i < pickupOrdersIds?.length; i++) {
        newSelected = newSelected?.filter(
          (item) => item !== pickupOrdersIds[i]
        );
      }
      if (checkAll) {
        setSelected([...newSelected, ...pickupOrdersIds]);
        setPickupOrderIds(pickupOrdersIds);
      } else {
        setSelected([...newSelected]);
        setPickupOrderIds([]);
      }
    } else if (activeTab === 2) {
      const replacementPickupsIds = currentDataItems.replacementPickups.map(
        (item) => item.rplpId
      );
      let newSelected = [...selected];
      for (let i = 0; i < replacementPickupsIds?.length; i++) {
        newSelected = newSelected?.filter(
          (item) => item !== replacementPickupsIds[i]
        );
      }
      if (checkAll) {
        setSelected([...newSelected, ...replacementPickupsIds]);
        setReplacementPickupIds(replacementPickupsIds);
      } else {
        setSelected([...newSelected]);
        setReplacementPickupIds([]);
      }
    } else if (activeTab === 3) {
      const replacementDeliveriesIds =
        currentDataItems.replacementDeliveries.map((item) => item.rpldId);
      let newSelected = [...selected];
      for (let i = 0; i < replacementDeliveriesIds?.length; i++) {
        newSelected = newSelected?.filter(
          (item) => item !== replacementDeliveriesIds[i]
        );
      }
      if (checkAll) {
        setSelected([...newSelected, ...replacementDeliveriesIds]);
        setReplacementDeliveryIds(replacementDeliveriesIds);
      } else {
        setSelected([...newSelected]);
        setReplacementDeliveryIds([]);
      }
    }
  };

  const dispatch = useDispatch();

  const handlePressDetail = (data: { value: any; activeTab: number }) => {
    dispatch(
      setB2BID(
        data.activeTab === 0
          ? data.value.orderId
          : data.activeTab === 1
            ? data.value.returnId
            : data.value.rplId
      )
    );

    if (data.activeTab === 0) {
      history.push("/delivery-order/" + data.value.doId);
    } else if (data.activeTab === 1) {
      history.push("/pickup-order/" + data.value.poId);
    } else if (data.activeTab === 2) {
      history.push("/replacement-pickup/" + data.value.rplpId);
    } else if (data.activeTab === 3) {
      history.push("/replacement-delivery/" + data.value.rpldId);
    }
  };

  return (
    <Layout title={title}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={8}>
          <HeaderPage title={title} subtitle={subtitle} showBackButton />
        </Grid>

        <Grid item xs={4} md={4} lg={2}>
          <AppButton fullWidth color="secondary" onClick={cancel}>
            Cancel
          </AppButton>
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          {props.type === "trip" && (
            <AppButton
              disabled={selected.length === 0 || !selectedDriver}
              loading={loadingSubmit}
              fullWidth
              color="primary"
              onClick={submit}
            >
              Save
            </AppButton>
          )}

          {props.type === "pickup" && (
            <AppButton
              disabled={selected.length === 0 || !pickupPIC}
              loading={loadingSubmit}
              fullWidth
              color="primary"
              onClick={submitPickup}
            >
              Save Pickup
            </AppButton>
          )}
        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.paper}>
        <Grid container className={classes.containerForm}>
          <Grid item xs={12} className={classes.input}>
            <Autocomplete
              id="select-on-focus-distributor"
              open={openDistributor}
              onOpen={() => {
                setOpenDistributor(true);
                setBranches([]);
                setSelectedBranch(null);

                setSelected([]);
                setCurrentDataItems({
                  deliveryOrders: [],
                  pickupOrders: [],
                  replacementPickups: [],
                  replacementDeliveries: [],
                });

                setDeliveryOrderIds([]);
                setPickupOrderIds([]);
                setReplacementPickupIds([]);
                setReplacementDeliveryIds([]);
              }}
              onClose={() => {
                setOpenDistributor(false);
              }}
              getOptionLabel={(option: DistributorProperties) => option.name}
              options={distributors}
              loading={loadingDistributors}
              value={selectedDistributor}
              onChange={(
                event: any,
                newValue: DistributorProperties | null
              ) => {
                setSelectedDistributor(newValue);
                setBranches(
                  newValue && newValue.branches ? newValue?.branches : []
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Distributor"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingDistributors ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} className={classes.input}>
            <Autocomplete
              options={branches}
              getOptionLabel={(option: BranchProperties) => option.name}
              id="select-on-focus-branch"
              renderInput={(params) => <TextField {...params} label="Branch" />}
              value={selectedBranch}
              onChange={(event: any, newValue: BranchProperties | null) => {
                setSelectedBranch(newValue);
              }}
            />
          </Grid>

          {props.type === "trip" && (
            <Grid item xs={12} className={classes.input}>
              <Autocomplete
                id="select-on-focus-driver"
                open={openDriver}
                onOpen={() => {
                  setOpenDriver(true);
                }}
                onClose={() => {
                  setOpenDriver(false);
                }}
                getOptionLabel={(option: AdminSingle) => option.name}
                options={drivers}
                loading={loadingDrivers}
                value={selectedDriver}
                onChange={(event: any, newValue: AdminSingle | null) => {
                  setSelectedDriver(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Driver"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingDrivers ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.input}>
            {props.type === "pickup" && (
              <FormInput
                onChange={(value: any) => setPickupPIC(value)}
                fullWidth
                label="PIC yang Melakukan Pickup"
              />
            )}
            <Autocomplete
              disabled
              options={warehouses}
              getOptionLabel={(option: WarehouseProperties) => option.name}
              id="select-on-focus-gudang"
              selectOnFocus
              value={selectedWarehouse}
              renderInput={(params) => (
                <TextField {...params} label="Gudang Oxone" />
              )}
            // value={value}
            // onChange={(event: any, newValue: string | null) => {
            //   setValue(newValue);
            // }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.containerForm}>
          <Grid item xs={12} className={classes.input}>
            <Typography>
              {props.type === "pickup"
                ? "Select Pickup Items"
                : "Select Trip Items"}
            </Typography>
            <Grid className={classes.row}>
              <ListDO
                selected={selected}
                selection
                data={currentDataItems}
                onSelected={(id: string, type: number) => {
                  handleChangeSelection(id, type);
                }}
                onSelectAll={(activeTab: number) => {
                  handleSelectAll(activeTab, true);
                }}
                onUnselectAll={(activeTab: number) => {
                  handleSelectAll(activeTab, false);
                }}
                onPressDetail={handlePressDetail}
                type={props.type}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(2, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
    formBranch: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
  })
);
