import { Typography } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import HeaderPage from '../../components/Layout/HeaderPage';
import Layout from '../../components/Layout/Layout';
import VoucherCard from '../../components/VoucherCard';
import { cancelTokenSource } from '../../helpers/api/api';
import useFetchOne from '../../hooks/useFetchOne';
import { EVoucherDiscountType, EVoucherStatus, initialVoucherData, VoucherProperties } from '../../types/voucher.type';
import CustomizedTable, { ITableHeader } from '../../components/Table/CustomizedTable';
import useFetch from '../../hooks/useFetch';
import { useDispatch } from 'react-redux';
import { convertNumToCurrency } from '../../helpers/functions/currency';
import { setLatestState } from '../../redux/action/config-app.action';
import { convertDateTimeToText } from '../../helpers/functions/datetime';

const headers: ITableHeader[] = [
  {
    title: 'User',
    column: 'user',
  },
  {
    title: 'Total Belanja',
    column: 'totalBelanja',
  },
  {
    title: 'Final Discount',
    column: 'finalDiscount',
  },
  {
    title: 'Waktu Claim',
    column: 'waktuClaim',
  },
]

interface ITransactionQuery {
  offset: number,
  limit: number,
  page: number,
  orderBy: string,
  voucherCode: string
}

let initialQuery: ITransactionQuery = {
  offset: 0,
  limit: 25,
  page: 1,
  orderBy: 'CREATED_AT_ASC',
  voucherCode: ''
}

const VoucherPartnerDetail = () => {
  let { id, voucherCode }: any = useParams();
  const history = useHistory()

  initialQuery = {
    ...initialQuery,
    voucherCode
  }

  const dispatch = useDispatch()

  const {
    data,
    loading,
    fetchOneData
  } = useFetchOne<VoucherProperties>({
    url: 'voucher',
    initialData: initialVoucherData
  })

  const {
    loading: transactionLoading,
    data: transactionData,
    pagination,
    query,
    handlePageChange,
    handlePerPageChange,
    setQuery,
    fetchDataTableAlt
  } = useFetch<VoucherProperties>({
    url: 'transaction-invoice',
    staticUrl: true,
    query: initialQuery
  })

  React.useEffect(() => {
    (async () => {
      await fetchOneData(voucherCode, cancelTokenSource)
      await fetchDataTableAlt(cancelTokenSource)
    })()
  }, [id])

  const dataMapped = transactionData.map((item: any) => {
    return {
      user: item.transactionMetadata.buyer.name,
      totalBelanja: convertNumToCurrency(item.finalTransactionPrice),
      finalDiscount: convertNumToCurrency(item.transactionMetadata.summaryPaymentTransaction.totalDiscountVoucher),
      waktuClaim: convertDateTimeToText(item.createdAt, 'dd mmm yyyy hh:mm')
    }
  })

  const handleBack = () => {
    history.go(-2)
  }

  return (
    <Layout title="Detail Voucher Partner" loading={loading}>
      <HeaderPage title="Detail Voucher" subtitle="Detail of voucher partner" showBackButton onBack={() => handleBack()} />

      <VoucherCard
        voucherType={data.voucherType}
        voucherCode={data.voucherCode}
        eventName={data.event.title}
        validStartAt={data.validStartAt}
        validEndAt={data.validEndAt}
        voucherStatus={data.voucherStatus}
        discountType={data.discountType}
        discountValue={data.discountValue}
        maxDiscountValue={data.maxDiscountValue}
        minTotalTransaction={data.requirement.minTotalTransaction}
        limitMaxUsePerPerson={data.limitMaxUsePerPerson}
        totalUsed={data.totalUsed}
        tags={data.event.tags}
        refCommissionValue={data.refCommissionValue}
      />

      <Typography style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 10 }}>Daftar Pengguna Voucher</Typography>

      <CustomizedTable
        data={dataMapped}
        headers={headers}
        totalData={pagination.totalData}
        // showAction={['detail']}
        page={pagination.page}
        totalPage={pagination.countPage}
        rowsPerPage={pagination.perPage}
        onChange={(newPage: number) => handlePageChange(newPage)}
        onRowsPerPageChange={(value: number) => handlePageChange(value)}
      />

    </Layout>
  )
}

export default VoucherPartnerDetail