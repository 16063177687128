import {
  Paper,
  Box,
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  CircularProgress,
  Switch,
  Typography,
  Chip,
  Snackbar,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import { useParams } from "react-router";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import Search from "../../components/Form/Search";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import Layout from "../../components/Layout/Layout";
import { LoadingScreen } from "../../components/LoadingScreen";
import CustomizedTable, { ITableHeader } from "../../components/Table/CustomizedTable";
import { cancelTokenSource, httpRequest } from "../../helpers/api/api";
import { httpRequestMultipart } from "../../helpers/api/apiMultipart";
import { generateQueryString } from "../../helpers/functions/generateQueryString";
import useDebounce from "../../hooks/useDebounce";
import useFetch, { DefaultResponse, DefaultResponseList } from "../../hooks/useFetch";
import { setDataProductStorefront, setDataRelatedProductStorefront } from "../../redux/action/storefront.action";
import { ApplicationState } from "../../redux/store";
import COLORS from "../../styles/colors";
import { ProductResponse } from "../../types/product.type";
import { IStorefrontQuery, StorefrontCategoryProperties, StorefrontProperties } from "../../types/storefront.type";

const initialQuery: any = {
  limit: 10,
  page: 1,
};

const headers: ITableHeader[] = [
  {
    title: "id",
    column: "id",
    type: "id",
  },
  {
    title: "Image",
    column: "image",
    type: "image-product",
    // width: '10%'
  },
  {
    title: "SKU",
    column: "sku",
    width: "15%",
  },
  {
    title: "Name",
    sortable: true,
    column: "name",
    width: "40%",
  },
  {
    title: "Status",
    column: "status",
  },
];

const headersDetail: ITableHeader[] = [
  {
    title: "id",
    column: "id",
    type: "id",
  },
  {
    title: "Image",
    column: "image",
    type: "image-product",
    width: "10%",
  },
  {
    title: "SKU",
    column: "sku",
    // width: '%'
  },
  {
    title: "Name",
    sortable: true,
    column: "name",
    // width: '40%'
  },
];

const filter = createFilterOptions();

export interface ProductResponseCustom extends ProductResponse {
  selected: boolean;
}

const StorefrontEdit: React.FC = () => {
  const classes = useStyles();
  const { id }: any = useParams();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();
  const storefront = useSelector((state: ApplicationState) => state.storefront);

  const titleLayout =
    id && location.pathname.includes("edit") ? "Edit Etalase" : id ? "Detail Etalase" : "Create Etalase";
  const subtitleLayout =
    id && location.pathname.includes("edit")
      ? "Update etalase data"
      : id
      ? "Detail informasi etalase"
      : "Add new etalase data";

  const [file, setFile] = React.useState<any>(null);
  const [image, setImage] = React.useState<any>(null);
  const [mode, setMode] = React.useState<"detail" | "edit" | "create">("detail");
  const [title, setTitle] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const [category, setCategory] = React.useState<StorefrontCategoryProperties>();
  const [loadingCategory, setLoadingCategory] = React.useState<boolean>(false);
  const [categoryData, setCategoryData] = React.useState<StorefrontCategoryProperties[]>([]);
  const [subtitle, setSubtitle] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  // const [relatedProductIds, setRelatedProductIds] = React.useState<string[]>([])
  // const [filterPublished, setFilterPublished] = React.useState<'all' | 'published' | 'unpublished'>('all')
  // const [dataProducts, setDataProducts] = React.useState<ProductResponseCustom[]>([])
  const [loadingProduct, setLoadingProduct] = React.useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);
  const [loadingEtalase, setLoadingEtalase] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [totalData, setTotalData] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [search, setSearch] = React.useState<string | null>(null);
  const [query, setQuery] = React.useState({ limit: 25, offset: 0, search: "" });
  const [validationErr, setValidationErr] = React.useState("");

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp);

  const debouncedTitle = useDebounce(search, 300);

  React.useEffect(() => {
    searchBank(search);
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value,
        };
      });
      setPage(1);
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: "" });
    }
  };

  React.useEffect(() => {
    if (updateParams) {
      fetchDataProducts(query);
    } else {
      setPage(latestParams.query.page || page || 1);
      fetchDataProducts(latestParams.query);
    }
  }, [query]);

  React.useEffect(() => {
    setTotalData(storefront.data.count);
    if (storefront.data.count > 0) {
      setTotalPage(Math.ceil(storefront.data.count / rowsPerPage));
    } else {
      setTotalPage(1);
    }
  }, [storefront.data]);

  function changePagination(value: number) {
    setPage(value);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    setPage(1);
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value,
      };
    });
  }

  // const {
  //   data,
  //   loading,
  //   pagination,
  //   query,
  //   search,
  //   handlePerPageChange,
  //   handlePageChange,
  //   setQuery,
  //   changeHandler,
  //   fetchDataTableAlt,
  // } = useFetch<ProductResponseCustom>({
  //   url: 'product',
  //   query: initialQuery,
  //   staticUrl: true
  // })

  const submit = async () => {
    try {
      setLoadingSubmit(true);
      const formData = new FormData();

      const productIds = storefront.relatedProductIds.map((item) => item.productId);
      const categories = [];
      const rawCategories = JSON.stringify({
        storefrontCategoryId: category?.storefrontCategoryId,
        name: category?.name,
        published: true,
      });

      categories.push(rawCategories);

      if (!title) {
        setValidationErr("Title tidak boleh kosong");
        setLoadingSubmit(false);
        return;
      }
      if (!categories || categories?.length === 0) {
        setValidationErr("Category tidak boleh kosong");
        setLoadingSubmit(false);
        return;
      }
      if (!subtitle) {
        setValidationErr("Short description tidak boleh kosong");
        setLoadingSubmit(false);
        return;
      }
      if (!productIds || productIds?.length === 0) {
        setValidationErr("Related Product tidak boleh kosong");
        setLoadingSubmit(false);
        return;
      }
      if (!file && !image) {
        setValidationErr("Gambar etalase tidak boleh kosong");
        setLoadingSubmit(false);
        return;
      }

      formData.append("image", file);
      formData.append("title", title);
      formData.append("subtitle", subtitle);
      formData.append("description", description);
      formData.append("relatedProductIds", `${JSON.stringify(productIds)}`);
      formData.append("published", "true");
      formData.append("categories", `[${categories}]`);

      if (id || location.pathname.includes("edit")) {
        await httpRequestMultipart.patch("storefront/" + id, formData);
      } else {
        await httpRequestMultipart.post("storefront", formData);
      }

      setLoadingSubmit(false);

      dispatch(setDataRelatedProductStorefront([]));

      history.go(-1);
    } catch (error) {
      setLoadingSubmit(false);
      console.error("httpRequestMultipart", JSON.stringify(error));
    }
  };

  const [que, setQue] = React.useState<any>({
    limit: 25,
    exclude: [],
  });

  React.useEffect(() => {
    getCategories();
    if (id && location.pathname.includes("edit")) {
      setMode("edit");
      fetchDataProducts(query);
      fetchDataEtalase();
    } else if (id) {
      setMode("detail");
      fetchDataEtalase();
    } else {
      fetchDataProducts(query);
      setMode("create");
    }
  }, []);

  const fetchDataEtalase = async () => {
    try {
      setLoadingEtalase(true);
      const res = await httpRequest.get<DefaultResponse<StorefrontProperties>>("storefront/" + id);

      if (res && res.data) {
        setTitle(res.data.payload.title);
        setCategory(res.data.payload.categories[0]);
        setSubtitle(res.data.payload.subtitle);
        setDescription(res.data.payload.description);
        setImage(res.data.payload.imageUrl);

        const dataProductMapped = res.data.payload.products.map((item) => {
          return {
            ...item,
            selected: true,
          };
        });
        dispatch(setDataRelatedProductStorefront(dataProductMapped));
        setLoadingEtalase(false);
      }
    } catch (error) {
      setLoadingEtalase(false);
      throw error;
    }
  };

  const fetchDataProducts = async (params?: any, exclude?: string[]) => {
    try {
      setLoadingProduct(true);

      const newQuery = generateQueryString({
        ...params,
        exclude,
      });

      const res = await httpRequest.get<DefaultResponseList<ProductResponseCustom>>("product" + newQuery);

      if (res && res.status === 200) {
        dispatch(setDataProductStorefront(res.data.payload));
        // setData(res.data.payload.results);
        // setPagination((pagination) => {
        //   return {
        //     ...pagination,
        //     page: props.query.page,
        //     next: res.data.payload.next,
        //     prev: res.data.payload.prev,
        //     totalData: res.data.payload.count,
        //     countPage: Math.ceil(res.data.payload.count / (props.query.limit || 25)),
        //   }
        // })
        setLoadingProduct(false);
      }
    } catch (error) {
      setLoadingProduct(false);
      throw error;
    }
  };

  // React.useEffect(() => {
  //   let active = true;

  //   if (open) {
  //     getCategories()
  //   }

  //   return () => {
  //     active = false;
  //   }

  // }, [open])

  const getCategories = async () => {
    try {
      const res = await httpRequest.get<DefaultResponse<{ results: StorefrontCategoryProperties[] }>>(
        "storefront/categories"
      );

      setCategoryData(res.data.payload.results);
    } catch (error) {
      console.error("Failed get storefront categories", JSON.stringify(error));
    }
  };

  const filterName = (value: string) => {
    if (value) {
      const val = value.split(" ");
      if (val && val.length > 1 && val[0] === "Add") {
        return val.slice(1).join(" ");
      } else if (val) {
        return val.join(" ");
      }
    } else {
      return "-";
    }
  };

  const handleChangeStatus = async (
    item: ProductResponseCustom,
    checked: boolean,
    type: "real-data" | "related-data"
  ) => {
    let newData = storefront.relatedProductIds;

    if (type === "real-data") {
      newData.push({
        ...item,
        selected: true,
      });
    } else {
      newData = newData.filter((da) => da.productId !== item.productId);
    }
    if (newData.length > 0) {
      const newQue = newData.map((item) => item.productId);
      await fetchDataProducts(query, newQue);
      setQue((oldVal: any) => {
        return {
          ...oldVal,
          exclude: newQue,
        };
      });
    }

    dispatch(setDataRelatedProductStorefront(newData));
  };

  const dataMapped = storefront.data.results.map((item) => {
    return {
      id: item.productId,
      image: item.images,
      sku: item.sku,
      name: item.productName,
      status: (
        <Switch
          checked={item.selected}
          onChange={(_, checked) => handleChangeStatus(item, checked, "real-data")}
          name="checkedA"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      ),
    };
  });

  const dataRelatedMapped = storefront.relatedProductIds.map((item) => {
    return {
      id: item.productId,
      image: item.images,
      sku: item.sku,
      name: item.productName,
      status: (
        <Switch
          checked={item.selected}
          onChange={(_, checked) => handleChangeStatus(item, checked, "related-data")}
          name="checkedA"
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      ),
    };
  });

  const handleEdit = () => {
    setMode("edit");
    // history.push()
    const exclude = storefront.relatedProductIds.map((item) => item.productId);

    fetchDataProducts(query, exclude);
  };

  if (loadingEtalase) {
    return <LoadingScreen />;
  }

  return (
    <Layout title="Etalase">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <HeaderPage title={titleLayout} subtitle={subtitleLayout} showBackButton />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {mode !== "detail" ? (
            <div>
              <AppButton
                onClick={() => {
                  history.go(-1);
                }}
                color="secondary"
              >
                Cancel
              </AppButton>
              <AppButton
                onClick={submit}
                variant="contained"
                color="primary"
                disabled={loadingSubmit}
                loading={loadingSubmit}
              >
                Save
              </AppButton>
            </div>
          ) : (
            <AppButton onClick={handleEdit} variant="contained" color="primary">
              Edit
            </AppButton>
          )}
        </div>
      </div>

      <Paper elevation={3} className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item style={{ width: "20%" }}>
            <UploadImage
              image={image || ""}
              title="Etalase Image"
              imageResolution="850x1000"
              onFileSelected={(e: any) => setFile(e)}
              editable={mode === "edit" || mode === "create"}
            />
          </Grid>
          {mode === "detail" ? (
            <>
              <Grid item md={4}>
                <Typography
                  style={{
                    color: COLORS.greyDark,
                    fontSize: 12,
                  }}
                >
                  Title
                </Typography>
                <Typography>{title}</Typography>
                <br />

                <Typography
                  style={{
                    color: COLORS.greyDark,
                    fontSize: 12,
                  }}
                >
                  Category boz
                </Typography>
                <div
                  style={{
                    borderRadius: 100,
                    border: "1px solid #000",
                    padding: "2px 15px",
                    marginBottom: 20,
                    marginTop: 5,
                    display: "inline-block",
                  }}
                >
                  {category?.name}
                </div>
                <br />

                <Typography
                  style={{
                    color: COLORS.greyDark,
                    fontSize: 12,
                  }}
                >
                  Short description
                </Typography>
                <Typography>{subtitle}</Typography>
                <br />
              </Grid>
              <Grid item md={4}>
                <Typography
                  style={{
                    color: COLORS.greyDark,
                    fontSize: 12,
                  }}
                >
                  Full Description
                </Typography>
                <Typography>{description}</Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={4}>
                <FormInput
                  label="Title"
                  fullWidth
                  value={title}
                  placeholder="Title"
                  onChange={(value: string) => setTitle(value)}
                />

                <Autocomplete
                  id="asynchronous-demo"
                  style={{ width: "100%" }}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  value={category}
                  onChange={(event, newValue: any) => {
                    if (typeof newValue === "string") {
                      setCategory({
                        name: newValue,
                        published: true,
                        storefrontCategoryId: null,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setCategory({
                        name: newValue.inputValue,
                        published: true,
                        storefrontCategoryId: null,
                      });
                    } else {
                      let newData = newValue;
                      if (newValue && newValue.name && newValue.name.includes("Add")) {
                        newData = {
                          published: true,
                          storefrontCategoryId: null,
                          name: filterName(newValue.name),
                        };
                      }
                      setCategory(newData);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        storefrontCategoryId: null,
                        published: true,
                        name: `Add ${params.inputValue}`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={categoryData}
                  loading={loadingCategory}
                  getOptionLabel={(option: any) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return filterName(option.name);
                  }}
                  renderOption={(option: any) => option.name}
                  freeSolo
                  renderInput={(params) => <TextField {...params} label="Category" />}
                />
                <FormInput
                  style={{
                    marginTop: 20,
                  }}
                  label="Short description"
                  type="address"
                  fullWidth
                  rows={8}
                  multiline
                  placeholder="Type short description about etalase"
                  value={subtitle}
                  onChange={(value: string) => setSubtitle(value)}
                />
              </Grid>
              <Grid item md={4}>
                <FormInput
                  label="Full description"
                  type="address"
                  multiline
                  fullWidth
                  rows={16}
                  placeholder="Type full description about etalase"
                  value={description}
                  onChange={(value: string) => setDescription(value)}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      <Paper elevation={3} className={classes.paper}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              Related products
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: COLORS.grey500,
                marginRight: 15,
              }}
            >
              Total Added
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>{dataRelatedMapped.length}</Typography>
          </div>
        </div>
        <div
          style={{
            maxHeight: 540,
            overflowY: "auto",
            border: "1px solid #ddd",
            borderRadius: 10,
          }}
        >
          <CustomizedTable
            data={dataRelatedMapped}
            headers={mode !== "detail" ? headers : headersDetail}
            totalData={dataRelatedMapped.length}
            // showAction={["detail", "edit", "delete"]}
            page={1}
            totalPage={10000}
            rowsPerPage={25}
            onChange={(newPage: number) => console.info("onChange")}
            onRowsPerPageChange={(value: number) => console.info("onRowsPerPageChange")}
            hidePagination={true}
          />
        </div>

        <br />

        {mode !== "detail" ? (
          <Grid item container xs={12} spacing={3} alignItems="center">
            <Grid item xs={12} md={9}>
              <Typography
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                List of All Products
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} alignItems="flex-end">
              <Search
                placeholder="Search by product name or product SKU"
                onChange={(value: string) => setSearch(value)}
              />
            </Grid>
          </Grid>
        ) : (
          false
        )}

        <br />

        {mode !== "detail" ? (
          <CustomizedTable
            data={dataMapped}
            headers={headers}
            totalData={totalData}
            // showAction={["detail", "edit", "delete"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
          />
        ) : (
          false
        )}
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={validationErr !== ""}
        autoHideDuration={2000}
        onClose={() => setValidationErr("")}
      >
        <Alert severity="error" variant="filled">
          {validationErr}
        </Alert>
      </Snackbar>
    </Layout>

    /* 
    <Grid item xs={12} md={6}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterPublished}
                defaultValue="all"
                onChange={(event: any) => {
                  const value = event.target.value
                  setFilterPublished(value)
                  if (value === 'all') {
                    setQuery((oldVal: IStorefrontQuery) => {
                      return {
                        ...oldVal,
                        filterPublished: false
                      }
                    })
                  } else {
                    setQuery((oldVal: IStorefrontQuery) => {
                      return {
                        ...oldVal,
                        filterPublished: value === 'published' ? '1' : '0'
                      }
                    })
                  }
                }}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'published'}>Published</MenuItem>
                <MenuItem value={'unpublished'}>Unpublished</MenuItem>
              </Select>
            </FormControl>
          </Grid> */
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
  })
);

export default StorefrontEdit;
