import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from "react";
import { ProvideAuth, useAuth } from "../hooks/useAuth";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "../styles/theme";

import Login from "../screens/Auth/Login";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import ResetPassword from "../screens/Auth/ResetPassword";

import Dashboard from "../screens/Dashboard/Dashboard";
import DashboardB2C from "../screens/Dashboard/DashboardB2C";

import Distributor from "../screens/Distributor/index";
import DistributorDetail from "../screens/Distributor/Detail";
import DistributorEdit from "../screens/Distributor/Edit";
import Category from "../screens/Category";
import CategoryEdit from "../screens/Category/Edit";
import User from "../screens/User/index";
import UserEdit from "../screens/User/Edit";
import Banner from "../screens/Banner";
import Content from "../screens/Content";
import BannerEdit from "../screens/Banner/Edit";
import Feedback from "../screens/Feedback";
import ContentEdit from "../screens/Content/Edit";
import UserDetail from "../screens/User/Detail";
import Product from "../screens/Product";
import ProductEdit from "../screens/Product/Edit";
import FeaturedProduct from "../screens/FeaturedProduct/index";
import FeaturedProductEdit from "../screens/FeaturedProduct/Edit";
import Order from "../screens/B2BTransaction";
import Trip from "../screens/Trip/index";
import TripDetail from "../screens/Trip/Detail";
import MyProfileDetail from "../screens/Profile/Detail";
import MyProfileEdit from "../screens/Profile/Edit";
import TripCreate from "../screens/Trip/Create";
import Transaction from "../screens/Transaction/index";
import TransactionDetail from "../screens/Transaction/Detail";
import Refund from "../screens/Refund/index";
import Disbursement from "../screens/Disbursement/index";
import DisbursementDetail from "../screens/Disbursement/Detail";
import StockRetail from "../screens/StockRetail/index";
import StockRetailDetail from "../screens/StockRetail/Detail";
import UserCustomer from "../screens/User/Customer";
import BankAccountApproval from "../screens/BankAccountApproval/index";
import DiscountReferral from "../screens/DiscountReferral/index";
import ShipmentDiscount from "../screens/ShipmentDiscount/index";
import "../helpers/interceptor";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Unauthorized from "../screens/Unauthorized";
import { ERoles } from "../helpers/role";
import Version from "../screens/Version/index";
import Referral from "../screens/Referral";
import VoucherGenerate from "../screens/VoucherGenerate";
import DetailVoucherGenerate from "../screens/VoucherGenerate/Detail";
import Partner from "../screens/Partner";
import { VoucherPartner } from "../screens/Partner/VoucherPartner";
import VoucherPartnerDetail from "../screens/Partner/VoucherPartnerDetail";
import Storefront from "../screens/Storefront";
import StorefrontEdit from "../screens/Storefront/Edit";
import DeliveryPickup from "../screens/DeliveryPickup";
import B2BTransactionDetail from "../screens/B2BTransaction/Detail";
import DeliveryPickupDetail from "../screens/DeliveryPickup/Detail";
import ReportVoucherEvent from "../screens/VoucherEvent";

import AgentRegion from "../screens/AgentRegion/index";
import CreateAgentRegion from "../screens/AgentRegion/CreateAro";
import DetailAgentRegion from "../screens/AgentRegion/DetailAro";

import AroTransaction from "../screens/AroTransaction";
import AroDisbursement from "../screens/AroDisbursement";
import DetailAroDisbursement from "../screens/AroDisbursement/DetailAroDisbursement";
import AroFullfillment from "../screens/AroFullfillment";
import FulfillmentSparepart from "../screens/FulfillmentSparepart";
import SparepartTransactionDetail from "../screens/FulfillmentSparepart/Detail";
import Loyalty from "../screens/Loyalty";
import DetailLoyalty from "../screens/Loyalty/DetailLoyalty";
import ExtraWarranty from "../screens/ExtraWarranty";
import Collection from "../screens/Collection";
import PickupPoint from "../screens/PickupPoint";

import ServiceCenterTransaction from "../screens/ServiceCenterTransaction";
import ServiceCenterTransactionDetail from "../screens/ServiceCenterTransaction/Detail";
import ServiceCenter from "../screens/ServiceCenter";
import ServiceCenterEdit from "../screens/ServiceCenter/Edit";
import ServiceCenterDetail from "../screens/ServiceCenter/Detail";

export default function RootNavigator() {
  const { userSignIn } = useAuth();

  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            {/* PUBLIC ROUTE */}
            <PublicRoute
              isAuthenticated={userSignIn}
              exact
              path="/reset-password/:sessionId"
              restricted={true}
            >
              <ResetPassword />
            </PublicRoute>
            <PublicRoute
              isAuthenticated={userSignIn}
              exact
              path="/"
              restricted={true}
            >
              <Login />
            </PublicRoute>
            <PublicRoute
              isAuthenticated={userSignIn}
              exact
              path="/login"
              restricted={true}
            >
              <Login />
            </PublicRoute>
            <PublicRoute
              isAuthenticated={userSignIn}
              exact
              path="/forgot-password"
              restricted={true}
            >
              <ForgotPassword />
            </PublicRoute>

            {/* PRIVATE ROUTE */}
            <PrivateRoute exact path="/unauthorized">
              <Unauthorized />
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/dashboard-b2c"
              roles={[
                ERoles.ADMIN,
                ERoles.TRANSACTION,
                ERoles.FINANCE,
                ERoles.PACKAGING,
                ERoles.SERVICE_CENTER,
              ]}
            >
              <DashboardB2C />
            </PrivateRoute>

            {/** USER */}
            <PrivateRoute exact path="/create-user" roles={[ERoles.ADMIN]}>
              <UserEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/user" roles={[ERoles.ADMIN]}>
              <User />
            </PrivateRoute>
            <PrivateRoute exact path="/user/:id" roles={[ERoles.ADMIN]}>
              <UserEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/user/:id/edit" roles={[ERoles.ADMIN]}>
              <UserEdit />
            </PrivateRoute>

            {/** Service Center */}
            <PrivateRoute
              exact
              path="/fulfillment-sparepart"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <FulfillmentSparepart />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/fulfillment-sparepart/:id"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <SparepartTransactionDetail />
            </PrivateRoute>

            {/** TRANSACTION */}
            <PrivateRoute
              exact
              path="/transaction"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION, ERoles.PACKAGING]}
            >
              <Transaction />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/transaction/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION, ERoles.PACKAGING]}
            >
              <TransactionDetail />
            </PrivateRoute>

            {/** REFUND */}
            <PrivateRoute
              exact
              path="/refund"
              roles={[ERoles.ADMIN, ERoles.FINANCE]}
            >
              <Refund />
            </PrivateRoute>

            {/** DISBURSEMENT */}
            <PrivateRoute
              exact
              path="/disbursement"
              roles={[ERoles.ADMIN, ERoles.FINANCE]}
            >
              <Disbursement />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/disbursement/:customerId"
              roles={[ERoles.ADMIN, ERoles.FINANCE]}
            >
              <DisbursementDetail />
            </PrivateRoute>

            {/** STOCK RETAIL */}
            <PrivateRoute
              exact
              path="/stock-retail"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <StockRetail />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/stock-retail/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <StockRetailDetail />
            </PrivateRoute>

            {/* VOUCHER GENERATE */}
            <PrivateRoute
              exact
              path="/voucher-generate"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <VoucherGenerate />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/voucher-generate/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <DetailVoucherGenerate />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/report-voucher-event"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ReportVoucherEvent />
            </PrivateRoute>

            {/* VOUCHER PARTNER */}
            <PrivateRoute
              exact
              path="/voucher-partner"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Partner />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/voucher-partner/:id/:voucherCode"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <VoucherPartnerDetail />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/voucher-partner/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <VoucherPartner />
            </PrivateRoute>

            {/* STOREFRONT */}
            <PrivateRoute
              exact
              path="/etalase"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Storefront />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/etalase/create"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <StorefrontEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/etalase/:id/edit"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <StorefrontEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/etalase/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <StorefrontEdit />
            </PrivateRoute>

            {/** CATEGORY */}
            <PrivateRoute exact path="/create-category" roles={[ERoles.ADMIN]}>
              <CategoryEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/category" roles={[ERoles.ADMIN]}>
              <Category />
            </PrivateRoute>
            <PrivateRoute exact path="/category/:id" roles={[ERoles.ADMIN]}>
              <CategoryEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/category/:id/edit"
              roles={[ERoles.ADMIN]}
            >
              <CategoryEdit />
            </PrivateRoute>

            {/** PRODUCT */}
            <PrivateRoute
              exact
              path="/product"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Product />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/product/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ProductEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/product/:id/edit"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ProductEdit />
            </PrivateRoute>

            {/** FEATURED PRODUCT */}
            <PrivateRoute
              exact
              path="/create-featured-product"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <FeaturedProductEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/featured-product"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <FeaturedProduct />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/featured-product/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <FeaturedProductEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/featured-product/:id/edit"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <FeaturedProductEdit />
            </PrivateRoute>

            {/** USER */}
            <PrivateRoute
              exact
              path="/create-customer"
              roles={[ERoles.ADMIN, ERoles.FINANCE, ERoles.TRANSACTION]}
            >
              <UserEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/customer"
              roles={[ERoles.ADMIN, ERoles.FINANCE, ERoles.TRANSACTION]}
            >
              <UserCustomer />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/customer/:id"
              roles={[ERoles.ADMIN, ERoles.FINANCE, ERoles.TRANSACTION]}
            >
              <UserDetail />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/customer/:id/edit"
              roles={[ERoles.ADMIN, ERoles.FINANCE, ERoles.TRANSACTION]}
            >
              <UserEdit />
            </PrivateRoute>

            {/** BANNER */}
            <PrivateRoute
              exact
              path="/create-banner"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <BannerEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/banner"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Banner />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/banner/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <BannerEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/banner/:id/edit"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <BannerEdit />
            </PrivateRoute>

            {/** PROMOTION */}
            <PrivateRoute
              exact
              path="/create-promotion"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ContentEdit type="promotion" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/promotion"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Content type="promotion" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/promotion/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ContentEdit type="promotion" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/promotion/:id/edit"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ContentEdit type="promotion" />
            </PrivateRoute>

            {/** NEWS & EVENT */}
            <PrivateRoute
              exact
              path="/create-news-event"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ContentEdit type="news-event" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/news-event"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Content type="news-event" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/news-event/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ContentEdit type="news-event" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/news-event/:id/edit"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ContentEdit type="news-event" />
            </PrivateRoute>
            <PrivateRoute exact path="/feedback" roles={[ERoles.ADMIN]}>
              <Feedback />
            </PrivateRoute>

            {/** MY PROFILE */}
            <PrivateRoute
              exact
              path="/my-profile"
              roles={[
                ERoles.ADMIN,
                ERoles.TRANSACTION,
                ERoles.FINANCE,
                ERoles.PACKAGING,
              ]}
            >
              <MyProfileDetail />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/my-profile/edit"
              roles={[
                ERoles.ADMIN,
                ERoles.TRANSACTION,
                ERoles.FINANCE,
                ERoles.PACKAGING,
              ]}
            >
              <MyProfileEdit />
            </PrivateRoute>

            {/** BankAccountApproval */}
            <PrivateRoute
              exact
              path="/bank-account-approval"
              roles={[ERoles.ADMIN, ERoles.FINANCE]}
            >
              <BankAccountApproval />
            </PrivateRoute>

            {/** REFERRAL */}
            <PrivateRoute exact path="/referral" roles={[ERoles.ADMIN]}>
              <Referral />
            </PrivateRoute>

            {/** DISCOUNT REFERRAL */}
            <PrivateRoute
              exact
              path="/discount-referral"
              roles={[ERoles.ADMIN]}
            >
              <DiscountReferral />
            </PrivateRoute>

            {/** SHIPMENT DISCOUNT */}
            <PrivateRoute
              exact
              path="/shipment-discount"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ShipmentDiscount />
            </PrivateRoute>

            {/** EXTRA WARRANTY */}
            <PrivateRoute
              exact
              path="/extra-warranty"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <ExtraWarranty />
            </PrivateRoute>

            {/** LOYALTY */}
            <PrivateRoute
              exact
              path="/loyalty"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <Loyalty />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/detail-loyalty/:id"
              roles={[ERoles.ADMIN, ERoles.TRANSACTION]}
            >
              <DetailLoyalty />
            </PrivateRoute>

            {/** APP VERSION */}
            <PrivateRoute
              exact
              path="/version-management"
              roles={[ERoles.ADMIN]}
            >
              <Version />
            </PrivateRoute>

            {/** SALES ORDER */}
            <PrivateRoute exact path="/sales-order">
              <Order type="sales-order" />
            </PrivateRoute>
            <PrivateRoute exact path="/sales-order/:id">
              <B2BTransactionDetail type="sales-order" />
            </PrivateRoute>

            <PrivateRoute exact path="/delivery-order">
              <DeliveryPickup type="delivery-order" />
            </PrivateRoute>
            <PrivateRoute exact path="/delivery-order/:id">
              <DeliveryPickupDetail type="delivery-order" />
            </PrivateRoute>
            {/* SALES ORDER */}

            {/* RETURN */}
            <PrivateRoute exact path="/sales-return">
              <Order type="sales-return" />
            </PrivateRoute>
            <PrivateRoute exact path="/sales-return/:id">
              <B2BTransactionDetail type="sales-return" />
            </PrivateRoute>

            <PrivateRoute exact path="/pickup-order">
              <DeliveryPickup type="pickup-order" />
            </PrivateRoute>
            <PrivateRoute exact path="/pickup-order/:id">
              <DeliveryPickupDetail type="pickup-order" />
            </PrivateRoute>
            {/* SALES RETURN */}

            {/* REPLACEMENT */}
            <PrivateRoute exact path="/replacements">
              <Order type="replacement" />
            </PrivateRoute>
            <PrivateRoute exact path="/replacements/:id">
              <B2BTransactionDetail type="replacement" />
            </PrivateRoute>

            <PrivateRoute exact path="/replacement-delivery">
              <DeliveryPickup type="replacement-delivery" />
            </PrivateRoute>
            <PrivateRoute exact path="/replacement-delivery/:id">
              <DeliveryPickupDetail type="replacement-delivery" />
            </PrivateRoute>

            <PrivateRoute exact path="/replacement-pickup">
              <DeliveryPickup type="replacement-pickup" />
            </PrivateRoute>
            <PrivateRoute exact path="/replacement-pickup/:id">
              <DeliveryPickupDetail type="replacement-pickup" />
            </PrivateRoute>
            {/* REPLACEMENT */}

            {/** TRIP */}
            <PrivateRoute exact path="/create-trip">
              <TripCreate type="trip" />
            </PrivateRoute>
            <PrivateRoute exact path="/trip">
              <Trip type="trip" />
            </PrivateRoute>
            <PrivateRoute exact path="/trip/:id">
              <TripDetail type="trip" />
            </PrivateRoute>

            {/** PICKUP */}
            <PrivateRoute exact path="/create-pickup">
              <TripCreate type="pickup" />
            </PrivateRoute>
            <PrivateRoute exact path="/pickups">
              <Trip type="pickup" />
            </PrivateRoute>
            <PrivateRoute exact path="/pickups/:id">
              <TripDetail type="pickup" />
            </PrivateRoute>

            {/** PICKUP POINT */}
            <PrivateRoute exact path="/pickup-point" roles={[ERoles.ADMIN]}>
              <PickupPoint />
            </PrivateRoute>

            {/** DISTRIBUTOR */}
            <PrivateRoute exact path="/create-distributor">
              <DistributorEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/distributor">
              <Distributor />
            </PrivateRoute>
            <PrivateRoute exact path="/distributor/:id">
              <DistributorDetail />
            </PrivateRoute>
            <PrivateRoute exact path="/distributor/:id/edit">
              <DistributorEdit />
            </PrivateRoute>

            <PrivateRoute exact path="/oxone-collection-approval">
              <Collection />
            </PrivateRoute>

            {/** ARO **/}
            <PrivateRoute exact path="/agent-region">
              <AgentRegion />
            </PrivateRoute>
            <PrivateRoute exact path="/create-agent-region">
              <CreateAgentRegion />
            </PrivateRoute>
            <PrivateRoute exact path="/agent-region/:aroId">
              <DetailAgentRegion />
            </PrivateRoute>

            {/** ARO TRANSACTION **/}
            <PrivateRoute exact path="/aro-transactions" roles={[ERoles.ADMIN]}>
              <AroTransaction />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/aro-transactions/:id"
              roles={[ERoles.ADMIN]}
            >
              <TransactionDetail isAro={true} />
            </PrivateRoute>
            <PrivateRoute exact path="/aro-disbursement" roles={[ERoles.ADMIN]}>
              <AroDisbursement />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/aro-disbursement/:id"
              roles={[ERoles.ADMIN]}
            >
              <DetailAroDisbursement />
            </PrivateRoute>
            <PrivateRoute exact path="/aro-fullfillment" roles={[ERoles.ADMIN]}>
              <AroFullfillment />
            </PrivateRoute>

            {/* SERVICE CENTER */}
            <PrivateRoute
              exact
              path="/service-center-list"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <ServiceCenter />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/service-center-list/:id"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <ServiceCenterDetail />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/service-center-list-create"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <ServiceCenterEdit />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/service-center-list/:id/edit"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <ServiceCenterEdit />
            </PrivateRoute>

            {/** SERVICE CENTER TRANSACTION **/}
            <PrivateRoute
              exact
              path="/service-center-transaction"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <ServiceCenterTransaction />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/service-center-transaction/:id"
              roles={[ERoles.ADMIN, ERoles.SERVICE_CENTER]}
            >
              <ServiceCenterTransactionDetail />
            </PrivateRoute>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </ProvideAuth>
  );
}
