import { CustomerProperties } from '../../customer.type';
import { BranchProperties } from '../branch/branch.type';

export interface DistributorProperties {
  distributorId: '',
  disabled: boolean
  name: string
  address: string
  phoneNumber: string
  distributorDiscount: number
  coordinate: string
  countryCode?: string
  cityCode?: string
  stateCode?: string
  postalCode?: string
  createdAt?: string | Date
  updatedAt?: string | Date
  branches?: BranchProperties[]
  agents?: CustomerProperties[]
}

export const initialDistributor: DistributorProperties = {
  distributorId: '',
  disabled: false,
  name: '',
  address: '',
  phoneNumber: '',
  distributorDiscount: 0,
  coordinate: '',
  countryCode: '',
  cityCode: '',
  stateCode: '',
  postalCode: '',
  createdAt: '',
  updatedAt: '',
  branches: [],
  agents: [],
}