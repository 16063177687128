import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Modal,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import COLORS from "../../styles/colors";
import { CustomerProperties, ECustomerRole } from '../../types/customer.type';
import useFetch from '../../hooks/useFetch';
import axios from 'axios';
import { X } from 'react-feather';
import { BranchProperties } from '../../types/b2b/branch/branch.type';
import DetailAgent from '../../components/B2B/DetailAgent';
import { ApplicationState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setAgentB2B } from '../../redux/action/b2b.action';
import { httpRequest } from '../../helpers/api/api';

const headers: ITableHeader[] = [
  {
    title: "id",
    column: "id",
    type: "id",
  },
  {
    title: "Name",
    column: "name",
  },
  {
    title: "Phone",
    column: "phone",
  },
  {
    title: "Email",
    column: "email",
  },
  {
    title: "Branch",
    column: "branch",
  },
  {
    title: "Role",
    column: "role",
  },
];

type Props = {
  showButton?: boolean;
  distributorId: string;
  onNext?: () => void;
  onBack?: () => void;
};

const initialQuery = {
  limit: 25,
  page: 1,
  sort: 'customerAgentName,ASC',
}

export default function ListUser(props: Props) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const [isShowModalFormCreate, setIsShowModalFormCreate] = useState(false);
  const dispatch = useDispatch()

  const [id, setId] = useState<string | undefined | null>('');
  const [name, setName] = useState<string | undefined | null>('');
  const [email, setEmail] = useState<string | undefined | null>('');
  const [phone, setPhone] = useState<string | undefined | null>('');
  const [branchId, setBranchId] = useState<string | undefined | null>('');
  const [role, setRole] = useState<string>(ECustomerRole.purchaser)
  const [mode, setMode] = useState<'detail' | 'edit' | 'create'>('create')
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)

  const handleCloseModalFormCreate = () => {
    setIsShowModalFormCreate(false);
  };
  const handleOpenModalFormCreate = () => {
    setId(undefined);
    setName(undefined);
    setEmail(undefined);
    setPhone(undefined);
    setRole(ECustomerRole.purchaser);
    setMode('create')
    setIsShowModalFormCreate(true);
  };

  const {
    data: dataRawAgents,
    loading,
    pagination,
    fetchDataTableAlt: fetchDataUserAgent,
    handlePerPageChange,
    handlePageChange,
  } = useFetch<CustomerProperties>({
    url: 'customer',
    query: {
      ...initialQuery,
      distributorId: props.distributorId,
      includeBranch: true
    },
    staticUrl: true
  })

  const {
    data: dataRawBranches,
    loading: loadingBranches,
    fetchDataTableAlt: fetchDataBranches,
  } = useFetch<BranchProperties>({
    url: 'branches',
    query: {
      limit: 100,
      sort: 'name,ASC',
      distributorId: props.distributorId,
    },
    staticUrl: true
  })

  React.useEffect(() => {
    const source = axios.CancelToken.source()

    if (props.distributorId) {
      fetchDataUserAgent(source)
    }

    return () => {
      source.cancel()
    };
  }, [props.distributorId])

  const convertAgentRole = (roles: string[]) => {
    let a = roles.slice(1)
    return a.map(item => {
      if (item === ECustomerRole.warehouse) {
        return 'Gudang'
      } else {
        return item.charAt(0).toUpperCase() + item.slice(1)
      }
    }).join(', ')
  }

  const dataAgents = dataRawAgents.map((item) => {
    return {
      id: item.customerId,
      name: item.customerAgentName,
      phone: item.phoneNumber,
      email: item.correspondenceEmail,
      role: convertAgentRole(item.customerRole),
      roles: item.customerRole,
      branch: item.branch && item.branch.name,
      branchId: item.branchId && item.branchId,
    }
  })

  const handleSeeDetailUser = (customerId: string) => {
    setMode('detail')
    const customer = dataRawAgents.find(item => item.customerId === customerId)
    if (customer) {
      dispatch(setAgentB2B(customer))
    }
    setIsShowModalFormCreate(true);
  }

  const handleEditDataUser = (
    data: {
      id: string,
      name: string,
      phone: string,
      email: string,
      role: string,
      roles: string[],
      branch: string,
      branchId: string,
    }
  ) => {
    setMode('edit')

    setId(data.id);
    setName(data.name);
    setEmail(data.email);
    setPhone(data.phone);
    setBranchId(data.branchId);
    setRole(data.roles[1])

    setIsShowModalFormCreate(true);
  }

  React.useEffect(() => {
    if (role !== ECustomerRole.warehouse) {
      setBranchId(null)
    }
  }, [role])

  React.useEffect(() => {
    const source = axios.CancelToken.source()
    fetchDataBranches(source)

    return () => {
      source.cancel()
    };
  }, [])

  const submit = async () => {
    const roles = [ECustomerRole.customer, ...[role]]
    setLoadingSubmit(true)
    if (id) {
      await httpRequest.patch<{ isSuccess: boolean }>(`customer/b2b/${id}`, {
        name: name,
        phone: phone,
        email: email,
        role: roles,
        branchId: branchId,
      })
      setLoadingSubmit(false)
    } else {
      await httpRequest.post<{ isSuccess: boolean }>(`customer/b2b`, {
        customerName: name,
        phoneNumber: phone,
        correspondenceEmail: email,
        customerRole: roles,
        branchId: branchId,
        distributorId: props.distributorId
      })
      setLoadingSubmit(false)
    }

    const source = axios.CancelToken.source()

    await fetchDataUserAgent(source)

    setIsShowModalFormCreate(false);
  }

  const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value)

    if (event.target.value === ECustomerRole.warehouse) {
      const source = axios.CancelToken.source()
      fetchDataBranches(source)
    }
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={classes.paperTitle}>
        <Typography variant="h5">User Information</Typography>
      </div>

      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <div className={classes.containerTable}>
            <div
              className={globalClasses.link}
            >
              <AppButton
                onClick={handleOpenModalFormCreate}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add User
              </AppButton>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* Recent Orders */}
      <div className={classes.containerTable}>
        <CustomizedTable
          data={dataAgents}
          headers={headers}
          totalData={pagination.totalData}
          showAction={["detail-dialog", "edit-dialog", "delete"]}
          page={pagination.page}
          totalPage={pagination.countPage}
          rowsPerPage={pagination.perPage}
          onPressDetail={(data) => handleSeeDetailUser(data.customerId)}
          onPressEditDialog={(data) => handleEditDataUser(data)}
          onChange={(newPage) => handlePageChange(newPage)}
          onRowsPerPageChange={(value: any) => handlePerPageChange(value)}
        />
      </div>
      {props.showButton && (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.containerButton}
        >
          <AppButton
            variant="contained"
            color="secondary"
            onClick={() => (props.onBack ? props.onBack() : false)}
          >
            Back
          </AppButton>
          <AppButton
            onClick={() => (props.onNext ? props.onNext() : false)}
            variant="contained"
            color="primary"
          >
            Finish / Save?
          </AppButton>
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        open={isShowModalFormCreate}
        onClose={handleCloseModalFormCreate}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h4">{mode === 'detail' ? 'Detail' : mode === 'edit' ? 'Edit' : 'Create'} User</Typography>
            <div onClick={handleCloseModalFormCreate} style={{ cursor: 'pointer' }}>
              <X />
            </div>
          </div>
        </DialogTitle>
        {mode === 'detail' ? (
          <DialogContent>
            <DetailAgent />
          </DialogContent>
        ) : (
            <DialogContent>
              <Box>
                <FormInput
                  fullWidth
                  required
                  label="Name"
                  value={name}
                  onChange={(newValue: any) => setName(newValue)}
                />
                <FormInput
                  type="email"
                  fullWidth
                  required
                  label="Email"
                  value={email}
                  onChange={(newValue: any) => setEmail(newValue)}
                />
                <FormInput
                  type="phone"
                  fullWidth
                  required
                  label="Phone"
                  disabled={mode === 'edit'}
                  value={phone}
                  helperText="Contoh. 081234567890"
                  onChange={(newValue: any) => setPhone(newValue)}
                />

                <FormControl component="fieldset">
                  <FormLabel component="legend">Role</FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    value={role}
                    onChange={handleChangeRole}
                  >
                    <FormControlLabel
                      color="#000"
                      value={ECustomerRole.purchaser}
                      control={<Radio color="primary" />}
                      label="Purchaser"
                    />
                    <FormControlLabel
                      color="#000"
                      value={ECustomerRole.warehouse}
                      control={<Radio color="primary" />}
                      label="Gudang"
                    />
                  </RadioGroup>
                </FormControl>

                {role === ECustomerRole.warehouse && (
                  <Box>
                    <FormControl className={classes.formBranch}>
                      <InputLabel id="select-branch">Branch</InputLabel>
                      <Select
                        required
                        label="Branch"
                        placeholder="Branch"
                        labelId="select-branch"
                        id="demo-customized-select"
                        value={branchId}
                        onChange={(event: any) => {
                          setBranchId(event.target.value);
                        }}
                      >
                        {loadingBranches ? (
                          <MenuItem disabled value="1">Loading data branch</MenuItem>
                        ) : (
                            dataRawBranches.map(item => (
                              <MenuItem
                                key={item.branchId}
                                value={item.branchId}
                              >{item.name}</MenuItem>
                            ))
                          )}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </DialogContent>
          )}
        {mode !== 'detail' && (
          <DialogActions>
            <AppButton
              color="secondary"
              autoFocus
              onClick={() => {
                handleCloseModalFormCreate();
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              disabled={loadingSubmit}
              loading={loadingSubmit}
              color="primary"
              onClick={() => {
                submit()
              }}
            >
              {mode === 'create' ? 'Add User' : 'Update'}
            </AppButton>
          </DialogActions>
        )}
      </Dialog>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    paperTitle: {
      // padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      margin: theme.spacing(2, 0, 1, 0),
    },
    containerTable: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alignContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 6, 2),
      width: 800,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
    boxImage: {
      backgroundColor: COLORS.greyLight,
      margin: theme.spacing(1, 2, 1, 0),
    },
    buttonBrowseImage: {
      margin: theme.spacing(1, 2, 1, 0),
    },
    formBranch: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);
