import { useDispatch } from 'react-redux';
import { apiFindAllFeedback, apiFindOneFeedback, apiUpdateFeedback } from '../api/feedback';
import { generateQueryString } from '../helpers/functions/generateQueryString';
import { setLoadingFeedback, findOneFeedback, findAllFeedback } from '../redux/action/feedback.action';

export default function useFeedback() {
  const dispatch = useDispatch()

  const fetchAllFeedback = async (params: any) => {
    try {
      dispatch(setLoadingFeedback(true))

      let query = generateQueryString(params)
      const result = await apiFindAllFeedback(query)

      dispatch(findAllFeedback(result.data))

      dispatch(setLoadingFeedback(false))
    } catch (error) {
      dispatch(setLoadingFeedback(false))
    }
  }

  const fetchOneFeedback = async (feedbackId: string) => {
    try {
      dispatch(setLoadingFeedback(true))
      const result = await apiFindOneFeedback(feedbackId)

      dispatch(findOneFeedback(result.data.payload))

      dispatch(setLoadingFeedback(false))
    } catch (error) {
      dispatch(setLoadingFeedback(false))
    }
  }

  const updateFeedback = async (data: any) => {
    try {
      dispatch(setLoadingFeedback(true))

      await apiUpdateFeedback(data)

      await fetchAllFeedback({ limit: 25, offset: 0 })

      dispatch(setLoadingFeedback(false))
    } catch (error) {
      dispatch(setLoadingFeedback(false))
    }
  }

  return {
    fetchAllFeedback,
    fetchOneFeedback,
    updateFeedback
  }
}