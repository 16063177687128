export enum DashboardTypes {
  SET_TOTAL_TRANSACTION = 'SET_TOTAL_TRANSACTION',
  SET_LIST_TRANSACTION = 'SET_LIST_TRANSACTION',
  SET_MOST_ACTIVE_TRANSACTION = 'SET_MOST_ACTIVE_TRANSACTION',
  SET_MOST_ACTIVE_UPLINE = 'SET_MOST_ACTIVE_UPLINE',
  SET_LOADING_DASHBOARD = 'SET_LOADING_DASHBOARD',
  SET_CUSTOMER_SUMMARY = 'SET_CUSTOMER_SUMMARY',
}

export type SummaryTransaction = {
  year: number;
  month: number;
  countTransaction: number;
  totalTransactionPrice: number;
  totalFinalTransactionPrice: number;
};

export type SummaryMostActiveTransaction = {
  externalId: string;
  countTransaction: number;
  customer?: {
    customerId: string;
    customerName: string;
  };
};

export type SummaryMostActiveUpline = {
  customerId: string;
  totalDownline: number;
  customer?: {
    customerId: string;
    customerName: string;
  };
};

export type CustomerSummaryResponse = {
  today: number,
  month: number,
  count: number
}

export type DashboardState = {
  isLoadingDashboard: boolean;
  listTransaction: SummaryTransaction[];
  totalTransaction: SummaryTransaction[];
  mostActiveTransaction: SummaryMostActiveTransaction[];
  mostActiveUpline: SummaryMostActiveUpline[];
  customerSummary: CustomerSummaryResponse;
};

export type SaleOrder = {
  date: string;
  count: number;
};

export type MostActiveUpline = {
  date: string;
  name: string;
  count: number;
  diffWithMonthBeforeInPercent: number;
};

export type MostActiveTransaction = {
  date: string;
  name: string;
  count: number;
  diffWithMonthBeforeInPercent: number;
};
