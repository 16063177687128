export function parseURLSearch(search: string) {
  const query = search.substring(1);
  const vars = query.split('&');
  let params: any = {}
  for (const idx in vars) {
    const exp = vars[idx].split('=');
    params[exp[0]] = exp[1];
  }

  return params;
}