import { useDispatch } from 'react-redux'
import { apiCreatePromotion, apiDeletePromotion, apiFindAllPromotion, apiFindOnePromotion, apiUpdatePromotion } from '../api/content/promotion';
import { findAllPromotion, setLoadingPromotion, setErrorPromotion, createPromotion, deletePromotion, updatePromotion, findOnePromotion } from '../redux/action/content/promotion.action';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { generateQueryString } from '../helpers/functions/generateQueryString';

export default function usePromotion() {
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchAllPromotion = async (params?: any) => {
    try {
      dispatch(setLoadingPromotion(true))

      let query = generateQueryString(params)

      const result = await apiFindAllPromotion(query)
      dispatch(findAllPromotion(result.data))
      dispatch(setLoadingPromotion(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingPromotion(false))
      dispatch(setErrorPromotion(error.response))
    }

  }

  const fetchOnePromotion = async (newsId: string) => {
    try {
      dispatch(setLoadingPromotion(true))

      const result = await apiFindOnePromotion(newsId)
      dispatch(findOnePromotion(result.data.payload))

      dispatch(setLoadingPromotion(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingPromotion(false))
      dispatch(setErrorPromotion(error.response))
    }

  }

  const createNewPromotion = async (data: any) => {
    try {
      dispatch(setLoadingPromotion(true))

      const result = await apiCreatePromotion(data)

      dispatch(createPromotion(result.data.payload))

      dispatch(setLoadingPromotion(false))

      toast.success("Success create promotion", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingPromotion(false))
      dispatch(setErrorPromotion(error.response))
    }
  }

  const updateCurrentPromotion = async (data: any) => {
    try {
      dispatch(setLoadingPromotion(true))

      const result = await apiUpdatePromotion(data)

      dispatch(updatePromotion(result.data.payload))

      dispatch(setLoadingPromotion(false))

      toast.success("Success update promotion", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingPromotion(false))
      dispatch(setErrorPromotion(error.response))
    }
  }

  const removePromotion = async (bannerId: string) => {
    try {
      dispatch(setLoadingPromotion(true))

      const result = await apiDeletePromotion(bannerId)

      dispatch(deletePromotion(result.data.payload))

      await fetchAllPromotion({ limit: 25, offset: 0, search: '' })

      dispatch(setLoadingPromotion(false))

      toast.success("Success remove promotion", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingPromotion(false))
      dispatch(setErrorPromotion(error.response))
    }
  }

  return {
    fetchAllPromotion,
    createNewPromotion,
    removePromotion,
    updateCurrentPromotion,
    fetchOnePromotion
  }
}