import axios from 'axios';
import { getHeadersMultiPart, getHeaders } from '../helpers/auth';

export async function apiFindAllCategory(params: string) {
  const query = params || null
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product-category' + query,
    method: 'GET',
    headers
  });
}

export async function apiFindOneCategory(categoryId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product-category/' + categoryId,
    method: 'GET',
    headers
  });
}

export async function apiCreateCategory(data: FormData) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product-category',
    method: 'POST',
    headers: headers,
    data
  });
}

export async function apiUpdateCategory(data: FormData) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product-category/' + data.get('categoryId'),
    method: 'PATCH',
    headers: headers,
    data
  });
}

export async function apiDeleteCategory(categoryId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/product-category/' + categoryId,
    method: 'DELETE',
    headers
  });
}