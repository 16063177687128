import { IconProps } from "./IconProps";

export default function Etalase(props: IconProps) {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 10.1889H6.65139L6.65139 4H2L2 10.1889ZM2 19H6.65139V12.7064H2V19ZM8.6743 19H13.3257V12.7064H8.6743V19ZM15.4489 19H20V12.7064H15.4489V19ZM8.6743 10.1889H13.3257L13.3257 4H8.6743L8.6743 10.1889ZM15.4489 4L15.4489 10.1889H20L20 4H15.4489Z" fill={props.color || "#201D1D"}
      />
    </svg>
  );
}
