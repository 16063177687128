import {
  Box,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  CardMedia,
  Divider,
  Grid,
  Modal,
} from "@material-ui/core";
import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import { Link } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import { apiChangePassword } from '../../api/auth';
import { toast } from 'react-toastify';
import { Close } from '@material-ui/icons';
import UploadImage from '../../components/Form/UploadImage';

type IMyProfileDetail = {
  id: string;
  name: string;
  email: string;
  phone: string;
  roles: string[];
  image: string;
  gudang: string;
  status: string | number;
};

export default function MyProfileDetail() {
  const { user } = useAuth();

  const classes = useStyles();

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [loading, setLoading] = useState(false)

  let title = "Profile";
  let subtitle = "Detailed information about profile";

  const updatePassword = async () => {
    try {
      setLoading(true)
      await apiChangePassword({ oldPassword, newPassword })
      setLoading(false)

      setOldPassword('')
      setNewPassword('')

      toast.success("Success update your password", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });

      handleCloseModalEditShipmentDiscount()
    } catch (error) {
      setLoading(false)
      handleCloseModalEditShipmentDiscount()
    }
  }

  const [
    isShowModalEditShipmentDiscount,
    setIsShowModalEditShipmentDiscount,
  ] = useState(false);

  const handleCloseModalEditShipmentDiscount = () => {
    setIsShowModalEditShipmentDiscount(false);
  };
  const handleOpenModalEditShipmentDiscount = () => {
    setIsShowModalEditShipmentDiscount(true);
  };

  return (
    <Layout title={title} loading={loading}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <HeaderPage title={title} subtitle={subtitle} showBackButton />

        <Box display="flex" justifyContent="flex-end">
          <Link to={'/my-profile/edit'}>
            <AppButton color="primary">Edit</AppButton>
          </Link>
        </Box>
      </Box>

      <Paper elevation={3} className={classes.paper}>
        <Box display="flex" flexWrap="wrap" className={classes.containerForm}>
          <Box width="30%">
            <UploadImage
              image={user.imageUrl}
            />
          </Box>
          <Box width="35%" className={classes.input}>
            <Box className={classes.row}>
              <Typography variant="body1" color="textSecondary">
                Name
              </Typography>
              <Typography variant="body1">{user.name}</Typography>
            </Box>
            <Box className={classes.row}>
              <Typography variant="body1" color="textSecondary">
                Phone
              </Typography>
              <Typography variant="body1">{user.phoneNumber}</Typography>
            </Box>
            <Box className={classes.row}>
              <Typography variant="body1" color="textSecondary">
                Email
              </Typography>
              <Typography variant="body1">{user.email}</Typography>
            </Box>
          </Box>
          <Box width="35%" className={classes.input}>
            <Box className={classes.row}>
              <Typography variant="body1" color="textSecondary">
                Status
              </Typography>
              <Typography variant="body1">
                {user.status.toUpperCase()}
              </Typography>
            </Box>
            <Box className={classes.row}>
              <Typography variant="body1" color="textSecondary">
                Role
              </Typography>
              <Typography variant="body1">{user.role}</Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box
          display="flex"
          flexDirection="row"
          className={classes.containerForm}
        >
          <Box width="100%" className={classes.input}>
            <Typography
              variant="h2"
              style={{ fontWeight: 'bold', marginBottom: 20 }}
            >
              Change Password
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                display="flex"
                flexDirection="row"
                width="80%"
                alignItems="center"
                gridGap={20}
              >
                <FormInput
                  onChange={(value: any) => setOldPassword(value)}
                  type="password"
                  fullWidth
                  required
                  value={oldPassword}
                  label="Old Password"
                  placeholder="Type your old password"
                  style={{ width: '100%' }}
                />
                <FormInput
                  onChange={(value: any) => setNewPassword(value)}
                  type="password"
                  fullWidth
                  required
                  value={newPassword}
                  label="New Password"
                  placeholder="Type your new password for next login"
                  style={{ width: '100%' }}
                />
              </Box>
              <Box width="20%" style={{ marginLeft: 20 }}>
                <AppButton
                  fullWidth
                  onClick={() => handleOpenModalEditShipmentDiscount()}
                  loading={loading}
                  disabled={!newPassword || !oldPassword}
                >
                  Update Password
                </AppButton>
              </Box>
            </Box>
          </Box>
        </Box>

        <Modal
          open={isShowModalEditShipmentDiscount}
          onClose={handleCloseModalEditShipmentDiscount}
        >
          <div className={classes.containerModal}>
            <div className={classes.contentModal}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography variant="h5">
                  Update Password Confirmation
                </Typography>

                <button onClick={() => handleCloseModalEditShipmentDiscount()}>
                  <Close />
                </button>
              </Box>

              <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                <Grid item xs={12}>
                  <Typography style={{ lineHeight: 1.7 }}>
                    Are you sure want to update your current password?. Please
                    remember and keep your new password save.
                  </Typography>
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end">
                <Box>
                  <AppButton
                    color="secondary"
                    onClick={handleCloseModalEditShipmentDiscount}
                  >
                    Cancel
                  </AppButton>
                </Box>
                <Box>
                  <AppButton loading={loading} onClick={() => updatePassword()}>
                    Update Password
                  </AppButton>
                </Box>
              </Box>
            </div>
          </div>
        </Modal>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    row: {
      padding: theme.spacing(1, 0, 1, 0),
    },
    containerModal: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      alihandleCloseModalApprovalgnContent: 'center',
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 460,
      margin: '0 auto',
      marginTop: '10%',
      borderRadius: 10,
    },
  })
);
