import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Copyright from "../../components/Copyright";
import Logo from "../../components/Icon/Logo";
import TextInput from "../../components/Form/TextInput";
import COLORS from "../../styles/colors";
import { useAuth } from "../../hooks/useAuth";
import { CircularProgress } from "@material-ui/core";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

export default function Login() {
  const classes = useStyles();
  const auth = useAuth();
  let history = useHistory();
  let location = useLocation();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  let { from } = location.state || { from: { pathname: "/dashboard-b2c" } };

  const submit = () => {
    auth.signin({ email, password }, () => {
      history.replace({ pathname: "dashboard-b2c" });
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submit();
    }
  };

  return (
    <div className={classes.body}>
      <Container className={classes.container} component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography> */}

          <Logo />
          <Typography
            variant="subtitle1"
            style={{ color: "white", lineHeight: 2 }}
          >
            Be a chef at your own home
          </Typography>

          <form className={classes.form} noValidate onKeyDown={handleKeyDown}>
            <TextInput
              type="email"
              fullWidth
              placeholder="Email"
              autoFocus={true}
              onChange={(value: string) => setemail(value)}
              value={email}
            />
            <TextInput
              type="password"
              fullWidth
              placeholder="Password"
              onChange={(value: string) => setpassword(value)}
              value={password}
            />

            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
            {auth.loading ? (
              <Button fullWidth variant="outlined" className={classes.submit}>
                <CircularProgress color="secondary" size="22px" />
              </Button>
            ) : (
              <Button
                onClick={() => submit()}
                fullWidth
                variant="outlined"
                className={classes.submit}
              >
                <Typography>Login</Typography>
              </Button>
            )}

            <Grid container>
              <Grid item xs>
                <Link to="/forgot-password" className={classes.forgotPassword}>
                  <Typography align="center">Forgot password?</Typography>
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "100vh",
    width: "100%",
    backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.77),
            rgba(0, 0, 0, 0.77)
          ), url(${"assets/background/bg-original.png"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "center",
  },
  container: {},
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    borderRadius: 40,
    margin: theme.spacing(3, 0, 6),
    padding: theme.spacing(1.5, 0, 1.5),
    backgroundColor: "transparent",
    borderColor: COLORS.accent,
    borderWidth: 2,
    color: COLORS.accent,
  },
  forgotPassword: {
    marginTop: 100,
    color: COLORS.accent,
  },
}));
