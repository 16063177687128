export enum ERoles {
  ADMIN = "admin",
  // ADMIN_B2B = 'ADMIN_B2B',
  TRANSACTION = "transaction",
  FINANCE = "finance",
  PACKAGING = "packaging",
  SERVICE_CENTER = "service_center",
}

export enum EPermission {
  DASHBOARD_SUPERADMIN = "DASHBOARD_SUPERADMIN",
  DASHBOARD_B2C = "DASHBOARD_B2C",
  DASHBOARD_B2B = "DASHBOARD_B2B",

  DISTRIBUTOR_FULL_ACCESS = "DISTRIBUTOR_FULL_ACCESS",
  DISTRIBUTOR_LIST = "DISTRIBUTOR_LIST",
  DISTRIBUTOR_CREATE = "DISTRIBUTOR_CREATE",
  DISTRIBUTOR_EDIT = "DISTRIBUTOR_EDIT",
  DISTRIBUTOR_DELETE = "DISTRIBUTOR_DELETE",

  USER_FULL_ACCESS = "USER_FULL_ACCESS",
  USER_LIST = "USER_LIST",
  USER_CREATE = "USER_CREATE",
  USER_EDIT = "USER_EDIT",
  USER_DELETE = "USER_DELETE",

  CUSTOMER_FULL_ACCESS = "CUSTOMER_FULL_ACCESS",
  CUSTOMER_LIST = "CUSTOMER_LIST",
  CUSTOMER_CREATE = "CUSTOMER_CREATE",
  CUSTOMER_EDIT = "CUSTOMER_EDIT",
  CUSTOMER_DELETE = "CUSTOMER_DELETE",

  CATEGORY_FULL_ACCESS = "CATEGORY_FULL_ACCESS",
  CATEGORY_LIST = "CATEGORY_LIST",
  CATEGORY_CREATE = "CATEGORY_CREATE",
  CATEGORY_EDIT = "CATEGORY_EDIT",
  CATEGORY_DELETE = "CATEGORY_DELETE",

  PRODUCT_FULL_ACCESS = "PRODUCT_FULL_ACCESS",
  PRODUCT_LIST = "PRODUCT_LIST",
  PRODUCT_CREATE = "PRODUCT_CREATE",
  PRODUCT_EDIT = "PRODUCT_EDIT",
  PRODUCT_DELETE = "PRODUCT_DELETE",

  FEATURED_PRODUCT_FULL_ACCESS = "FEATURED_PRODUCT_FULL_ACCESS",
  FEATURED_PRODUCT_LIST = "FEATURED_PRODUCT_LIST",
  FEATURED_PRODUCT_CREATE = "FEATURED_PRODUCT_CREATE",
  FEATURED_PRODUCT_EDIT = "FEATURED_PRODUCT_EDIT",
  FEATURED_PRODUCT_DELETE = "FEATURED_PRODUCT_DELETE",

  SALES_ORDER_FULL_ACCESS = "SALES_ORDER_FULL_ACCESS",
  SALES_ORDER_LIST = "SALES_ORDER_LIST",
  SALES_ORDER_CREATE = "SALES_ORDER_CREATE",
  SALES_ORDER_EDIT = "SALES_ORDER_EDIT",
  SALES_ORDER_DELETE = "SALES_ORDER_DELETE",

  DELIVERY_ORDER_FULL_ACCESS = "DELIVERY_ORDER_FULL_ACCESS",
  DELIVERY_ORDER_LIST = "DELIVERY_ORDER_LIST",
  DELIVERY_ORDER_CREATE = "DELIVERY_ORDER_CREATE",
  DELIVERY_ORDER_EDIT = "DELIVERY_ORDER_EDIT",
  DELIVERY_ORDER_DELETE = "DELIVERY_ORDER_DELETE",

  RETURN_ORDER_FULL_ACCESS = "RETURN_ORDER_FULL_ACCESS",
  RETURN_ORDER_LIST = "RETURN_ORDER_LIST",
  RETURN_ORDER_CREATE = "RETURN_ORDER_CREATE",
  RETURN_ORDER_EDIT = "RETURN_ORDER_EDIT",
  RETURN_ORDER_DELETE = "RETURN_ORDER_DELETE",

  RETURN_DELIVERY_ORDER_FULL_ACCESS = "RETURN_DELIVERY_ORDER_FULL_ACCESS",
  RETURN_DELIVERY_ORDER_LIST = "RETURN_DELIVERY_ORDER_LIST",
  RETURN_DELIVERY_ORDER_CREATE = "RETURN_DELIVERY_ORDER_CREATE",
  RETURN_DELIVERY_ORDER_EDIT = "RETURN_DELIVERY_ORDER_EDIT",
  RETURN_DELIVERY_ORDER_DELETE = "RETURN_DELIVERY_ORDER_DELETE",

  REPLACEMENT_ORDER_FULL_ACCESS = "REPLACEMENT_ORDER_FULL_ACCESS",
  REPLACEMENT_ORDER_LIST = "REPLACEMENT_ORDER_LIST",
  REPLACEMENT_ORDER_CREATE = "REPLACEMENT_ORDER_CREATE",
  REPLACEMENT_ORDER_EDIT = "REPLACEMENT_ORDER_EDIT",
  REPLACEMENT_ORDER_DELETE = "REPLACEMENT_ORDER_DELETE",

  REPLACEMENT_DELIVERY_ORDER_FULL_ACCESS = "REPLACEMENT_DELIVERY_ORDER_FULL_ACCESS",
  REPLACEMENT_DELIVERY_ORDER_LIST = "REPLACEMENT_DELIVERY_ORDER_LIST",
  REPLACEMENT_DELIVERY_ORDER_CREATE = "REPLACEMENT_DELIVERY_ORDER_CREATE",
  REPLACEMENT_DELIVERY_ORDER_EDIT = "REPLACEMENT_DELIVERY_ORDER_EDIT",
  REPLACEMENT_DELIVERY_ORDER_DELETE = "REPLACEMENT_DELIVERY_ORDER_DELETE",

  TRIP_FULL_ACCESS = "TRIP_FULL_ACCESS",
  TRIP_LIST = "TRIP_LIST",
  TRIP_CREATE = "TRIP_CREATE",
  TRIP_EDIT = "TRIP_EDIT",
  TRIP_DELETE = "TRIP_DELETE",

  PICKUP_FULL_ACCESS = "PICKUP_FULL_ACCESS",
  PICKUP_LIST = "PICKUP_LIST",
  PICKUP_CREATE = "PICKUP_CREATE",
  PICKUP_EDIT = "PICKUP_EDIT",
  PICKUP_DELETE = "PICKUP_DELETE",

  BANNER_FULL_ACCESS = "BANNER_FULL_ACCESS",
  BANNER_LIST = "BANNER_LIST",
  BANNER_CREATE = "BANNER_CREATE",
  BANNER_EDIT = "BANNER_EDIT",
  BANNER_DELETE = "BANNER_DELETE",

  PROMOTION_FULL_ACCESS = "PROMOTION_FULL_ACCESS",
  PROMOTION_LIST = "PROMOTION_LIST",
  PROMOTION_CREATE = "PROMOTION_CREATE",
  PROMOTION_EDIT = "PROMOTION_EDIT",
  PROMOTION_DELETE = "PROMOTION_DELETE",

  NEWS_EVENT_FULL_ACCESS = "NEWS_EVENT_FULL_ACCESS",
  NEWS_EVENT_LIST = "NEWS_EVENT_LIST",
  NEWS_EVENT_CREATE = "NEWS_EVENT_CREATE",
  NEWS_EVENT_EDIT = "NEWS_EVENT_EDIT",
  NEWS_EVENT_DELETE = "NEWS_EVENT_DELETE",

  FEEDBACK_FULL_ACCESS = "FEEDBACK_FULL_ACCESS",
  FEEDBACK_LIST = "FEEDBACK_LIST",
  FEEDBACK_CREATE = "FEEDBACK_CREATE",
  FEEDBACK_EDIT = "FEEDBACK_EDIT",
  FEEDBACK_DELETE = "FEEDBACK_DELETE",

  TRANSACTION_FULL_ACCESS = "TRANSACTION_FULL_ACCESS",
  TRANSACTION_LIST = "TRANSACTION_LIST",
  TRANSACTION_CREATE = "TRANSACTION_CREATE",
  TRANSACTION_EDIT = "TRANSACTION_EDIT",
  TRANSACTION_DELETE = "TRANSACTION_DELETE",

  DISBURSMENT_FULL_ACCESS = "DISBURSMENT_FULL_ACCESS",
  DISBURSMENT_LIST = "DISBURSMENT_LIST",
  DISBURSMENT_CREATE = "DISBURSMENT_CREATE",
  DISBURSMENT_EDIT = "DISBURSMENT_EDIT",
  DISBURSMENT_DELETE = "DISBURSMENT_DELETE",

  REFUND_FULL_ACCESS = "REFUND_FULL_ACCESS",
  REFUND_LIST = "REFUND_LIST",
  REFUND_CREATE = "REFUND_CREATE",
  REFUND_EDIT = "REFUND_EDIT",
  REFUND_DELETE = "REFUND_DELETE",

  STOCK_RETAIL_FULL_ACCESS = "STOCK_RETAIL_FULL_ACCESS",
  STOCK_RETAIL_LIST = "STOCK_RETAIL_LIST",
  STOCK_RETAIL_CREATE = "STOCK_RETAIL_CREATE",
  STOCK_RETAIL_EDIT = "STOCK_RETAIL_EDIT",
  STOCK_RETAIL_DELETE = "STOCK_RETAIL_DELETE",

  REFERRAL_FULL_ACCESS = "REFERRAL_FULL_ACCESS",
  REFERRAL_LIST = "REFERRAL_LIST",
  REFERRAL_CREATE = "REFERRAL_CREATE",
  REFERRAL_EDIT = "REFERRAL_EDIT",
  REFERRAL_DELETE = "REFERRAL_DELETE",

  DISCOUNT_REFERRAL_FULL_ACCESS = "DISCOUNT_REFERRAL_FULL_ACCESS",
  DISCOUNT_REFERRAL_LIST = "DISCOUNT_REFERRAL_LIST",
  DISCOUNT_REFERRAL_CREATE = "DISCOUNT_REFERRAL_CREATE",
  DISCOUNT_REFERRAL_EDIT = "DISCOUNT_REFERRAL_EDIT",
  DISCOUNT_REFERRAL_DELETE = "DISCOUNT_REFERRAL_DELETE",

  SHIPMENT_DISCOUNT_FULL_ACCESS = "SHIPMENT_DISCOUNT_FULL_ACCESS",
  SHIPMENT_DISCOUNT_LIST = "SHIPMENT_DISCOUNT_LIST",
  SHIPMENT_DISCOUNT_CREATE = "SHIPMENT_DISCOUNT_CREATE",
  SHIPMENT_DISCOUNT_EDIT = "SHIPMENT_DISCOUNT_EDIT",
  SHIPMENT_DISCOUNT_DELETE = "SHIPMENT_DISCOUNT_DELETE",

  BANK_ACCOUNT_APPROVAL_FULL_ACCESS = "BANK_ACCOUNT_APPROVAL_FULL_ACCESS",
  BANK_ACCOUNT_APPROVAL_LIST = "BANK_ACCOUNT_APPROVAL_LIST",
  BANK_ACCOUNT_APPROVAL_CREATE = "BANK_ACCOUNT_APPROVAL_CREATE",
  BANK_ACCOUNT_APPROVAL_EDIT = "BANK_ACCOUNT_APPROVAL_EDIT",
  BANK_ACCOUNT_APPROVAL_DELETE = "BANK_ACCOUNT_APPROVAL_DELETE",

  APP_VERSION_FULL_ACCESS = "APP_VERSION_FULL_ACCESS",
  APP_VERSION_LIST = "APP_VERSION_LIST",
  APP_VERSION_CREATE = "APP_VERSION_CREATE",
  APP_VERSION_EDIT = "APP_VERSION_EDIT",
  APP_VERSION_DELETE = "APP_VERSION_DELETE",

  VOUCHER_GENERATE_FULL_ACCESS = "VOUCHER_GENERATE_FULL_ACCESS",
  VOUCHER_GENERATE_LIST = "VOUCHER_GENERATE_LIST",
  VOUCHER_GENERATE_CREATE = "VOUCHER_GENERATE_CREATE",
  VOUCHER_GENERATE_EDIT = "VOUCHER_GENERATE_EDIT",
  VOUCHER_GENERATE_DELETE = "VOUCHER_GENERATE_DELETE",

  STOREFRONT_FULL_ACCESS = "STOREFRONT_FULL_ACCESS",
  STOREFRONT_LIST = "STOREFRONT_LIST",
  STOREFRONT_CREATE = "STOREFRONT_CREATE",
  STOREFRONT_EDIT = "STOREFRONT_EDIT",
  STOREFRONT_DELETE = "STOREFRONT_DELETE",

  COLLECTION_APPROVAL_FULL_ACCESS = "COLLECTION_APPROVAL_FULL_ACCESS",
  COLLECTION_APPROVAL_LIST = "COLLECTION_APPROVAL_LIST",

  SERVICE_CENTER_TRANSACTION_FULL_ACCESS = "SERVICE_CENTER_TRANSACTION_FULL_ACCESS",
  SERVICE_CENTER_TRANSACTION_LIST = "SERVICE_CENTER_TRANSACTION_LIST",
}

export type EnumKeysAsString<T> = keyof T;

type IPermission = {
  id: string;
  name: string;
};

export type IRole = {
  id: string;
  name: string;
  permissions: Array<string>;
};

const FULL_ACCESS: IPermission[] = Object.keys(EPermission).map((item) => {
  return {
    id: item as any,
    name: EPermission[item as keyof typeof EPermission],
  };
});

// const ADMIN_B2B: IPermission[] = [
//   {
//     id: EPermission.DASHBOARD_B2B,
//     name: EPermission.DASHBOARD_B2B,
//   },
//   {
//     id: EPermission.USER_CREATE,
//     name: EPermission.USER_CREATE,
//   },
// ];

const TRANSACTION: IPermission[] = [
  {
    id: EPermission.DASHBOARD_B2C,
    name: EPermission.DASHBOARD_B2C,
  },
  {
    id: EPermission.TRANSACTION_FULL_ACCESS,
    name: EPermission.TRANSACTION_FULL_ACCESS,
  },
  {
    id: EPermission.PRODUCT_LIST,
    name: EPermission.PRODUCT_LIST,
  },
  {
    id: EPermission.FEATURED_PRODUCT_FULL_ACCESS,
    name: EPermission.FEATURED_PRODUCT_FULL_ACCESS,
  },
  {
    id: EPermission.STOCK_RETAIL_FULL_ACCESS,
    name: EPermission.STOCK_RETAIL_FULL_ACCESS,
  },
  {
    id: EPermission.CUSTOMER_LIST,
    name: EPermission.CUSTOMER_LIST,
  },
  {
    id: EPermission.SHIPMENT_DISCOUNT_FULL_ACCESS,
    name: EPermission.SHIPMENT_DISCOUNT_FULL_ACCESS,
  },
  {
    id: EPermission.NEWS_EVENT_FULL_ACCESS,
    name: EPermission.NEWS_EVENT_FULL_ACCESS,
  },
  {
    id: EPermission.PROMOTION_FULL_ACCESS,
    name: EPermission.PROMOTION_FULL_ACCESS,
  },
  {
    id: EPermission.BANNER_FULL_ACCESS,
    name: EPermission.BANNER_FULL_ACCESS,
  },
  {
    id: EPermission.VOUCHER_GENERATE_FULL_ACCESS,
    name: EPermission.VOUCHER_GENERATE_FULL_ACCESS,
  },
  {
    id: EPermission.VOUCHER_GENERATE_FULL_ACCESS,
    name: EPermission.VOUCHER_GENERATE_FULL_ACCESS,
  },
  {
    id: EPermission.STOREFRONT_FULL_ACCESS,
    name: EPermission.STOREFRONT_FULL_ACCESS,
  },
  {
    id: EPermission.SALES_ORDER_FULL_ACCESS,
    name: EPermission.SALES_ORDER_FULL_ACCESS,
  },
  {
    id: EPermission.DELIVERY_ORDER_FULL_ACCESS,
    name: EPermission.DELIVERY_ORDER_FULL_ACCESS,
  },
  {
    id: EPermission.RETURN_ORDER_FULL_ACCESS,
    name: EPermission.RETURN_ORDER_FULL_ACCESS,
  },
  {
    id: EPermission.RETURN_DELIVERY_ORDER_FULL_ACCESS,
    name: EPermission.RETURN_DELIVERY_ORDER_FULL_ACCESS,
  },
  {
    id: EPermission.REPLACEMENT_ORDER_FULL_ACCESS,
    name: EPermission.REPLACEMENT_ORDER_FULL_ACCESS,
  },
  {
    id: EPermission.REPLACEMENT_DELIVERY_ORDER_FULL_ACCESS,
    name: EPermission.REPLACEMENT_DELIVERY_ORDER_FULL_ACCESS,
  },
  {
    id: EPermission.TRIP_FULL_ACCESS,
    name: EPermission.TRIP_FULL_ACCESS,
  },
  {
    id: EPermission.PICKUP_FULL_ACCESS,
    name: EPermission.PICKUP_FULL_ACCESS,
  },
  {
    id: EPermission.DISTRIBUTOR_FULL_ACCESS,
    name: EPermission.DISTRIBUTOR_FULL_ACCESS,
  },
];

const FINANCE: IPermission[] = [
  {
    id: EPermission.DASHBOARD_B2C,
    name: EPermission.DASHBOARD_B2C,
  },
  {
    id: EPermission.DISBURSMENT_FULL_ACCESS,
    name: EPermission.DISBURSMENT_FULL_ACCESS,
  },
  {
    id: EPermission.REFUND_FULL_ACCESS,
    name: EPermission.REFUND_FULL_ACCESS,
  },
  {
    id: EPermission.CUSTOMER_LIST,
    name: EPermission.CUSTOMER_LIST,
  },
  {
    id: EPermission.BANK_ACCOUNT_APPROVAL_FULL_ACCESS,
    name: EPermission.BANK_ACCOUNT_APPROVAL_FULL_ACCESS,
  },
];

const PACKAGING: IPermission[] = [
  {
    id: EPermission.DASHBOARD_B2C,
    name: EPermission.DASHBOARD_B2C,
  },
  {
    id: EPermission.TRANSACTION_LIST,
    name: EPermission.TRANSACTION_LIST,
  },
];

const SERVICE_CENTER: IPermission[] = [
  {
    id: EPermission.DASHBOARD_B2C,
    name: EPermission.DASHBOARD_B2C,
  },
  {
    id: EPermission.SERVICE_CENTER_TRANSACTION_FULL_ACCESS,
    name: EPermission.SERVICE_CENTER_TRANSACTION_FULL_ACCESS,
  },
  {
    id: EPermission.SERVICE_CENTER_TRANSACTION_LIST,
    name: EPermission.SERVICE_CENTER_TRANSACTION_LIST,
  },
];

export const LIST_ROLE: IRole[] = [
  {
    id: ERoles.ADMIN,
    name: "Superadmin",
    permissions: FULL_ACCESS.map((item: IPermission) => item.id),
  },
  {
    id: ERoles.TRANSACTION,
    name: "Admin Transaction",
    permissions: TRANSACTION.map((item: IPermission) => item.id),
  },
  {
    id: ERoles.FINANCE,
    name: "Admin Finance",
    permissions: FINANCE.map((item: IPermission) => item.id),
  },
  {
    id: ERoles.PACKAGING,
    name: "Admin Packaging",
    permissions: PACKAGING.map((item: IPermission) => item.id),
  },
  {
    id: ERoles.SERVICE_CENTER,
    name: "Admin Service Center",
    permissions: SERVICE_CENTER.map((item: IPermission) => item.id),
  },
];
