import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import FormInput from "../../components/Form/FormInput";
import { BranchProperties } from '../../types/b2b/branch/branch.type';
import useFetch from '../../hooks/useFetch';
import { useDispatch } from 'react-redux';
import AddIcon from "@material-ui/icons/Add";
import { setDataBranch } from '../../redux/action/b2b.action';
import axios from 'axios';
import { X } from 'react-feather';
import AppMap, { Marker } from '../../components/AppMap';
import DetailBranch from '../../components/B2B/DetailBranch';
import IconMarker from '../../assets/distributor-marker.png';
import { httpRequest } from '../../helpers/api/api';
import { Coords } from 'google-map-react';

const headers: ITableHeader[] = [
  {
    title: "id",
    column: "id",
    type: "id",
  },
  {
    title: "Branch Name",
    column: "name",
  },
  {
    title: "Address",
    column: "address",
  },
  {
    title: "Phone",
    column: "phoneNumber",
  },
];

type Props = {
  showButton?: boolean;
  onNext?: () => void;
  onBack?: () => void;
  distributorId: string;
  distributorName?: string;
};

const initialQuery = {
  limit: 25,
  page: 1,
  sort: 'name,ASC'
}

export default function ListBranch(props: Props) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch()

  const [isShowModalFormCreate, setIsShowModalFormCreate] = useState(false);
  const [mode, setMode] = useState<'detail' | 'edit' | 'create'>('create')
  const [markers, setMarkers] = useState<Array<Marker>>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [id, setId] = useState<string>()
  const [name, setName] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [latlng, setLatLng] = useState<string>()
  const [centerMap, setCentermap] = useState<Coords>({ lat: -6.1734251377558955, lng: 106.82676927467757 })

  const handleCloseModalFormCreate = () => {
    setIsShowModalFormCreate(false);
  };
  const handleOpenModalFormCreate = () => {
    setMode('create')

    setId(undefined);
    setName(undefined);
    setPhoneNumber(undefined);
    setMarkers([])

    setIsShowModalFormCreate(true);
  };

  const handleEditDataBranch = (
    data: {
      id: string,
      name: string,
      phoneNumber: string,
      address: string,
      lat: number,
      lng: number
    }
  ) => {
    setMode('edit')
    setId(data.id);
    setName(data.name);
    setPhoneNumber(data.phoneNumber);
    setAddress(data.address);
    handleChangeMarker({ lat: data.lat, lng: data.lng })

    setCentermap({ lat: data.lat, lng: data.lng })
    setIsShowModalFormCreate(true);
  }

  const {
    data,
    loading,
    pagination,
    fetchDataTableAlt,
    handlePerPageChange,
    handlePageChange,
  } = useFetch<BranchProperties>({
    url: 'branches',
    query: { ...initialQuery, distributorId: props.distributorId },
    staticUrl: true
  })

  React.useEffect(() => {
    const source = axios.CancelToken.source()

    if (props.distributorId) {
      fetchDataTableAlt(source)
    }

    return () => {
      source.cancel()
    };
  }, [props.distributorId])

  const dataBranches = data.map((item) => {
    const coordinate = item.coordinate.split(';')
    return {
      id: item.branchId,
      name: item.name,
      address: item.address,
      phoneNumber: item.phoneNumber,
      lat: Number(coordinate[0]),
      lng: Number(coordinate[1]),
    }
  })

  const handleSeeDetailBranch = (branchId: string) => {
    setMode('detail')
    const dataBranch = data.find(item => item.branchId === branchId)
    if (dataBranch) {
      dispatch(setDataBranch(dataBranch))
    }
    setIsShowModalFormCreate(true);
  }

  const handleChangeMarker = (value: { lat: number, lng: number }) => {
    console.info('data latlng', value)
    setLatLng(`${value.lat}, ${value.lng}`)
    setCentermap({ lat: value.lat, lng: value.lng })
    setMarkers([{
      lat: value.lat,
      lng: value.lng,
      content: (
        <img
          style={{
            transform: 'translateZ(0) translate(-50%, -100%)',
            backfaceVisibility: 'hidden',
            height: 50
          }}
          src={IconMarker}
        />
      )
    }])
  }

  const submit = async () => {
    setLoadingSubmit(true)

    if (id) {
      await httpRequest.patch<BranchProperties>(`branches/${id}`, {
        distributorId: props.distributorId,
        name: name,
        address: address,
        phoneNumber: phoneNumber,
        coordinate: {
          lat: markers[0].lat,
          lng: markers[0].lng
        }
      })
      setLoadingSubmit(false)
    } else {
      await httpRequest.post<BranchProperties>(`branches`, {
        distributorId: props.distributorId,
        name: name,
        address: address,
        phoneNumber: phoneNumber,
        coordinate: {
          lat: markers[0].lat,
          lng: markers[0].lng
        }
      })
      setLoadingSubmit(false)
    }

    const source = axios.CancelToken.source()

    fetchDataTableAlt(source)

    setIsShowModalFormCreate(false);
    setId('');
    setName('');
    setPhoneNumber('');
    setAddress('');
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={classes.paperTitle}>
        <Typography variant="h5">Branch Information</Typography>
      </div>

      <Grid container spacing={3} justify="space-between">
        <Grid item>
          <div className={classes.containerTable}>
            <div
              className={globalClasses.link}
            >
              <AppButton
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenModalFormCreate}
              >
                Add Branch
              </AppButton>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className={classes.containerTable}>
        <CustomizedTable
          data={dataBranches}
          headers={headers}
          totalData={pagination.totalData}
          showAction={["detail-dialog", "edit-dialog", "delete"]}
          page={pagination.page}
          totalPage={pagination.countPage}
          rowsPerPage={pagination.perPage}
          onPressDetail={(data) => {
            handleSeeDetailBranch(data.id)
          }}
          onPressEditDialog={(data) => handleEditDataBranch(data)}
          onChange={(newPage) => handlePageChange(newPage)}
          onRowsPerPageChange={(value: any) => handlePerPageChange(value)}
        />
      </div>

      {props.showButton && (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.containerButton}
        >
          <AppButton
            variant="contained"
            color="secondary"
            onClick={() => (props.onBack ? props.onBack() : false)}
          >
            Back
          </AppButton>
          <AppButton
            onClick={() => (props.onNext ? props.onNext() : false)}
            variant="contained"
            color="primary"
          >
            Next
          </AppButton>
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        open={isShowModalFormCreate}
        onClose={handleCloseModalFormCreate}
        scroll={'body'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Typography variant="h4">{mode === 'detail' ? 'Detail' : mode === 'edit' ? 'Edit' : 'Create'} Branch</Typography>
            <div onClick={handleCloseModalFormCreate} style={{ cursor: 'pointer' }}>
              <X />
            </div>
          </div>
        </DialogTitle>
        {mode === 'detail' ? (
          <DialogContent>
            <DetailBranch />
          </DialogContent>
        ) : (
            <DialogContent>
              <FormInput
                fullWidth
                required
                label="Name"
                value={name}
                onChange={(newValue: any) => setName(newValue)}
              />
              <div
                style={{
                  height: 350,
                  width: '100%',
                  borderRadius: 10,
                  overflow: 'hidden'
                }}
              >
                <AppMap
                  handleOnClick={(value) => handleChangeMarker(value)}
                  markers={markers}
                  height={350}
                  centerMap={centerMap}
                />
              </div>
              <FormInput
                style={{ marginTop: 15 }}
                fullWidth
                required
                label="Latitude & Longitude"
                value={latlng}
                onChange={(newValue: any) => {
                  const newLatlng = newValue.split(',')
                  if (newLatlng.length > 0) {
                    setCentermap({ lat: Number(newLatlng[0]), lng: Number(newLatlng[1]) })
                    handleChangeMarker({ lat: Number(newLatlng[0]), lng: Number(newLatlng[1]) })
                  }
                  setLatLng(newValue)
                }
                }
              />
              <FormInput
                style={{ marginTop: 15 }}
                fullWidth
                required
                label="Address"
                value={address}
                onChange={(value: any) => setAddress(value)}
                type="address"
              />
              <FormInput
                fullWidth
                required
                label="Phone"
                value={phoneNumber}
                onChange={(value: any) => setPhoneNumber(value)}
                type="phone"
              />
            </DialogContent>
          )}
        {mode !== 'detail' && (
          <DialogActions>
            <AppButton
              color="secondary"
              onClick={handleCloseModalFormCreate}
            >
              Cancel
            </AppButton>
            <AppButton
              disabled={loadingSubmit}
              loading={loadingSubmit}
              onClick={() => {
                submit()
              }}
            >
              {mode === 'create' ? 'Add Branch' : 'Update'}
            </AppButton>
          </DialogActions>
        )}
      </Dialog>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    paperTitle: {
      // padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      margin: theme.spacing(2, 0, 1, 0),
    },
    containerTable: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    containerModal: {
      position: "absolute",
      width: "100%",
      height: "100%",
      alihandleCloseModalFormCreategnContent: "center",
    },
    contentModal: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      width: 600,
      margin: "0 auto",
      marginTop: "10%",
      borderRadius: 10,
    },
  })
);
