import { useDispatch } from 'react-redux';
import { currentReferralCommission, findLogReferralCommission, setLoadingMemberPremium, setLoadingReferralCommission, setLoadingRetailCommission, setLoadingSPGCommission } from '../redux/action/master-data-referral.action';
import { apiCurrentReferralCommission, apiLogReferralCommission, apiUpdateReferralCommission } from '../api/master-data/referral-commission';
import { generateQueryString } from '../helpers/functions/generateQueryString';


export default function useReferralCommission() {
  const dispatch = useDispatch()

  const fetchLogReferralCommission = async (params: any) => {
    try {
      // dispatch(setLoadingReferralCommission(true))

      let query = generateQueryString(params)
      const result = await apiLogReferralCommission(query)

      dispatch(findLogReferralCommission(result.data))

      // dispatch(setLoadingReferralCommission(false))
    } catch (error) {
      // dispatch(setLoadingReferralCommission(false))
    }
  }

  const fetchCurrentReferralCommission = async () => {
    try {
      // dispatch(setLoadingReferralCommission(true))
      const result = await apiCurrentReferralCommission()

      dispatch(currentReferralCommission(result.data.payload))

      // dispatch(setLoadingReferralCommission(false))
    } catch (error) {
      // dispatch(setLoadingReferralCommission(false))
    }
  }

  const updateReferralCommission = async (payload: { data: any, type: 'referral' | 'retail' | 'member' | 'spg' }) => {
    try {
      if (payload.type === 'referral') {
        dispatch(setLoadingReferralCommission(true))
      } else if (payload.type === 'retail') {
        dispatch(setLoadingRetailCommission(true))
      } else if (payload.type === 'spg') {
        dispatch(setLoadingSPGCommission(true))
      } else {
        dispatch(setLoadingMemberPremium(true))
      }
      const result = await apiUpdateReferralCommission(payload.data)

      // dispatch(currentReferralCommission(result.data))

      await fetchLogReferralCommission({ limit: 25, offset: 0 })
      await fetchCurrentReferralCommission()

      if (payload.type === 'referral') {
        dispatch(setLoadingReferralCommission(false))
      } else if (payload.type === 'retail') {
        dispatch(setLoadingRetailCommission(false))
      } else {
        dispatch(setLoadingMemberPremium(false))
      }
    } catch (error) {
      if (payload.type === 'referral') {
        dispatch(setLoadingReferralCommission(false))
      } else if (payload.type === 'retail') {
        dispatch(setLoadingRetailCommission(false))
      } else if (payload.type === 'spg') {
        dispatch(setLoadingSPGCommission(false))
      } else {
        dispatch(setLoadingMemberPremium(false))
      }
    }
  }

  return {
    fetchLogReferralCommission,
    fetchCurrentReferralCommission,
    updateReferralCommission
  }
}