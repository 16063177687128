/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Paper,
  createStyles,
  makeStyles,
  Theme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/Form/FormInput";
import AppButton from "../../components/Form/AppButton";
import COLORS from "../../styles/colors";
import { useLocation, useParams } from "react-router-dom";
import UploadImage from "../../components/Form/UploadImage";
import HeaderPage from "../../components/Layout/HeaderPage";
import { useAdmin } from "../../hooks/useAdmin";
import { AdminSingle, EAdminRole } from "../../types/admin.type";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { enableUpdateParams } from "../../redux/action/config-app.action";
import { apiFindOneAdmin } from "../../api/admin";

export default function UserEdit() {
  const location = useLocation();
  const history = useHistory();
  let { id } = useParams<{ id: string }>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [mode, setMode] = useState<"detail" | "edit" | "create">("detail");
  const [gudangOxoneId, setGudangOxoneId] = useState();

  const [dataAdmin, setDataAdmin] = useState<AdminSingle>({
    adminId: "",
    name: "",
    email: "",
    phoneNumber: "",
    role: "admin",
    status: "inactive",
  });
  const [updatedImage, setUpdatedImage] = useState<any>();

  const { isLoadingAdmin, admin } = useSelector(
    (state: ApplicationState) => state.admin
  );

  const { createAdmin, updateAdmin } = useAdmin();

  const getAdmin = async () => {
    try {
      const res = await apiFindOneAdmin(id);
      if (res) {
        setDataAdmin({
          ...res.data.payload,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id && location.pathname.includes("edit")) {
      // edit
      getAdmin();
      // setDataAdmin({ ...admin });
      setMode("edit");
    } else if (id) {
      // detail
      getAdmin();
      // setDataAdmin({ ...admin });
      setMode("detail");
    } else {
      // create
      setMode("create");
    }
  }, []);

  const cancel = () => {
    dispatch(enableUpdateParams(false));
    history.go(-1);
  };

  const submit = () => {
    if (id) {
      updateAdmin({ ...dataAdmin, updatedImage });
    } else {
      createAdmin({ ...dataAdmin, updatedImage });
    }
  };

  const handleChangeRole = (role: string) => {
    setDataAdmin({ ...dataAdmin, role });
  };

  let title = "";
  let subtitle = "";

  if (mode === "create") {
    title = "Create User";
    subtitle = "Create new user";
  } else if (mode === "edit") {
    title = "Edit User";
    subtitle = "Edit user";
  } else if (mode === "detail") {
    title = "Detail User";
    subtitle = "Detail user";
  }

  return (
    <Layout title={title} loading={isLoadingAdmin}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <HeaderPage title={title} subtitle={subtitle} showBackButton />

        {mode === "create" || mode === "edit" ? (
          <Box display="flex" justifyContent="flex-end">
            <AppButton color="secondary" onClick={cancel}>
              Cancel
            </AppButton>
            <AppButton
              color="primary"
              onClick={submit}
              loading={isLoadingAdmin}
            >
              Save
            </AppButton>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Paper elevation={3} className={classes.paper}>
        <Box display="flex" flexWrap="wrap" className={classes.containerForm}>
          <Box width="30%">
            <UploadImage
              image={dataAdmin.imageUrl}
              editable={mode === "create" || mode === "edit"}
              onFileSelected={(value: any) => {
                setUpdatedImage(value);
              }}
            />
          </Box>

          <Box width="70%" className={classes.input}>
            <FormInput
              value={dataAdmin.name}
              onChange={(value: any) =>
                setDataAdmin({ ...dataAdmin, name: value })
              }
              fullWidth
              required
              readOnly={mode === "detail"}
              label="Name"
            />
            <FormInput
              value={dataAdmin.email}
              onChange={(value: any) =>
                setDataAdmin({ ...dataAdmin, email: value.toLowerCase() })
              }
              type="email"
              fullWidth
              required
              readOnly={mode === "detail"}
              label="Email"
            />
            <FormInput
              value={dataAdmin.phoneNumber}
              onChange={(value: any) =>
                setDataAdmin({ ...dataAdmin, phoneNumber: value })
              }
              type="phone"
              fullWidth
              required
              readOnly={mode === "detail"}
              label="Phone"
            />

            <Typography variant="body1">Role</Typography>

            <RadioGroup
              value={dataAdmin.role}
              row
              aria-label="role"
              name="role"
              onChange={(e: ChangeEvent<HTMLInputElement>, value: string) =>
                handleChangeRole(value)
              }
            >
              <FormControlLabel
                disabled={mode === "detail"}
                value="admin"
                control={<Radio color="primary" />}
                label="Admin"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="admin_trip"
                control={<Radio color="primary" />}
                label="Admin Trip"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="driver"
                control={<Radio color="primary" />}
                label="Driver"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="transaction"
                control={<Radio color="primary" />}
                label="Transaction"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="packaging"
                control={<Radio color="primary" />}
                label="Packaging"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="finance"
                control={<Radio color="primary" />}
                label="Finance"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="service_center"
                control={<Radio color="primary" />}
                label="Service Center"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="spg_voucher_all"
                control={<Radio color="primary" />}
                label="SPG Voucher All"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="spg_voucher_generate"
                control={<Radio color="primary" />}
                label="SPG Voucher Generate"
              />
              <FormControlLabel
                disabled={mode === "detail"}
                value="spg_voucher_validate"
                control={<Radio color="primary" />}
                label="SPG Voucher Validate"
              />
            </RadioGroup>

            {/* <Box>
              <FormControl className={classes.formBranch}>
                <InputLabel required id="select-gudangOxone">
                  Gudang Oxone
                </InputLabel>
                <Select
                  required
                  label="Gudang Oxone"
                  placeholder="Gudang Oxone"
                  labelId="select-gudangOxone"
                  id="demo-customized-select"
                  value={gudangOxoneId}
                  onChange={(event: any) => {
                    setGudangOxoneId(event.target.value);
                  }}
                >
                  <MenuItem value="1">Gudang Oxone 1</MenuItem>
                  <MenuItem value="2">Gudang Oxone 2</MenuItem>
                  <MenuItem value="3">Gudang Oxone 3</MenuItem>
                </Select>
              </FormControl>
            </Box> */}

            <Box>
              <FormControl className={classes.formBranch}>
                <InputLabel id="select-branch" required>
                  Status
                </InputLabel>
                <Select
                  readOnly={mode === "detail"}
                  required
                  label="Status"
                  placeholder="Status*"
                  value={dataAdmin.status}
                  labelId="select-branch"
                  id="demo-customized-select"
                  onChange={(event: any) => {
                    setDataAdmin({ ...dataAdmin, status: event.target.value });
                  }}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2),
    },
    containerForm: {},
    input: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    paperTitle: {
      padding: theme.spacing(2),
    },
    containerButton: {
      flexDirection: "row",
      padding: theme.spacing(2),
    },
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
    cardRoot: {
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: COLORS.greyLighten,
      marginTop: 10,
    },
    cardMedia: {
      height: 200,
      width: 200,
      alignSelf: "center",
      backgroundColor: COLORS.accent,
      borderColor: COLORS.accent,
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 10,
    },
    cardActionArea: {
      display: "flex",
      padding: 10,
    },
    formBranch: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);
