export enum VersionTypes {
  FIND_ALL_VERSION = 'FIND_ALL_VERSION',
  FIND_ONE_VERSION = 'FIND_ONE_VERSION',
  CREATE_VERSION = 'CREATE_VERSION',
  UPDATE_VERSION = 'UPDATE_VERSION',
  DELETE_VERSION = 'DELETE_VERSION',
  CURRENT_VERSION = 'CURRENT_VERSION',
  SET_LOADING_VERSION = 'SET_LOADING_VERSION',
  SET_ERROR_VERSION = 'SET_ERROR_VERSION',
  SET_LAST_PATH_VERSION = 'SET_LAST_PATH_VERSION',
}

export type VersionResponse = {
  appVersionId: string;
  androidVersion: string;
  iosVersion: string;
  forceUpdate: boolean;
  forceUpdateIos: boolean;
  adminMetadata: {
    adminName: string;
    role: string;
  };
  createdAt?: Date;
  updatedAt?: Date;
}

export type VersionRequest = {
  appVersionId?: string;
  androidVersion: string | null;
  iosVersion: string | null;
  forceUpdate: boolean;
  forceUpdateIos: boolean;
}

export type VersionResponsePayload = {
  code: string;
  message: string;
  payload: {
    prev: string;
    next: string;
    count: number;
    results: VersionResponse[];
  },
}

export type VersionState = {
  isLoadingVersion: boolean;
  data: VersionResponsePayload;
  version: VersionResponse;
  currentVersion: VersionResponse;
  error: any;
}

export type VersionDeleteResponse = {
  isSuccess: boolean;
}