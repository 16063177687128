import axios from 'axios';
import { getHeaders } from '../helpers/auth';
import { SignInRequest } from '../types/auth.types';

export function apiSignIn(data: SignInRequest) {
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/auth/signin',
    method: 'POST',
    data
  });
}

export async function apiSignOut() {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/auth/signout',
    method: 'POST',
    headers
  });
}

export async function apiChangePassword(data: { oldPassword: string, newPassword: string }) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/auth/change-password/old-password',
    method: 'POST',
    headers,
    data
  });
}

export async function apiForgotPassword(email: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/auth/forgot-password',
    method: 'POST',
    headers,
    data: {
      email
    }
  });
}

export async function apiResetPassword(data: { sessionId: string, newPassword: string, }) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/auth/change-password/session',
    method: 'POST',
    headers,
    data
  });
}