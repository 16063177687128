import axios from 'axios';
import { getHeaders, getHeadersMultiPart } from '../helpers/auth';
import { AdminSingle } from '../types/admin.type';


export async function apiFindAllAdmin(params?: any) {
  const headers = await getHeaders()
  const query = params || null
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/admin' + query,
    method: 'GET',
    headers,
  });
}

export async function apiGetAdminInfo(): Promise<AdminSingle> {
  const headers = await getHeaders()
  const res = await axios({
    url: process.env.REACT_APP_BASE_URL + '/admin/info/me',
    method: 'GET',
    headers,
  });

  return res.data.payload
}

export async function apiFindOneAdmin(adminId: string) {
  const headers = await getHeaders()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/admin/' + adminId,
    method: 'GET',
    headers,
  });
}

export async function apiCreateAdmin(data: any) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/admin',
    method: 'POST',
    headers,
    data
  });
}

export async function apiUpdateAdmin(adminId: string, data: any) {
  const headers = await getHeadersMultiPart()
  return axios({
    url: process.env.REACT_APP_BASE_URL + '/admin/' + adminId,
    method: 'PUT',
    headers,
    data
  });
}
