import React, { useEffect, useState } from 'react';
import COLORS from '../../styles/colors';
import { Typography } from '@material-ui/core';
import CustomizedTable, {
  ITableHeader,
} from '../../components/Table/CustomizedTable';
import { useSelector } from 'react-redux';
import useTransaction from '../../hooks/useTransaction';
import { ApplicationState } from '../../redux/store';
import { convertDateTimeToString } from '../../helpers/functions/datetime';
import { TransactionListResponse } from '../../types/transaction.types';
import { useHistory } from 'react-router-dom';

const headers: ITableHeader[] = [
  {
    title: 'Transaction ID',
    align: 'left',
    column: 'transactionId',
    type: 'id',
  },
  {
    title: 'Transaction ID',
    align: 'left',
    column: 'invoiceId',
  },
  {
    title: 'Customer',
    align: 'left',
    column: 'customerName',
  },
  {
    title: 'Transaction Date',
    align: 'left',
    sortable: true,
    column: 'createdAt',
    type: 'date',
  },
  {
    title: 'Status',
    align: 'left',
    column: 'transactionStatus',
    type: 'status-transaction',
  },
];

let data: any[] = [];

const date = new Date();
let defaultStartDate: Date | string = new Date(
  date.getFullYear(),
  date.getMonth(),
  1,
  0,
  0,
  0,
  0
);
let defaultEndDate: Date | string = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0,
  23,
  59,
  59,
  999
);
defaultStartDate = convertDateTimeToString(defaultStartDate);
defaultEndDate = convertDateTimeToString(defaultEndDate);

export default function TransactionActivity() {
  const history = useHistory();

  const initialParams = {
    offset: 0,
    limit: 50,
    filterStatus: 'paid,process,sent',
    startAt: defaultStartDate,
    endAt: defaultEndDate,
  };
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { fetchAllTransaction } = useTransaction();

  const transactionState = useSelector(
    (state: ApplicationState) => state.transaction
  );

  useEffect(() => {
    fetchAllTransaction(initialParams);
  }, []);

  useEffect(() => {
    if (transactionState.data.payload.count > 0) {
      setTotalData(transactionState.data.payload.count);
      setTotalPage(
        Math.ceil(transactionState.data.payload.count / rowsPerPage)
      );
    }
  }, [transactionState.data]);

  function changePagination(value: number) {
    // value is page
    const offset = (value - 1) * initialParams.limit;
    setPage(value);
    fetchAllTransaction({
      ...initialParams,
      offset,
    });
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value);
    fetchAllTransaction({
      ...initialParams,
      limit: value,
      offset: 0,
    });
  }

  const data = transactionState.data.payload.results.map(
    (item: TransactionListResponse) => {
      return {
        ...item,
        invoiceId: item.invoice ? item.invoice.invoiceId : item.transactionId,
        customerName:
          item.transactionMetadata && item.transactionMetadata.buyer
            ? item.transactionMetadata.buyer.name
            : item.externalId,
      };
    }
  );

  return (
    <CustomizedTable
      data={data}
      headers={headers}
      totalData={totalData}
      page={page}
      totalPage={totalPage}
      rowsPerPage={rowsPerPage}
      onChange={changePagination}
      onRowsPerPageChange={changeRowsPerPage}
      // showAction={["detail"]}
      // onPressDetail={(data: any) => {
      //   console.info('cek', data)
      //   history.push('/transaction/' + data.transactionId)
      // }}
    />
  );
}
