import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { apiFindAllVersion, apiFindOneVersion, apiCreateVersion, apiUpdateVersion, apiDeleteVersion, apiCurrentVersion } from '../api/version';
import { setUpladImageStatus } from '../redux/action/config-app.action';
import { setLoadingVersion, findAllVersion, setErrorVersion, findOneVersion, createVersion, updateVersion, deleteVersion, currentVersion } from '../redux/action/version.action';
import { VersionRequest } from '../types/version.type';
import { generateQueryString } from '../helpers/functions/generateQueryString';

export default function useVersion() {
  const dispatch = useDispatch()
  const history = useHistory()

  const fetchAllVersion = async (params?: any) => {
    try {
      dispatch(setLoadingVersion(true))

      let query = generateQueryString(params)

      const result = await apiFindAllVersion(query)
      dispatch(findAllVersion(result.data))

      dispatch(setLoadingVersion(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingVersion(false))
      dispatch(setErrorVersion(error.response))
    }

  }

  const fetchOneVersion = async (versionId: string) => {
    try {
      dispatch(setLoadingVersion(true))

      const result = await apiFindOneVersion(versionId)
      dispatch(findOneVersion(result.data.payload))

      dispatch(setLoadingVersion(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingVersion(false))
      dispatch(setErrorVersion(error.response))
    }

  }

  const createNewVersion = async (data: VersionRequest) => {
    try {
      dispatch(setLoadingVersion(true))

      const result = await apiCreateVersion(data)

      dispatch(createVersion(result.data.payload))

      dispatch(setLoadingVersion(false))

      toast.success("Success create version", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingVersion(false))
      dispatch(setErrorVersion(error.response))
    }
  }

  const updateCurrentVersion = async (data: VersionRequest) => {
    try {
      dispatch(setLoadingVersion(true))

      const result = await apiUpdateVersion(data)

      dispatch(updateVersion(result.data.payload))

      dispatch(setLoadingVersion(false))

      toast.success("Success update version", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingVersion(false))
      dispatch(setErrorVersion(error.response))
    }
  }

  const removeVersion = async (versionId: string) => {
    try {
      dispatch(setLoadingVersion(true))

      const result = await apiDeleteVersion(versionId)

      dispatch(deleteVersion(result.data.payload))

      await fetchAllVersion({ limit: 25, offset: 0, search: '' })

      dispatch(setLoadingVersion(false))

      toast.success("Success remove version", {
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      });
    } catch (error) {
      dispatch(setLoadingVersion(false))
      dispatch(setErrorVersion(error.response))
    }

  }

  const getCurrentVersion = async () => {
    try {

      const result = await apiCurrentVersion()

      dispatch(currentVersion(result.data.payload))
    } catch (error) {
      dispatch(setErrorVersion(error.response))
    }

  }

  return {
    fetchAllVersion,
    createNewVersion,
    removeVersion,
    updateCurrentVersion,
    fetchOneVersion,
    getCurrentVersion
  }
}