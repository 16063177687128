import { VersionResponsePayload, VersionTypes, VersionResponse, VersionDeleteResponse } from '../../types/version.type'


export function findAllVersion(payload: VersionResponsePayload) {
  return {
    type: VersionTypes.FIND_ALL_VERSION,
    payload
  }
}

export function findOneVersion(payload: VersionResponse) {
  return {
    type: VersionTypes.FIND_ONE_VERSION,
    payload
  }
}

export function createVersion(payload: VersionResponse) {
  return {
    type: VersionTypes.CREATE_VERSION,
    payload
  }
}

export function updateVersion(payload: VersionResponse) {
  return {
    type: VersionTypes.UPDATE_VERSION,
    payload
  }
}

export function deleteVersion(payload: VersionDeleteResponse) {
  return {
    type: VersionTypes.DELETE_VERSION,
    payload
  }
}

export function setLoadingVersion(payload: boolean) {
  return {
    type: VersionTypes.SET_LOADING_VERSION,
    payload
  }
}

export function setErrorVersion(payload: any) {
  return {
    type: VersionTypes.SET_ERROR_VERSION,
    payload
  }
}

export function currentVersion(payload: any) {
  return {
    type: VersionTypes.CURRENT_VERSION,
    payload
  }
}