import { Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core'
import React from 'react'
import { SummaryProductImages } from '../../types/b2b/order/delivery-order.type'
import COLORS from '../../styles/colors';
import { CardMedia } from '@material-ui/core';
import { X } from 'react-feather';

interface Props {
  podImages: SummaryProductImages[]
  isShow: boolean
  handleCloseDialog: () => void
  title: string
}

const PODImagePreview: React.FC<Props> = ({ podImages, isShow, handleCloseDialog, title }) => {

  const [selectedImage, setSelectedImage] = React.useState(0)

  const handleClickImage = (idx: number) => {
    setSelectedImage(idx)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isShow}
      onClose={handleCloseDialog}
      scroll={'body'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography variant="h4">{title}</Typography>
          <div onClick={handleCloseDialog} style={{ cursor: 'pointer' }}>
            <X />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{
          width: '100%',
          height: 600,
          backgroundColor: COLORS.grey,
          borderRadius: 10,
          marginBottom: 10,
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative'
        }}>
          <div style={{
            borderRadius: 100,
            padding: '1px 15px',
            backgroundColor: 'rgba(0,0,0,0.6)',
            color: 'white',
            position: 'absolute',
            top: 10,
            right: 10,
          }}>
            <b>{selectedImage + 1} / {podImages.length}</b>
          </div>
          <img
            src={podImages[selectedImage].imageLink}
            style={{
              width: 'auto',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </div>

        <div style={{
          width: '100%',
          overflowX: 'auto',
          height: 100,
          display: 'flex',
          alignItems: 'center',
          marginBottom: 20
        }}>
          {podImages.map((item, idx) => (
            <div
              key={idx}
              style={{
                cursor: 'pointer',
                marginRight: 10,
                width: 100,
                height: '100%',
              }}
            >
              <img
                onClick={() => handleClickImage(idx)}
                src={item.imageLink}
                key={idx}
                style={{
                  border: idx === selectedImage ? '2px solid #000' : '2px solid #eaeaea',
                  width: 100,
                  height: 100,
                  objectFit: 'cover',
                  borderRadius: 10,
                }}
              />
            </div>
          ))}
        </div>

      </DialogContent>
    </Dialog>
  )
}

export default PODImagePreview