import { IconProps } from './IconProps';

export default function Settings(props: IconProps) {
  return (
    <svg width={props.size || 24}
      height={props.size || 24} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M4.81836 21.2158V14.2158" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.81836 10.2158V3.21582" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.8184 21.2158V12.2158" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.8184 8.21582V3.21582" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.8184 21.2158V16.2158" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.8184 12.2158V3.21582" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.81836 14.2158H7.81836" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.81836 8.21582H15.8184" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.8184 16.2158H23.8184" stroke={props.color || '#201D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(0.818359 0.21582)" />
        </clipPath>
      </defs>
    </svg>

  );
}
