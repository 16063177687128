import {
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import React from 'react';
import COLORS from '../../styles/colors';

type Data = {
  label?: string | null;
  value?: any;
  url?: string | null;
  leftIconComponent?: any;
  onPressAction?: () => void;
};

type Props = {
  itemPerRow?: number;
  data: Array<Data>;
  showTitle?: boolean;
  styleContainer?: any;
};

export default function DetailGeneralInformation(props: Props) {
  const classes = useStyles();

  return (
    <div>
      {props.showTitle && (
        <Typography variant="h5">General Information</Typography>
      )}

      <Box
        display="flex"
        flexWrap="wrap"
        className={classes.boxRow}
        style={props.styleContainer}
      >
        {props.data.map((item, index) =>
          item.label || item.value ? (
            <Box
              display="flex"
              flexDirection="row"
              key={index}
              width={props.itemPerRow ? 100 / props.itemPerRow + '%' : '25%'}
              className={classes.boxItem}
            >
              {item.leftIconComponent ? (
                <Box width={40}>{item.leftIconComponent}</Box>
              ) : (
                  false
                )}
              <Box>
                {item.label ? (
                  <Typography variant="body1" color="textSecondary">
                    {item.label}
                  </Typography>
                ) : (
                    false
                  )}

                {item.value && item.onPressAction ? (
                  <Typography
                    variant="body1"
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => item.onPressAction && item.onPressAction()}
                  >
                    {item.value}
                  </Typography>
                ) : item.url && item.value ? (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    <Typography variant="body1">{item.value}</Typography>
                  </a>
                ) : item.value ? (
                  <Typography variant="body1">{item.value}</Typography>
                ) : (
                        <Typography
                          variant="body1"
                          style={{ fontStyle: 'italic', color: COLORS.grey }}
                        >
                          Not set
                        </Typography>
                      )
                }
              </Box>
            </Box>
          ) : (
              <Box key={index} width="25%" className={classes.boxItem} />
            )
        )}
      </Box>
    </div >
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxRow: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    boxItem: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  })
);
