import { IconProps } from "./IconProps";

export default function VoucherGenerateIcon(props: IconProps) {
  return (
    <svg width={props.size || '22'} height={props.size || '22'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 4.8125V17.1875" stroke={props.color || '#fff'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.0625 14.3679C2.06247 14.2095 2.11721 14.056 2.21743 13.9334C2.31766 13.8107 2.45721 13.7265 2.61243 13.695C3.23353 13.5681 3.79175 13.2306 4.19264 12.7395C4.59353 12.2484 4.8125 11.6339 4.8125 11C4.8125 10.3661 4.59353 9.75158 4.19264 9.26051C3.79175 8.76943 3.23353 8.43189 2.61243 8.305C2.45721 8.27349 2.31766 8.18928 2.21743 8.06664C2.11721 7.944 2.06247 7.79047 2.0625 7.63209V5.5C2.0625 5.31766 2.13493 5.1428 2.26386 5.01386C2.3928 4.88493 2.56766 4.8125 2.75 4.8125H19.25C19.4323 4.8125 19.6072 4.88493 19.7361 5.01386C19.8651 5.1428 19.9375 5.31766 19.9375 5.5V7.63209C19.9375 7.79047 19.8828 7.944 19.7826 8.06664C19.6823 8.18928 19.5428 8.27349 19.3876 8.305C18.7665 8.43189 18.2083 8.76943 17.8074 9.26051C17.4065 9.75159 17.1875 10.3661 17.1875 11C17.1875 11.6339 17.4065 12.2484 17.8074 12.7395C18.2083 13.2306 18.7665 13.5681 19.3876 13.695C19.5428 13.7265 19.6823 13.8107 19.7826 13.9334C19.8828 14.056 19.9375 14.2095 19.9375 14.3679V16.5C19.9375 16.6823 19.8651 16.8572 19.7361 16.9861C19.6072 17.1151 19.4323 17.1875 19.25 17.1875H2.75C2.56766 17.1875 2.3928 17.1151 2.26386 16.9861C2.13493 16.8572 2.0625 16.6823 2.0625 16.5V14.3679Z" stroke={props.color || '#fff'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
