import { useDispatch } from 'react-redux'
import { apiCreateAdmin, apiFindAllAdmin, apiFindOneAdmin, apiUpdateAdmin } from '../api/admin'
import { findAllAdmin, findOneAdmin, setLoadingAdmin } from '../redux/action/admin.action'
import { AdminSingle } from '../types/admin.type'
import { useHistory } from 'react-router-dom';
import { generateQueryString } from '../helpers/functions/generateQueryString';

export function useAdmin() {
  const dispatch = useDispatch()

  const history = useHistory()

  const fetchAllAdmin = async (params?: any) => {
    try {
      dispatch(setLoadingAdmin(true))

      let query = generateQueryString(params)

      const result = await apiFindAllAdmin(query)

      dispatch(findAllAdmin(result.data))

      dispatch(setLoadingAdmin(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingAdmin(false))
    }
  }

  const fetchOneAdmin = async (adminId: string) => {
    try {
      dispatch(setLoadingAdmin(true))

      const result = await apiFindOneAdmin(adminId)

      dispatch(findOneAdmin(result.data))

      dispatch(setLoadingAdmin(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingAdmin(false))
    }
  }

  const createAdmin = async (data: AdminSingle) => {
    try {
      dispatch(setLoadingAdmin(true))

      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (key === 'updatedImage') {
          formData.append('image', data[key] as any);
        } else {
          formData.append(key, String((data as any)[key]) as any);
        }
      })
      const result = await apiCreateAdmin(formData)

      if (result.data && result.data.payload) {
        history.go(-1)
      }

      dispatch(setLoadingAdmin(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingAdmin(false))
    }
  }

  const updateAdmin = async (data: AdminSingle) => {
    try {
      dispatch(setLoadingAdmin(true))

      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === 'updatedImage') {
          formData.append('image', data[key] as any);
        } else {
          formData.append(key, String((data as any)[key]) as any);
        }
      });
      const result = await apiUpdateAdmin(data.adminId as string, formData)

      if (result.data && result.data.payload) {
        history.go(-1)
      }

      dispatch(setLoadingAdmin(false))
    } catch (error) {
      console.info(error)
      dispatch(setLoadingAdmin(false))
    }
  }

  return {
    fetchAllAdmin,
    fetchOneAdmin,
    createAdmin,
    updateAdmin
  }
}