import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import CustomizedTable, {
  ITableHeader,
} from "../../components/Table/CustomizedTable";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../styles/globalStyles";
import AppButton from "../../components/Form/AppButton";
import HeaderPage from "../../components/Layout/HeaderPage";
import Search from "../../components/Form/Search";
import useFeaturedProduct from '../../hooks/useFeaturedProduct';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { ApplicationState } from '../../redux/store';
import { findOneFeaturedProduct } from '../../redux/action/featured-product';
import { generateQueryString } from '../../helpers/functions/generateQueryString';
import { setLatestParams, enableUpdateParams } from '../../redux/action/config-app.action';
import { useLocation } from 'react-router-dom';

const headers: ITableHeader[] = [
  {
    title: "productId",
    column: "productId",
    type: "id",
  },
  {
    title: "Image",
    sortable: true,
    column: "featuredImageUrl",
    type: "image",
    width: "10%",
    imageSize: "small-landscape",
    imageResolution: '1200x600'
  },
  {
    title: "SKU",
    sortable: true,
    column: "sku",
  },
  {
    title: "Name",
    sortable: true,
    column: "productName",
  },
  {
    title: "Retail Price",
    type: "nested-price",
    sortable: true,
    column: JSON.stringify(['price', 'retailPrice']),
  },
  {
    title: "Status",
    sortable: true,
    column: "featuredPublished",
    type: 'status-boolean'
  },
];

export default function FeaturedProduct() {
  const globalClasses = useGlobalStyles();

  const dispatch = useDispatch()

  const featuredProductState = useSelector((state: ApplicationState) => state.featuredProduct)

  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState<string | null>(null);
  const [query, setQuery] = useState({ limit: 25, offset: 0, search: '' });

  const { fetchAllFeaturedProduct, removeFeaturedProduct } = useFeaturedProduct()
  const debouncedTitle = useDebounce(search, 300);

  const location = useLocation();

  const { updateParams, latestParams } = useSelector((state: ApplicationState) => state.configApp)

  useEffect(() => {
    searchBank(search)
  }, [debouncedTitle]);

  const searchBank = async (value: any) => {
    if (value !== null && value.length > 0) {
      setQuery((oldVal: any) => {
        return {
          ...oldVal,
          limit: 25,
          offset: 0,
          search: value
        }
      })
    } else if (value !== null) {
      setQuery({ limit: 25, offset: 0, search: '' })
    }
  }

  useEffect(() => {
    if (updateParams) {
      dispatch(setLatestParams({
        pathname: location.pathname,
        params: generateQueryString(query),
        query: {
          limit: query['limit'],
          offset: query['offset'],
          page
        }
      }))
      fetchAllFeaturedProduct(query)
    } else {
      setPage(latestParams.query.page || page || 1)
      fetchAllFeaturedProduct(latestParams.query)
      dispatch(enableUpdateParams(true))
    }
  }, [query])

  useEffect(() => {
    setTotalData(featuredProductState.data.payload.count)
    if (featuredProductState.data.payload.count > 0) {
      setTotalPage(Math.ceil(featuredProductState.data.payload.count / rowsPerPage))
    } else {
      setTotalPage(1)
    }
  }, [featuredProductState.data])

  function changePagination(value: number) {
    setPage(value)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: (value - 1) * rowsPerPage,
        limit: rowsPerPage
      }
    })
  }

  function changeRowsPerPage(value: number) {
    setRowsPerPage(value)
    setPage(1)
    setQuery((oldVal: any) => {
      return {
        ...oldVal,
        offset: 0,
        limit: value
      }
    })
  }


  return (
    <Layout title="Featured Product" loading={featuredProductState.isLoadingFeaturedProduct}>
      <HeaderPage
        title="Featured Product"
        subtitle="List of all featured product"
      />

      <Grid container spacing={3} justify="space-between" alignItems="center">
        <Grid item xs={12} md={12} lg={7}>
          <Link
            to={{ pathname: "create-featured-product" }}
            className={globalClasses.link}
          >
            <AppButton
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Add Featured Product
            </AppButton>
          </Link>
        </Grid>
        {/* <Grid item xs={12} md={6} lg={2}>
          <FormControl className={classes.formStatus}>
            <InputLabel id="select-status" required>
              Status
            </InputLabel>
            <Select
              required
              label="Status"
              placeholder="Status"
              labelId="select-status"
              id="demo-customized-select"
              value={filterStatus}
              onChange={(event: any) => {
                setFilterStatus(event.target.value);
              }}
            >
              <MenuItem value="1">Published</MenuItem>
              <MenuItem value="0">Unpublished</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={6} lg={3}>
          <Search
            placeholder="Search by product name, SKU"
            onChange={(value: string) => setSearch(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <CustomizedTable
            data={featuredProductState.data.payload.results}
            headers={headers}
            totalData={totalData}
            showAction={["detail", "edit", "delete"]}
            page={page}
            totalPage={totalPage}
            rowsPerPage={rowsPerPage}
            onPressDetail={(data: any) => {
              dispatch(findOneFeaturedProduct(data))
            }}
            onPressEdit={(data: any) => {
              dispatch(findOneFeaturedProduct(data))
            }}
            onChange={(newPage) => changePagination(newPage)}
            onRowsPerPageChange={(value: any) => changeRowsPerPage(value)}
            onDeleteItem={(item: any) => removeFeaturedProduct(item.productId)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStatus: {
      width: "100%",
      margin: theme.spacing(1, 0, 2, 0),
    },
  })
);
